import { RadioLiveInfo } from '@app/core/models/Radio';

const tag = '[LiveInfo]';

export class LiveInfoRequest {
  static readonly type = `${tag} request live infos`;
}

export class LiveInfoSuccess {
  static readonly type = `${tag} success live infos`;
  constructor(public liveInfos: RadioLiveInfo) {}
}

export class LiveInfoFailure {
  static readonly type = `${tag} error live infos`;
  constructor(public error: Error) {}
}
