<div
  class="rounded-full overflow-hidden flex"
  [style.width.px]="size"
  [style.height.px]="size"
>
  <img *ngIf="image; else name" [src]="image" [alt]="name" />
  <ng-template #name>
    <div
      [style.background-color]="getRandomColor()"
      [style.line-height.px]="size"
      class="flex flex-1 items-center justify-center text-white"
    >
      {{ getName() }}
    </div>
  </ng-template>
</div>
