import { StateOperator } from '@ngxs/store';

export function deleteKey<T>(key: string): StateOperator<{ [key: string]: T }> {
  return inputValue => {
    const newVal = { ...inputValue };
    delete newVal[key];
    if (Object.keys(newVal).length === Object.keys(inputValue).length) {
      return inputValue;
    }

    return newVal;
  };
}
