<div
  #container
  class="main flex flex-col"
  [ngClass]="{ full: fullWidth }"
  [style.height.px]="windowHeight"
  (window:resize)="getWindowHeight()"
>
  <rk-mobile-side-header (closed)="cancelled.emit()"></rk-mobile-side-header>

  <div
    class="content flex-1"
    [ngClass]="{ 'no-scroll': !enableScrollbar }"
    rkPerfectScrollbar
    #mainContent
  >
    <ng-content></ng-content>
  </div>
</div>
