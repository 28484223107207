import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LogModal } from '@app/core/states/events-tracking.actions';
import { EventProperties } from '@app/core/states/events-tracking.state';
import { LiveTrackingState } from '@app/core/states/live-tracking.state';
import { AMPLITUDE_EVENT } from '@app/shared/amplitude-events';
import { UpgradeOfferModalComponent } from '@app/shared/modals/upgrade-offer/components/upgrade-offer-modal/upgrade-offer-modal.component';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Store } from '@ngxs/store';

@Component({
  selector: 'rk-upgrade-discover-modal',
  templateUrl: './upgrade-discover-modal.component.html',
  styleUrls: ['./upgrade-discover-modal.component.scss'],
})
export class UpgradeDiscoverModalComponent implements OnInit {
  constructor(
    public readonly dialogRef: MatDialogRef<UpgradeDiscoverModalComponent>,
    private readonly dialog: MatDialog,
    private readonly store: Store,
  ) {}

  ngOnInit() {
    this.logModal({
      name:
        this.store.selectSnapshot(LiveTrackingState.planDesc) === 'Discover'
          ? AMPLITUDE_EVENT.MODAL.DISCOVER_INFO
          : AMPLITUDE_EVENT.MODAL.DEMO_INFO,
    });
  }

  openUpgradeModal() {
    this.dialog.open(UpgradeOfferModalComponent, { data: 'discover_modal' });
    this.onCancel();
  }
  onCancel() {
    this.dialogRef.close(false);
  }

  @Dispatch()
  logModal = (properties: EventProperties) => new LogModal(properties);
}
