import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { merge, Observable } from 'rxjs';
import { mapTo, take } from 'rxjs/operators';

import {
  EmptyActionForGuard,
  FailTryActionUnlessPanelIsBlocking,
  TryActionUnlessPanelIsBlocking,
} from '../states/panels.actions';
import { PanelsState } from '../states/panels.state';

@Injectable({
  providedIn: 'root',
})
export class CanDeactivatePanelGuard {
  constructor(private readonly store: Store, private readonly actions$: Actions) {}

  canDeactivate(
    component: any,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    const isPanelOpened = this.store.selectSnapshot(PanelsState.panelOpened);
    if (!isPanelOpened) {
      return true;
    }

    const ret = merge(
      this.actions$.pipe(ofActionDispatched(EmptyActionForGuard), take(1), mapTo(true)),
      this.actions$.pipe(
        ofActionDispatched(FailTryActionUnlessPanelIsBlocking),
        take(1),
        mapTo(false),
      ),
    )
      .pipe(take(1))
      .toPromise();
    this.store.dispatch(new TryActionUnlessPanelIsBlocking(new EmptyActionForGuard()));

    return ret;
  }
}
