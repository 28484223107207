import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';

import {
  CloseCurrentPanel,
  DisableAnimation,
  EnableAnimation,
  OpenSpecificPanel,
  SetPanelActive,
  SetPanelUnactivated,
  ToogleSidenavOpacity,
  TryActionUnlessPanelIsBlocking,
} from './panels.actions';

export class PanelsStateModel {
  shownPanel: string;
  panelOpened: boolean;
  closeAnimation: boolean;
  lessOpacity: boolean;
}

@State<PanelsStateModel>({
  name: 'panels',
  defaults: {
    shownPanel: '',
    panelOpened: false,
    closeAnimation: false,
    lessOpacity: false,
  },
})
@Injectable()
export class PanelsState {
  @Selector()
  static currentPanel(state: PanelsStateModel): string {
    return state.shownPanel;
  }

  @Selector()
  static closeAnimation(state: PanelsStateModel): boolean {
    return state.closeAnimation;
  }

  @Selector()
  static panelOpened(state: PanelsStateModel): boolean {
    return state.panelOpened;
  }

  @Selector()
  static lessOpacity(state: PanelsStateModel): boolean {
    return state.lessOpacity;
  }

  @Action(OpenSpecificPanel)
  openSpecificPanel(
    ctx: StateContext<PanelsStateModel>,
    { name, actionsAfter }: OpenSpecificPanel,
  ) {
    ctx.patchState({ shownPanel: name, closeAnimation: false });
    if (actionsAfter) {
      ctx.dispatch(actionsAfter);
    }
  }

  @Action(CloseCurrentPanel)
  closeCurrentPanel(ctx: StateContext<PanelsStateModel>, { animate }: CloseCurrentPanel) {
    if (animate) {
      ctx.patchState({ shownPanel: '', closeAnimation: animate });

      return;
    }
    ctx.patchState({ shownPanel: '' });
  }

  @Action(DisableAnimation)
  disableAnimation(ctx: StateContext<PanelsStateModel>) {
    ctx.patchState({ closeAnimation: false });
  }

  @Action(EnableAnimation)
  enableAnimation(ctx: StateContext<PanelsStateModel>) {
    ctx.patchState({ closeAnimation: true });
  }

  @Action(SetPanelActive)
  panelActive(ctx: StateContext<PanelsStateModel>, {}: SetPanelActive) {
    ctx.patchState({ panelOpened: true });
  }

  @Action(SetPanelUnactivated)
  panelUnactive(ctx: StateContext<PanelsStateModel>, {}: SetPanelUnactivated) {
    ctx.patchState({ panelOpened: false });
  }

  @Action(TryActionUnlessPanelIsBlocking)
  tryThing(
    ctx: StateContext<PanelsStateModel>,
    { action }: TryActionUnlessPanelIsBlocking,
  ): Observable<void> | void {
    if (!ctx.getState().panelOpened) {
      return ctx.dispatch(action);
    }
  }

  @Action(ToogleSidenavOpacity)
  toogleSidenavOpacity(
    ctx: StateContext<PanelsStateModel>,
    { lessOpacity }: ToogleSidenavOpacity,
  ): Observable<void> | void {
    ctx.patchState({ lessOpacity });
  }
}
