import { Pipe, PipeTransform } from '@angular/core';
import { exportSecMin } from '@app/shared/utils';
import { padStart } from 'lodash';

@Pipe({
  name: 'timeFormat',
})
export class TimeFormatPipe implements PipeTransform {
  transform(value: number, forceHour = false): string {
    const { minutes, hours, seconds } = exportSecMin(value);

    const paddedSeconds = padStart(`${seconds}`, 2, '0');
    const paddedMinutes = padStart(`${minutes}`, 2, '0');
    const paddedHours = padStart(`${hours}`, 2, '0');
    if (hours || forceHour) {
      return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
    }

    return `${paddedMinutes}:${paddedSeconds}`;
  }
}
