import { Genre } from '@app/core/models/Genre';

const tag = '[Genre]';

export class GenresRequest {
  static readonly type = `${tag} request genres`;
}

export class GenresSuccess {
  static readonly type = `${tag} success genres`;
  constructor(public genres: Genre[]) {}
}

export class GenresFailure {
  static readonly type = `${tag} error genres`;
  constructor(public error: Error) {}
}
