import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'rk-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  @Input()
  image: string;

  @Input()
  name: string;

  @Input()
  size = 50;

  getName(): string {
    return this.name
      ? this.name
          .split(' ')
          .map(word => word.charAt(0))
          .join('')
          .substring(0, 3)
          .toUpperCase()
      : '';
  }

  defaultColors = [
    '#1abc9c',
    '#3498db',
    '#f1c40f',
    '#8e44ad',
    '#e74c3c',
    '#d35400',
    '#2c3e50',
    '#7f8c8d',
  ];

  getRandomColor(): string {
    if (!this.name) {
      return 'transparent';
    }
    const asciiCodeSum = this.calculateAsciiCode(this.name);
    return this.defaultColors[asciiCodeSum % this.defaultColors.length];
  }

  calculateAsciiCode(value: string) {
    return value
      .split('')
      .map(letter => letter.charCodeAt(0))
      .reduce((previous, current) => previous + current);
  }
}
