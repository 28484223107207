import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ShortRadio } from '@app/core/models/Radio';
import { LogModal } from '@app/core/states/events-tracking.actions';
import { SwitchToRadioRequest } from '@app/core/states/radio.actions';
import { RadioState } from '@app/core/states/radio.state';
import { UploadState } from '@app/core/states/upload.state';
import { AMPLITUDE_EVENT } from '@app/shared/amplitude-events';
import { Select, Store } from '@ngxs/store';
import { TryActionUnlessPanelIsBlocking } from '@radioking/shared/pannel-manager';
import { slideInOutBlock } from '@radioking/ui/animations';
import { Observable } from 'rxjs';

@Component({
  selector: 'rk-change-radio-modal',
  templateUrl: './change-radio-modal.component.html',
  styleUrls: ['./change-radio-modal.component.scss'],
  animations: [slideInOutBlock],
})
export class ChangeRadioModalComponent implements OnInit {
  @Select(RadioState.radios)
  radios$: Observable<ShortRadio[]>;

  @Select(RadioState.currentRadioId)
  currentRadioId$: Observable<number>;

  @Select(UploadState.remainingFiles)
  filesUploading$: Observable<boolean>;

  constructor(
    public readonly dialogRef: MatDialogRef<ChangeRadioModalComponent>,
    private readonly store: Store,
  ) {}

  ngOnInit() {
    this.store.dispatch(
      new LogModal({ name: AMPLITUDE_EVENT.MODAL.CHANGE_RADIO, origin: 'menu' }),
    );
  }

  goToRadio(radioId: number) {
    this.store.dispatch(
      new TryActionUnlessPanelIsBlocking(new SwitchToRadioRequest(radioId)),
    );
    this.dialogRef.close();
  }

  onCancel() {
    this.dialogRef.close(false);
  }
}
