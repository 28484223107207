export interface SettingRadio {
  name: string;
  slogan: string;
  slug?: string;
  description: string;
  website: string;
  language: string;
  genres: string[];
  country: string;
  timezone: string;
  logo?: string;
}

export enum NormType {
  STANDARD = 'standard',
  ADVANCED = 'advanced',
}

export interface SettingBroadcast {
  titleRepeat: number;
  artistRepeat: number;
  albumRepeat: number;
  fadeoutDefault: number;
  mixpointAuto: boolean;
  mixpointThreshold: number;
  normType: NormType;
  normThreshold?: number;
  normEBU?: number;
}

export interface SettingStream {
  aacStream: boolean;
  aacQuality: number;
  aacId?: number;
  mp3Stream: boolean;
  mp3Quality: number;
  mp3Id?: number;
}

export interface Cover {
  key: 'rk' | 'string';
  isDisabled: boolean;
  title: 'string';
}

export interface SettingCoverAndLive {
  coverDefault?: string;
  coverOrder: Cover[];
  forcedTitle: boolean;
  artist: string;
  title: string;
  buyLink: string;
  coverLive?: string;
}

export interface SettingDirectory {
  weloveradio?: boolean;
  orange: boolean;
  icecast?: boolean;
  radioline: boolean;
  sonos?: boolean;
}

export interface SettingSocial {
  facebookUrl: string;
  xUsername?: string;
  itunesAffiliateId: string;
}

export interface SettingSecurity {
  autoDisconnectionTimer: number;
  geoblockEnabled: boolean;
  geoblockMode: 'allow' | 'deny';
  geoblockCountries: string[];
  publicListeners: boolean;
  ipEnabled: boolean;
  ipMode: 'allow' | 'deny';
  ipList: string[];
}

export interface SettingMisc {
  weeklyReport: boolean;
  mixpointsTransitionTrack?: number;
}
