import { IconProp } from '../components/core/radio-page/components/icon-search/icon-list';

export interface Smartlink {
  enabled: boolean;
}

export interface ShareLink {
  format: string;
  bitrate: number;
  url: string;
  type: string;
}

export interface PlayerWidget {
  mainColor: string;
  contentColor: string;
  format: string;
  sizeUnit: string;
  height: number;
  width: number;
  displayCover: boolean;
  displaySharing: boolean;
  displayBuy: boolean;
  displayLike: boolean;
  displayListenersCount: boolean;
  displayPopUp: boolean;
  displayShadow: boolean;
}

export interface PlayedTracksWidget {
  count: number;
  displayDate: boolean;
  displayBuy: boolean;
}

export interface TopTracksWidget {
  count: number;
  displayBuy: boolean;
}

export interface CurrentTrackWidget {
  displayBuy: boolean;
}

export interface NextTrackWidget {
  displayBuy: boolean;
}

export interface RadioPageLink {
  icon?: IconProp;
  label: string;
  url: string;
}

interface RadioPagePlayer {
  displayCover: boolean;
  displaySharing: boolean;
  displayBuy: boolean;
  displayLike: boolean;
  displayListenersCount: boolean;
  displayPopUp: boolean;
  displayShadow: boolean;
}

interface RadioPageDesign {
  display: 'center' | 'side';
  primaryColor: string;
  secondaryColor: string;
  displayLogo: boolean;
  displayName: boolean;
  displaySlogan: boolean;
  background: {
    type: 'color' | 'gradient' | 'image';
    color1?: string;
    color2?: string;
    imageUrl?: string;
  };
}

export interface RadioPage {
  enabled: boolean;
  pageUrl?: string;
  design: RadioPageDesign;
  links: RadioPageLink[];
  player: RadioPagePlayer;
}

export interface RadioPageApi {
  enabled: boolean;
  page_url: string;
  design: {
    display: 'center' | 'side';
    primary_color: string;
    secondary_color: string;
    background: {
      type: 'color' | 'gradient' | 'image';
      color1: string;
      color2: string;
      image_url: string;
    };
    display_logo: boolean;
    display_name: boolean;
    display_slogan: boolean;
  };
  links: {
    label: string;
    link_url: string;
    icon: {
      name: string;
      category: string;
    };
  }[];
  player: {
    enable_popup: boolean;
    enable_like: boolean;
    enable_cover: boolean;
    enable_social_share: boolean;
    enable_shadow: boolean;
    enable_listener_count: boolean;
    enable_buy_link: boolean;
  };
}

export function convertToRadioPage(rpApi: RadioPageApi): RadioPage {
  return {
    enabled: rpApi.enabled,
    pageUrl: rpApi.page_url,
    design: {
      background: {
        type: rpApi.design.background.type,
        color1: rpApi.design.background.color1,
        color2: rpApi.design.background.color2,
        imageUrl: rpApi.design.background.image_url,
      },
      display: rpApi.design.display,
      displayLogo: rpApi.design.display_logo,
      displayName: rpApi.design.display_name,
      displaySlogan: rpApi.design.display_slogan,
      primaryColor: rpApi.design.primary_color,
      secondaryColor: rpApi.design.secondary_color,
    },
    player: {
      displayCover: rpApi.player.enable_cover,
      displaySharing: rpApi.player.enable_social_share,
      displayBuy: rpApi.player.enable_buy_link,
      displayLike: rpApi.player.enable_like,
      displayListenersCount: rpApi.player.enable_listener_count,
      displayPopUp: rpApi.player.enable_popup,
      displayShadow: rpApi.player.enable_shadow,
    },
    links: rpApi.links.map(l => {
      return {
        icon: l.icon
          ? {
              category: l.icon.category,
              name: l.icon.name,
            }
          : null,
        label: l.label,
        url: l.link_url,
      };
    }),
  };
}

export interface RadioPagePutData {
  design: {
    background: { color1: string; color2: string; type: string };
    display: string;
    secondary_color: string;
    display_logo: boolean;
    primary_color: string;
    display_name: boolean;
    display_slogan: boolean;
  };
  links: { icon: { name: string; category: string }; link_url: string; label: string }[];
  enabled: boolean;
  player: {
    enable_popup: boolean;
    enable_like: boolean;
    enable_cover: boolean;
    enable_social_share: boolean;
    enable_shadow: boolean;
    enable_listener_count: boolean;
    enable_buy_link: boolean;
  };
}

export function convertToRadioPageApi(rp: RadioPage): RadioPagePutData {
  return {
    enabled: rp.enabled,
    design: {
      background: {
        type: rp.design.background.type,
        color1: rp.design.background.color1,
        color2: rp.design.background.color2,
      },
      display: rp.design.display,
      display_logo: rp.design.displayLogo,
      display_name: rp.design.displayName,
      display_slogan: rp.design.displaySlogan,
      primary_color: rp.design.primaryColor,
      secondary_color: rp.design.secondaryColor,
    },
    player: {
      enable_cover: rp.player.displayCover,
      enable_social_share: rp.player.displaySharing,
      enable_buy_link: rp.player.displayBuy,
      enable_like: rp.player.displayLike,
      enable_listener_count: rp.player.displayListenersCount,
      enable_popup: rp.player.displayPopUp,
      enable_shadow: rp.player.displayShadow,
    },
    links: rp.links.map(l => {
      return {
        icon: l.icon
          ? {
              category: l.icon.category,
              name: l.icon.name,
            }
          : null,
        label: l.label,
        link_url: l.url,
      };
    }),
  };
}
