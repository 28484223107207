import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { UiDatepickerModule } from '@radioking/ui/datepicker';
import { UiInputModule } from '@radioking/ui/input';
import { DateSelectComponent } from './components/date-select/date-select.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UiInputModule,
    MatIconModule,
    UiDatepickerModule,
  ],
  declarations: [DateSelectComponent],
  exports: [DateSelectComponent],
})
export class SchedulerCoreModule {}
