import {
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { replaceAccents } from '@app/shared/utils';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

import { SugestionInput } from '../../models/sugestion-input.model';

const selectAllSuggestion: SugestionInput = {
  id: -10, // Must be unique
  name: 'SELECT_ALL',
  translation: marker('CHIPS.SELECT_ALL'),
};

@Component({
  selector: 'rk-chips-input',
  templateUrl: './chips-input.component.html',
  styleUrls: ['./chips-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipsInputComponent),
      multi: true,
    },
  ],
})
export class ChipsInputComponent implements ControlValueAccessor {
  @Input()
  label: string;

  @Input()
  addNewCaseSensitive = false;

  @Input()
  debounceTime = 0;

  @Input()
  minLengthTrigger = 0;

  @Input()
  isLoadingData = false;

  @Input()
  modal = false;

  @Input()
  isTag = false;

  @Input()
  hint: string;

  private _data: SugestionInput[];

  @Input()
  set dataFetched(data: SugestionInput[]) {
    this._data = this._withSelectAll ? [selectAllSuggestion, ...data] : data;
    this.storeFiltered();
    if (this.inputVal) {
      this.searchData(this.inputVal);
    } else {
      this.filteredData = this._data;
    }
  }

  @Input()
  set withSelectAll(choice: boolean) {
    if (choice === this._withSelectAll) {
      return;
    }

    this._withSelectAll = choice;
    this.dataFetched = this._data.filter(v => !this.isSelectAllSuggestion(v));
  }
  private _withSelectAll = false;

  filteredData: SugestionInput[] = [];

  inputVal: string;

  get data() {
    return this._data;
  }

  @Input()
  canAddNew = true;

  @Input()
  shouldFilterWithData = true;

  @Input()
  placeholder: string;

  @Input()
  maxChips: number;

  @Input()
  chipMaxLength = 524288;

  @Output()
  readonly submitted = new EventEmitter<SugestionInput>();

  @Output()
  readonly changed = new EventEmitter<string>();

  @HostBinding('class.disabled')
  isDisabled = false;

  chips: SugestionInput[] = [];

  dataWithoutSeleced: SugestionInput[] = [];

  searchData(val: string) {
    this.inputVal = val;
    this.filteredData = this.dataWithoutSeleced.filter(data => {
      if (this.isSelectAllSuggestion(data)) {
        return val === '';
      }

      return replaceAccents(data.name.toLowerCase()).includes(
        replaceAccents(val.toLowerCase()),
      );
    });
    this.changed.emit(val);
  }

  storeFiltered() {
    this.dataWithoutSeleced = this.data.filter(
      data => !this.chips.map(chip => chip.id).includes(data.id),
    );

    if (this._withSelectAll && this.dataWithoutSeleced.length === 1) {
      this.dataWithoutSeleced = [];
    }
  }

  canAddNewChips() {
    if (this.maxChips && this.maxChips > 0) {
      return this.chips.length < this.maxChips;
    }

    return true;
  }

  propagateChange = (_: any) => {};

  addTag(txt: SugestionInput) {
    // Select all
    if (this.isSelectAllSuggestion(txt)) {
      this.chips = this.data.filter(v => !this.isSelectAllSuggestion(v));
    } else {
      this.chips = [...this.chips, txt];

      if (this.addNewCaseSensitive) {
        this.chips = this.chips.filter(
          (x: SugestionInput, i: number, a: SugestionInput[]) =>
            a.findIndex(item => item.name === x.name) === i,
        );
      } else {
        this.chips = this.chips.filter(
          (x: SugestionInput, i: number, a: SugestionInput[]) =>
            a.findIndex(item => item.name.toLowerCase() === x.name.toLowerCase()) === i,
        );
      }
    }

    this.propagateChange(this.chips);
    this.storeFiltered();
    this.filteredData = this.dataWithoutSeleced;
    this.inputVal = ''; // Because <rk-input-with-suggestions>.shouldClearOnSubmit is true
  }

  removeTag(tag: SugestionInput) {
    this.chips = this.chips.filter(val => val !== tag);
    this.propagateChange(this.chips);
    this.storeFiltered();
    this.searchData(this.inputVal || '');
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      if (Array.isArray(value)) {
        this.chips = value;
      } else {
        this.chips = [value];
      }
    }
    this.storeFiltered();
    this.filteredData = this.dataWithoutSeleced;
  }

  isSelectAllSuggestion(data: SugestionInput): boolean {
    return data.id === selectAllSuggestion.id;
  }
}
