import { Component, OnDestroy, OnInit } from '@angular/core';
import { ClearUploadList, UploadFileList } from '@app/core/states/upload.actions';
import { UploadState } from '@app/core/states/upload.state';
import { Actions, ofActionDispatched, Select, Store } from '@ngxs/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'rk-header-upload',
  templateUrl: './header-upload.component.html',
  styleUrls: ['./header-upload.component.scss'],
})
export class HeaderUploadComponent implements OnInit, OnDestroy {
  @Select(UploadState.uploads)
  currentUploads$: Observable<any>;

  @Select(UploadState.uploadPercentageGroup)
  uploadPercentage$: Observable<number>;

  @Select(UploadState.uploadsArray)
  uploadsArray$: Observable<any[]>;

  @Select(UploadState.hasUploads)
  hasUploads$: Observable<boolean>;

  @Select(UploadState.hasErrors)
  hasErrors$: Observable<boolean>;

  @Select(UploadState.allCompleted)
  complete$: Observable<boolean>;

  minHeight = '0px';

  showUploadsList = false;

  subscription = new Subscription();

  constructor(private readonly store: Store, private readonly action$: Actions) {}

  ngOnInit() {
    this.action$.pipe(ofActionDispatched(UploadFileList)).subscribe(() => {
      this.showUploadsList = true;
    });
    this.subscription.add(
      this.uploadsArray$.pipe(map(arr => arr.length)).subscribe(num => {
        this.minHeight = `${Math.min(num * 70, 400)}px`;
      }),
    );

    this.subscription.add(
      combineLatest([this.complete$, this.hasErrors$]).subscribe(([complete, error]) => {
        if (complete && !error) {
          this.hideUploadList();
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  trackByFn(index: number, item: any) {
    return item.id;
  }

  clearUploadList() {
    this.store.dispatch(new ClearUploadList());
  }

  toggleUploadList(e: Event) {
    e.stopPropagation();
    e.preventDefault();
    this.showUploadsList = !this.showUploadsList;
  }

  hideUploadList() {
    this.showUploadsList = false;
  }
}
