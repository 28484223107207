import { RadioStatus } from '@app/core/models/Radio';
import { EventProperties } from '@app/core/states/events-tracking.state';

const tag = '[LiveTracking]';

export class StartLiveTrack {
  static readonly type = `${tag} start tracking radio`;

  constructor(public idRadio: number) {}
}

export class StopLiveTrack {
  static readonly type = `${tag} stop tracking radio`;
}

export class StartSoftLiveTrack {
  static readonly type = `${tag} start soft tracking radio`;

  constructor(public idRadio: number) {}
}

export class StopSoftLiveTrack {
  static readonly type = `${tag} stop tracking radio`;
}

export class GetPreviousTrack {
  static readonly type = `${tag} get previous track`;
}

export class RadioStatusSuccess {
  static readonly type = `${tag} success radio status`;
  constructor(
    public id: number,
    public status: RadioStatus,
  ) {}
}

export class RadioStatusFailure {
  static readonly type = `${tag} error radio status`;
  constructor(public error: Error) {}
}

export class StartTrackApollonStatus {
  static readonly type = `${tag} start track apollon status`;
  constructor(public id: number) {}
}

export class ApollonStatusNeedsRestart {
  static readonly type = `${tag} apollon status needs restart`;
}

export class DisplayApollonUpdateModal {
  static readonly type = `${tag} display apollon update modal`;
  constructor(public id: number) {}
}

export class DisplayUpgradeDiscoverModal {
  static readonly type = `${tag} display upgrade discover modal`;
  constructor(public props: EventProperties = null) {}
}
