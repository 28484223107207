<svg
  *ngIf="isDynamic()"
  [attr.width]="size"
  [attr.height]="size"
  viewBox="0 0 10 10"
  xmlns="http://www.w3.org/2000/svg"
>
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g transform="translate(-10.000000, -81.000000)" stroke="#5352ED" stroke-width="2">
      <g transform="translate(0.000000, 26.000000)">
        <g>
          <g>
            <g transform="translate(0.000000, 40.000000)">
              <g transform="translate(10.000000, 10.000000)">
                <g>
                  <g transform="translate(0.000000, 5.000000)">
                    <circle cx="5" cy="5" r="4" [attr.stroke]="playlist.color"></circle>
                    <circle cx="5" cy="5" r="1" [attr.stroke]="playlist.color"></circle>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
<svg
  *ngIf="isManual()"
  [attr.width]="size"
  [attr.height]="size"
  viewBox="0 0 10 10"
  xmlns="http://www.w3.org/2000/svg"
>
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g transform="translate(-10.000000, -42.000000)" stroke="#FF6B81" stroke-width="2">
      <g transform="translate(0.000000, 26.000000)">
        <g>
          <g>
            <g transform="translate(0.000000, 2.000000)">
              <g transform="translate(10.000000, 10.000000)">
                <g>
                  <circle cx="5" cy="9" r="4" [attr.stroke]="playlist.color"></circle>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
