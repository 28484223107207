import {
  ApollonVersion,
  Radio,
  RADIO_PLAN_DESC,
  RadioLiveStatus,
  RadioPlan,
  RadioStatus,
  RadioSubscription,
} from '@app/core/models/Radio';
import moment from 'moment-timezone';

export declare type whmcsCurrency = 'EUR' | 'GBP' | 'USD';

export interface RadioApiModel {
  name: string;
  slug: string;
  logo: string;
  timezone: string;
  created_at: string;
}

export interface UserRadioApiModel {
  idradio: number;
  logo: string;
  name: string;
  role?: 'user' | 'customer';
  status: 'active' | 'inactive' | 'deleted';
}

export interface RadioLiveBroadcasterApiModel {
  username: string;
}

export interface RadioLiveStatusApiModel {
  started_at: string;
  duration: number;
  broadcaster: RadioLiveBroadcasterApiModel;
}

export interface RadioStatusApiModel {
  status: 'active' | 'inactive' | 'deleted';
  stream_status: 'started' | 'stopped';
  can_upload: boolean;
  plan_id: number;
  plan: {
    id: number;
    desc: RADIO_PLAN_DESC;
  };
  service_id: number;
  downloadable_chronics: boolean;
  demo_remaining_days: number;
  live?: RadioLiveStatusApiModel;
}

export interface RadioSubscriptionApiModel {
  plan: RadioPlan;
  plan_id: number;
  service_id: number;
  billing: 'Annually' | 'Monthly' | 'Free Account';
  currency: whmcsCurrency;
  options: {
    radio_page: boolean;
  };
}

export function convertToRadio(radio: RadioApiModel): Radio {
  return {
    name: radio.name,
    slug: radio.slug,
    logo: radio.logo,
    timezone: radio.timezone,
    createdAt: moment(radio.created_at),
  };
}

function convertToLive(live: RadioLiveStatusApiModel): RadioLiveStatus {
  return live
    ? {
        startedAt: live.started_at,
        duration: live.duration,
        broadcaster: live.broadcaster,
      }
    : null;
}

export function convertToRadioStatus(status: RadioStatusApiModel): RadioStatus {
  return {
    status: status.status,
    streamStatus: status.stream_status,
    canUpload: status.can_upload,
    radioPlan: {
      planId: status.plan_id,
      description: status.plan.desc,
    },
    idService: status.service_id,
    downloadableChronics: status.downloadable_chronics,
    demoRemainingDays: status.demo_remaining_days,
    live: convertToLive(status.live),
  };
}

export function convertToRadioSubscription(
  subscription: RadioSubscriptionApiModel,
): RadioSubscription {
  return {
    plan: subscription.plan,
    serviceId: subscription.service_id,
    billing: subscription.billing === 'Annually' ? 'Annually' : 'Monthly',
    currency: subscription.currency ? subscription.currency : 'EUR',
    options: {
      radioPage: subscription.options.radio_page,
    },
  };
}

export interface ApollonVersionApiModel {
  current: string;
  expected: string;
  deadline: string;
}

export function convertToApollonVersion(apollon: ApollonVersionApiModel): ApollonVersion {
  return {
    current: apollon.current,
    expected: apollon.expected,
    deadline: apollon.deadline ? moment(apollon.deadline) : null,
  };
}
