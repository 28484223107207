import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { UiTooltipModule } from '@radioking/ui/tooltip';

import { QuestionMarkComponent } from './question-mark.component';

@NgModule({
  imports: [CommonModule, MatIconModule, UiTooltipModule],
  declarations: [QuestionMarkComponent],
  exports: [QuestionMarkComponent],
})
export class UiQuestionMarkModule {}
