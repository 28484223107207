const tag = '[app]';

export class UpdateAvailable {
  static readonly type = `${tag} service worker update available`;
}

export class Error500 {
  static readonly type = `${tag} error 500`;
}

export class SetOnMobile {
  static readonly type = `${tag} set on mobile`;
  constructor(public mobile: boolean) {}
}

export class SetOnTablet {
  static readonly type = `${tag} set on tablet`;
  constructor(public tablet: boolean) {}
}

export class PageNotAvailable {
  static readonly type = `${tag} page not available on mobile`;
}
