import { Injectable } from '@angular/core';
import { Playlist } from '@app/library/models/playlist.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SugestionInput } from '@radioking/ui/input';
import { isNil } from 'lodash';
import { catchError, mergeMap } from 'rxjs/operators';

import { SchedulerTrack } from '../models/scheduler-core.model';
import { SchedulerCoreService } from '../services/scheduler-core.service';

import {
  ResetAllTracks,
  SchedulerAllPlaylistsFailure,
  SchedulerAllPlaylistsRequest,
  SchedulerAllPlaylistsSuccess,
  SchedulerAllTracksFailure,
  SchedulerAllTracksRequest,
  SchedulerAllTracksSuccess,
} from './scheduler-core.actions';

export class SchedulerCoreStateModel {
  tracks: SchedulerTrack[];
  playlists: Playlist[];
}

@State<SchedulerCoreStateModel>({
  name: 'schedulerCore',
  defaults: {
    tracks: null,
    playlists: null,
  },
})
@Injectable()
export class SchedulerCoreState {
  constructor(private readonly schedulerService: SchedulerCoreService) {}

  @Selector()
  static allTracks(state: SchedulerCoreStateModel): SchedulerTrack[] {
    return state.tracks;
  }

  @Selector()
  static allTracksButChronics(state: SchedulerCoreStateModel): SchedulerTrack[] {
    return state.tracks.filter(t => t.box !== '__CHRONIC__');
  }

  @Selector()
  static hasAlreadyBeenFetched(state: SchedulerCoreStateModel): boolean {
    return state.tracks.length > 0;
  }

  @Selector()
  static trackBacDbNameUnfiltered(state: SchedulerCoreStateModel) {
    return (name: string) => {
      return state.tracks.filter(track => track.box === name);
    };
  }

  @Selector()
  static trackWithId(
    state: SchedulerCoreStateModel,
  ): (trackId: number) => SchedulerTrack {
    return trackId =>
      state.tracks?.find((track: SchedulerTrack) => track && track.id === trackId);
  }

  @Selector()
  static allPlaylistsForDropdown(state: SchedulerCoreStateModel): SugestionInput[] {
    return state.playlists.map(playlist => {
      return {
        id: playlist.id,
        name: playlist.name,
        color: playlist.color,
        type: playlist.type,
        extra: { duration: playlist.duration },
      };
    });
  }

  @Selector()
  static allNotEmptyPlaylistsForDropdown(
    state: SchedulerCoreStateModel,
  ): SugestionInput[] {
    return state.playlists
      .filter(p => p.nbTracks > 0)
      .map(playlist => {
        return {
          id: playlist.id,
          name: playlist.name,
          color: playlist.color,
          type: playlist.type,
          extra: { duration: playlist.duration },
        };
      });
  }

  @Selector()
  static firstPlaylist(state: SchedulerCoreStateModel): SugestionInput {
    if (isNil(state.playlists)) {
      return null;
    }
    const firstPlaylist = state.playlists[0];

    return {
      id: firstPlaylist.id,
      name: firstPlaylist.name,
      color: firstPlaylist.color,
      type: firstPlaylist.type,
    };
  }

  @Selector()
  static hasPlaylists(state: SchedulerCoreStateModel): boolean {
    return !!state.playlists.length;
  }

  @Selector()
  static hasNotEmptyPlaylists(state: SchedulerCoreStateModel): boolean {
    return !!state.playlists.filter(p => p.nbTracks > 0).length;
  }

  @Selector()
  static playlistWithId(
    state: SchedulerCoreStateModel,
  ): (playlistId: number) => Playlist {
    return playlistId =>
      state.playlists?.find(
        (playlist: Playlist) => playlist && playlist.id === playlistId,
      );
  }

  @Action(SchedulerAllTracksRequest)
  getAllTracks(
    ctx: StateContext<SchedulerCoreStateModel>,
    { radioId }: SchedulerAllTracksRequest,
  ) {
    return this.schedulerService.getAllTracks(radioId).pipe(
      mergeMap(data => ctx.dispatch(new SchedulerAllTracksSuccess(data))),
      catchError(err => ctx.dispatch(new SchedulerAllTracksFailure(err))),
    );
  }

  @Action(SchedulerAllTracksSuccess)
  getAllTracksSuccess(
    ctx: StateContext<SchedulerCoreStateModel>,
    { tracks }: SchedulerAllTracksSuccess,
  ) {
    const equals = (a: SchedulerTrack[], b: SchedulerTrack[]) =>
      a?.length === b?.length &&
      a.every(
        (v, i) =>
          v.id === b[i].id &&
          v.title === b[i].title &&
          v.artist === b[i].artist &&
          v.album === b[i].album,
      );
    if (!ctx.getState().tracks || !equals(ctx.getState().tracks, tracks)) {
      ctx.patchState({ tracks });
    }
  }

  @Action(SchedulerAllPlaylistsRequest)
  getAllPlaylists(
    ctx: StateContext<SchedulerCoreStateModel>,
    { radioId }: SchedulerAllPlaylistsRequest,
  ) {
    return this.schedulerService.getAllPlaylists(radioId).pipe(
      mergeMap(data => ctx.dispatch(new SchedulerAllPlaylistsSuccess(data))),
      catchError(err => ctx.dispatch(new SchedulerAllPlaylistsFailure(err))),
    );
  }

  @Action(SchedulerAllPlaylistsSuccess)
  getAllPlaylistsSuccess(
    ctx: StateContext<SchedulerCoreStateModel>,
    { playlists }: SchedulerAllPlaylistsSuccess,
  ) {
    ctx.patchState({ playlists });
  }

  @Action(ResetAllTracks)
  resetTracks(ctx: StateContext<SchedulerCoreStateModel>) {
    ctx.patchState({ tracks: null });
  }
}
