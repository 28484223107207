import { Injectable } from '@angular/core';
import { SettingService } from '@app/core/services/setting.service';
import { RadioState, RadioStateModel } from '@app/core/states/radio.state';
import {
  SettingBroadcast,
  SettingCoverAndLive,
  SettingDirectory,
  SettingMisc,
  SettingRadio,
  SettingSecurity,
  SettingSocial,
  SettingStream,
} from '@app/library/models/setting.model';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, take } from 'rxjs/operators';

import {
  CheckSlugFailure,
  CheckSlugRequest,
  CheckSlugSuccess,
  SettingBroadcastFailure,
  SettingBroadcastRequest,
  SettingBroadcastSuccess,
  SettingCoverAndLiveFailure,
  SettingCoverAndLiveRequest,
  SettingCoverAndLiveSuccess,
  SettingDirectoryFailure,
  SettingDirectoryRequest,
  SettingDirectorySuccess,
  SettingMiscFailure,
  SettingMiscRequest,
  SettingMiscSuccess,
  SettingRadioFailure,
  SettingRadioRequest,
  SettingRadioSuccess,
  SettingSecurityFailure,
  SettingSecurityRequest,
  SettingSecuritySuccess,
  SettingSocialFailure,
  SettingSocialRequest,
  SettingSocialSuccess,
  SettingStreamFailure,
  SettingStreamRequest,
  SettingStreamSuccess,
  StartupRadioFailure,
  StartupRadioRequest,
  StartupRadioSuccess,
  UpdateSettingBroadcastFailure,
  UpdateSettingBroadcastRequest,
  UpdateSettingBroadcastSuccess,
  UpdateSettingCoverAndLiveFailure,
  UpdateSettingCoverAndLiveRequest,
  UpdateSettingCoverAndLiveSuccess,
  UpdateSettingDirectoryFailure,
  UpdateSettingDirectoryRequest,
  UpdateSettingDirectorySuccess,
  UpdateSettingMiscFailure,
  UpdateSettingMiscRequest,
  UpdateSettingMiscSuccess,
  UpdateSettingRadioFailure,
  UpdateSettingRadioRequest,
  UpdateSettingRadioSuccess,
  UpdateSettingSecurityFailure,
  UpdateSettingSecurityRequest,
  UpdateSettingSecuritySuccess,
  UpdateSettingSocialFailure,
  UpdateSettingSocialRequest,
  UpdateSettingSocialSuccess,
  UpdateSettingStreamFailure,
  UpdateSettingStreamRequest,
  UpdateSettingStreamSuccess,
} from './setting.actions';

export class SettingStateModel {
  settingRadio: SettingRadio;
  settingBroadcast: SettingBroadcast;
  settingStream: SettingStream;
  settingCoverAndLive: SettingCoverAndLive;
  settingDirectory: SettingDirectory;
  settingSocial: SettingSocial;
  settingSecurity: SettingSecurity;
  settingMisc: SettingMisc;
}

@State<SettingStateModel>({
  name: 'setting',
  defaults: {
    settingRadio: null,
    settingBroadcast: null,
    settingCoverAndLive: null,
    settingDirectory: null,
    settingMisc: null,
    settingSecurity: null,
    settingSocial: null,
    settingStream: null,
  },
})
@Injectable()
export class SettingState {
  constructor(
    private readonly store: Store,
    private readonly settingService: SettingService,
  ) {}
  @Selector()
  static settingRadio(state: SettingStateModel): SettingRadio {
    return state.settingRadio;
  }

  @Selector()
  static settingBroadcast(state: SettingStateModel): SettingBroadcast {
    return state.settingBroadcast;
  }

  @Selector()
  static settingStream(state: SettingStateModel): SettingStream {
    return state.settingStream;
  }

  @Selector()
  static settingCoverAndLive(state: SettingStateModel): SettingCoverAndLive {
    return state.settingCoverAndLive;
  }

  @Selector()
  static settingDirectory(state: SettingStateModel): SettingDirectory {
    return state.settingDirectory;
  }

  @Selector()
  static settingSocial(state: SettingStateModel): SettingSocial {
    return state.settingSocial;
  }

  @Selector()
  static settingSecurity(state: SettingStateModel): SettingSecurity {
    return state.settingSecurity;
  }

  @Selector()
  static settingMisc(state: SettingStateModel): SettingMisc {
    return state.settingMisc;
  }

  @Selector()
  static iTunesId(state: SettingStateModel): string {
    return state.settingSocial.itunesAffiliateId;
  }

  @Action(SettingRadioRequest)
  getSettingRadio(ctx: StateContext<SettingStateModel>, { id }: SettingRadioRequest) {
    return this.settingService.getSettingRadio(id).pipe(
      mergeMap(data => ctx.dispatch(new SettingRadioSuccess(data, id))),
      catchError(err => ctx.dispatch(new SettingRadioFailure(err))),
    );
  }

  @Action([SettingRadioSuccess, UpdateSettingRadioSuccess, StartupRadioSuccess])
  getSettingRadioSuccess(
    ctx: StateContext<SettingStateModel>,
    { setting }: SettingRadioSuccess | UpdateSettingRadioSuccess | StartupRadioSuccess,
  ) {
    ctx.setState(
      patch({
        settingRadio: setting,
      }),
    );
  }

  @Action(UpdateSettingRadioRequest)
  updateSettingRadio(
    ctx: StateContext<SettingStateModel>,
    { setting, file }: UpdateSettingRadioRequest,
  ) {
    return this.getCurrentRadioId().pipe(
      mergeMap(id => {
        if (typeof file === 'string' || file === null) {
          return of(id);
        }

        return this.settingService.updateSettingRadioLogo(id, file).pipe(map(() => id));
      }),
      mergeMap(idRadio => this.settingService.updateSettingRadio(idRadio, setting)),
      mergeMap(data => ctx.dispatch(new UpdateSettingRadioSuccess(data))),
      catchError(err => ctx.dispatch(new UpdateSettingRadioFailure(err))),
    );
  }

  @Action(SettingBroadcastRequest)
  getSettingBroadcast(
    ctx: StateContext<SettingStateModel>,
    { id }: SettingBroadcastRequest,
  ) {
    return this.settingService.getSettingBroadcast(id).pipe(
      mergeMap(data => ctx.dispatch(new SettingBroadcastSuccess(data))),
      catchError(err => ctx.dispatch(new SettingBroadcastFailure(err))),
    );
  }

  @Action([SettingBroadcastSuccess, UpdateSettingBroadcastSuccess])
  getSettingBroadcastSuccess(
    ctx: StateContext<SettingStateModel>,
    { setting }: SettingBroadcastSuccess | UpdateSettingBroadcastSuccess,
  ) {
    ctx.setState(
      patch({
        settingBroadcast: setting,
      }),
    );
  }

  @Action(UpdateSettingBroadcastRequest)
  updateSettingBroadcast(
    ctx: StateContext<SettingStateModel>,
    { setting }: UpdateSettingBroadcastRequest,
  ) {
    return this.getCurrentRadioId().pipe(
      mergeMap(idRadio => this.settingService.updateSettingBroadcast(idRadio, setting)),
      mergeMap(data => ctx.dispatch(new UpdateSettingBroadcastSuccess(data))),
      catchError(err => ctx.dispatch(new UpdateSettingBroadcastFailure(err))),
    );
  }

  @Action(SettingStreamRequest)
  getSettingStream(ctx: StateContext<SettingStateModel>, { id }: SettingStreamRequest) {
    return this.settingService.getSettingStream(id).pipe(
      mergeMap(data => ctx.dispatch(new SettingStreamSuccess(data))),
      catchError(err => ctx.dispatch(new SettingStreamFailure(err))),
    );
  }

  @Action([SettingStreamSuccess, UpdateSettingStreamSuccess])
  getSettingStreamSuccess(
    ctx: StateContext<SettingStateModel>,
    { setting }: SettingStreamSuccess | UpdateSettingStreamSuccess,
  ) {
    ctx.setState(
      patch({
        settingStream: setting,
      }),
    );
  }

  @Action(UpdateSettingStreamRequest)
  updateSettingStream(
    ctx: StateContext<SettingStateModel>,
    { setting }: UpdateSettingStreamRequest,
  ) {
    return this.getCurrentRadioId().pipe(
      mergeMap(idRadio => this.settingService.updateSettingStream(idRadio, setting)),
      mergeMap(data => ctx.dispatch(new UpdateSettingStreamSuccess(data))),
      catchError(err => ctx.dispatch(new UpdateSettingStreamFailure(err))),
    );
  }

  @Action(SettingCoverAndLiveRequest)
  getSettingCover(
    ctx: StateContext<SettingStateModel>,
    { id }: SettingCoverAndLiveRequest,
  ) {
    return this.settingService.getSettingCoverAndLive(id).pipe(
      mergeMap(data => ctx.dispatch(new SettingCoverAndLiveSuccess(data))),
      catchError(err => ctx.dispatch(new SettingCoverAndLiveFailure(err))),
    );
  }

  @Action([SettingCoverAndLiveSuccess, UpdateSettingCoverAndLiveSuccess])
  getSettingCoverSuccess(
    ctx: StateContext<SettingStateModel>,
    { setting }: SettingCoverAndLiveSuccess | UpdateSettingCoverAndLiveSuccess,
  ) {
    ctx.setState(
      patch({
        settingCoverAndLive: setting,
      }),
    );
  }

  @Action(UpdateSettingCoverAndLiveRequest)
  updateSettingCoverAndLive(
    ctx: StateContext<SettingStateModel>,
    { setting, defaultCover, liveCover }: UpdateSettingCoverAndLiveRequest,
  ) {
    return this.getCurrentRadioId().pipe(
      mergeMap(id => {
        if (typeof defaultCover === 'string' || defaultCover === null) {
          return of(id);
        }
        if (typeof defaultCover === 'boolean') {
          return this.settingService.deleteSettingDefaultCover(id).pipe(map(() => id));
        }

        return this.settingService
          .updateSettingDefaultCover(id, defaultCover)
          .pipe(map(() => id));
      }),
      mergeMap(id => {
        if (typeof liveCover === 'string' || liveCover === null) {
          return of(id);
        }
        if (typeof liveCover === 'boolean') {
          return this.settingService.deleteSettingLiveCover(id).pipe(map(() => id));
        }

        return this.settingService
          .updateSettingLiveCover(id, liveCover)
          .pipe(map(() => id));
      }),
      mergeMap(idRadio =>
        this.settingService.updateSettingCoverAndLive(idRadio, setting),
      ),
      mergeMap(data => ctx.dispatch(new UpdateSettingCoverAndLiveSuccess(data))),
      catchError(err => ctx.dispatch(new UpdateSettingCoverAndLiveFailure(err))),
    );
  }

  @Action(SettingDirectoryRequest)
  getSettingDirectory(
    ctx: StateContext<SettingStateModel>,
    { id }: SettingDirectoryRequest,
  ) {
    return this.settingService.getSettingDirectory(id).pipe(
      mergeMap(directory => ctx.dispatch(new SettingDirectorySuccess(directory))),
      catchError(err => ctx.dispatch(new SettingDirectoryFailure(err))),
    );
  }

  @Action([SettingDirectorySuccess, UpdateSettingDirectorySuccess])
  getSettingDirectorySuccess(
    ctx: StateContext<SettingStateModel>,
    { setting }: SettingDirectorySuccess | UpdateSettingDirectorySuccess,
  ) {
    ctx.setState(
      patch({
        settingDirectory: setting,
      }),
    );
  }

  @Action(UpdateSettingDirectoryRequest)
  updateSettingDirectory(
    ctx: StateContext<SettingStateModel>,
    { setting }: UpdateSettingDirectoryRequest,
  ) {
    return this.getCurrentRadioId().pipe(
      mergeMap(idRadio => this.settingService.updateSettingDirectory(idRadio, setting)),
      mergeMap(data => ctx.dispatch(new UpdateSettingDirectorySuccess(data))),
      catchError(err => ctx.dispatch(new UpdateSettingDirectoryFailure(err))),
    );
  }

  @Action(SettingSocialRequest)
  getSettingSocial(ctx: StateContext<SettingStateModel>, { id }: SettingSocialRequest) {
    return this.settingService.getSettingSocial(id).pipe(
      mergeMap(data => ctx.dispatch(new SettingSocialSuccess(data))),
      catchError(err => ctx.dispatch(new SettingSocialFailure(err))),
    );
  }

  @Action([SettingSocialSuccess, UpdateSettingSocialSuccess])
  getSettingSocialSuccess(
    ctx: StateContext<SettingStateModel>,
    { setting }: SettingSocialSuccess | UpdateSettingSocialSuccess,
  ) {
    ctx.setState(
      patch({
        settingSocial: setting,
      }),
    );
  }

  @Action(UpdateSettingSocialRequest)
  updateSettingSocial(
    ctx: StateContext<SettingStateModel>,
    { setting }: UpdateSettingSocialRequest,
  ) {
    return this.getCurrentRadioId().pipe(
      mergeMap(idRadio => this.settingService.updateSettingSocial(idRadio, setting)),
      mergeMap(data => ctx.dispatch(new UpdateSettingSocialSuccess(data))),
      catchError(err => ctx.dispatch(new UpdateSettingSocialFailure(err))),
    );
  }

  @Action(SettingSecurityRequest)
  getSettingSecurity(
    ctx: StateContext<SettingStateModel>,
    { id }: SettingSecurityRequest,
  ) {
    return this.settingService.getSettingSecurity(id).pipe(
      mergeMap(setting => ctx.dispatch(new SettingSecuritySuccess(setting))),
      catchError(err => ctx.dispatch(new SettingSecurityFailure(err))),
    );
  }

  @Action([SettingSecuritySuccess, UpdateSettingSecuritySuccess])
  getSettingSecuritySuccess(
    ctx: StateContext<SettingStateModel>,
    { setting }: SettingSecuritySuccess | UpdateSettingSecuritySuccess,
  ) {
    ctx.setState(
      patch({
        settingSecurity: setting,
      }),
    );
  }

  @Action(UpdateSettingSecurityRequest)
  updateSettingSecurity(
    ctx: StateContext<SettingStateModel>,
    { setting }: UpdateSettingSecurityRequest,
  ) {
    return this.getCurrentRadioId().pipe(
      mergeMap(idRadio => this.settingService.updateSettingSecurity(idRadio, setting)),
      mergeMap(data => ctx.dispatch(new UpdateSettingSecuritySuccess(data))),
      catchError(err => ctx.dispatch(new UpdateSettingSecurityFailure(err))),
    );
  }

  @Action(SettingMiscRequest)
  getSettingMisc(ctx: StateContext<SettingStateModel>, { id }: SettingMiscRequest) {
    return this.settingService.getSettingMisc(id).pipe(
      mergeMap(data => ctx.dispatch(new SettingMiscSuccess(data))),
      catchError(err => ctx.dispatch(new SettingMiscFailure(err))),
    );
  }

  @Action([SettingMiscSuccess, UpdateSettingMiscSuccess])
  getSettingMiscSuccess(
    ctx: StateContext<SettingStateModel>,
    { setting }: SettingMiscSuccess | UpdateSettingMiscSuccess,
  ) {
    ctx.setState(
      patch({
        settingMisc: setting,
      }),
    );
  }

  @Action(UpdateSettingMiscRequest)
  updateSettingMisc(
    ctx: StateContext<SettingStateModel>,
    { setting }: UpdateSettingMiscRequest,
  ) {
    return this.getCurrentRadioId().pipe(
      mergeMap(idRadio => this.settingService.updateSettingMisc(idRadio, setting)),
      mergeMap(data => ctx.dispatch(new UpdateSettingMiscSuccess(data))),
      catchError(err => ctx.dispatch(new UpdateSettingMiscFailure(err))),
    );
  }

  @Action(StartupRadioRequest)
  startupRadio(ctx: StateContext<RadioStateModel>, { radio }: StartupRadioRequest) {
    return this.store.select(RadioState.currentRadioId).pipe(
      filter(data => !!data),
      take(1),
      mergeMap(id =>
        this.settingService
          .updateSettingRadio(id, radio)
          .pipe(map(data => ({ id, data }))),
      ),
      mergeMap(data => ctx.dispatch(new StartupRadioSuccess(data.id, data.data))),
      catchError(err => ctx.dispatch(new StartupRadioFailure(err))),
    );
  }

  @Action(CheckSlugRequest)
  checkSlug(ctx: StateContext<SettingStateModel>, { slug }: CheckSlugRequest) {
    return this.getCurrentRadioId().pipe(
      mergeMap(idRadio => this.settingService.checkSlug(idRadio, slug)),
      mergeMap(() => ctx.dispatch(new CheckSlugSuccess())),
      catchError(err => ctx.dispatch(new CheckSlugFailure(err))),
    );
  }

  getCurrentRadioId(): Observable<number> {
    return this.store.select(RadioState.currentRadioId).pipe(
      filter(data => !!data),
      take(1),
    );
  }
}
