import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LightApiTrack } from '@app/core/models/LightTrack';
import { ROLES } from '@app/core/models/Preset';
import { RadioLiveBroadcaster } from '@app/core/models/Radio';
import { PlayerService } from '@app/core/services/player.service';
import {
  RoleHelperService,
  RoleOperatorActions,
} from '@app/core/services/role-helper.service';
import { AppState } from '@app/core/states/app.state';
import { PlayRadio, Stop } from '@app/core/states/audio.actions';
import { AudioState } from '@app/core/states/audio.state';
import { LiveTrackingState } from '@app/core/states/live-tracking.state';
import {
  RadioRestartRequest,
  RadioStartFailure,
  RadioStartRequest,
  RadioStartSuccess,
  RadioStopFailure,
  RadioStopRequest,
  RadioStopSuccess,
} from '@app/core/states/radio.actions';
import { RadioState } from '@app/core/states/radio.state';
import { BacsRequest } from '@app/library/states/bacs.actions';
import { BacsState } from '@app/library/states/bacs.state';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Actions, ofActionDispatched, Select, Store } from '@ngxs/store';
import {
  CheckListListenToRadio,
  ChecklistStep,
  ToggleFirstButton,
} from '@radioking/onboarding-checklist';
import moment from 'moment-timezone';
import { combineLatest, interval, merge, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, map, mergeMap } from 'rxjs/operators';

import { DashboardState } from '../../../../../../../../libs/dashboard/src/lib/states/dashboard.state';

@Component({
  selector: 'rk-radio-sidebar-main',
  templateUrl: './radio-sidebar-main.component.html',
  styleUrls: ['./radio-sidebar-main.component.scss'],
})
export class RadioSidebarMainComponent implements OnInit, OnDestroy {
  // duotone : https://codepen.io/anon/pen/MXVZoo

  @ViewChild('playRadioBtn', { static: false })
  playRadioBtn: HTMLButtonElement;

  @Select(LiveTrackingState.isBroadcasting)
  isRadioStarted$: Observable<boolean>;

  @Select(LiveTrackingState.status)
  radioStatus$: Observable<string>;

  @Select(LiveTrackingState.isLive)
  isLive$: Observable<boolean>;

  isListening$: Observable<boolean>;

  isAudioLoading$: Observable<boolean>;

  isRadioStopping$: Observable<boolean>;

  isRadioRestarting$: Observable<boolean>;

  @Select(LiveTrackingState.listeners)
  listeners$: Observable<number>;

  @Select(LiveTrackingState.currentTrack)
  currentTrack$: Observable<LightApiTrack>;

  @Select(LiveTrackingState.nextTracks)
  nextTracks$: Observable<LightApiTrack[]>;

  @Select(DashboardState.listeningTimeExceeded)
  listeningTimeExceeded$: Observable<boolean>;

  @Select(LiveTrackingState.broadcaster)
  broadcaster$: Observable<RadioLiveBroadcaster>;

  @Select(RadioState.isCustomer)
  isCustomer$: Observable<boolean>;

  @Select(BacsState.totalCountTracksBoxesNotLocked)
  totalCountTracksBoxesNotLocked$: Observable<number>;

  @Select(AppState.onMobile)
  onMobile$: Observable<boolean>;

  hasBeenInputed = new Subject();

  liveDuration$: Observable<number>;

  isRadioStarting$: Observable<boolean>;

  volumeBeforeMute: number;
  isChangingVolume = false;
  isMuted = false;

  listeners: number;
  pulse = false;

  subscription = new Subscription();

  isLibraryEmpty$: Observable<boolean>;

  constructor(
    private readonly store: Store,
    private readonly action$: Actions,
    private readonly router: Router,
    public readonly playerService: PlayerService,
    private readonly _roleHelperService: RoleHelperService,
  ) {}

  ngOnInit() {
    this.getBoxes();
    this.isListening$ = combineLatest([
      this.store.select(AudioState.isRadioPlayerCurrent),
      this.store.select(AudioState.isPlaying),
    ]).pipe(map(([b1, b2]) => b1 && b2));
    this.isAudioLoading$ = combineLatest([
      this.store.select(AudioState.isRadioPlayerCurrent),
      this.store.select(AudioState.isAudioLoading),
      this.store.select(RadioState.isRadioReady),
    ]).pipe(map(([b1, b2, b3]) => b1 && b2 && b3));

    this.hasBeenInputed
      .asObservable()
      .pipe(debounceTime(600))
      .subscribe(() => {
        this.isChangingVolume = false;
      });

    this.liveDuration$ = interval(500).pipe(
      mergeMap(() => this.store.select(LiveTrackingState.isLive)),
      map(val => {
        if (val) {
          return this.store.selectSnapshot(LiveTrackingState.durationLive);
        }

        return 0;
      }),
      map(started => moment().valueOf() - started),
      map(time => Math.floor(time / 1000)),
    );

    this.isRadioStarting$ = merge(
      this.action$.pipe(
        ofActionDispatched(RadioStartRequest),
        map(() => true),
      ),
      this.action$.pipe(
        ofActionDispatched(RadioStartFailure, RadioStartSuccess),
        map(() => false),
      ),
    );

    this.isRadioStopping$ = merge(
      this.action$.pipe(
        ofActionDispatched(RadioStopRequest),
        map(() => true),
      ),
      this.action$.pipe(
        ofActionDispatched(RadioStopFailure, RadioStopSuccess),
        map(() => false),
      ),
    );

    this.isRadioRestarting$ = merge(
      this.action$.pipe(
        ofActionDispatched(RadioRestartRequest),
        map(() => true),
      ),
      this.action$.pipe(
        ofActionDispatched(RadioStartSuccess, RadioStartFailure),
        map(() => false),
      ),
    );

    this.isLibraryEmpty$ = this.totalCountTracksBoxesNotLocked$.pipe(
      map(val => val === 0),
    );
    this.action$.pipe(ofActionDispatched(CheckListListenToRadio)).subscribe(() => {
      this.pulse = true;
    });
    this.isLibraryEmpty$.subscribe(this.disableChecklistRadio);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @Dispatch()
  getBoxes() {
    return new BacsRequest();
  }

  @Dispatch()
  disableChecklistRadio = (empty: boolean) =>
    new ToggleFirstButton(ChecklistStep.LISTEN_TO_RADIO, empty);

  hasStatisticsRole() {
    return this._roleHelperService.hasRole({
      type: RoleOperatorActions.SPECIFIC,
      roleId: ROLES.STATISTICS,
    });
  }

  startBroadcastRadio() {
    this.store.dispatch(new RadioStartRequest());
  }

  playRadio() {
    this.pulse = false;
    const currentUrl = this.store.selectSnapshot(RadioState.currentRadioStreamUrl);
    this.store.dispatch(new PlayRadio(currentUrl));
    this.setVolume(this.getVolume());
  }

  stopRadio() {
    this.store.dispatch(new Stop());
  }

  getColorMatrix(colors: number[][]) {
    let value: number[] = [];
    const color1 = colors[0] || [255, 255, 255];
    const color2 = colors[1] || [0, 0, 0];
    value = value.concat([color1[0] / 256 - color2[0] / 256, 0, 0, 0, color2[0] / 256]);
    value = value.concat([color1[1] / 256 - color2[1] / 256, 0, 0, 0, color2[1] / 256]);
    value = value.concat([color1[2] / 256 - color2[2] / 256, 0, 0, 0, color2[2] / 256]);
    value = value.concat([0, 0, 0, 1, 0]);

    return value.join(' ');
  }

  toggleVolume() {
    if (!this.isMuted) {
      this.volumeBeforeMute = this.getVolume() || 0;
      this.setVolume(-60);
      this.isMuted = true;
    } else {
      this.setVolume(this.volumeBeforeMute || 0);
      this.isMuted = false;
    }
  }

  getVolume(): number {
    return this.playerService.getVolume() || 0;
  }

  setVolume(vol: number): void {
    this.isChangingVolume = true;
    this.playerService.setVolume(vol);
    this.hasBeenInputed.next();
    if (vol > -60) {
      this.isMuted = false;
    } else {
      this.isMuted = true;
    }
  }

  finSetVolume() {
    this.isChangingVolume = false;
  }

  goToGeneration() {
    const idRadio = this.store.selectSnapshot(RadioState.currentRadioId);
    this.router.navigateByUrl(`/radio/${idRadio}/generated-planning`);
  }
}
