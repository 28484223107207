import { Injectable } from '@angular/core';
import { ShoutOut } from '@app/core/models/ShoutOut';
import { ShoutOutsService } from '@app/core/services/shout-outs.service';
import { RadioState } from '@app/core/states/radio.state';
import {
  DisableShoutOutRequest,
  DisableShoutOutsFailure,
  DisableShoutOutSuccess,
  EnableShoutOutRequest,
  EnableShoutOutsFailure,
  EnableShoutOutSuccess,
  ShoutOutsFailure,
  ShoutOutsRequest,
  ShoutOutsSuccess,
  UpdateShoutOutRequest,
  UpdateShoutOutsFailure,
  UpdateShoutOutsSuccess,
} from '@app/core/states/shout-outs.actions';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { catchError, filter, mergeMap, mergeMapTo, take } from 'rxjs/operators';

export class ShoutOutsStateModel {
  shoutOuts: ShoutOut;
}

@State<ShoutOutsStateModel>({
  name: 'shoutOuts',
  defaults: {
    shoutOuts: {
      welcomeMessage: '',
      endMessage: '',
      primaryColor: '#1e7fcb',
      secondaryColor: '#ffffff',
      enabled: 0,
      key: null,
    },
  },
})
@Injectable()
export class ShoutOutsState {
  constructor(
    private readonly store: Store,
    private readonly shoutOutsService: ShoutOutsService,
  ) {}
  @Selector()
  static shoutOuts(state: ShoutOutsStateModel) {
    return state.shoutOuts;
  }

  @Action(ShoutOutsRequest)
  getShoutOutsConfig(ctx: StateContext<ShoutOutsStateModel>) {
    return this.getCurrentRadioId().pipe(
      mergeMap(idRadio => this.shoutOutsService.getShoutOutSettings(idRadio)),
      mergeMap(data => ctx.dispatch(new ShoutOutsSuccess(data))),
      catchError(err => ctx.dispatch(new ShoutOutsFailure(err))),
    );
  }

  @Action([
    ShoutOutsSuccess,
    EnableShoutOutSuccess,
    UpdateShoutOutsSuccess,
    DisableShoutOutSuccess,
  ])
  getShoutOutsSuccess(
    ctx: StateContext<ShoutOutsStateModel>,
    {
      shoutOuts,
    }:
      | ShoutOutsSuccess
      | EnableShoutOutSuccess
      | UpdateShoutOutsSuccess
      | DisableShoutOutSuccess,
  ) {
    ctx.patchState({
      shoutOuts,
    });
  }

  @Action(EnableShoutOutRequest)
  enableShouts(ctx: StateContext<ShoutOutsStateModel>) {
    return this.getCurrentRadioId().pipe(
      mergeMap(idRadio => this.shoutOutsService.toggleShoutOut(idRadio, true)),
      mergeMap(data => ctx.dispatch(new EnableShoutOutSuccess(data))),
      mergeMapTo(this.getCurrentRadioId()),
      mergeMap(id =>
        ctx.dispatch(
          new Navigate(
            ['radio', id, 'widgets', 'audio_shoutouts'],
            {},
            { replaceUrl: true },
          ),
        ),
      ),
      catchError(err => ctx.dispatch(new EnableShoutOutsFailure(err))),
    );
  }

  @Action(DisableShoutOutRequest)
  disableShouts(ctx: StateContext<ShoutOutsStateModel>) {
    return this.getCurrentRadioId().pipe(
      mergeMap(idRadio => this.shoutOutsService.toggleShoutOut(idRadio, false)),
      mergeMap(data => ctx.dispatch(new DisableShoutOutSuccess(data))),
      catchError(err => ctx.dispatch(new DisableShoutOutsFailure(err))),
    );
  }

  @Action(UpdateShoutOutRequest)
  updateShoutsConfig(
    ctx: StateContext<ShoutOutsStateModel>,
    { payload, showModal }: UpdateShoutOutRequest,
  ) {
    return this.getCurrentRadioId().pipe(
      mergeMap(idRadio => this.shoutOutsService.updateShoutOutSettings(idRadio, payload)),
      mergeMap(data => ctx.dispatch(new UpdateShoutOutsSuccess(data, showModal))),
      catchError(err => ctx.dispatch(new UpdateShoutOutsFailure(err))),
    );
  }

  getCurrentRadioId(): Observable<number> {
    return this.store.select(RadioState.currentRadioId).pipe(
      filter(data => !!data),
      take(1),
    );
  }
}
