import { EventApi } from '@fullcalendar/core';
import { PlanningDatesRange, Slot, SlotOccurrence } from '../models/planning.model';

const tag = '[Planning]';

export class GetPlanningRequest {
  static readonly type = `${tag} request get planning`;
  constructor(public range: PlanningDatesRange) {}
}

export class GetPlanningSuccess {
  static readonly type = `${tag} success get planning`;
  constructor(public slots: Slot[]) {}
}

export class GetPlanningFailure {
  static readonly type = `${tag} error get planning`;
  constructor(public error: Error) {}
}

export class PlanningCreateSlotRequest {
  static readonly type = `${tag} request create slot`;
  constructor(public slot: Slot) {}
}

export class PlanningCreateSlotSuccess {
  static readonly type = `${tag} success create slot`;
}

export class PlanningCreateSlotFailure {
  static readonly type = `${tag} error create slot`;
  constructor(public error: Error) {}
}

export class PlanningDropSlotRequest {
  static readonly type = `${tag} request drop slot`;
  constructor(public slot: Partial<Slot>) {}
}

export class PlanningEditSlotRequest {
  static readonly type = `${tag} request edit slot`;

  constructor(public slot: Slot) {}
}

export class PlanningEditSlotSuccess {
  static readonly type = `${tag} success edit slot`;
}

export class PlanningEditSlotFailure {
  static readonly type = `${tag} error edit slot`;

  constructor(public error: Error) {}
}

export class PlanningDeleteSlotRequest {
  static readonly type = `${tag} request delete slot`;

  constructor(
    public deleteAll: boolean,
    public slotId: number,
    public occurrenceId: number,
  ) {}
}

export class PlanningDeleteSlotSuccess {
  static readonly type = `${tag} success delete slot`;

  constructor() {}
}

export class PlanningDeleteSlotFailure {
  static readonly type = `${tag} error delete slot`;

  constructor(public error: Error) {}
}

export class PlanningMoveSlotRequest {
  static readonly type = `${tag} request move slot`;

  constructor(
    public moveAll: boolean,
    public slot: Slot,
    public occurrence: SlotOccurrence,
  ) {}
}

export class PlanningMoveSlotSuccess {
  static readonly type = `${tag} success move slot`;

  constructor() {}
}

export class PlanningMoveSlotFailure {
  static readonly type = `${tag} error move slot`;

  constructor(public error: Error) {}
}

export class SlotOverrideExisting {
  static readonly type = `${tag} slot override existing`;
  constructor(public translateParams: object) {}
}

export class SlotDropSameDayOnly {
  static readonly type = `${tag} slot drop same day`;
  constructor() {}
}

export class ClearPlanning {
  static readonly type = `${tag} clear planning`;
  constructor() {}
}

export class AddSlotFromSidenav {
  static readonly type = `${tag} add slot from sidenav`;
  constructor(public slot: Partial<EventApi>) {}
}
