import { Injectable } from '@angular/core';
import { Timezone } from '@app/core/models/Timezone';
import { GenericDataService } from '@app/core/services/generic-data.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SugestionInput } from '@radioking/ui/input';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { TimezonesFailure, TimezonesRequest, TimezonesSuccess } from './timezone.actions';

export class TimezoneStateModel {
  timezones: Timezone[];
}

@State<TimezoneStateModel>({
  name: 'timezone',
  defaults: {
    timezones: undefined,
  },
})
@Injectable()
export class TimezoneState {
  constructor(private readonly genericDataService: GenericDataService) {}

  @Selector()
  static timezoneList(state: TimezoneStateModel): Timezone[] {
    return state.timezones;
  }

  @Selector()
  static timezoneListForInput(state: TimezoneStateModel): SugestionInput[] {
    return state.timezones.map(t => ({ id: t.code, name: t.name }));
  }

  @Action(TimezonesRequest)
  getAllCountries(
    ctx: StateContext<TimezoneStateModel>,
  ): Observable<void | Observable<void>> | void {
    const timezones = ctx.getState().timezones;
    if (timezones) {
      return;
    }

    return this.genericDataService.getTimezones().pipe(
      map(data => ctx.dispatch(new TimezonesSuccess(data))),
      catchError(err => ctx.dispatch(new TimezonesFailure(err))),
    );
  }

  @Action(TimezonesSuccess)
  add(ctx: StateContext<TimezoneStateModel>, { timezones }: TimezonesSuccess) {
    ctx.patchState({
      timezones,
    });
  }
}
