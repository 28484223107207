import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PerfectScrollbarDirective } from './perfect-scrollbar.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [PerfectScrollbarDirective],
  exports: [PerfectScrollbarDirective],
})
export class UiPerfectScrollBarModule {}
