import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { UiTooltipModule } from '@radioking/ui/tooltip';

import { LockedIconComponent } from './locked-icon/locked-icon.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    UiTooltipModule,
  ],
  declarations: [LockedIconComponent],
  exports: [LockedIconComponent],
})
export class UiLockedIconModule {}
