import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { FoldItemComponent } from './fold-item/fold-item.component';

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [FoldItemComponent],
  exports: [FoldItemComponent],
})
export class UiFoldItemModule {}
