<div class="bac">
  <mat-icon fontSet="fa" [fontIcon]="upload.box.icon" *ngIf="upload.box.icon"></mat-icon>
  {{ upload.box.translated$ | async }}
  <ng-container *ngIf="upload.playlist">
    &gt;
    {{ upload.playlist.name }}
  </ng-container>
</div>
<div class="file-infos">
  <span class="name">{{ upload.file.name }}</span>
  <span class="size">{{
    'HEADER.UPLOAD.FILE_MB' | translate: { val: getTranslatedBytes(upload.file.size) }
  }}</span>
  <div [class.hidden]="this.isProcessing()" class="cancel-button">
    <span [ngClass]="{ hidden: !(isError() || isCanceled()) }" (click)="retryUpload()">
      {{ 'HEADER.UPLOAD.RETRY' | translate }}
    </span>
    <rk-icon-button
      [bordered]="true"
      [faIcon]="'fa-times'"
      (click)="cancelUpload()"
    ></rk-icon-button>
  </div>
  <div *ngIf="isProcessing()" [style.margin-top.px]="-4">
    <rk-loader [isLoading]="true" [size]="25"></rk-loader>
  </div>
</div>
<div class="progress-line" [ngSwitch]="upload.state">
  <ng-container *ngSwitchCase="'uploading'">
    <mat-progress-bar mode="determinate" [value]="upload.progress"></mat-progress-bar>
    <span class="percentage" [ngClass]="{ complete: upload.progress === 100 }"
      >{{ upload.progress }}%</span
    >
  </ng-container>
  <div class="upload-status">
    <span *ngSwitchCase="'duplicate'">{{
      'HEADER.UPLOAD.DUPLICATE' | translate: { name: getDuplicateBacName(upload) | async }
    }}</span>
    <span *ngSwitchCase="'error'" translate>HEADER.UPLOAD.ERROR</span>
    <span *ngSwitchCase="'wrong_format'" translate>HEADER.UPLOAD.WRONG_FORMAT</span>
    <span *ngSwitchCase="'file_too_large'" translate>HEADER.UPLOAD.FILE_TOO_LARGE</span>
    <span *ngSwitchCase="'canceled'" translate>HEADER.UPLOAD.CANCELED</span>
    <span *ngSwitchCase="'ready'" class="complete" translate>HEADER.UPLOAD.PENDING</span>
    <span *ngSwitchCase="'complete'" class="complete" translate
      >HEADER.UPLOAD.COMPLETE</span
    >
    <span *ngSwitchCase="'processing'" class="complete processing" translate
      >HEADER.UPLOAD.PROCESSING</span
    >
  </div>
</div>
