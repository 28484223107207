import { HttpErrorResponse } from '@angular/common/http';
import { PlaylistTrack } from '@app/core/models/Track';
import { PlaylistApi } from '@app/library/models/playlist-api.model';
import { Playlist } from '@app/library/models/playlist.model';

const tag = '[Playlists]';

export class PlaylistsRequest {
  static readonly type = `${tag} request playlists for current radio`;
  constructor(public noLoader?: boolean) {}
}

export class PlaylistsSuccess {
  static readonly type = `${tag} success fetching playlists`;
  constructor(public playlist: Playlist[]) {}
}

export class PlaylistsFailure {
  static readonly type = `${tag} error fetching playlists`;
  constructor(public error: Error) {}
}

export class CreatePlaylistManualRequest {
  static readonly type = `${tag} request create playlist`;
  constructor(public playlist: Partial<PlaylistApi>, public tracks?: number[]) {}
}

export class CreatePlaylistManualSuccess {
  static readonly type = `${tag} success create playlist`;
  constructor(public playlist: Playlist, public withTracks?: number[]) {}
}

export class CreatePlaylistManualFailure {
  static readonly type = `${tag} error create playlist`;
  constructor(public error: Error) {}
}

export class CreatePlaylistDynamicRequest {
  static readonly type = `${tag} request create dynamic playlist`;
  constructor(public playlist: Partial<PlaylistApi>) {}
}

export class CreatePlaylistDynamicSuccess {
  static readonly type = `${tag} success create dynamic playlist`;
  constructor(public playlist: Playlist) {}
}

export class CreatePlaylistDynamicFailure {
  static readonly type = `${tag} error create dynamic playlist`;
  constructor(public error: Error) {}
}

export class StartEditPlaylist {
  static readonly type = `${tag} start edit playlist`;
  constructor(public playlist: Playlist) {}
}

export class EndEditPlaylist {
  static readonly type = `${tag} end edit playlist`;
}

export class EditPlaylistManualRequest {
  static readonly type = `${tag} request edit playlist`;
  constructor(public playlistId: number, public playlist: Partial<Playlist>) {}
}

export class EditPlaylistManualSuccess {
  static readonly type = `${tag} success edit playlist`;
}

export class EditPlaylistManualFailure {
  static readonly type = `${tag} error edit playlist`;
  constructor(public error: HttpErrorResponse) {}
}

export class EditPlaylistDynamicRequest {
  static readonly type = `${tag} request edit dynamic playlist`;
  constructor(public playlistId: number, public playlist: Partial<Playlist>) {}
}

export class EditPlaylistDynamicSuccess {
  static readonly type = `${tag} success edit dynamic playlist`;
  constructor(public playlist: Playlist) {}
}

export class EditPlaylistDynamicFailure {
  static readonly type = `${tag} error edit dynamic playlist`;
  constructor(public error: Error) {}
}

export class AddToPlaylistRequest {
  static readonly type = `${tag} request add to playlist`;
  constructor(
    public playlistId: number,
    public tracks: number[],
    public position?: number,
  ) {}
}
export class AddToPlaylistSuccess {
  static readonly type = `${tag} success add to playlist`;
  constructor(
    public playlistId: number,
    public tracks: PlaylistTrack[],
    public translateParams: object,
    public position?: number,
  ) {}
}
export class AddToPlaylistFailure {
  static readonly type = `${tag} error add to playlist`;
  constructor(
    public error: HttpErrorResponse,
    public playlistId: number,
    public tracks: number[],
    public position?: number,
  ) {}
}
export class GetPlaylistRequest {
  static readonly type = `${tag} request get playlist`;
  constructor(public idPlaylist: number) {}
}

export class GetPlaylistSuccess {
  static readonly type = `${tag} success get playlist`;
  constructor(public playlist: Playlist) {}
}

export class GetPlaylistFailure {
  static readonly type = `${tag} error get playlist`;
  constructor(public error: Error) {}
}

export class DeletePlaylistRequest {
  static readonly type = `${tag} request delete playlist`;
  constructor(public playlist: Playlist) {}
}

export class DeletePlaylistSuccess {
  static readonly type = `${tag} success delete playlist`;
  constructor(public playlist: Playlist) {}
}

export class DeletePlaylistFailure {
  static readonly type = `${tag} error delete playlist`;
  constructor(public error: Error) {}
}

export class AddToPlaylistWaitDuplicates {
  static readonly type = `${tag} duplicates add to playlist`;
  constructor(
    public playlistId: number,
    public tracks: number[],
    public position?: number,
  ) {}
}

export class ForceAddToPlaylistRequest {
  static readonly type = `${tag} request force add to playlist`;
  constructor(
    public playlistId: number,
    public tracks: number[],
    public mode: string,
    public position?: number,
  ) {}
}

export class UpdatePlaylist {
  static readonly type = `${tag} update playlist`;
  constructor(public playlist: Playlist) {}
}

export class ResumePlaylistProcess {
  static readonly type = `${tag} resume playlist process`;
}

export class DuplicatePlaylistRequest {
  static readonly type = `${tag} request duplicate playlist`;
  constructor(public playlist: Playlist) {}
}

export class DuplicatePlaylistSuccess {
  static readonly type = `${tag} success duplicate playlist`;
}

export class DuplicatePlaylistFailure {
  static readonly type = `${tag} error duplicate playlist`;
  constructor(public error: Error) {}
}

export class InvalidCriteria {
  static readonly type = `${tag} error invalid criteria`;
}

export class UpdatePlaylistTracksCountAfterDelete {
  static readonly type = `${tag} update playlist tracks count`;
  constructor(public idPlaylist: number, public numberToRemove: number) {}
}

export class PreventClosePlaylistPanel {
  static readonly type = `${tag} prevent close playlist panel after save`;
}

export class SetActionAfterEditPlaylist {
  static readonly type = `${tag} set action after edit playlist`;
  constructor(public action: any) {}
}
