import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackPlaylist } from '@app/core/models/Track';
import { Playlist } from '@app/library/models/playlist.model';
import { SlotPlaylist } from '@radioking/planning';
import { ProgramPlaylist } from '@radioking/programs';

@Component({
  selector: 'rk-playlist-icon',
  templateUrl: './playlist-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaylistIconComponent {
  @Input() playlist: Partial<Playlist> | TrackPlaylist | ProgramPlaylist | SlotPlaylist;

  @Input() size = '10px';

  isDynamic(): boolean {
    return this.playlist?.type === 'dynamic';
  }

  isManual(): boolean {
    return this.playlist?.type === 'manual';
  }
}
