import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { HotkeysGeneric } from '@app/core/services/hotkeys.service';
import { isMacOS } from '@app/shared/utils';

@Component({
  selector: 'rk-hotkeys-help-clue',
  templateUrl: './hotkeys-help-clue.component.html',
  styleUrls: ['./hotkeys-help-clue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotkeysHelpClueComponent {
  @Input() hotkey: HotkeysGeneric;

  @HostBinding('class')
  @Input()
  context: 'inContextMenu' | 'inTooltip' = 'inContextMenu';

  isMacOS() {
    return isMacOS;
  }
}
