import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EventManager } from '@angular/platform-browser';
import { isMacOS } from '@app/shared/utils';
import { Store } from '@ngxs/store';
import { isNil } from 'lodash';
import { EMPTY, Observable, Subscriber, Subscription, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';

import { PanelsState } from '../../../../../../libs/shared/pannel-manager/src/lib/states/panels.state'; // Use direct path to avoid circular dependency

export interface HotkeysOptions {
  target: HTMLElement | Document | Window;
  keys: string | string[];
  strategy: 'keydown' | 'keyup';
  debounceMs: number;
  preventDefault: boolean;
  stopPropagation: boolean;
  stopImmediatePropagation: boolean;
  disabledOnInputs: boolean;
  disabledOnOpenedDialog: boolean;
  disabledOnOpenedPanel: boolean;
  disabledOnConditional(e: KeyboardEvent): boolean;
}

/**
 * Generic hotkeys: use common names for application-wide hotkeys
 */
export type HotkeysGeneric =
  | 'selectAll'
  | 'selectUp'
  | 'selectDown'
  | 'selectUpWithSelection'
  | 'selectDownWithSelection'
  | 'cancel'
  | 'confirm'
  | 'search'
  | 'edit'
  | 'delete'
  | 'playPause';

export interface HotkeysConfigArray {
  hotkey: HotkeysGeneric;
  override?: Partial<HotkeysOptions>;
  handler(e: KeyboardEvent): void;
}

@Injectable({
  providedIn: 'root',
})
export class HotkeysService {
  defaults: Partial<HotkeysOptions>;

  /**
   * Generic hotkeys: use common names for application-wide hotkeys
   * and handle all configuration here, for maintainability reasons
   */
  readonly genericHotkeys = new Map<HotkeysGeneric, Partial<HotkeysOptions>>([
    ['selectAll', { keys: 'ctrlorcmd.a', debounceMs: 50, preventDefault: true }],
    ['selectUp', { keys: 'arrowUp', debounceMs: 10, preventDefault: true }],
    ['selectDown', { keys: 'arrowDown', debounceMs: 10, preventDefault: true }],
    [
      'selectUpWithSelection',
      { keys: 'shift.arrowUp', debounceMs: 10, preventDefault: true },
    ],
    [
      'selectDownWithSelection',
      { keys: 'shift.arrowDown', debounceMs: 10, preventDefault: true },
    ],
    [
      'cancel',
      {
        keys: 'escape',
        strategy: 'keyup',
        disabledOnInputs: false,
        disabledOnOpenedPanel: true,
      },
    ],
    [
      'confirm',
      { keys: 'ctrlorcmd.enter', disabledOnInputs: false, disabledOnOpenedDialog: false },
    ],
    [
      'search',
      {
        keys: 'ctrlorcmd.f',
        preventDefault: true,
        disabledOnInputs: false,
        disabledOnOpenedPanel: true,
      },
    ],
    ['edit', { keys: 'ctrlorcmd.e', preventDefault: true }],
    ['delete', { keys: ['meta.backspace', 'delete'] }],
    ['playPause', { keys: 'space', disabledOnOpenedDialog: false, preventDefault: true }],
  ]);

  constructor(
    private readonly eventManager: EventManager,
    private readonly dialog: MatDialog,
    private readonly store: Store,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    this.defaults = {
      target: document,
      strategy: 'keydown',
      debounceMs: 250,
      preventDefault: false,
      stopPropagation: false,
      stopImmediatePropagation: false,
      disabledOnInputs: true,
      disabledOnOpenedDialog: true,
      disabledOnOpenedPanel: false,
    };
  }

  /**
   * Helper method for listening to generic hotkeys, directly returning a subscription
   * wrapping all observables
   *
   * See {@link genericHotkey} for usage
   */
  fromConfigArray(config: HotkeysConfigArray[]): Subscription {
    const subscription = new Subscription();

    config.forEach(c =>
      subscription.add(this.genericHotkey(c.hotkey, c.override).subscribe(c.handler)),
    );

    return subscription;
  }

  /**
   * Listen to a generic hotkey, available from HotkeysGeneric
   *
   * @param hotkey Generic hotkey name
   * @param override Generic options can be overridden using this parameter
   */
  genericHotkey(
    hotkey: HotkeysGeneric,
    override: Partial<HotkeysOptions> = {},
  ): Observable<KeyboardEvent> {
    if (!this.genericHotkeys.has(hotkey)) {
      throw new RangeError(`Hotkey "${hotkey}" is not registered in HotkeysService`);
    }

    // Merge generic options with override
    const options = { ...this.genericHotkeys.get(hotkey), ...override };

    return this.customHotkey(options);
  }

  /**
   * Listen to a custom hotkey
   *
   * Recommandation: prefer usage of genericHotkey() instead for maintainability
   */
  customHotkey(options: Partial<HotkeysOptions>): Observable<KeyboardEvent> {
    // Merge options with defaults
    const opts = { ...this.defaults, ...options };

    return new Observable<KeyboardEvent>(observer => {
      // Register keyboard event listener(s)
      const keys = Array.isArray(opts.keys) ? opts.keys : [opts.keys];
      const disposables = keys.map(key => {
        const event = `${opts.strategy}.${this.normalizeKeys(key)}`;

        return this.eventManager.addEventListener(
          opts.target as any,
          event,
          (e: KeyboardEvent) => this.keyboardEventHandler(observer, opts, e),
        );
      });

      return () => disposables.map(dispose => dispose());
    }).pipe(
      // Debounce keyboard events for better performance, UX and application logic
      debounce(() => (!isNil(opts.debounceMs) ? timer(opts.debounceMs) : EMPTY)),
    );
  }

  /**
   * Keyboard event handler
   *
   * Event can be modified/filtered-out
   */
  keyboardEventHandler(
    observer: Subscriber<KeyboardEvent>,
    options: Partial<HotkeysOptions>,
    e: KeyboardEvent,
  ): void {
    if (this.isKeyboardEventDisabled(e, options)) {
      return;
    }

    if (options.preventDefault) {
      e.preventDefault();
    }

    if (options.stopPropagation) {
      e.stopPropagation();
    }

    if (options.stopImmediatePropagation) {
      e.stopImmediatePropagation();
    }

    observer.next(e);
  }

  /**
   * Keyboard event can be disabled/skipped in some cases, depending on provided options
   */
  isKeyboardEventDisabled(e: KeyboardEvent, options: Partial<HotkeysOptions>): boolean {
    return (
      (options.disabledOnInputs && this.isFocusingFormInput()) ||
      (options.disabledOnOpenedDialog && this.hasOpenedDialog()) ||
      (options.disabledOnOpenedPanel && this.hasOpenedPanel()) ||
      (options.disabledOnConditional && options.disabledOnConditional(e))
    );
  }

  /**
   * Whether active/focused element is a form input or not
   *
   * Hotkeys might be disabled when active/focused element is a form input,
   * in order to preserve native keyboard events.
   */
  isFocusingFormInput(): boolean {
    const activeElement = this.document.activeElement;

    return (
      (activeElement instanceof HTMLInputElement && activeElement.type !== 'checkbox') ||
      activeElement instanceof HTMLTextAreaElement ||
      activeElement instanceof HTMLSelectElement
    );
  }

  /**
   * Whether a dialog is opened or not
   */
  hasOpenedDialog(): boolean {
    return Boolean(this.dialog.openDialogs.length);
  }

  /**
   * Whether a panel is opened or not
   */
  hasOpenedPanel(): boolean {
    return Boolean(this.store.selectSnapshot(PanelsState.currentPanel));
  }

  /**
   * Normalize keys
   *
   * - Handle cross-platform CTRL/COMMAND through "ctrlorcmd" special key
   */
  protected normalizeKeys(keys: string): string {
    return keys.replace(/ctrlorcmd/gi, isMacOS ? 'meta' : 'control');
  }
}
