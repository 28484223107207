export const tokenPath = 'auth.auth.token';
export const tokenRefreshToken = 'auth.auth.refreshToken';

export const SAVED_TO_LOCAL_STORAGE_NGXS = [
  tokenPath,
  tokenRefreshToken,
  'library.columns',
  'library.bacs.bacsOpen',
  'library.filters',
  'library.trackMixPoints',
  'playlists.playlistColumns',
  'playlists.process',
  'share.player',
  'share.playedTracks',
  'share.currentTrack',
  'share.topTracks',
  'liveTracking.radioApollonStatus',
  'liveTracking.radioPlan',
  'onboardingChecklist.opened',
  'onboardingChecklist.stepOpened',
  'onboardingChecklist.destroyed',
  'radio.subscription.options',
];
