<div
  class="tag"
  [matTooltip]="hover"
  [matTooltipPosition]="'above'"
  [ngStyle]="getSlotStyle()"
>
  <span [ngClass]="{ 'has-button': canRemove, 'has-color': color }">{{ label }}</span>
  <rk-icon-button
    *ngIf="canRemove"
    (click)="clickRemove()"
    faIcon="fa-times"
    [style]="{ backgroundColor: '#FFFFFF', padding: '6px' }"
  ></rk-icon-button>
</div>
