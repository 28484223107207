import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject } from 'rxjs';

export interface MobileSidenavOptions {
  from?: 'right' | 'bottom' | 'left' | 'full';
  hasBackdrop?: boolean;
  hasPadding?: boolean;
  style?: object;
  closeAction?: any;
  submitAction?: any;
  bgType?: 'white' | 'gray';
  bottomFullHeight?: boolean;
  noScroll?: boolean;
  data?: any;
}

export interface MobileDialog {
  dialog: ComponentType<any>;
  options: MobileSidenavOptions;
}
@Injectable({
  providedIn: 'root',
})
export class MobileSidenavService {
  constructor(private readonly store: Store) {}
  private readonly openedDialog$ = new BehaviorSubject<MobileDialog>(null);

  public openedDialog() {
    return this.openedDialog$;
  }

  public open(component: ComponentType<any>, options?: MobileSidenavOptions) {
    this.openedDialog$.next({ dialog: component, options });
  }

  public close(force = false) {
    if (!force && this.openedDialog$.value?.options?.closeAction) {
      this.store.dispatch(this.openedDialog$.value.options.closeAction);
      return;
    }
    this.openedDialog$.next(null);
  }
}
