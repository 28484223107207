import { RADIO_PLAN_DESC } from '@app/core/models/Radio';

export interface DiscoverRoute {
  prefix: string;
  targetPlan?: RADIO_PLAN_DESC; // Default is Discover
  locked?: string[];
  redirectTo?: string;
}
export const LOCKED_ROUTES: DiscoverRoute[] = [
  {
    prefix: 'settings',
    targetPlan: 'Demo',
    locked: ['platform', 'visibility', 'reports'],
    redirectTo: 'radio',
  },
  {
    prefix: 'settings',
    locked: ['covers', 'platform', 'visibility', 'social', 'reports'],
    redirectTo: 'radio',
  },
  {
    prefix: 'statistics',
    locked: ['listeners', 'sessions', 'duration', 'location', 'sources', 'musics'],
    redirectTo: 'live',
  },
  {
    prefix: 'widgets',
    locked: [
      'player',
      'audio_shoutouts',
      'played_tracks',
      'top_tracks',
      'current_track',
      'next_track',
    ],
    redirectTo: 'smartlink',
  },
  {
    prefix: 'team',
  },
  {
    prefix: 'team',
    targetPlan: 'Demo',
  },
  {
    prefix: 'live',
  },
];
