import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { ChecklistStep, CompleteStep } from '@radioking/onboarding-checklist';
import { ClipboardService, ToasterService } from '@radioking/shared/common-services';

@Component({
  selector: 'rk-input-with-copy',
  templateUrl: './input-with-copy.component.html',
  styleUrls: ['./input-with-copy.component.scss'],
})
export class InputWithCopyComponent {
  @Input()
  hintQuestionMark: string;
  @Input()
  label: string;
  @Input()
  value: string;
  @HostBinding('class.disabled')
  @Input()
  disabled = false;

  @Input()
  pulse = false;

  @Input()
  shareStep = false;

  @HostBinding('class.disable-open')
  @Input()
  disableOpen = false;

  @HostBinding('class.keep-width')
  @Input()
  keepWidth = false;

  @Output()
  readonly urlOpened = new EventEmitter();

  constructor(
    private readonly clipboard: ClipboardService,
    private readonly toaster: ToasterService,
  ) {}

  openURL() {
    if (this.shareStep) {
      // Share step in Onboarding checklist
      this.completeShareStep();
      this.urlOpened.emit();
    }
    window.open(this.value, '_blank');
  }

  copyLink() {
    this.clipboard.copyMessage(this.value);
    this.toaster.success(marker('TOAST.COPY_SUCCESS'));
  }

  @Dispatch()
  completeShareStep = () => new CompleteStep(ChecklistStep.SHARE_SMARTLINK);
}
