import { Injectable } from '@angular/core';
import { AuthState } from '@app/core/states/auth.state';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class BigNumberService {
  userLang: string;
  constructor(private readonly store: Store) {
    this.userLang =
      this.store.selectSnapshot(AuthState.userLang).substr(0, 2) === 'fr'
        ? 'fr-FR'
        : 'en-US';
  }

  format(val: number): string {
    return Intl.NumberFormat(this.userLang).format(val);
  }
}
