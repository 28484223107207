import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { UiButtonModule } from '@radioking/ui/button';
import { UiPlaylistIconModule } from '@radioking/ui/playlist-icon';
import { UiQuestionMarkModule } from '@radioking/ui/question-mark';
import { UiTooltipModule } from '@radioking/ui/tooltip';

import { ChipsInputComponent } from './component/chips-input/chips-input.component';
import { DropdownAutocompleteComponent } from './component/dropdown-autocomplete/dropdown-autocomplete.component';
import { FormErrorComponent } from './component/form-error/form-error.component';
import { FormFieldComponent } from './component/form-field/form-field.component';
import { InputWithCopyComponent } from './component/input-with-copy/input-with-copy.component';
import { InputWithSuggestionsComponent } from './component/input-with-suggestions/input-with-suggestions.component';
import { SearchBarComponent } from './component/search-bar/search-bar.component';
import { TagComponent } from './component/tag/tag.component';
import { InputDirective } from './directive/input.directive';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ScrollingModule,
    UiButtonModule,
    MatTooltipModule,
    MatIconModule,
    UiPlaylistIconModule,
    UiTooltipModule,
    UiQuestionMarkModule,
  ],
  declarations: [
    InputDirective,
    TagComponent,
    SearchBarComponent,
    InputWithSuggestionsComponent,
    InputWithCopyComponent,
    FormFieldComponent,
    FormErrorComponent,
    DropdownAutocompleteComponent,
    ChipsInputComponent,
  ],
  exports: [
    InputDirective,
    TagComponent,
    SearchBarComponent,
    InputWithSuggestionsComponent,
    InputWithCopyComponent,
    FormFieldComponent,
    FormErrorComponent,
    DropdownAutocompleteComponent,
    ChipsInputComponent,
  ],
})
export class UiInputModule {}
