<rk-modal-wrapper
  (submitted)="onSubmit()"
  (cancelled)="onCancel()"
  (closed)="onCancel()"
  [title]="
    canSave
      ? ('MEDIA.MODAL.UNSAVED_CHANGES.TITLE_CAN_SAVE' | translate)
      : ('MEDIA.MODAL.UNSAVED_CHANGES.TITLE' | translate)
  "
  [submitText]="
    canSave
      ? ('MEDIA.MODAL.UNSAVED_CHANGES.SAVE_CHANGES' | translate)
      : ('MEDIA.MODAL.UNSAVED_CHANGES.ACTION_BUTTON' | translate)
  "
>
  <div *ngIf="!canSave">{{ 'MEDIA.MODAL.UNSAVED_CHANGES.BODY' | translate }}</div>
  <div *ngIf="canSave">{{ 'MEDIA.MODAL.UNSAVED_CHANGES.BODY_CAN_SAVE' | translate }}</div>
</rk-modal-wrapper>
