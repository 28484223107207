import {
  Consumption,
  DashboardOverview,
  Followers,
  ListenerTime,
  Peak,
  Ranking,
  Timeline,
} from '../models/Dashboard';

const tag = '[Dashboard]';

export class ConsumptionRequest {
  static readonly type = `${tag} request consumption`;
}

export class ConsumptionSuccess {
  static readonly type = `${tag} success consumption`;

  constructor(public consumption: Consumption) {}
}

export class ConsumptionFailure {
  static readonly type = `${tag} error consumption`;

  constructor(public error: Error) {}
}

export class ListenersPeriodRequest {
  static readonly type = `${tag} request listeners period`;

  constructor(public from: string) {}
}

export class ListenersPeriodSuccess {
  static readonly type = `${tag} success listeners period`;

  constructor(public listeners: ListenerTime[]) {}
}

export class ListenersPeriodFailure {
  static readonly type = `${tag} error listeners period`;

  constructor(public error: Error) {}
}

export class ListenersPeakRequest {
  static readonly type = `${tag} request listeners peak`;
}

export class ListenersPeakSuccess {
  static readonly type = `${tag} success listeners peak`;

  constructor(public peak: Peak) {}
}

export class ListenersPeakFailure {
  static readonly type = `${tag} error listeners peak`;

  constructor(public error: Error) {}
}

export class UpdatePeak {
  static readonly type = `${tag} update listeners peak`;

  constructor(public peak: Peak) {}
}

export class FollowersRequest {
  static readonly type = `${tag} request radio followers`;
}

export class FollowersSuccess {
  static readonly type = `${tag} success radio followers`;

  constructor(public followers: Followers) {}
}

export class FollowersFailure {
  static readonly type = `${tag} error radio followers`;

  constructor(public error: Error) {}
}

export class RankingRequest {
  static readonly type = `${tag} request radio ranking`;
}

export class RankingSuccess {
  static readonly type = `${tag} success radio ranking`;

  constructor(public ranking: Ranking) {}
}

export class RankingFailure {
  static readonly type = `${tag} error radio ranking`;

  constructor(public error: Error) {}
}

export class DashboardOverviewRequest {
  static readonly type = `${tag} request dashboard overview`;
}

export class DashboardOverviewSuccess {
  static readonly type = `${tag} success dashboard overview`;

  constructor(public overview: DashboardOverview) {}
}

export class DashboardOverviewFailure {
  static readonly type = `${tag} error dashboard overview`;

  constructor(public error: Error) {}
}

export class TimelineRequest {
  static readonly type = `${tag} request timeline`;
}

export class TimelineUpdateRequest {
  static readonly type = `${tag} request update timeline`;
}

export class TimelineSuccess {
  static readonly type = `${tag} success timeline`;

  constructor(public timeline: Timeline) {}
}

export class TimelineFailure {
  static readonly type = `${tag} error timeline`;

  constructor(public error: Error) {}
}
