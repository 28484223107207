import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import {
  RoleHelperService,
  RoleOperatorActions,
  RoleOperators,
} from '@app/core/services/role-helper.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[rkHasRole]',
})
export class HasRoleDirective {
  isViewDisplayed = false;

  @Input('rkHasRole')
  set inputCondition(input: RoleOperators | string) {
    if (!input) {
      this.showContent();

      return;
    }

    let searchTearm: RoleOperators;
    if (typeof input === 'string') {
      searchTearm = {
        type: RoleOperatorActions.SPECIFIC,
        roleId: input,
      };
    } else {
      searchTearm = input;
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.roleHelper.hasRole(searchTearm).subscribe(val => {
      if (val) {
        this.showContent();
      } else {
        this.hideContent();
      }
    });
  }

  subscription: Subscription;

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly roleHelper: RoleHelperService,
  ) {}

  showContent() {
    if (this.isViewDisplayed) {
      return;
    }
    this.isViewDisplayed = true;
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  hideContent() {
    if (!this.isViewDisplayed) {
      return;
    }
    this.isViewDisplayed = false;
    this.viewContainer.clear();
  }
}
