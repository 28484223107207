import { Injectable } from '@angular/core';

import { RedirectToLogin } from '@app/core/states/auth.actions';
import { AuthState } from '@app/core/states/auth.state';
import { Store } from '@ngxs/store';
import { Logger } from '@radioking/shared/logger';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const log = new Logger('AuthenticationGuard');

@Injectable()
export class AuthenticationGuard {
  constructor(private readonly store: Store) {}

  canActivate(): Observable<boolean> {
    return this.store.select(AuthState.isAuthenticated).pipe(
      map((isAuth: boolean) => {
        if (isAuth) {
          return true;
        }
        log.debug('Not authenticated, redirecting...');
        this.store.dispatch(new RedirectToLogin());

        return false;
      }),
    );
  }

  canActivateChild(): Observable<boolean> {
    return this.canActivate();
  }
}
