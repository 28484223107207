import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { AuthState } from '@app/core/states/auth.state';
import { Select } from '@ngxs/store';
import { MobileSidenavService } from '@radioking/shared/common-services';
import { MobileUserMenuComponent } from '@radioking/ui/mobile-user-menu';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'rk-mobile-side-header',
  templateUrl: './mobile-side-header.component.html',
  styleUrls: ['./mobile-side-header.component.scss'],
})
export class MobileSideHeaderComponent implements OnDestroy {
  @Input()
  showUserMenu = true;

  @Output()
  readonly closed = new EventEmitter();

  @Select(AuthState.currentUsername)
  username$: Observable<string>;

  @Select(AuthState.currentProfilePic)
  profilePic$: Observable<string>;

  subscription = new Subscription();

  constructor(private readonly mobileSidenavService: MobileSidenavService) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openUserMenu() {
    this.mobileSidenavService.open(MobileUserMenuComponent, { from: 'right' });
  }
}
