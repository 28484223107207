export const PANELS_KEYS = {
  EDIT_TRACK_INFO: 'edit-track-info',
  CREATE_PLAYLIST: 'create-playlist',
  EDIT_PLAYLIST: 'edit-playlist',
  FILTER_EDIT: 'filter-edit-panel',
  ADD_TO_PLAYLIST: 'add-to-playlist',
  NEW_ANIMATOR: 'new-animator',
  EDIT_ANIMATOR: 'edit-animator',
  EDIT_TAG: 'edit-tag',
  CREATE_PROGRAM: 'create-program',
  EDIT_PROGRAM: 'edit-program',
  TRACK_DETAILS: 'track-details',
  ADD_TO_GENERATION: 'add-to-generation',
  PROGRAM_DETAILS: 'program-details',
  PLANNING_SLOT_ADD: 'planning-slot-add',
  LISTENERS_LIST: 'listeners-list',
};
