import { Track, TrackCues } from '@app/core/models/Track';

const tag = '[TrackMixPoints]';

export class TrackCuesRequest {
  static readonly type = `${tag} request track cues`;
  constructor(public track: Track) {}
}

export class TrackCuesSuccess {
  static readonly type = `${tag} success track cues`;
  constructor(public track: Track, public cues: TrackCues) {}
}

export class TrackCuesFailure {
  static readonly type = `${tag} error track cues`;
  constructor(public error: Error) {}
}

export class PreviousTrackCuesRequest {
  static readonly type = `${tag} request previous track cues`;
  constructor(public track: Track) {}
}

export class PreviousTrackCuesSuccess {
  static readonly type = `${tag} success previous track cues`;
  constructor(public track: Track, public cues: TrackCues) {}
}

export class PreviousTrackCuesFailure {
  static readonly type = `${tag} error previous track cues`;
  constructor(public error: Error) {}
}

export class DeletePreviousTrackCues {
  static readonly type = `${tag} delete previous track cues`;
}

export class NextTrackCuesRequest {
  static readonly type = `${tag} request next track cues`;
  constructor(public track: Track) {}
}

export class NextTrackCuesSuccess {
  static readonly type = `${tag} success next track cues`;
  constructor(public track: Track, public cues: TrackCues) {}
}

export class NextTrackCuesFailure {
  static readonly type = `${tag} error next track cues`;
  constructor(public error: Error) {}
}

export class DeleteNextTrackCues {
  static readonly type = `${tag} delete next track cues`;
}

export class UpdateTrackCuesRequest {
  static readonly type = `${tag} request update track cues`;
  constructor(public track: Track, public cues: TrackCues) {}
}

export class UpdateTrackCuesSuccess {
  static readonly type = `${tag} success update track cues`;
  constructor(public track: Track, public cues: TrackCues) {}
}

export class UpdateTrackCuesFailure {
  static readonly type = `${tag} error update track cues`;
  constructor(public error: Error) {}
}

export class CalculateTrackCuesRequest {
  static readonly type = `${tag} request calculate track cues`;
  constructor(public track: Track) {}
}

export class CalculateTrackCuesSuccess {
  static readonly type = `${tag} success calculate track cues`;
  constructor(public cues: TrackCues) {}
}

export class CalculateTrackCuesFailure {
  static readonly type = `${tag} error calculate track cues`;
  constructor(public error: Error) {}
}

export class SavePreviousTrack {
  static readonly type = `${tag} save previous mix point track`;
  constructor(public track: Track) {}
}

export class SaveNextTrack {
  static readonly type = `${tag} save next mix point track`;
  constructor(public track: Track) {}
}
