// Original source code: https://github.com/fetrarij/ngx-daterangepicker-material
// @ts-nocheck
/* tslint:disable */
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Inject,
  InjectionToken,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import amplitude from 'amplitude-js';
import moment from 'moment-timezone';
import { DateRange } from '../daterangepicker.directive';

export const CONTAINER_DATA = new InjectionToken<{}>('CONTAINER_DATA');

export enum SideEnum {
  left = 'left',
  right = 'right',
}

export interface LocaleConfig {
  direction?: string;
  separator?: string;
  weekLabel?: string;
  applyLabel?: string;
  cancelLabel?: string;
  customRangeLabel?: string;
  daysOfWeek?: string[];
  monthNames?: string[];
  firstDay?: number;
  format?: string;
}
/**
 *  DefaultLocaleConfig
 */
export const DefaultLocaleConfig: LocaleConfig = {
  direction: 'ltr',
  separator: ' - ',
  weekLabel: 'W',
  applyLabel: 'Apply',
  cancelLabel: 'Cancel',
  customRangeLabel: 'Custom range',
  daysOfWeek: moment.weekdaysMin(),
  monthNames: moment.months(),
  firstDay: moment.localeData().firstDayOfWeek(),
};

@Component({
  selector: 'rk-daterangepicker',
  templateUrl: './daterangepicker.component.html',
  styleUrls: ['./daterangepicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DaterangepickerComponent),
      multi: true,
    },
  ],
})
export class DaterangepickerComponent implements OnInit {
  private readonly _old: { start: any; end: any } = { start: null, end: null };
  chosenLabel: string;
  calendarVariables: { left: any; right: any } = { left: {}, right: {} };
  timepickerVariables: { left: any; right: any } = { left: {}, right: {} };
  daterangepicker: { start: UntypedFormControl; end: UntypedFormControl } = {
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  };
  applyBtn: { disabled: boolean } = { disabled: false };

  dateLimit = null;
  // used in template for compile time support of enum values.
  sideEnum = SideEnum;

  startDate = moment().subtract(29, 'days').startOf('day');
  endDate = moment().endOf('day');
  isRangeFirstDate = false;

  @Input()
  minDate: moment.Moment = null;
  @Input()
  maxDate: moment.Moment = null;

  autoApply = false;
  singleDatePicker = false;
  linkedCalendars = false;

  @Input()
  showDropdowns = false;
  @Input()
  showWeekNumbers = false;
  @Input()
  showISOWeekNumbers = false;
  @Input()
  autoUpdateInput = true;
  @Input()
  alwaysShowCalendars = false;
  @Input()
  maxSpan = false;
  // timepicker variables
  @Input()
  timePicker = false;
  @Input()
  timePicker24Hour = false;
  @Input()
  timePickerIncrement = 1;
  @Input()
  timePickerSeconds = false;
  // end of timepicker variables
  @Input()
  showClearButton = false;
  @Input()
  firstMonthDayClass: string = null;
  @Input()
  lastMonthDayClass: string = null;
  @Input()
  emptyWeekRowClass: string = null;
  @Input()
  firstDayOfNextMonthClass: string = null;
  @Input()
  lastDayOfPreviousMonthClass: string = null;
  _locale: LocaleConfig = {};
  @Input() set locale(value) {
    this._locale = { ...DefaultLocaleConfig, ...value };
  }
  get locale(): any {
    return this._locale;
  }
  // custom ranges
  _ranges: any = {};

  @Input() set ranges(value) {
    this._ranges = value;
    this.renderRanges();
  }
  get ranges(): any {
    return this._ranges;
  }

  @Input()
  highlightDates: string[];
  @Input()
  showCustomRangeLabel: boolean;
  @Input()
  showCancel = false;
  @Input()
  keepCalendarOpeningWithRange = false;
  @Input()
  showRangeLabelOnInput = false;
  chosenRange: string;
  rangesArray: Array<any> = [];

  // Amplitude
  @Input()
  rangeKeys: object;
  @Input()
  amplitudeEvent: string;

  // some state information
  isShown: Boolean = false;
  inline = true;
  leftCalendar: any = {};
  rightCalendar: any = {};
  showRanges: boolean;
  defaultRange: DateRange = null;

  options: any = {}; // should get some opt from user
  @Input() drops: string;
  @Input() opens: string;
  @Output() readonly choosedDate: EventEmitter<Object> = new EventEmitter();
  @Output() readonly rangeClicked: EventEmitter<Object> = new EventEmitter();
  @Output() readonly datesUpdated: EventEmitter<Object> = new EventEmitter();
  @ViewChild('pickerContainer', { static: true }) pickerContainer: ElementRef;

  constructor(
    private readonly _ref: ChangeDetectorRef,
    private readonly translate: TranslateService,
    @Inject(CONTAINER_DATA) public data,
  ) {
    this.locale = {
      direction: 'ltr',
      separator: ' - ',
      weekLabel: 'W',
      applyLabel: 'Apply',
      cancelLabel: 'Cancel',
      customRangeLabel: 'Custom range',
      daysOfWeek: moment.weekdaysMin(),
      monthNames: moment.months(),
      firstDay: moment.localeData().firstDayOfWeek(),
    };
    this.updateMonthsInView();
    this.singleDatePicker = data.singleDatePicker;
    this.autoApply = data.autoApply;
    this.linkedCalendars = data.linkedCalendars;

    this.minDate = data.minDate;
    this.maxDate = data.maxDate;
    if (data.startDate) {
      this.startDate = data.startDate;
      this.endDate = data.endDate;
    }
    if (data.isRangeFirstDate) {
      this.isRangeFirstDate = data.isRangeFirstDate;
    }
    if (data.defaultRange) {
      this.defaultRange = data.defaultRange;
    }
    this.showRanges = data.showRanges;
    if (data.showRanges) {
      this.ranges = {
        [this.translate.instant('GLOBAL.DATEPICKER.YESTERDAY')]: [
          moment().subtract(1, 'days'),
          moment().subtract(1, 'days'),
        ],
        [this.translate.instant('GLOBAL.DATEPICKER.LAST_7_DAYS')]: [
          moment().subtract(6, 'days'),
          moment(),
        ],
        [this.translate.instant('GLOBAL.DATEPICKER.LAST_30_DAYS')]: [
          moment().subtract(29, 'days'),
          moment(),
        ],
        [this.translate.instant('GLOBAL.DATEPICKER.LAST_90_DAYS')]: [
          moment().subtract(89, 'days'),
          moment(),
        ],
        [this.translate.instant('GLOBAL.DATEPICKER.LAST_MONTH')]: [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month'),
        ],
      };
      this.rangeKeys = {
        [this.translate.instant('GLOBAL.DATEPICKER.YESTERDAY')]: 'yesterday',
        [this.translate.instant('GLOBAL.DATEPICKER.LAST_7_DAYS')]: 'last_7_days',
        [this.translate.instant('GLOBAL.DATEPICKER.LAST_30_DAYS')]: 'last_30_days',
        [this.translate.instant('GLOBAL.DATEPICKER.LAST_90_DAYS')]: 'last_90_days',
        [this.translate.instant('GLOBAL.DATEPICKER.LAST_MONTH')]: 'last_month',
      };
    }
    if (data.amplitudeEvent) {
      this.amplitudeEvent = data.amplitudeEvent;
    }
    if (data.highlightDates) {
      this.highlightDates = data.highlightDates;
    }
  }

  ngOnInit() {
    if (this.locale.firstDay !== 0) {
      let iterator = this.locale.firstDay;
      while (iterator > 0) {
        this.locale.daysOfWeek.push(this.locale.daysOfWeek.shift());
        iterator--;
      }
    }
    if (this.inline) {
      this._old.start = this.startDate.clone();
      this._old.end = this.endDate.clone();
    }
    if (!this.locale.format) {
      if (this.timePicker) {
        this.locale.format = moment.localeData().longDateFormat('lll');
      } else {
        this.locale.format = moment.localeData().longDateFormat('L');
      }
    }
    this.renderCalendar(SideEnum.left);
    this.renderCalendar(SideEnum.right);
    this.renderRanges();
  }
  renderRanges() {
    this.rangesArray = [];
    let start, end;
    if (typeof this.ranges === 'object') {
      for (const range in this.ranges) {
        if (!range) {
          continue;
        }
        if (typeof this.ranges[range][0] === 'string') {
          start = moment(this.ranges[range][0], this.locale.format);
        } else {
          start = moment(this.ranges[range][0]);
        }

        if (typeof this.ranges[range][1] === 'string') {
          end = moment(this.ranges[range][1], this.locale.format);
        } else {
          end = moment(this.ranges[range][1]);
        }

        // If the start or end date exceed those allowed by the minDate or maxSpan
        // options, shorten the range to the allowable period.
        if (this.minDate && start.isBefore(this.minDate)) {
          start = this.minDate.clone();
        }

        let maxDate = this.maxDate;
        if (this.maxSpan && maxDate && start.clone().add(this.maxSpan).isAfter(maxDate)) {
          maxDate = start.clone().add(this.maxSpan);
        }
        if (maxDate && end.isAfter(maxDate)) {
          end = maxDate.clone();
        }

        // If the end of the range is before the minimum or the start of the range is
        // after the maximum, don't display this range option at all.
        if (
          (this.minDate &&
            end.isBefore(this.minDate, this.timePicker ? 'minute' : 'day')) ||
          (maxDate && start.isAfter(maxDate, this.timePicker ? 'minute' : 'day'))
        ) {
          continue;
        }

        start.startOf('day');

        // Support unicode chars in the range names.
        const elem = document.createElement('textarea');
        elem.innerHTML = range;
        const rangeHtml = elem.value;

        this.ranges[rangeHtml] = [start, end];
      }
      for (const range in this.ranges) {
        if (!range) {
          continue;
        }
        this.rangesArray.push(range);
      }
      if (this.showCustomRangeLabel) {
        this.rangesArray.push(this.locale.customRangeLabel);
      }
      if (!this.timePicker) {
        this.startDate = this.startDate.startOf('day');
        this.endDate = this.endDate.endOf('day');
      }
      // can't be used together for now
      if (this.timePicker && this.autoApply) {
        this.autoApply = false;
      }
    }
  }
  renderTimePicker(side: SideEnum) {
    if (side === SideEnum.right && !this.endDate) {
      return;
    }
    let selected, minDate;
    const maxDate = this.maxDate;
    if (side === SideEnum.left) {
      selected = this.startDate.clone();
      minDate = this.minDate;
    } else if (side === SideEnum.right) {
      selected = this.endDate.clone();
      minDate = this.startDate;
    }
    const start = this.timePicker24Hour ? 0 : 1;
    const end = this.timePicker24Hour ? 23 : 12;
    this.timepickerVariables[side] = {
      hours: [],
      minutes: [],
      minutesLabel: [],
      seconds: [],
      secondsLabel: [],
      disabledHours: [],
      disabledMinutes: [],
      disabledSeconds: [],
      selectedHour: 0,
      selectedMinute: 0,
      selectedSecond: 0,
    };
    // generate hours
    for (let i = start; i <= end; i++) {
      let i_in_24 = i;
      if (!this.timePicker24Hour) {
        i_in_24 = selected.hour() >= 12 ? (i === 12 ? 12 : i + 12) : i === 12 ? 0 : i;
      }

      const time = selected.clone().hour(i_in_24);
      let disabled = false;
      if (minDate && time.minute(59).isBefore(minDate)) {
        disabled = true;
      }
      if (maxDate && time.minute(0).isAfter(maxDate)) {
        disabled = true;
      }

      this.timepickerVariables[side].hours.push(i);
      if (i_in_24 === selected.hour() && !disabled) {
        this.timepickerVariables[side].selectedHour = i;
      } else if (disabled) {
        this.timepickerVariables[side].disabledHours.push(i);
      }
    }
    // generate minutes
    for (let i = 0; i < 60; i += this.timePickerIncrement) {
      const padded = i < 10 ? '0' + i : i;
      const time = selected.clone().minute(i);

      let disabled = false;
      if (minDate && time.second(59).isBefore(minDate)) {
        disabled = true;
      }
      if (maxDate && time.second(0).isAfter(maxDate)) {
        disabled = true;
      }
      this.timepickerVariables[side].minutes.push(i);
      this.timepickerVariables[side].minutesLabel.push(padded);
      if (selected.minute() === i && !disabled) {
        this.timepickerVariables[side].selectedMinute = i;
      } else if (disabled) {
        this.timepickerVariables[side].disabledMinutes.push(i);
      }
    }
    // generate seconds
    if (this.timePickerSeconds) {
      for (let i = 0; i < 60; i++) {
        const padded = i < 10 ? '0' + i : i;
        const time = selected.clone().second(i);

        let disabled = false;
        if (minDate && time.isBefore(minDate)) {
          disabled = true;
        }
        if (maxDate && time.isAfter(maxDate)) {
          disabled = true;
        }

        this.timepickerVariables[side].seconds.push(i);
        this.timepickerVariables[side].secondsLabel.push(padded);
        if (selected.second() === i && !disabled) {
          this.timepickerVariables[side].selectedSecond = i;
        } else if (disabled) {
          this.timepickerVariables[side].disabledSeconds.push(i);
        }
      }
    }
    // generate AM/PM
    if (!this.timePicker24Hour) {
      if (minDate && selected.clone().hour(12).minute(0).second(0).isBefore(minDate)) {
        this.timepickerVariables[side].amDisabled = true;
      }

      if (maxDate && selected.clone().hour(0).minute(0).second(0).isAfter(maxDate)) {
        this.timepickerVariables[side].pmDisabled = true;
      }
      if (selected.hour() >= 12) {
        this.timepickerVariables[side].ampmModel = 'PM';
      } else {
        this.timepickerVariables[side].ampmModel = 'AM';
      }
    }
    this.timepickerVariables[side].selected = selected;
  }
  renderCalendar(side: SideEnum) {
    // side enum
    const mainCalendar: any =
      side === SideEnum.left ? this.leftCalendar : this.rightCalendar;
    const month = mainCalendar.month.month();
    const year = mainCalendar.month.year();
    const hour = mainCalendar.month.hour();
    const minute = mainCalendar.month.minute();
    const second = mainCalendar.month.second();
    const daysInMonth = moment([year, month]).daysInMonth();
    const firstDay = moment([year, month, 1]);
    const lastDay = moment([year, month, daysInMonth]);
    const lastMonth = moment(firstDay).subtract(1, 'month').month();
    const lastYear = moment(firstDay).subtract(1, 'month').year();
    const daysInLastMonth = moment([lastYear, lastMonth]).daysInMonth();
    const dayOfWeek = firstDay.day();
    // initialize a 6 rows x 7 columns array for the calendar
    const calendar: any = [];
    calendar.firstDay = firstDay;
    calendar.lastDay = lastDay;

    for (let i = 0; i < 6; i++) {
      calendar[i] = [];
    }

    // populate the calendar with date objects
    let startDay = daysInLastMonth - dayOfWeek + this.locale.firstDay + 1;
    if (startDay > daysInLastMonth) {
      startDay -= 7;
    }
    if (dayOfWeek === this.locale.firstDay) {
      startDay = daysInLastMonth - 6;
    }

    let curDate = moment([lastYear, lastMonth, startDay, 12, minute, second]);

    for (
      let i = 0, col = 0, row = 0;
      i < 42;
      i++, col++, curDate = moment(curDate).add(24, 'hour')
    ) {
      if (i > 0 && col % 7 === 0) {
        col = 0;
        row++;
      }
      calendar[row][col] = curDate.clone().hour(hour).minute(minute).second(second);
      curDate.hour(12);

      if (
        this.minDate &&
        calendar[row][col].format('YYYY-MM-DD') === this.minDate.format('YYYY-MM-DD') &&
        calendar[row][col].isBefore(this.minDate) &&
        side === 'left'
      ) {
        calendar[row][col] = this.minDate.clone();
      }

      if (
        this.maxDate &&
        calendar[row][col].format('YYYY-MM-DD') === this.maxDate.format('YYYY-MM-DD') &&
        calendar[row][col].isAfter(this.maxDate) &&
        side === 'right'
      ) {
        calendar[row][col] = this.maxDate.clone();
      }
    }

    // make the calendar object available to hoverDate/clickDate
    if (side === SideEnum.left) {
      this.leftCalendar.calendar = calendar;
    } else {
      this.rightCalendar.calendar = calendar;
    }
    //
    // Display the calendar
    //
    const minDate = side === 'left' ? this.minDate : this.startDate;
    let maxDate = this.maxDate;
    // adjust maxDate to reflect the dateLimit setting in order to
    // grey out end dates beyond the dateLimit
    if (this.endDate === null && this.dateLimit) {
      const maxLimit = this.startDate.clone().add(this.dateLimit).endOf('day');
      if (!maxDate || maxLimit.isBefore(maxDate)) {
        maxDate = maxLimit;
      }
    }
    this.calendarVariables[side] = {
      month,
      year,
      hour,
      minute,
      second,
      daysInMonth,
      firstDay,
      lastDay,
      lastMonth,
      lastYear,
      daysInLastMonth,
      dayOfWeek,
      // other vars
      calRows: Array.from(Array(6).keys()),
      calCols: Array.from(Array(7).keys()),
      classes: {},
      minDate,
      maxDate,
      calendar,
    };
    if (this.showDropdowns) {
      const currentMonth = calendar[1][1].month();
      const currentYear = calendar[1][1].year();
      const maxYear = (maxDate && maxDate.year()) || currentYear + 5;
      const minYear = (minDate && minDate.year()) || currentYear - 50;
      const inMinYear = currentYear === minYear;
      const inMaxYear = currentYear === maxYear;
      const years = [];
      for (let y = minYear; y <= maxYear; y++) {
        years.push(y);
      }
      this.calendarVariables[side].dropdowns = {
        currentMonth,
        currentYear,
        maxYear,
        minYear,
        inMinYear,
        inMaxYear,
        monthArrays: Array.from(Array(12).keys()),
        yearArrays: years,
      };
    }

    this._buildCells(calendar, side);
  }
  setStartDate(startDate) {
    if (typeof startDate === 'string') {
      this.startDate = moment(startDate, this.locale.format);
    }

    if (typeof startDate === 'object') {
      this.startDate = moment(startDate);
    }
    if (!this.timePicker) {
      this.startDate = this.startDate.startOf('day');
    }

    if (this.timePicker && this.timePickerIncrement) {
      this.startDate.minute(
        Math.round(this.startDate.minute() / this.timePickerIncrement) *
          this.timePickerIncrement,
      );
    }

    if (this.minDate && this.startDate.isBefore(this.minDate)) {
      this.startDate = this.minDate.clone();
      if (this.timePicker && this.timePickerIncrement) {
        this.startDate.minute(
          Math.round(this.startDate.minute() / this.timePickerIncrement) *
            this.timePickerIncrement,
        );
      }
    }

    if (this.maxDate && this.startDate.isAfter(this.maxDate)) {
      this.startDate = this.maxDate.clone();
      if (this.timePicker && this.timePickerIncrement) {
        this.startDate.minute(
          Math.floor(this.startDate.minute() / this.timePickerIncrement) *
            this.timePickerIncrement,
        );
      }
    }

    if (!this.isShown) {
      this.updateElement();
    }

    this.updateMonthsInView();
  }

  setEndDate(endDate) {
    if (typeof endDate === 'string') {
      this.endDate = moment(endDate, this.locale.format);
    }

    if (typeof endDate === 'object') {
      this.endDate = moment(endDate);
    }
    if (!this.timePicker) {
      this.endDate = this.endDate.add(1, 'd').startOf('day').subtract(1, 'second');
    }

    if (this.timePicker && this.timePickerIncrement) {
      this.endDate.minute(
        Math.round(this.endDate.minute() / this.timePickerIncrement) *
          this.timePickerIncrement,
      );
    }

    if (this.endDate.isBefore(this.startDate)) {
      this.endDate = this.startDate.clone();
    }

    if (this.maxDate && this.endDate.isAfter(this.maxDate)) {
      this.endDate = this.maxDate.clone();
    }

    if (
      this.dateLimit &&
      this.startDate.clone().add(this.dateLimit).isBefore(this.endDate)
    ) {
      this.endDate = this.startDate.clone().add(this.dateLimit);
    }

    if (!this.isShown) {
      // this.updateElement();
    }
    this.updateMonthsInView();
  }
  @Input()
  isInvalidDate(date) {
    return false;
  }
  @Input()
  isCustomDate(date) {
    return false;
  }

  updateView() {
    if (this.timePicker) {
      this.renderTimePicker(SideEnum.left);
      this.renderTimePicker(SideEnum.right);
    }
    this.updateMonthsInView();
    this.updateCalendars();
  }

  updateMonthsInView() {
    if (this.endDate) {
      // if both dates are visible already, do nothing
      if (
        !this.singleDatePicker &&
        this.leftCalendar.month &&
        this.rightCalendar.month &&
        ((this.startDate &&
          this.leftCalendar &&
          this.startDate.format('YYYY-MM') ===
            this.leftCalendar.month.format('YYYY-MM')) ||
          (this.startDate &&
            this.rightCalendar &&
            this.startDate.format('YYYY-MM') ===
              this.rightCalendar.month.format('YYYY-MM'))) &&
        (this.endDate.format('YYYY-MM') === this.leftCalendar.month.format('YYYY-MM') ||
          this.endDate.format('YYYY-MM') === this.rightCalendar.month.format('YYYY-MM'))
      ) {
        return;
      }
      if (this.startDate) {
        this.leftCalendar.month = this.startDate.clone().date(2);
        if (
          !this.linkedCalendars &&
          (this.endDate.month() !== this.startDate.month() ||
            this.endDate.year() !== this.startDate.year())
        ) {
          this.rightCalendar.month = this.endDate.clone().date(2);
        } else {
          this.rightCalendar.month = this.startDate.clone().date(2).add(1, 'month');
        }
      }
    } else {
      if (
        this.leftCalendar.month.format('YYYY-MM') !== this.startDate.format('YYYY-MM') &&
        this.rightCalendar.month.format('YYYY-MM') !== this.startDate.format('YYYY-MM')
      ) {
        this.leftCalendar.month = this.startDate.clone().date(2);
        this.rightCalendar.month = this.startDate.clone().date(2).add(1, 'month');
      }
    }
    if (
      this.maxDate &&
      this.linkedCalendars &&
      !this.singleDatePicker &&
      this.rightCalendar.month > this.maxDate
    ) {
      this.rightCalendar.month = this.maxDate.clone().date(2);
      this.leftCalendar.month = this.maxDate.clone().date(2).subtract(1, 'month');
    }
  }
  /**
   *  This is responsible for updating the calendars
   */
  updateCalendars() {
    this.renderCalendar(SideEnum.left);
    this.renderCalendar(SideEnum.right);

    if (this.endDate === null) {
      return;
    }
    this.calculateChosenLabel();
  }
  updateElement() {
    if (!this.singleDatePicker && this.autoUpdateInput) {
      if (this.startDate && this.endDate) {
        // if we use ranges and should show range label on inpu
        if (
          this.rangesArray.length &&
          this.showRangeLabelOnInput &&
          this.chosenRange &&
          this.locale.customRangeLabel !== this.chosenRange
        ) {
          this.chosenLabel = this.chosenRange;
        } else {
          this.chosenLabel = this.isRangeFirstDate
            ? this.startDate.format(this.locale.format)
            : this.endDate.format(this.locale.format);
        }
      }
    } else if (this.autoUpdateInput) {
      this.chosenLabel = this.startDate.format(this.locale.format);
    }
  }

  remove() {
    this.isShown = false;
  }
  /**
   * this should calculate the label
   */
  calculateChosenLabel() {
    let customRange = true;
    let i = 0;
    if (this.rangesArray.length > 0) {
      for (const range in this.ranges) {
        if (!range) {
          continue;
        }
        if (this.timePicker) {
          const format = this.timePickerSeconds
            ? 'YYYY-MM-DD HH:mm:ss'
            : 'YYYY-MM-DD HH:mm';
          // ignore times when comparing dates if time picker seconds is not enabled
          if (
            this.startDate.format(format) === this.ranges[range][0].format(format) &&
            this.endDate.format(format) === this.ranges[range][1].format(format)
          ) {
            customRange = false;
            this.chosenRange = this.rangesArray[i];
            break;
          }
        } else {
          // ignore times when comparing dates if time picker is not enabled
          if (
            this.startDate.format('YYYY-MM-DD') ===
              this.ranges[range][0].format('YYYY-MM-DD') &&
            this.endDate.format('YYYY-MM-DD') ===
              this.ranges[range][1].format('YYYY-MM-DD')
          ) {
            customRange = false;
            this.chosenRange = this.rangesArray[i];
            break;
          }
        }

        i++;
      }
      if (customRange) {
        if (this.showCustomRangeLabel) {
          this.chosenRange = this.locale.customRangeLabel;
        } else {
          this.chosenRange = null;
        }
      }
    }

    this.updateElement();
  }

  clickApply(e?) {
    if (!this.singleDatePicker && this.startDate && !this.endDate) {
      this.endDate = this.startDate.clone();
      this.calculateChosenLabel();
    }
    if (this.isInvalidDate && this.startDate && this.endDate) {
      // get if there are invalid date between range
      const d = this.startDate.clone();
      while (d.isBefore(this.endDate)) {
        if (this.isInvalidDate(d)) {
          this.endDate = d.subtract(1, 'days');
          this.calculateChosenLabel();
          break;
        }
        d.add(1, 'days');
      }
    }
    if (this.chosenLabel) {
      this.choosedDate.emit({
        chosenLabel: this.chosenLabel,
        startDate: this.startDate,
        endDate: this.endDate,
      });
    }
    this.datesUpdated.emit({ startDate: this.startDate, endDate: this.endDate });
    this.hide();
  }

  setToday() {
    this.choosedDate.emit({
      chosenLabel: (this.chosenLabel = this.startDate.format(this.locale.format)),
      startDate: this.startDate,
      endDate: this.endDate,
    });
  }

  clickCancel(e) {
    this.startDate = this._old.start;
    this.endDate = this._old.end;
    if (this.inline) {
      this.updateView();
    }
    this.hide();
  }
  /**
   * called when month is changed
   * @param monthEvent get value in event.target.value
   * @param side left or right
   */
  monthChanged(monthEvent: any, side: SideEnum) {
    const year = this.calendarVariables[side].dropdowns.currentYear;
    const month = parseInt(monthEvent.target.value, 10);
    this.monthOrYearChanged(month, year, side);
  }
  /**
   * called when year is changed
   * @param yearEvent get value in event.target.value
   * @param side left or right
   */
  yearChanged(yearEvent: any, side: SideEnum) {
    const month = this.calendarVariables[side].dropdowns.currentMonth;
    const year = parseInt(yearEvent.target.value, 10);
    this.monthOrYearChanged(month, year, side);
  }
  /**
   * called when time is changed
   * @param timeEvent  an event
   * @param side left or right
   */
  timeChanged(timeEvent: any, side: SideEnum) {
    let hour = parseInt(this.timepickerVariables[side].selectedHour, 10);
    const minute = parseInt(this.timepickerVariables[side].selectedMinute, 10);
    const second = this.timePickerSeconds
      ? parseInt(this.timepickerVariables[side].selectedSecond, 10)
      : 0;

    if (!this.timePicker24Hour) {
      const ampm = this.timepickerVariables[side].ampmModel;
      if (ampm === 'PM' && hour < 12) {
        hour += 12;
      }
      if (ampm === 'AM' && hour === 12) {
        hour = 0;
      }
    }

    if (side === SideEnum.left) {
      const start = this.startDate.clone();
      start.hour(hour);
      start.minute(minute);
      start.second(second);
      this.setStartDate(start);
      if (this.singleDatePicker) {
        this.endDate = this.startDate.clone();
      } else if (
        this.endDate &&
        this.endDate.format('YYYY-MM-DD') === start.format('YYYY-MM-DD') &&
        this.endDate.isBefore(start)
      ) {
        this.setEndDate(start.clone());
      }
    } else if (this.endDate) {
      const end = this.endDate.clone();
      end.hour(hour);
      end.minute(minute);
      end.second(second);
      this.setEndDate(end);
    }

    // update the calendars so all clickable dates reflect the new time component
    this.updateCalendars();

    // re-render the time pickers because changing one selection can affect what's enabled in another
    this.renderTimePicker(SideEnum.left);
    this.renderTimePicker(SideEnum.right);
  }
  /**
   *  call when month or year changed
   * @param month month number 0 -11
   * @param year year eg: 1995
   * @param side left or right
   */
  monthOrYearChanged(month: number, year: number, side: SideEnum) {
    const isLeft = side === SideEnum.left;

    if (!isLeft) {
      if (
        year < this.startDate.year() ||
        (year === this.startDate.year() && month < this.startDate.month())
      ) {
        month = this.startDate.month();
        year = this.startDate.year();
      }
    }

    if (this.minDate) {
      if (
        year < this.minDate.year() ||
        (year === this.minDate.year() && month < this.minDate.month())
      ) {
        month = this.minDate.month();
        year = this.minDate.year();
      }
    }

    if (this.maxDate) {
      if (
        year > this.maxDate.year() ||
        (year === this.maxDate.year() && month > this.maxDate.month())
      ) {
        month = this.maxDate.month();
        year = this.maxDate.year();
      }
    }
    this.calendarVariables[side].dropdowns.currentYear = year;
    this.calendarVariables[side].dropdowns.currentMonth = month;
    if (isLeft) {
      this.leftCalendar.month.month(month).year(year);
      if (this.linkedCalendars) {
        this.rightCalendar.month = this.leftCalendar.month.clone().add(1, 'month');
      }
    } else {
      this.rightCalendar.month.month(month).year(year);
      if (this.linkedCalendars) {
        this.leftCalendar.month = this.rightCalendar.month.clone().subtract(1, 'month');
      }
    }
    this.updateCalendars();
  }

  /**
   * Click on previous month
   * @param side left or right calendar
   */
  clickPrev(side: SideEnum) {
    if (side === SideEnum.left) {
      this.leftCalendar.month.subtract(1, 'month');
      if (this.linkedCalendars) {
        this.rightCalendar.month.subtract(1, 'month');
      }
    } else {
      this.rightCalendar.month.subtract(1, 'month');
    }
    this.updateCalendars();
  }
  /**
   * Click on next month
   * @param side left or right calendar
   */
  clickNext(side: SideEnum) {
    if (side === SideEnum.left) {
      this.leftCalendar.month.add(1, 'month');
    } else {
      this.rightCalendar.month.add(1, 'month');
      if (this.linkedCalendars) {
        this.leftCalendar.month.add(1, 'month');
      }
    }
    this.updateCalendars();
  }
  /**
   * When selecting a date
   * @param e event: get value by e.target.value
   * @param side left or right
   * @param row row position of the current date clicked
   * @param col col position of the current date clicked
   */
  clickDate(e, side: SideEnum, row: number, col: number) {
    if (e.target.tagName === 'TD') {
      if (!e.target.classList.contains('available')) {
        return;
      }
    } else if (e.target.tagName === 'SPAN') {
      if (!e.target.parentElement.classList.contains('available')) {
        return;
      }
    }
    if (this.rangesArray.length) {
      this.chosenRange = this.locale.customRangeLabel;
    }

    let date =
      side === SideEnum.left
        ? this.leftCalendar.calendar[row][col]
        : this.rightCalendar.calendar[row][col];

    if (this.endDate) {
      // picking start
      if (this.timePicker) {
        date = this._getDateWithTime(date, SideEnum.left);
      }
      this.endDate = null;
      this.setStartDate(date.clone());
    } else if (date.isBefore(this.startDate, 'day')) {
      this.setEndDate(this.startDate.clone());
      this.setStartDate(date.clone());
      if (this.autoApply) {
        this.calculateChosenLabel();
        this.clickApply();
      }
    } else if (!this.endDate && date.isBefore(this.startDate)) {
      // special case: clicking the same date for start/end,
      // but the time of the end date is before the start date
      this.setEndDate(this.startDate.clone());
    } else {
      // picking end
      if (this.timePicker) {
        date = this._getDateWithTime(date, SideEnum.right);
      }
      this.setEndDate(date.clone());
      if (this.autoApply) {
        this.calculateChosenLabel();
        this.clickApply();
      }
    }

    if (this.singleDatePicker) {
      this.setEndDate(this.startDate);
      this.updateElement();
      if (this.autoApply) {
        this.clickApply();
      }
    }

    this.updateView();

    // This is to cancel the blur event handler if the mouse was in one of the inputs
    e.stopPropagation();
  }
  /**
   *  Click on the custom range
   * @param e: Event
   * @param label
   */
  clickRange(e, label) {
    this.chosenRange = label;
    if (label === this.locale.customRangeLabel) {
      this.isShown = true; // show calendars
    } else {
      const dates = this.ranges[label];
      this.startDate = dates[0].clone();
      this.endDate = dates[1].clone();
      if (this.showRangeLabelOnInput && label !== this.locale.customRangeLabel) {
        this.chosenLabel = label;
      } else {
        this.calculateChosenLabel();
      }

      if (!this.timePicker) {
        this.startDate.startOf('day');
        if (!moment().isSame(this.endDate, 'day')) {
          this.endDate.endOf('day');
        }
      }

      if (!this.alwaysShowCalendars) {
        this.isShown = false; // hide calendars
      }
      this.rangeClicked.emit({ label, dates });
      if (!this.keepCalendarOpeningWithRange) {
        this.clickApply();
      } else {
        this.leftCalendar.month.month(dates[0].month());
        this.leftCalendar.month.year(dates[0].year());
        this.rightCalendar.month.month(dates[1].month());
        this.rightCalendar.month.year(dates[1].year());
        this.updateCalendars();
        if (this.timePicker) {
          this.renderTimePicker(SideEnum.left);
          this.renderTimePicker(SideEnum.right);
        }
      }
    }
    const rangeKey = this.rangeKeys[label];
    if (this.amplitudeEvent && rangeKey) {
      const eventProperties = {
        range: rangeKey,
      };
      amplitude.getInstance().logEvent(this.amplitudeEvent, eventProperties);
    }
  }

  show(e?) {
    if (this.isShown) {
      return;
    }
    this._old.start = this.startDate.clone();
    this._old.end = this.endDate.clone();
    this.isShown = true;
    this.updateView();
  }

  hide(e?) {
    return;
    if (!this.isShown) {
      return;
    }
    // incomplete date selection, revert to last values
    if (!this.endDate) {
      if (this._old.start) {
        this.startDate = this._old.start.clone();
      }
      if (this._old.end) {
        this.endDate = this._old.end.clone();
      }
    }

    // if a new date range was selected, invoke the user callback function
    if (!this.startDate.isSame(this._old.start) || !this.endDate.isSame(this._old.end)) {
      // this.callback(this.startDate, this.endDate, this.chosenLabel);
    }

    // if picker is attached to a text input, update it
    this.updateElement();
    this.isShown = false;
    this._ref.detectChanges();
  }

  /**
   * handle click on all element in the component, usefull for outside of click
   * @param e event
   */
  @HostListener('click')
  handleInternalClick(e) {
    if (e) {
      e.stopPropagation();
    }
  }
  /**
   * update the locale options
   * @param locale
   */
  updateLocale(locale) {
    for (const key in locale) {
      if (locale.hasOwnProperty(key)) {
        this.locale[key] = locale[key];
      }
    }
  }
  /**
   *  clear the daterange picker
   */
  clear() {
    this.startDate = this.defaultRange
      ? this.defaultRange.startDate
      : moment().startOf('day');
    this.endDate = this.defaultRange ? this.defaultRange.endDate : moment().endOf('day');
    this.choosedDate.emit({ chosenLabel: '', startDate: null, endDate: null });
    this.datesUpdated.emit({ startDate: null, endDate: null });
    this.hide();
  }

  /**
   * Find out if the selected range should be disabled if it doesn't
   * fit into minDate and maxDate limitations.
   */
  disableRange(range) {
    if (range === this.locale.customRangeLabel) {
      return false;
    }
    const rangeMarkers = this.ranges[range];
    const areBothBefore = rangeMarkers.every(date => {
      if (!this.minDate) {
        return false;
      }

      return date.isBefore(this.minDate);
    });

    const areBothAfter = rangeMarkers.every(date => {
      if (!this.maxDate) {
        return false;
      }

      return date.isAfter(this.maxDate);
    });

    return areBothBefore || areBothAfter;
  }
  /**
   *
   * @param date the date to add time
   * @param side left or right
   */
  private _getDateWithTime(date, side: SideEnum): moment.Moment {
    let hour = parseInt(this.timepickerVariables[side].selectedHour, 10);
    if (!this.timePicker24Hour) {
      const ampm = this.timepickerVariables[side].ampmModel;
      if (ampm === 'PM' && hour < 12) {
        hour += 12;
      }
      if (ampm === 'AM' && hour === 12) {
        hour = 0;
      }
    }
    const minute = parseInt(this.timepickerVariables[side].selectedMinute, 10);
    const second = this.timePickerSeconds
      ? parseInt(this.timepickerVariables[side].selectedSecond, 10)
      : 0;

    return date.clone().hour(hour).minute(minute).second(second);
  }
  private _buildCells(calendar, side: SideEnum) {
    for (let row = 0; row < calendar.length; row++) {
      this.calendarVariables[side].classes[row] = {};
      const rowClasses = [];
      if (
        this.emptyWeekRowClass &&
        !this.hasCurrentMonthDays(this.calendarVariables[side].month, calendar[row])
      ) {
        rowClasses.push(this.emptyWeekRowClass);
      }
      for (let col = 0; col < 7; col++) {
        const classes = [];
        // highlight today's date
        if (calendar[row][col].isSame(new Date(), 'day')) {
          classes.push('today');
        }
        // highlight weekends
        if (calendar[row][col].isoWeekday() > 5) {
          classes.push('weekend');
        }
        // grey out the dates in other months displayed at beginning and end of this calendar
        if (calendar[row][col].month() !== calendar[1][1].month()) {
          classes.push('off');

          // mark the last day of the previous month in this calendar
          if (
            this.lastDayOfPreviousMonthClass &&
            (calendar[row][col].month() < calendar[1][1].month() ||
              calendar[1][1].month() === 0) &&
            calendar[row][col].date() === this.calendarVariables[side].daysInLastMonth
          ) {
            classes.push(this.lastDayOfPreviousMonthClass);
          }

          // mark the first day of the next month in this calendar
          if (
            this.firstDayOfNextMonthClass &&
            (calendar[row][col].month() > calendar[1][1].month() ||
              calendar[row][col].month() === 0) &&
            calendar[row][col].date() === 1
          ) {
            classes.push(this.firstDayOfNextMonthClass);
          }
        }
        // mark the first day of the current month with a custom class
        if (
          this.firstMonthDayClass &&
          calendar[row][col].month() === calendar[1][1].month() &&
          calendar[row][col].date() === calendar.firstDay.date()
        ) {
          classes.push(this.firstMonthDayClass);
        }
        // mark the last day of the current month with a custom class
        if (
          this.lastMonthDayClass &&
          calendar[row][col].month() === calendar[1][1].month() &&
          calendar[row][col].date() === calendar.lastDay.date()
        ) {
          classes.push(this.lastMonthDayClass);
        }
        // don't allow selection of dates before the minimum date
        if (this.minDate && calendar[row][col].isBefore(this.minDate, 'day')) {
          classes.push('off', 'disabled');
        }
        // don't allow selection of dates after the maximum date
        if (
          this.calendarVariables[side].maxDate &&
          calendar[row][col].isAfter(this.calendarVariables[side].maxDate, 'day')
        ) {
          classes.push('off', 'disabled');
        }
        // add specific class for last selectable date (useful for tests)
        if (
          this.calendarVariables[side].maxDate &&
          calendar[row][col].isSame(this.calendarVariables[side].maxDate, 'day')
        ) {
          classes.push('max-date');
        }
        // don't allow selection of date if a custom function decides it's invalid
        if (this.isInvalidDate(calendar[row][col])) {
          classes.push('off', 'disabled');
        }
        // highlight the currently selected start date
        if (
          this.startDate &&
          calendar[row][col].format('YYYY-MM-DD') === this.startDate.format('YYYY-MM-DD')
        ) {
          classes.push('active', 'start-date');
        }
        // highlight the currently selected end date
        if (
          this.endDate !== null &&
          calendar[row][col].format('YYYY-MM-DD') === this.endDate.format('YYYY-MM-DD')
        ) {
          classes.push('active', 'end-date');
        }
        // highlight dates in-between the selected dates
        if (
          this.endDate !== null &&
          calendar[row][col] > this.startDate &&
          calendar[row][col] < this.endDate &&
          !this.singleDatePicker
        ) {
          classes.push('in-range');
        }
        // highlight specific dates
        if (this.highlightDates?.indexOf(calendar[row][col].format('YYYY-MM-DD')) > -1) {
          classes.push('highlight');
        }
        // apply custom classes for this date
        const isCustom = this.isCustomDate(calendar[row][col]);
        if (isCustom) {
          if (typeof isCustom === 'string') {
            classes.push(isCustom);
          } else {
            Array.prototype.push.apply(classes, isCustom);
          }
        }
        // store classes var
        let cname = '',
          disabled = false;
        for (const item of classes) {
          cname += item + ' ';
          if (item === 'disabled') {
            disabled = true;
          }
        }
        if (!disabled) {
          cname += 'available';
        }
        this.calendarVariables[side].classes[row][col] = cname.replace(/^\s+|\s+$/g, '');
      }
      this.calendarVariables[side].classes[row].classList = rowClasses.join(' ');
    }
  }

  /**
   * Find out if the current calendar row has current month days
   * (as opposed to consisting of only previous/next month days)
   */
  hasCurrentMonthDays(currentMonth, row) {
    for (let day = 0; day < 7; day++) {
      if (row[day].month() === currentMonth) {
        return true;
      }
    }

    return false;
  }
}
