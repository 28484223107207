import { StateOperator } from '@ngxs/store';

export function uniqArray<T>(): StateOperator<T[]> {
  return (inputValue): T[] => {
    const newVal = inputValue.filter(
      (item, index, array) => array.indexOf(item) === index,
    );
    if (newVal.length === inputValue.length) {
      return inputValue.slice();
    }

    return newVal;
  };
}
