import { ComponentFactoryResolver, Injectable } from '@angular/core';

import { DynamicEnabledPanel, PanelContainerInput } from '../types/dynamic-panel-types';

interface DataContener<T extends DynamicEnabledPanel> {
  [key: string]: PanelContainerInput<T>;
}

@Injectable({
  providedIn: 'root',
})
export class PanelRegistryService {
  private store: DataContener<any> = {};

  registerSinglePanel(panel: PanelContainerInput, factory: ComponentFactoryResolver) {
    this.store = {
      ...this.store,
      [panel.key]: {
        ...panel,
        factory,
      },
    };
  }

  registerMultiplePanels(
    panels: PanelContainerInput[],
    factory: ComponentFactoryResolver,
  ) {
    panels.forEach(pan => this.registerSinglePanel(pan, factory));
  }

  unregisterPanel(panel: PanelContainerInput) {
    const { [panel.key]: val, ...rest } = this.store;
    this.store = rest;
  }

  unregisterMultiplePanels(panels: PanelContainerInput[]) {
    panels.forEach(pan => this.unregisterPanel(pan));
  }

  getPanelByKey(key: string): PanelContainerInput {
    return this.store[key];
  }
}
