import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AutoFocusDirective } from './auto-focus.directive';
import { ContainerDirective } from './container.directive';
import { HasPlanDirective } from './has-plan.directive';
import { HasRoleDirective } from './has-role.directive';
import { IsAdminDirective } from './is-admin.directive';
import { IsOwnerDirective } from './is-owner.directive';
import { IsSuperUserDirective } from './is-super-user.directive';
import { LinkWithoutDragDirective } from './link-without-drag.directive';

@NgModule({
  declarations: [
    HasPlanDirective,
    HasRoleDirective,
    IsSuperUserDirective,
    IsAdminDirective,
    IsOwnerDirective,
    LinkWithoutDragDirective,
    AutoFocusDirective,
    ContainerDirective,
  ],
  exports: [
    HasPlanDirective,
    HasRoleDirective,
    IsSuperUserDirective,
    IsAdminDirective,
    IsOwnerDirective,
    LinkWithoutDragDirective,
    AutoFocusDirective,
    ContainerDirective,
  ],
  imports: [CommonModule],
})
export class DirectivesModule {}
