<button
  [disabled]="isDisabled()"
  (click)="onClick($event)"
  (focus)="focused.emit($event)"
  (blur)="blurred.emit($event)"
  [ngClass]="getBtnClasses()"
  [ngStyle]="style"
  [type]="type"
>
  <mat-icon
    [fontSet]="faFont"
    [fontIcon]="faIcon"
    *ngIf="faIcon"
    [ngStyle]="getStyleIcon()"
  ></mat-icon>
  <mat-icon
    [svgIcon]="svgIcon"
    *ngIf="svgIcon"
    [ngStyle]="getStyleIcon()"
    [class]="getSvgColorClass()"
    [class.hidden]="loading"
  ></mat-icon>
  <span class="overlay-loading" *ngIf="loading">
    <rk-loader [isLoading]="true" [size]="20"></rk-loader>
  </span>
  <span class="txt-button" *ngIf="label" [innerHTML]="label"></span>
  <mat-icon
    *ngIf="noMobile"
    class="absolute right-0 desktop"
    fontSet="fa"
    fontIcon="fa-desktop"
  ></mat-icon>
</button>
