<div class="header">
  <div class="back" (click)="closed.emit()">
    <mat-icon fontSet="fa" fontIcon="fa-chevron-left"></mat-icon>
    {{ 'HEADER.ALERT_ADMIN_BACK' | translate }}
  </div>
  <div *ngIf="showUserMenu" class="user flex items-center" (click)="openUserMenu()">
    <rk-avatar
      [image]="profilePic$ | async"
      [name]="username$ | async"
      [size]="30"
      data-cy="user-icon"
    ></rk-avatar>
    <mat-icon fontSet="fa" fontIcon="fa-chevron-down"></mat-icon>
  </div>
</div>
