import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UpgradeOfferModalComponent } from '@app/shared/modals/upgrade-offer/components/upgrade-offer-modal/upgrade-offer-modal.component';

@Component({
  selector: 'rk-advanced-tooltip',
  templateUrl: './advanced-tooltip.component.html',
  styleUrls: ['./advanced-tooltip.component.scss'],
})
export class AdvancedTooltipComponent {
  constructor(private readonly dialog: MatDialog) {}
  @Input()
  isListeningTimeExceeded = true;

  updateOffer() {
    this.dialog.open(UpgradeOfferModalComponent, { data: 'exceeded_listening_tooltip' });
  }
}
