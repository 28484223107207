import { RADIO_PLAN_DESC, RADIO_PLAN_ORDER } from '@app/core/models/Radio';
import { Track } from '@app/core/models/Track';
import { isEqualWith, isNull, isString } from 'lodash';
import sanitize from 'sanitize-html';
import { hex as hexContrast } from 'wcag-contrast';

export const uniqFilter = (x: any, i: number, a: any[]) => a.indexOf(x) === i;

export const replaceAccents = (str: string) =>
  str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export interface TimeSplited {
  hours: number;
  minutes: number;
  seconds: number;
}

export enum BacName {
  music = 'music',
  identification = 'identification',
  podcast = 'podcast',
  ad = 'ad',
  chronic = 'chronic',
  dedication = 'dedication',
  none = '-',
}

export const exportSecMin = (value: number): TimeSplited => {
  const rounded = Math.floor(value);
  let totalSeconds = rounded;

  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  return {
    hours,
    minutes,
    seconds,
  };
};

export const formatBytesToMB = (bytes: number): number => {
  const toMo = parseFloat((bytes / 1048576).toFixed(1));

  return toMo;
};

export function getEllipsisTitle(track: Track): string {
  const fullTitle = track.title + (track.artist ? ` - ${track.artist}` : '');
  const shouldTruncFileName = fullTitle.length > 140;

  return fullTitle.substr(0, 140) + (shouldTruncFileName ? '...' : '');
}

export function hexToRGBA(hex: string, opacity: number): string {
  if (!hex) {
    return hex;
  }
  hex = hex.replace('#', '');
  hex = hex.length === 3 ? [...hex].map(s => s.repeat(2)).join('') : hex;
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export class ColorUtils {
  private static readonly bestContrastTextColorCache = new Map<string, string>();

  /**
   * Return the best contrast color for a text depending on its background
   */
  static bestContrastTextColor(
    backgroundColor: string,
    lightTextColor = '#fff',
    darkTextColor = '#000', // This should be #000 for a better contrast
  ): string {
    const cacheKey = `${backgroundColor}:${lightTextColor}:${darkTextColor}`;

    if (ColorUtils.bestContrastTextColorCache.has(cacheKey)) {
      return ColorUtils.bestContrastTextColorCache.get(cacheKey);
    }

    const bestContrastColor =
      hexContrast(backgroundColor, darkTextColor) - 6 >
      hexContrast(backgroundColor, lightTextColor)
        ? darkTextColor
        : lightTextColor;

    ColorUtils.bestContrastTextColorCache.set(cacheKey, bestContrastColor);

    return bestContrastColor;
  }
}

export function bacNameFromDbName(name: string): BacName {
  switch (name) {
    case '__MUSIC__':
      return BacName.music;
    case '__IDENTIFICATION__':
      return BacName.identification;
    case '__PODCAST__':
      return BacName.podcast;
    case '__AD__':
      return BacName.ad;
    case '__CHRONIC__':
      return BacName.chronic;
    case '__DEDICATION__':
      return BacName.dedication;
  }

  return BacName.none;
}

export const isMacOS = !!navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i);

export function platformCmdOrCtrlKey(e: KeyboardEvent | MouseEvent): boolean {
  return (isMacOS && e.metaKey) || (!isMacOS && e.ctrlKey);
}

/**
 * Compare equality of form data, for each field, recursively
 *
 * - Consider null values equivalent to empty string values
 * - Compare trimmed string
 *
 * @param current Current form data, possibly user-modified
 * @param original Original form data, generally from EPs
 */
export function isEqualFormData<T>(current: T, original: T): boolean {
  return isEqualWith(current, original, (a, b) => {
    // Null <=> ''
    if ((isNull(a) || a === '') && (isNull(b) || b === '')) {
      return true;
    }

    // Compare trimmed string
    if (isString(a) && isString(b) && a.trim() === b.trim()) {
      return true;
    }

    return undefined;
  });
}

export function sortNumber(prop: string) {
  return (a: any, b: any) => {
    const aProp = a[prop];
    const bProp = b[prop];
    if (aProp < bProp) {
      return -1;
    }
    if (aProp > bProp) {
      return 1;
    }

    return 0;
  };
}

export function sortNumberReverse(prop: string) {
  return (a: any, b: any) => {
    const aProp = a[prop];
    const bProp = b[prop];
    if (aProp < bProp) {
      return 1;
    }
    if (aProp > bProp) {
      return -1;
    }

    return 0;
  };
}

export function sortString(prop: string) {
  return (a: any, b: any) => {
    const aProp = a[prop].toLowerCase();
    const bProp = b[prop].toLowerCase();

    return aProp.localeCompare(bProp);
  };
}

export function sortStringReverse(prop: string) {
  return (a: any, b: any) => {
    const aProp = a[prop].toLowerCase();
    const bProp = b[prop].toLowerCase();

    return bProp.localeCompare(aProp);
  };
}

export function sortDate(prop: string) {
  return (a: any, b: any) => {
    if (!a[prop]) {
      return -1;
    }
    if (!b[prop]) {
      return 1;
    }

    return a[prop].valueOf() - b[prop].valueOf();
  };
}

export function sortDateReverse(prop: string) {
  return (a: any, b: any) => {
    if (!a[prop]) {
      return 1;
    }
    if (!b[prop]) {
      return -1;
    }

    return (a[prop].valueOf() - b[prop].valueOf()) * -1;
  };
}

export function sortObject(obj: object, getKey = false) {
  const arrValues: object[] = [];
  Object.entries(obj)
    .sort(([, a], [, b]) => b - a)
    .forEach(i => {
      arrValues.push({ [i[0]]: i[1] });
    });

  return arrValues.map(k => (getKey ? Object.keys(k)[0] : Object.values(k)[0]));
}

export function mSanitize(str: string): string {
  return sanitize(str).split('&amp;').join('&');
}

/*
 * @return true if first parameter is bigger
 */
export function planBigger(
  plan: RADIO_PLAN_DESC,
  compareTo: RADIO_PLAN_DESC,
  orEquals = false,
) {
  return orEquals
    ? RADIO_PLAN_ORDER.indexOf(plan) >= RADIO_PLAN_ORDER.indexOf(compareTo)
    : RADIO_PLAN_ORDER.indexOf(plan) > RADIO_PLAN_ORDER.indexOf(compareTo);
}

export function formatTrackingNavName(subnav: string): string {
  const subArr = subnav
    .split('-')
    .filter(s => !!s)
    .map(s => s.charAt(0).toUpperCase() + s.slice(1));

  return subArr.join('');
}

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getDeviceCategory() {
  const ua = navigator.userAgent;
  if (/mobile/i.test(ua)) {
    return 'Mobile';
  } else if (/tablet|ipad|playbook|silk/i.test(ua)) {
    return 'Tablet';
  } else if (/MSIE|Trident|Firefox|Safari|Chrome|Edge/i.test(ua)) {
    return 'Desktop';
  }

  return 'Other';
}
