<rk-modal-wrapper
  rkPerfectScrollbar
  [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
  [showHeader]="false"
  [showSubmitBlock]="false"
  [wide]="true"
>
  <div class="bg absolute w-full overflow-hidden">
    <img
      src="/assets/wave.svg"
      class="absolute max-w-none min-w-full max-h-full object-bottom object-cover"
    />
  </div>
  <div class="text-white text-center z-10">
    <h1 class="mt-16 md:mt-12 mb-0">
      {{
        planBigger(planDesc$ | async, 'Demo')
          ? ('HEADER.OFFER.UPDATE' | translate)
          : ('GLOBAL.MODAL.UPGRADE_OFFER.PICK_TITLE' | translate)
      }}
    </h1>
    <span translate>GLOBAL.MODAL.UPGRADE_OFFER.SUBTITLE</span>
  </div>

  <mat-button-toggle-group
    class="mt-9"
    (change)="changeBilling($event)"
    [class.monthly]="billing === 'Monthly'"
  >
    <mat-button-toggle value="Monthly" checked>{{
      'GLOBAL.MODAL.UPGRADE_OFFER.MONTHLY' | translate
    }}</mat-button-toggle>
    <mat-button-toggle value="Annually"
      >{{ 'GLOBAL.MODAL.UPGRADE_OFFER.ANNUAL' | translate }}
      <span class="tag text-sm h-4.5 rounded-full -top-0.5 ml-4">{{
        'GLOBAL.MODAL.UPGRADE_OFFER.TWO_MONTHS' | translate
      }}</span></mat-button-toggle
    >
  </mat-button-toggle-group>
  <div class="plans" *ngIf="currency$ | async">
    <swiper class="px-6 pb-10" [config]="swiperConfig" (activeIndexChange)="swipeOffer()">
      @if (planBigger('Start', planDesc$ | async)) {
        <ng-template swiperSlide>
          <div class="mt-[51px]">
            <rk-offer
              [plan]="'Discover'"
              [whmcsId]="plansId.Discover"
              [listeningHours]="150"
              [listeningLocale]="$listeningLocale()"
              [diskSpace]="1"
              [billing]="billing"
              [currency]="currency$ | async"
              [focused$]="swiped.asObservable()"
              (clickButton)="onCancel()"
            ></rk-offer>
          </div>
        </ng-template>
      }
      @if (planBigger('Pro', planDesc$ | async)) {
        <ng-template swiperSlide>
          <div [class.mt-[51px]]="recommendedPlan !== 'Start'">
            @if (recommendedPlan === 'Start') {
              <div class="offer-header relative bg-black-new text-white" translate>
                GLOBAL.MODAL.UPGRADE_OFFER.RECOMMENDED
              </div>
            }
            <rk-offer
              [plan]="'Start'"
              [whmcsId]="plansId.Start"
              [listeningHours]="20000"
              [listeningLocale]="$listeningLocale()"
              [diskSpace]="5"
              [billing]="billing"
              [currency]="currency$ | async"
              [recommended]="recommendedPlan === 'Start'"
              [focused$]="swiped.asObservable()"
              (clickButton)="onCancel()"
            ></rk-offer>
          </div>
        </ng-template>
      }
      <ng-template swiperSlide>
        <div [class.mt-[51px]]="recommendedPlan !== 'Pro'">
          @if (recommendedPlan === 'Pro') {
            <div class="offer-header relative bg-black-new text-white" translate>
              GLOBAL.MODAL.UPGRADE_OFFER.RECOMMENDED
            </div>
          }
          <rk-offer
            [plan]="'Pro'"
            [whmcsId]="plansId.Pro"
            [listeningHours]="150000"
            [listeningLocale]="$listeningLocale()"
            [diskSpace]="10"
            [billing]="billing"
            [currency]="currency$ | async"
            [recommended]="recommendedPlan === 'Pro'"
            [focused$]="swiped.asObservable()"
            (clickButton)="onCancel()"
          ></rk-offer>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div [class.mt-[51px]]="recommendedPlan !== 'Business'">
          @if (recommendedPlan === 'Business') {
            <div class="offer-header relative bg-black-new text-white" translate>
              GLOBAL.MODAL.UPGRADE_OFFER.RECOMMENDED
            </div>
          }
          <rk-offer
            [plan]="'Business'"
            [whmcsId]="plansId.Business"
            [listeningHours]="500000"
            [listeningLocale]="$listeningLocale()"
            [diskSpace]="20"
            [billing]="billing"
            [currency]="currency$ | async"
            [recommended]="recommendedPlan === 'Business'"
            [focused$]="swiped.asObservable()"
            (clickButton)="onCancel()"
          ></rk-offer>
        </div>
      </ng-template>
    </swiper>
  </div>
  <rk-trustpilot class="-mt-10" [userLang]="userLang$ | async"></rk-trustpilot>
  <rk-faq></rk-faq>
</rk-modal-wrapper>
<rk-button
  class="fixed z-20 flex self-end mt-5 mr-5"
  [btnStyle]="'flat'"
  [faIcon]="'fa-times'"
  [style]="{ width: '40px', height: '40px' }"
  (click)="onCancel(true)"
></rk-button>
