import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { MobileChangeRadioComponent } from './mobile-change-radio.component';

@NgModule({
  imports: [CommonModule, MatIconModule, TranslateModule],
  declarations: [MobileChangeRadioComponent],
  exports: [MobileChangeRadioComponent],
})
export class UiMobileChangeRadioModule {}
