import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Logout, SetTokens } from '@app/core/states/auth.actions';
import { environment } from '@env/environment';
import { Store } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';

interface UserApiModel {
  iduser: number;
  username: string;
  slug: string;
  firstname: string;
  lastname: string;
  avatar_url: string;
  email: string;
  gender: null;
  birthday_year: string;
  city: string;
  zipcode: number;
  country: string;
  lang: string;
  last_radio_used: number;
  is_google_account: boolean;
  is_facebook_account: boolean;
  id_whmcs: number;
  zendesk_hash: string;
  is_admin: number;
  is_superuser: number;
  only_social: boolean;
  late_invoice?: {
    id: number;
    due_date: string;
  };
  organization: string;
}

export interface User {
  username: string;
  email: string;
  firstname: string;
  lastname: string;
  iduser: number;
  idwhmcs: number;
  zendesk_hash: string;
  slug: string;
  country: string;
  zipcode?: string;
  city?: string;
  isFacebookAcccount: boolean;
  isGoogleAcccount: boolean;
  only_social: boolean;
  is_admin: boolean;
  is_superuser: boolean;
  lang: string;
  lastRadioUsed: number;
  idfile_avatar?: string;
  gender?: string;
  birthday_year?: string;
  lateInvoice?: {
    id: number;
    dueDate: string;
  };
  organization: string;
}

export interface TokenItem {
  access_token: string;
  refresh_token: string;
  expire: number;
}

export interface LoginContext {
  username: string;
  password: string;
}

const authUrls = {
  login: () => `${environment.urls.MAIN_API_V2}/oauth/login`,
  logout: () => `${environment.urls.MAIN_API_V2}/oauth/logout`,
  refreshToken: () => `${environment.urls.MAIN_API_V2}/oauth/refresh`,
  me: () => `${environment.urls.MAIN_API_V2}/me`,
  user: (id: number) => `${environment.urls.MAIN_API_V2}/user/${id}`,
};

/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private readonly store: Store, private readonly httpClient: HttpClient) {}

  /*
expire: 86400
token
     */
  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login({ username, password }: LoginContext): Observable<TokenItem> {
    return this.httpClient.post<TokenItem>(authUrls.login(), {
      password,
      login: username,
    });
  }

  getMe(): Observable<User> {
    return this.httpClient.get<UserApiModel>(authUrls.me()).pipe(map(this.convertToUser));
  }

  logout(): Observable<void> {
    return this.httpClient.post<void>(authUrls.logout(), {});
  }

  useSpecificRadio(idUser: number, idRadio: number): Observable<void> {
    return this.httpClient
      .put(authUrls.user(idUser), { last_radio_used: idRadio })
      .pipe(map(() => {}));
  }

  logoutFromInterceptor(): Observable<any> {
    return this.store.dispatch(new Logout());
  }

  refreshToken(refreshToken: string): Observable<TokenItem> {
    return this.httpClient.post<TokenItem>(authUrls.refreshToken(), {
      refresh_token: refreshToken,
    });
  }

  refreshTokenWithDispatch(refreshToken: string): Observable<TokenItem> {
    if (!refreshToken) {
      return throwError(new Error('Not any refresh token proided'));
    }

    return this.refreshToken(refreshToken).pipe(
      tap((token: TokenItem) =>
        this.store.dispatch(new SetTokens(token.access_token, 2082, token.refresh_token)),
      ),
    );
  }

  convertToUser(user: UserApiModel): User {
    return {
      iduser: user.iduser,
      username: user.username,
      slug: user.slug,
      firstname: user.firstname,
      lastname: user.lastname,
      idfile_avatar: user.avatar_url,
      email: user.email,
      gender: user.gender,
      birthday_year: user.birthday_year,
      city: user.city,
      zipcode: `${user.zipcode}`,
      country: user.country,
      lang: user.lang,
      lastRadioUsed: user.last_radio_used,
      isGoogleAcccount: user.is_google_account,
      isFacebookAcccount: user.is_facebook_account,
      idwhmcs: user.id_whmcs,
      zendesk_hash: user.zendesk_hash,
      is_admin: !!user.is_admin,
      is_superuser: !!user.is_superuser,
      only_social: user.only_social,
      lateInvoice: user.late_invoice
        ? {
            id: user.late_invoice.id,
            dueDate: user.late_invoice.due_date,
          }
        : null,
      organization: user.organization,
    };
  }
}
