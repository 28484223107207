import { Component } from '@angular/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'rk-no-radio',
  templateUrl: './no-radio.component.html',
  styleUrls: ['./no-radio.component.scss'],
})
export class NoRadioComponent {
  translate: TranslateService;
  constructor(translate: TranslateService) {
    this.translate = translate;
  }

  backToHome() {
    location.href = 'https://www.radioking.com';
  }

  createRadio() {
    this.translate
      .stream(marker('GLOBAL.LINK.CREATE_RADIO'))
      .pipe(filter(trad => !!trad))
      .subscribe(trad => (location.href = trad));
  }
}
