import { ROLES } from '@app/core/models/Preset';
import { RoleOperatorActions } from '@app/core/services/role-helper.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

import { ModelNav } from './navigation.service';

export const baseNav: ModelNav[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    translate: marker('SIDENAV.MENU.DASHBOARD'),
    type: 'item',
    icon: 'fa-tachometer-alt',
    roles: {
      type: RoleOperatorActions.ALL,
      roleIds: [ROLES.DASHBOARD],
    },
    url: '/dashboard',
    exactMatch: true,
  },
  {
    id: 'library',
    title: 'Library',
    translate: marker('SIDENAV.MENU.MEDIACENTER'),
    type: 'item',
    icon: 'fa-music',
    url: '/media',
    roles: ROLES.LIBRARY,
  },
  {
    id: 'scheduling',
    translate: marker('SIDENAV.MENU.SCHEDULING'),
    type: 'collapse',
    icon: 'fa-calendar-alt',
    roles: {
      type: RoleOperatorActions.OR,
      operators: [
        {
          type: RoleOperatorActions.ALL,
          roleIds: [ROLES.PROGRAMS, ROLES.LIBRARY],
        },
        {
          type: RoleOperatorActions.ALL,
          roleIds: [ROLES.PROGRAMS, ROLES.LIBRARY, ROLES.SCHEDULE, ROLES.BREAKS],
        },
        {
          type: RoleOperatorActions.ALL,
          roleIds: [ROLES.PLANNING, ROLES.LIBRARY],
        },
        {
          type: RoleOperatorActions.ALL,
          roleIds: [ROLES.PLANNING],
        },
      ],
    },
    children: [
      {
        id: 'scheduling-programs',
        translate: marker('SIDENAV.MENU.PROGRAMS'),
        type: 'item',
        url: '/scheduler/programs',
        roles: {
          type: RoleOperatorActions.ALL,
          roleIds: [ROLES.PROGRAMS, ROLES.LIBRARY],
        },
      },
      {
        id: 'scheduling-planning',
        translate: marker('SIDENAV.MENU.PLANNING'),
        type: 'item',
        notOnMobile: true,
        url: '/scheduler/planning',
        roles: {
          type: RoleOperatorActions.ALL,
          roleIds: [ROLES.PROGRAMS, ROLES.LIBRARY, ROLES.SCHEDULE],
        },
      },
      {
        id: 'scheduling-breaks',
        translate: marker('SIDENAV.MENU.BREAKS'),
        type: 'item',
        notOnMobile: true,
        url: '/scheduler/breaks',
        roles: {
          type: RoleOperatorActions.ALL,
          roleIds: [ROLES.PROGRAMS, ROLES.LIBRARY, ROLES.SCHEDULE],
        },
      },
      {
        id: 'scheduling-daily-generation',
        translate: marker('SIDENAV.MENU.DAILY_GENERATION'),
        type: 'item',
        url: '/generated-planning',
        roles: {
          type: RoleOperatorActions.ALL,
          roleIds: [ROLES.PLANNING, ROLES.LIBRARY],
        },
      },
      {
        id: 'scheduling-history',
        translate: marker('SIDENAV.MENU.HISTORY'),
        type: 'item',
        url: '/history',
        roles: {
          type: RoleOperatorActions.ALL,
          roleIds: [ROLES.PLANNING],
        },
      },
    ],
  },
  {
    id: 'statistics',
    translate: marker('SIDENAV.MENU.STATISTICS_ITEM'),
    type: 'collapse',
    icon: 'fa-chart-bar',
    roles: ROLES.STATISTICS,
    children: [
      {
        id: 'statistics-live',
        translate: marker('SIDENAV.MENU.STATISTICS.LIVE'),
        type: 'item',
        url: '/statistics/live',
      },
      {
        id: 'statistics-listeners',
        translate: marker('SIDENAV.MENU.STATISTICS.LISTENERS'),
        type: 'item',
        url: '/statistics/listeners',
        notOnMobile: true,
        lockedUntilPlan: 'Discover',
      },
      {
        id: 'statistics-sessions',
        translate: marker('SIDENAV.MENU.STATISTICS.SESSIONS'),
        type: 'item',
        url: '/statistics/sessions',
        notOnMobile: true,
        lockedUntilPlan: 'Discover',
      },
      {
        id: 'statistics-duration',
        translate: marker('SIDENAV.MENU.STATISTICS.DURATION'),
        type: 'item',
        url: '/statistics/duration',
        notOnMobile: true,
        lockedUntilPlan: 'Discover',
      },
      {
        id: 'statistics-location',
        translate: marker('SIDENAV.MENU.STATISTICS.LOCATION'),
        type: 'item',
        url: '/statistics/location',
        notOnMobile: true,
        lockedUntilPlan: 'Discover',
      },
      {
        id: 'statistics-sources',
        translate: marker('SIDENAV.MENU.STATISTICS.SOURCES'),
        type: 'item',
        url: '/statistics/sources',
        notOnMobile: true,
        lockedUntilPlan: 'Discover',
      },
      {
        id: 'statistics-musics',
        translate: marker('SIDENAV.MENU.STATISTICS.MUSICS'),
        type: 'item',
        url: '/statistics/musics',
        notOnMobile: true,
        lockedUntilPlan: 'Discover',
      },
    ],
  },
  {
    id: 'live',
    title: 'Page Layouts',
    type: 'item',
    translate: marker('SIDENAV.MENU.DIRECT'),
    icon: 'fa-microphone',
    url: '/live',
    roles: ROLES.LIVE,
    lockedUntilPlan: 'Discover',
  },
  {
    id: 'team',
    translate: marker('SIDENAV.MENU.DJS'),
    type: 'item',
    url: '/team',
    icon: 'fa-user-friends',
    roles: ROLES.USERS,
    lockedUntilPlan: 'Demo',
  },
  {
    id: 'share',
    title: 'Widget',
    translate: marker('SIDENAV.MENU.SHARE'),
    type: 'item',
    icon: 'fa-window-restore',
    url: '/widgets',
    roles: ROLES.WIDGETS,
  },
  {
    id: 'settings',
    title: 'Settings',
    translate: marker('SIDENAV.MENU.SETTINGS'),
    type: 'item',
    icon: 'fa-wrench',
    url: '/settings',
    roles: ROLES.SETTINGS,
  },
  {
    id: 'admin',
    translate: marker('SIDENAV.MENU.ADMIN_ITEM'),
    type: 'collapse',
    icon: 'fa-screwdriver',
    isAdminRoute: true,
    children: [
      {
        id: 'admin_radio',
        translate: marker('SIDENAV.MENU.ADMIN.RADIO'),
        type: 'item',
        url: '/admin/radios',
      },
      {
        id: 'admin_users',
        translate: marker('SIDENAV.MENU.ADMIN.USERS'),
        type: 'item',
        url: '/admin/users',
      },
    ],
  },
];
