import { Country } from '@app/core/models/Country';

const tag = '[Country]';

export class CountriesRequest {
  static readonly type = `${tag} request countries`;
}

export class CountriesSuccess {
  static readonly type = `${tag} success countries`;
  constructor(public countries: Country[]) {}
}

export class CountriesFailure {
  static readonly type = `${tag} error countries`;
  constructor(public error: Error) {}
}
