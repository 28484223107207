import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { NavigationEnd, Router } from '@angular/router';
import { AuthState } from '@app/core/states/auth.state';
import { LiveTrackingState } from '@app/core/states/live-tracking.state';
import { AddDefaultTracksRequest } from '@app/library/states/tracks.actions';
import { aLogEvent, AMPLITUDE_EVENT } from '@app/shared/amplitude-events';
import { TranslateService } from '@ngx-translate/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import {
  Actions,
  ofActionDispatched,
  ofActionSuccessful,
  Select,
  Store,
} from '@ngxs/store';
import {
  CheckListAddPlaylist,
  CheckListAddTrack,
  CheckListGenerate,
  CheckListPlanning,
  CheckListShare,
  ChecklistStep,
  CompleteStep,
  DestroyChecklist,
  ToggleChecklist,
  ToggleChecklistStep,
} from '@radioking/onboarding-checklist';
import { PanelsState } from '@radioking/shared/pannel-manager';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { delay, filter, first, take } from 'rxjs/operators';

import { OnboardingChecklistModel } from '../onboarding-checklist.model';
import { OnboardingChecklistState } from '../states/onboarding-checklist.state';

@Component({
  selector: 'rk-onboarding-checklist-shell',
  templateUrl: './onboarding-checklist-shell.component.html',
  styleUrls: ['./onboarding-checklist-shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingChecklistShellComponent implements OnInit, OnDestroy {
  @ViewChild('mainPanel')
  mainPanel: MatExpansionPanel;

  @ViewChild('congrats')
  congrats: ElementRef<HTMLDivElement>;

  panels: QueryList<MatExpansionPanel>;
  @ViewChildren('panel') set _panels(panels: QueryList<MatExpansionPanel>) {
    this.panels = panels;
  }

  @Select(AuthState.userLang)
  userLang$: Observable<string>;

  @Select(AuthState.currentFirstname)
  firstName$: Observable<string>;

  @Select(OnboardingChecklistState.steps)
  steps$: Observable<OnboardingChecklistModel[]>;

  @Select(OnboardingChecklistState.notCompletedSteps)
  notCompletedSteps$: Observable<number>;

  @Select(OnboardingChecklistState.opened)
  opened$: Observable<boolean>;

  @Select(OnboardingChecklistState.completed)
  checklistCompleted$: Observable<boolean>;

  @Select(OnboardingChecklistState.progression)
  progression$: Observable<number>;

  @Select(OnboardingChecklistState.stepOpened)
  stepOpened$: Observable<ChecklistStep>;

  @Select(PanelsState.currentPanel)
  currentPanel$: Observable<string>;

  @Select(LiveTrackingState.status)
  radioStatus$: Observable<string>;

  encouragement: string;
  subscription = new Subscription();
  checklistWasOpen: boolean;

  openedAnimation$: BehaviorSubject<boolean>;

  constructor(
    public readonly translate: TranslateService,
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly store: Store,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.progression$.subscribe(progression => {
        this.encouragementWords(progression);
      }),
    );
    this.opened$.pipe(first()).subscribe(op => {
      this.openedAnimation$ = new BehaviorSubject<boolean>(op);
    });

    // Open next step
    this.subscription.add(
      this.actions$.pipe(ofActionSuccessful(CompleteStep), delay(100)).subscribe(() => {
        this.openedAnimation$.next(true);
        this.panels
          ?.get(
            this.store.selectSnapshot(OnboardingChecklistState.firstNotCompletedIndex),
          )
          ?.open();
        this.mainPanel.open();
      }),
    );

    this.subscription.add(
      this.currentPanel$.subscribe(opened => {
        if (this.mainPanel) {
          if (opened) {
            this.checklistWasOpen = this.mainPanel.expanded;
            this.mainPanel.close();
          } else {
            if (this.checklistWasOpen) {
              this.openedAnimation$.next(true);
              this.mainPanel.open();
            }
          }
        }
      }),
    );

    this.subscription.add(
      this.actions$
        .pipe(
          ofActionDispatched(ToggleChecklist),
          filter(({ opened }) => opened),
          take(1),
        )
        .subscribe(() => {
          this.openedAnimation$.next(true);
        }),
    );

    this.subscription.add(
      this.actions$
        .pipe(
          ofActionDispatched(
            CheckListAddTrack,
            CheckListAddPlaylist,
            CheckListPlanning,
            CheckListGenerate,
            AddDefaultTracksRequest,
            CheckListShare,
          ),
        )
        .subscribe(() => this.mainPanel.close()),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  encouragementWords(progression: number) {
    if (this.congrats) {
      this.congrats.nativeElement.classList.add('animate');
      setTimeout(() => {
        if (this.congrats) {
          this.congrats.nativeElement.classList.remove('animate');
        }
      }, 5000);
    }
    if (progression < 16) {
      this.encouragement = this.translate.instant('CHECKLIST.ENCOURAGEMENT.GOOD_START');
      return;
    }
    if (progression < 31) {
      this.encouragement = this.translate.instant('CHECKLIST.ENCOURAGEMENT.CONTINUE');
      return;
    }
    if (progression < 46) {
      this.encouragement = this.translate.instant(
        'CHECKLIST.ENCOURAGEMENT.GOOD_PROGRESSION',
      );
      return;
    }
    if (progression < 61) {
      this.encouragement = this.translate.instant('CHECKLIST.ENCOURAGEMENT.PERFECT');
      return;
    }
    if (progression < 76) {
      this.encouragement = this.translate.instant('CHECKLIST.ENCOURAGEMENT.READY_LAUNCH');
      return;
    }
    if (progression < 91) {
      this.encouragement = this.translate.instant('CHECKLIST.ENCOURAGEMENT.ON_AIR');
      return;
    }

    this.encouragement = '';
  }

  buttonAction(action: any, url: string, event: string) {
    aLogEvent(event);
    // Not on step page, first redirect then execute action
    if (window.location.pathname.indexOf(url) < 0) {
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd && event.url.indexOf(url) > -1),
          first(),
          delay(1000),
        )
        .subscribe(() => {
          this.dispatchAction(action);
        });
      const pathAfterRadio = window.location.pathname.indexOf('/', 7);
      const targetUrl = window.location.pathname.substring(0, pathAfterRadio) + url;
      this.router.navigateByUrl(targetUrl);
    } else {
      this.dispatchAction(action);
    }
  }

  @Dispatch()
  dispatchAction = (action: any) => action;

  @Dispatch()
  afterExpand = () => new ToggleChecklist(true);

  @Dispatch()
  afterExpandStep = (step: ChecklistStep) => new ToggleChecklistStep(step);

  @Dispatch()
  afterCollapse = () => {
    this.openedAnimation$.next(false);
    return new ToggleChecklist(false);
  };

  startOpen = () => {
    // We change this value after collapse and before open, because width animation can causes issues when opening
    if (this.openedAnimation$.value === false) {
      this.openedAnimation$.next(true);
    }
  };

  @Dispatch()
  destroyChecklist = (button = true) => {
    aLogEvent(
      button
        ? AMPLITUDE_EVENT.CHECKLIST.CHECKLIST_CONGRATULATIONS_CTA_CLOSE
        : AMPLITUDE_EVENT.CHECKLIST.CHECKLIST_CONGRATULATIONS_CLOSE,
    );
    return new DestroyChecklist();
  };
}
