import { Injectable, Injector } from '@angular/core';
import { I18nService } from '@app/core/services/i18n.service';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';

import { SetLang } from './i18n.actions';

export interface I18nStateModel {
  currentLang: string;
  supportedLanguages: string[];
}

@State<I18nStateModel>({
  name: 'i18n',
  defaults: {
    currentLang: '',
    supportedLanguages: [],
  },
})
@Injectable()
export class I18nState implements NgxsOnInit {
  i18nService: I18nService;

  constructor(private readonly injector: Injector) {}

  @Selector()
  static currentLang(state: I18nStateModel): string {
    return state.currentLang;
  }

  @Selector()
  static supportedLang(state: I18nStateModel): string[] {
    return state.supportedLanguages;
  }

  ngxsOnInit(ctx: StateContext<I18nStateModel>): void | any {
    this.i18nService = this.injector.get(I18nService);

    this.i18nService.langSubject.subscribe(lang => {
      ctx.patchState({
        currentLang: lang,
      });
    });

    this.i18nService.supportedLangsSubject.subscribe(supported => {
      ctx.patchState({
        supportedLanguages: supported,
      });
    });

    return undefined;
  }

  @Action(SetLang)
  setLang(ctx: StateContext<I18nStateModel>, { lang }: SetLang) {
    this.i18nService.language = lang;
    ctx.patchState({
      currentLang: lang,
    });
  }
}
