<div
  class="root"
  [class.wide]="wide"
  [class.larger]="larger"
  [class.santa]="santa"
  [class.slideshow]="slideshow"
  [class.sticky]="sticky"
  [style]="customStyle()"
>
  <div class="header" *ngIf="showHeader">
    <span data-cy="modal-title" [innerHTML]="title"></span>
    <div class="button-container">
      <rk-icon-button
        faIcon="fa-times"
        (click)="onAbort()"
        [class.hide-button]="!showCancelButon()"
      ></rk-icon-button>
    </div>
  </div>
  <div class="body"><ng-content></ng-content></div>
  <div class="error-wrapper" *ngIf="errorMessage">
    <mat-icon fontSet="fa" fontIcon="fa-exclamation-circle"></mat-icon>
    <span>{{ errorMessage || ('MODAL.DEFAULT.ERROR_MESSAGE' | translate) }}&#125;</span>
  </div>
  <div class="footer" *ngIf="showSubmitBlock">
    <rk-button
      data-cy="modal-cancel-button"
      *ngIf="showCancel && !loading"
      (click)="onCancel()"
      [btnStyle]="'flat'"
      [label]="cancelText || ('MODAL.DEFAULT.CANCEL' | translate)"
    ></rk-button>
    <rk-hotkeys-help-tooltip [disable]="isSubmitDisabled">
      <rk-button
        data-cy="modal-submit-button"
        [disabled]="loading || isSubmitDisabled"
        [loading]="loading"
        (click)="!loading ? onSubmit() : null"
        [label]="submitText || ('MODAL.DEFAULT.SUBMIT' | translate)"
      ></rk-button>
    </rk-hotkeys-help-tooltip>
  </div>
</div>
