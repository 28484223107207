import { PLACEHOLDER } from '@app/shared/constants';

import { SchedulerTrack } from './scheduler-core.model';

export interface SchedulerTrackApi {
  id: number;
  title: string;
  artist: string;
  album: string;
  duration: number;
  cover: string;
  audio_url: string;
  boxName: string;
  tags: string[];
}

export function convertToSchedulerTrack(track: SchedulerTrackApi): SchedulerTrack {
  return {
    id: track.id,
    title: track.title || '',
    artist: track.artist || '',
    album: track.album || '',
    duration: track.duration,
    cover: track.cover || PLACEHOLDER,
    file: track.audio_url,
    box: track.boxName,
    tagNames: track.tags,
  };
}
