import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { UiButtonModule } from '@radioking/ui/button';
import { AnimatedArrowComponent } from './animated-arrow/animated-arrow.component';
import { ConfettiComponent } from './confetti/confetti.component';

import { OnboardingChecklistShellComponent } from './onboarding-checklist-shell/onboarding-checklist-shell.component';

@NgModule({
  imports: [
    CdkAccordionModule,
    CommonModule,
    MatIconModule,
    UiButtonModule,
    TranslateModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatTooltipModule,
  ],
  declarations: [
    OnboardingChecklistShellComponent,
    AnimatedArrowComponent,
    ConfettiComponent,
  ],
  exports: [OnboardingChecklistShellComponent, AnimatedArrowComponent, ConfettiComponent],
})
export class OnboardingChecklistModule {}
