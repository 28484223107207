import { SchedulerTrack } from '@radioking/scheduler-core';
import moment from 'moment-timezone';

const tag = '[DailyGeneration]';

export class DailyGenerationRequest {
  static readonly type = `${tag} request daily generation`;
  constructor(public date: moment.Moment) {}
}

export class DailyGenerationSuccess {
  static readonly type = `${tag} success daily generation`;
  constructor(public generation: any) {}
}

export class DailyGenerationFailure {
  static readonly type = `${tag} error daily generation`;
  constructor(public error: Error) {}
}

export class SilentDailyGenerationRequest {
  static readonly type = `${tag} request daily generation without loader`;
  constructor(public date: moment.Moment) {}
}

export class GeneratedDatesRequest {
  static readonly type = `${tag} request generated dates`;
}

export class GeneratedDatesSuccess {
  static readonly type = `${tag} success generated dates`;
  constructor(public generatedDates: string[]) {}
}

export class GeneratedDatesFailure {
  static readonly type = `${tag} error generated dates`;
  constructor(public error: Error) {}
}

export class RegenerateCurrentDayRequest {
  static readonly type = `${tag} request regenerate current day`;
}

export class RegenerateCurrentDaySuccess {
  static readonly type = `${tag} success regenerate current day`;
}

export class RegenerateCurrentDayFailure {
  static readonly type = `${tag} error regenerate current day`;
  constructor(public error: Error) {}
}

export class SearchEntry {
  static readonly type = `${tag} search entry`;
  constructor(public search: string) {}
}

export class DeleteEntryRequest {
  static readonly type = `${tag} request delete daily track`;
  constructor(public entryId: number) {}
}

export class DeleteEntrySuccess {
  static readonly type = `${tag} success delete daily track`;
  constructor(public entryId: number) {}
}

export class DeleteEntryFailure {
  static readonly type = `${tag} error delete daily track`;
  constructor(public error: Error) {}
}

export class MoveEntryRequest {
  static readonly type = `${tag} request move entry`;
  constructor(public entryId: number, public targetEntryId: number) {}
}

export class MoveEntrySuccess {
  static readonly type = `${tag} success move entry`;
}

export class MoveEntryFailure {
  static readonly type = `${tag} error move entry`;
  constructor(public error: Error) {}
}

export class GenerateDayRequest {
  static readonly type = `${tag} request generate day`;
  constructor(public auto = false) {}
}

export class GenerateDaySuccess {
  static readonly type = `${tag} success generate day`;
  constructor(public date: moment.Moment) {}
}

export class GenerateDayFailure {
  static readonly type = `${tag} error generate day`;
  constructor(public error: Error) {}
}

export class DeleteGeneratedDayRequest {
  static readonly type = `${tag} request delete generated day`;
}

export class DeleteGeneratedDaySuccess {
  static readonly type = `${tag} success delete generated day`;
}

export class DeleteGeneratedDayFailure {
  static readonly type = `${tag} error delete generated day`;
  constructor(public error: Error) {}
}

export class AddTrackToGenerationRequest {
  static readonly type = `${tag} request add track to generation`;
  constructor(public track: SchedulerTrack, public entryId: number) {}
}

export class AddTrackToGenerationSuccess {
  static readonly type = `${tag} success add track to generation`;
}

export class AddTrackToGenerationFailure {
  static readonly type = `${tag} error add track to generation`;
  constructor(public error: Error) {}
}

export class AddTrackToMiddleOfGenerationRequest {
  static readonly type = `${tag} request add track to the middle of current generation`;
  constructor(public track: SchedulerTrack, public mobile = true) {}
}

export class ToggleDragging {
  static readonly type = `${tag} toggle dragging`;
  constructor(public dragging: boolean) {}
}
