import { Component, Input } from '@angular/core';

@Component({
  selector: 'rk-animated-arrow',
  templateUrl: './animated-arrow.component.html',
  styleUrls: ['./animated-arrow.component.scss'],
})
export class AnimatedArrowComponent {
  @Input()
  description: string;
}
