import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { UpdateAvailable } from '@app/core/states/app.actions';
import { Store } from '@ngxs/store';
import { combineLatest, interval } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SwloggerService {
  constructor(private readonly updates: SwUpdate, private readonly store: Store) {}

  public watchForUpdate() {
    combineLatest([this.updates.checkForUpdate(), interval(30000)])
      .pipe(filter(() => !document.hidden))
      .subscribe(([updateAvailable]) => {
        if (updateAvailable) {
          this.store.dispatch(new UpdateAvailable());
        }
      });
  }
}
