<div *ngIf="imageAbove" class="container-img mt-20"><img [src]="imageAbove" /></div>

<div class="container">
  <div class="key">
    <mat-icon class="key-icon" fontSet="fa" fontIcon="fa-lock"></mat-icon>
  </div>
  <div class="title">{{ title }}</div>
  <div class="content">{{ content }}</div>
  <rk-button
    *rkIsOwner
    class="upgrade-button"
    (click)="updateOffer()"
    label="{{ 'GLOBAL.NOACCESS.UPGRADE' | translate }}"
  ></rk-button>
</div>

<div *ngIf="imageBelow" class="container-img mb-20"><img [src]="imageBelow" /></div>
