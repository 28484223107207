import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HotkeysService } from '@app/core/services/hotkeys.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rk-modal-wrapper',
  templateUrl: './modal-wrapper.component.html',
  styleUrls: ['./modal-wrapper.component.scss'],
})
export class ModalWrapperComponent implements OnInit, OnDestroy {
  @Input()
  title: string;

  @Input()
  cancelText: string;

  @Input()
  submitText: string;

  @Input()
  isSubmitDisabled: boolean;

  @Input()
  loading: boolean;

  @Input()
  errorMessage: string;

  @Input()
  showSubmitBlock = true;

  @Input()
  showCancel = true;

  @Input()
  hideCancelOnLoading = false;

  @Input()
  larger = false;

  @Input()
  santa = false;

  @Input()
  wide = false;

  @Input()
  slideshow = false;

  @Input()
  style: object;

  @Input()
  sticky = false;

  @Input()
  dialogRef: MatDialogRef<any>;

  @Input() showHeader = true;

  @Output()
  readonly closed = new EventEmitter();

  @Output()
  readonly cancelled = new EventEmitter();

  @Output()
  readonly submitted = new EventEmitter();

  subscription = new Subscription();

  constructor(private readonly hotkeys: HotkeysService) {}

  ngOnInit() {
    // Hotkeys
    this.subscription.add(
      this.hotkeys.fromConfigArray([
        { hotkey: 'confirm', handler: () => this.onConfirmHotkey() },
      ]),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onSubmit() {
    if (this.dialogRef) {
      this.dialogRef.close(true);
    }
    this.submitted.emit();
  }
  onCancel() {
    if (this.dialogRef) {
      this.dialogRef.close(false);
    }
    this.cancelled.emit();
  }
  onAbort() {
    if (this.dialogRef) {
      this.dialogRef.close(false);
    }
    this.closed.emit();
  }

  showCancelButon() {
    if (!this.hideCancelOnLoading) {
      return true;
    }

    return !this.loading;
  }

  onConfirmHotkey() {
    if (!this.isSubmitDisabled) {
      this.submitted.emit();
    }
  }

  customStyle() {
    return this.style ? this.style : null;
  }
}
