import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'rk-radio-sidebar',
  templateUrl: './radio-sidebar.component.html',
  styleUrls: ['./radio-sidebar.component.scss'],
})
export class RadioSidebarComponent {
  @Output()
  readonly toggleSideNav = new EventEmitter();
}
