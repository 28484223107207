import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { FoldContainerSidebarComponent } from '@app/shared/components/shell/fold-container-sidebar/fold-container-sidebar.component';

@Injectable({
  providedIn: 'root',
})
export class MainSideNavService {
  private sideNav: MatSidenav;
  private foldContainer: FoldContainerSidebarComponent;

  toggleFold() {
    this.foldContainer.toggleFold();
  }

  toggleSideNav() {
    this.sideNav.toggle();
  }

  setSideNav(sidenav: MatSidenav) {
    this.sideNav = sidenav;
  }

  getSideNav(): MatSidenav {
    return this.sideNav;
  }

  getFoldContener(): FoldContainerSidebarComponent {
    return this.foldContainer;
  }

  setFoldContener(container: FoldContainerSidebarComponent) {
    this.foldContainer = container;
  }
}
