<div class="confetti-container base-confetti pointer-events-none">
  <svg
    width="16"
    height="28"
    viewBox="0 0 16 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="confetti-item"
  >
    <rect
      width="27.3413"
      height="12.6032"
      rx="6.30162"
      transform="matrix(-0.187638 -0.982238 -0.987747 0.156066 16.7739 26.0977)"
      fill="url(#paint0_linear_34_17101)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_34_17101"
        x1="0"
        y1="0"
        x2="9.58293"
        y2="20.7891"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF9150" />
        <stop offset="1" stop-color="#FF3648" />
      </linearGradient>
    </defs>
  </svg>
</div>
