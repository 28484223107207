import { Injectable } from '@angular/core';
import { AuthState } from '@app/core/states/auth.state';
import { Store } from '@ngxs/store';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class AdminGuard {
  constructor(private readonly store: Store) {}

  canMatch(): Observable<boolean> {
    return combineLatest([
      this.store.select(AuthState.isAdminOrSuperuser),
      this.store.select(AuthState.userId),
    ]).pipe(
      filter(data => data[1] !== -1),
      map(data => data[0]),
    );
  }

  canActivate(): Observable<boolean> {
    return this.canMatch();
  }

  canActivateChild(): Observable<boolean> {
    return this.canMatch();
  }
}
