<span *ngIf="hotkey === 'playPause'" class="shortcut">{{
  'MEDIA.CONTEXT_MENU.PLAY_SHORTCUT' | translate
}}</span>

<span *ngIf="hotkey === 'edit'" class="shortcut">
  <span class="meta"
    ><img [src]="'/assets/icons/' + (isMacOS() ? 'cmd.svg' : 'ctrl.svg')" /></span
  >E
</span>

<ng-container *ngIf="hotkey === 'delete'">
  <span *ngIf="!isMacOS()" class="shortcut">{{
    'MEDIA.CONTEXT_MENU.DELETE_SHORTCUT' | translate
  }}</span>
  <span *ngIf="isMacOS()" class="shortcut">
    <span class="meta"><img src="/assets/icons/cmd.svg" /></span
    ><img src="/assets/icons/backspace.svg" />
  </span>
</ng-container>

<div *ngIf="hotkey === 'confirm'" class="shortcut">
  <span class="meta"
    ><img [src]="'/assets/icons/' + (isMacOS() ? 'cmd' : 'ctrl') + '-bg.svg'" /></span
  ><span class="meta"><img src="/assets/icons/enter-bg.svg" /></span>︎
</div>
