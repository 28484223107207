import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { RADIO_PLAN_DESC } from '@app/core/models/Radio';
import { AppState } from '@app/core/states/app.state';
import { LiveTrackingState } from '@app/core/states/live-tracking.state';
import { RadioState } from '@app/core/states/radio.state';
import { ModelNav } from '@app/shared/modules/navigation/navigation.service';
import { planBigger } from '@app/shared/utils';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RadioPageRequest } from '../../../../../../../libs/share/src/lib/states/share.actions';

@Component({
  selector: 'rk-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FuseNavigationComponent implements OnInit, OnDestroy {
  @Input()
  layout = 'vertical';

  @Input()
  navigation: ModelNav[];

  @Select(RadioState.currentRadioId)
  currentRadioId$: Observable<number>;

  @Select(AppState.onMobile)
  onMobile$: Observable<boolean>;

  @Select(RadioState.radioPageAvailable)
  radioPageAvailable$: Observable<boolean>;

  @Select(LiveTrackingState.planDesc)
  planDesc$: Observable<RADIO_PLAN_DESC>;

  subscription = new Subscription();

  planBigger(plan: RADIO_PLAN_DESC, compareTo: RADIO_PLAN_DESC, orEquals = false) {
    return planBigger(plan, compareTo, orEquals);
  }

  ngOnInit() {
    this.subscription.add(
      combineLatest([
        this.radioPageAvailable$.pipe(filter(rp => rp)),
        this.currentRadioId$.pipe(filter(id => !!id)),
      ]).subscribe(() => this.getRadioPage()),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @Dispatch()
  getRadioPage = () => new RadioPageRequest();
}
