import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import moment from 'moment-timezone';

import { RadioState } from '../../../apps/manager/src/app/core/states/radio.state';

@Pipe({
  name: 'toRadioTz',
  pure: false, // tslint:disable-line:no-pipe-impure
})
export class ToRadioTzPipe implements PipeTransform {
  constructor(private readonly store: Store) {}

  transform(value: string | moment.Moment, alreadyMoment?: boolean): moment.Moment {
    const timezoneRadio = this.store.selectSnapshot(RadioState.currentRadioTimezone);
    if (alreadyMoment) {
      const momentValue = value as moment.Moment;

      return momentValue.tz(timezoneRadio) ?? momentValue;
    }

    return moment(value).tz(timezoneRadio) ?? moment(value);
  }
}
