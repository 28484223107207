import {
  Cover,
  NormType,
  SettingBroadcast,
  SettingCoverAndLive,
  SettingMisc,
  SettingSecurity,
  SettingSocial,
  SettingStream,
} from '@app/library/models/setting.model';

export interface SettingRadioApiModel {
  name: string;
  slogan: string;
  slug: string;
  description: string;
  website: string;
  language: string;
  genres: string[];
  country: string;
  timezone: string;
  logo: string;
}

export interface SettingBroadcastApiModel {
  title_repeat: number;
  artist_repeat: number;
  album_repeat: number;
  fadeout_default: number;
  mixpoint_auto: boolean;
  mixpoint_threshold: number;
  norm_threshold?: number;
  ebu_r128_level?: number;
}

export interface SettingStreamApiModel {
  aac_stream: boolean;
  aac_quality: number;
  aac_id: number;
  mp3_stream: boolean;
  mp3_quality: number;
  mp3_id: number;
}

export interface CoverApiModel {
  key: 'rk' | 'string';
  is_disabled: boolean;
  title: 'string';
}

export interface SettingCoverAndLiveApiModel {
  cover_default: string;
  cover_order: CoverApiModel[];
  forced_title: boolean;
  artist: string;
  title: string;
  buy_link: string;
  cover_live: string;
}

export interface SettingPlatformApiModel {
  public: boolean;
  comments?: boolean;
  header?: string;
}

export interface SettingDirectoryApiModel {
  orange: boolean;
  icecast: boolean;
  radioline: boolean;
  sonos: boolean;
}

export interface SettingSocialApiModel {
  facebook_url: string;
  twitter_username: string;
  itunes_affiliate_id: string;
}

export interface SettingSecurityApiModel {
  auto_disconnection_timer: number;
  geoblock_enabled: boolean;
  geoblock_mode: 'allow' | 'deny';
  geoblock_countries: string[];
  public_listeners: boolean;
  ip_restriction_enabled: boolean;
  ip_restriction_mode: 'allow' | 'deny';
  ip_restriction_list: string[];
}

export interface SettingMiscApiModel {
  weekly_report: boolean;
  mixpoints_transition_track: number;
}

export function convertToSettingBroadcast(
  setting: SettingBroadcastApiModel,
): SettingBroadcast {
  return {
    titleRepeat: setting.title_repeat,
    artistRepeat: setting.artist_repeat,
    albumRepeat: setting.album_repeat,
    fadeoutDefault: setting.fadeout_default,
    mixpointAuto: setting.mixpoint_auto,
    mixpointThreshold: setting.mixpoint_threshold,
    normThreshold: setting.norm_threshold,
    normType: (setting.ebu_r128_level ? 'advanced' : 'standard') as NormType,
    normEBU: setting.ebu_r128_level,
  };
}

export function convertToSettingBroadcastApi(
  setting: SettingBroadcast,
): SettingBroadcastApiModel {
  const settingApi: SettingBroadcastApiModel = {
    title_repeat: setting.titleRepeat,
    artist_repeat: setting.artistRepeat,
    album_repeat: setting.albumRepeat,
    fadeout_default: setting.fadeoutDefault,
    mixpoint_auto: setting.mixpointAuto,
    mixpoint_threshold: setting.mixpointThreshold,
  };
  if (setting.normEBU === null && setting.normThreshold === null) {
    // Normalization disabled
    settingApi.norm_threshold = null;
    settingApi.ebu_r128_level = null;
  } else if (setting.normType === NormType.ADVANCED) {
    // EBU-R 128 Normalization
    settingApi.ebu_r128_level = setting.normEBU;
  } else {
    // Peak Normalization
    settingApi.norm_threshold = setting.normThreshold;
    settingApi.ebu_r128_level = null;
  }

  return settingApi;
}

export function convertToSettingStream(setting: SettingStreamApiModel): SettingStream {
  return {
    aacStream: setting.aac_stream,
    aacQuality: setting.aac_quality,
    aacId: setting.aac_id,
    mp3Stream: setting.mp3_stream,
    mp3Quality: setting.mp3_quality,
    mp3Id: setting.mp3_id,
  };
}

export function convertToSettingStreamApi(setting: SettingStream): SettingStreamApiModel {
  return {
    aac_stream: setting.aacStream,
    aac_quality: setting.aacQuality,
    aac_id: setting.aacId,
    mp3_stream: setting.mp3Stream,
    mp3_quality: setting.mp3Quality,
    mp3_id: setting.mp3Id,
  };
}

function convertToOrder(cover: CoverApiModel): Cover {
  return {
    isDisabled: cover.is_disabled,
    key: cover.key,
    title: cover.title,
  };
}

function convertToOrderApi(cover: Cover): CoverApiModel {
  return {
    is_disabled: cover.isDisabled,
    key: cover.key,
    title: cover.title,
  };
}

export function convertToSettingCoverAndLive(
  setting: SettingCoverAndLiveApiModel,
): SettingCoverAndLive {
  return {
    coverDefault: setting.cover_default,
    coverOrder: setting.cover_order.map(convertToOrder),
    artist: setting.artist,
    buyLink: setting.buy_link,
    coverLive: setting.cover_live,
    forcedTitle: setting.forced_title,
    title: setting.title,
  };
}

export function convertToSettingCoverAndLiveApi(
  setting: SettingCoverAndLive,
): SettingCoverAndLiveApiModel {
  return {
    cover_default: setting.coverDefault,
    cover_order: setting.coverOrder.map(convertToOrderApi),
    artist: setting.artist,
    buy_link: setting.buyLink,
    cover_live: setting.coverLive,
    forced_title: setting.forcedTitle,
    title: setting.title,
  };
}

export function convertToSettingSocial(setting: SettingSocialApiModel): SettingSocial {
  return {
    facebookUrl: setting.facebook_url,
    xUsername: setting.twitter_username ? `@${setting.twitter_username}` : null,
    itunesAffiliateId: setting.itunes_affiliate_id,
  };
}

export function convertToSettingSocialApi(
  setting: SettingSocial,
): Partial<SettingSocialApiModel> {
  return {
    facebook_url: setting.facebookUrl,
    twitter_username: setting.xUsername ? setting.xUsername.substring(1) : '',
    itunes_affiliate_id: setting.itunesAffiliateId,
  };
}

export function convertToSettingSecurity(
  setting: SettingSecurityApiModel,
): SettingSecurity {
  return {
    autoDisconnectionTimer: setting.auto_disconnection_timer,
    geoblockEnabled: setting.geoblock_enabled,
    geoblockMode: setting.geoblock_mode,
    geoblockCountries: setting.geoblock_countries,
    publicListeners: setting.public_listeners,
    ipEnabled: setting.ip_restriction_enabled,
    ipMode: setting.ip_restriction_mode,
    ipList: setting.ip_restriction_list,
  };
}

export function convertToSettingSecurityApi(
  setting: SettingSecurity,
): SettingSecurityApiModel {
  return {
    auto_disconnection_timer: setting.autoDisconnectionTimer,
    geoblock_enabled: setting.geoblockEnabled,
    geoblock_mode: setting.geoblockMode,
    geoblock_countries: setting.geoblockCountries,
    public_listeners: setting.publicListeners,
    ip_restriction_enabled: setting.ipEnabled,
    ip_restriction_list: setting.ipList,
    ip_restriction_mode: setting.ipMode,
  };
}

export function convertToSettingMisc(setting: SettingMiscApiModel): SettingMisc {
  return {
    mixpointsTransitionTrack: setting.mixpoints_transition_track,
    weeklyReport: setting.weekly_report,
  };
}

export function convertToSettingMiscApi(setting: SettingMisc): SettingMiscApiModel {
  return {
    mixpoints_transition_track: setting.mixpointsTransitionTrack,
    weekly_report: setting.weeklyReport,
  };
}
