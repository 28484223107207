import { StateOperator } from '@ngxs/store';
import { Predicate } from '@ngxs/store/operators';

export function isPredicate<T>(
  value: Predicate<T> | boolean | number | number[],
): value is Predicate<T> {
  return typeof value === 'function';
}

export function isArrayNumber(value: number[]): boolean {
  for (const valueItem of value) {
    if (!isNumber(valueItem)) {
      return false;
    }
  }

  return true;
}

export function invalidIndexs<T>(indices: number[], existing: Readonly<T[]>): boolean {
  for (const index of indices) {
    if (!existing[index] || !isNumber(index) || invalidIndex(index)) {
      return true;
    }
  }

  return false;
}

export function isStateOperator<T>(
  value: Partial<T> | StateOperator<T>,
): value is StateOperator<T> {
  return typeof value === 'function';
}

export function isNumber(value: any): value is number {
  return typeof value === 'number';
}

export function invalidIndex(index: number): boolean {
  return Number.isNaN(index) || index === -1;
}

export function isObject(value: any): boolean {
  return typeof value === 'object';
}
