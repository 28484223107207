import { Env } from '@env/model';
import packageJson from 'package.json';

export const environment: Env = {
  production: true,
  disableLogs: false,
  show18nSwitch: false,
  version: 'dev',
  versionFull: `${packageJson.version}-dev`,
  urls: {
    OLD_MAIN_API: 'https://www.dev.radioking.xyz/api',
    MAIN_API_V2: 'https://api.dev.radioking.xyz',
    WIDGET_API: 'https://www.dev.radioking.xyz/widgets/api/v1',
    WIDGET_API_V2: 'https://api.dev.radioking.xyz/widget',
    WHMCS: 'https://www.dev.radioking.xyz/on',
    RADIOKING_DOMAIN: 'dev.radioking.xyz',
    RADIOKING_PLAY_DOMAIN: 'play.dev.radioking.xyz',
    WIDGETS: 'https://www.dev.radioking.xyz/widgets',
    WIDGETS_V2: 'https://widget.dev.radioking.xyz',
    PLAYER_WIDGET: 'https://player.dev.radioking.xyz',
    LISTEN: 'https://play.api.dev.radioking.xyz/',
    SMARTLINK: 'https://link.dev.radioking.xyz/',
    RADIO_PAGE: 'https://pageradio.dev.radioking.xyz/',
    WELOVE_DOMAIN: 'dev.welove.radio',
  },
  whmcsId: {
    Demo: 44,
    Discover: 57,
    Start: 45,
    Pro: 46,
    Business: 47,
  },
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'fr-FR', 'en-GB', 'de-DE'],
  auth: {
    isOnManagerLoginEnable: false,
    redirectUrl:
      'https://www.dev.radioking.xyz/login?redirect=https:%2F%2Fmanager.dev.radioking.xyz',
  },
  sentry: {
    url: 'https://5755836ebc9947999085e76c383e406f@sentry.io/1487411',
    isEnabled: true,
    env: 'dev',
  },
  cookies: {
    config: {
      domain: '.dev.radioking.xyz',
      expires: 365,
      secure: true,
    },
    refreshTokenKey: 'refresh_token',
    tokenKey: 'token',
  },
  googleMapsApiKey: 'AIzaSyB5Si3nNVu8PQAHhAyYOIr0351CUitTkws',
  amplitudeApiKey: '02221b0d901a074475ad262a2d5536ca',
  facebookPixelId: '',
  gtm: 'GTM-KT784GD',
};
