// Original source code: https://github.com/fetrarij/ngx-daterangepicker-material
// @ts-nocheck
/* tslint:disable */
import {
  ConnectedPosition,
  Overlay,
  OverlayConfig,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  Directive,
  DoCheck,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Injector,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import moment from 'moment-timezone';

import {
  CONTAINER_DATA,
  DaterangepickerComponent,
  DefaultLocaleConfig,
  LocaleConfig,
} from './daterangepicker/daterangepicker.component';

export interface DateRange {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

@Directive({
  selector: 'input[rkDaterangepicker]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DaterangepickerDirective),
      multi: true,
    },
  ],
})
export class DaterangepickerDirective implements OnInit, OnChanges, DoCheck {
  public picker: DaterangepickerComponent;
  private _onChange = Function.prototype;
  private _onTouched = Function.prototype;
  private _value: any;
  private localeDiffer: KeyValueDiffer<string, any>;
  private overlayRef: OverlayRef;
  @Input()
  minDate: moment.Moment;
  @Input()
  maxDate: moment.Moment = null;
  @Input()
  autoApply = true;
  @Input()
  alwaysShowCalendars: boolean;
  @Input()
  showCustomRangeLabel: boolean;
  @Input()
  linkedCalendars = true;
  @Input()
  singleDatePicker: boolean;
  @Input()
  isRangeFirstDate: boolean;
  @Input()
  showWeekNumbers: boolean;
  @Input()
  showISOWeekNumbers: boolean;
  @Input()
  showDropdowns: boolean;
  @Input()
  isInvalidDate: Function;
  @Input()
  isCustomDate: Function;
  @Input()
  showClearButton: boolean;
  @Input()
  ranges: any;
  @Input()
  opens: string;
  @Input()
  defaultRange: DateRange = null;
  @Input()
  drops: string;
  firstMonthDayClass: string;
  @Input()
  lastMonthDayClass: string;
  @Input()
  emptyWeekRowClass: string;
  @Input()
  firstDayOfNextMonthClass: string;
  @Input()
  lastDayOfPreviousMonthClass: string;
  @Input()
  keepCalendarOpeningWithRange: boolean;
  @Input()
  showRangeLabelOnInput: boolean;
  @Input()
  showCancel = false;
  @Input()
  showRanges: boolean;
  // timepicker variables
  @Input()
  timePicker: Boolean = false;
  @Input()
  timePicker24Hour: Boolean = false;
  @Input()
  timePickerIncrement = 1;
  @Input()
  timePickerSeconds: Boolean = false;
  @Input()
  updateInputValue = true;

  _locale: LocaleConfig = {};
  @Input() set locale(value) {
    this._locale = { ...DefaultLocaleConfig, ...value };
  }
  get locale(): any {
    return this._locale;
  }
  @Input()
  private _endKey = 'endDate';
  private _startKey = 'startDate';
  @Input() set startKey(value) {
    if (value !== null) {
      this._startKey = value;
    } else {
      this._startKey = 'startDate';
    }
  }
  @Input() set endKey(value) {
    if (value !== null) {
      this._endKey = value;
    } else {
      this._endKey = 'endDate';
    }
  }
  notForChangesProperty: Array<string> = ['locale', 'endKey', 'startKey'];

  _applyToday: number;
  @Input() set applyToday(value) {
    this.setValue({ startDate: moment(), endDate: moment() });
    this.picker?.setToday();
  }
  get applyToday(): any {
    return this._applyToday;
  }

  get value() {
    return this._value || null;
  }
  set value(val) {
    this._value = val;
    this._onChange(val);
    this._changeDetectorRef.markForCheck();
  }
  @Output() readonly change: EventEmitter<Object> = new EventEmitter();
  @Output() readonly rangeClicked: EventEmitter<Object> = new EventEmitter();
  @Output() readonly datesUpdated: EventEmitter<Object> = new EventEmitter();

  // Specific cases
  @Input()
  amplitudeEvent: string;
  @Input()
  highlightDates: string[];

  constructor(
    public viewContainerRef: ViewContainerRef,
    public _changeDetectorRef: ChangeDetectorRef,
    private readonly _el: ElementRef,
    private readonly _renderer: Renderer2,
    private readonly differs: KeyValueDiffers,
    private readonly overlay: Overlay,
    private readonly injector: Injector,
  ) {
    this.drops = 'down';
    this.opens = 'right';
  }
  ngOnInit() {
    this.localeDiffer = this.differs.find(this.locale).create();
    if (this.defaultRange) {
      this._value = this.defaultRange;
      this._onChange(this.defaultRange);
      this._changeDetectorRef.markForCheck();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const change in changes) {
      if (changes.hasOwnProperty(change)) {
        if (this.notForChangesProperty.indexOf(change) === -1 && this.picker) {
          this.picker[change] = changes[change].currentValue;
        }
      }
    }
  }

  ngDoCheck() {
    if (this.localeDiffer) {
      const changes = this.localeDiffer.diff(this.locale);
      if (changes && this.picker) {
        this.picker.updateLocale(this.locale);
      }
    }
  }

  initPicker() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
    const strategy = this.overlay.position().flexibleConnectedTo(this._el);
    const cp: ConnectedPosition = {
      offsetX: 0,
      offsetY: -29,
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top',
    };
    strategy.withPositions([cp]);

    const config = new OverlayConfig({
      positionStrategy: strategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
    });
    this.overlayRef = this.overlay.create(config);

    const dataToPass = {
      singleDatePicker: this.singleDatePicker,
      autoApply: this.autoApply,
      linkedCalendars: this.linkedCalendars,
      minDate: this.minDate,
      maxDate: this.maxDate,
      ranges: this.ranges,
      alwaysShowCalendars: this.alwaysShowCalendars,
      showRanges: this.showRanges,
    };
    if (this.value) {
      dataToPass['startDate'] = this.value.startDate;
      dataToPass['endDate'] = this.value.endDate;
    }
    if (this.isRangeFirstDate) {
      dataToPass['isRangeFirstDate'] = this.isRangeFirstDate;
    }
    if (this.defaultRange) {
      dataToPass['defaultRange'] = this.defaultRange;
    }
    if (this.amplitudeEvent) {
      dataToPass['amplitudeEvent'] = this.amplitudeEvent;
    }
    if (this.highlightDates) {
      dataToPass['highlightDates'] = this.highlightDates;
    }

    const portal = new ComponentPortal(
      DaterangepickerComponent,
      null,
      this.createInjector(dataToPass),
    );
    const attached = this.overlayRef.attach(portal);
    this.overlayRef.backdropClick().subscribe(() => this.overlayRef.dispose());
    this.picker = attached.instance;

    this.writeValue(this.value);

    this.picker.rangeClicked.asObservable().subscribe((range: any) => {
      this.rangeClicked.emit(range);
    });
    this.picker.datesUpdated.asObservable().subscribe((range: any) => {
      this.datesUpdated.emit(range);
    });
    this.picker.choosedDate.asObservable().subscribe((change: any) => {
      if (change) {
        const value = {};
        value[this._startKey] = change.startDate;
        value[this._endKey] = change.endDate;
        this.value = value;
        this.change.emit(value);
        if (this.updateInputValue && typeof change.chosenLabel === 'string') {
          this._el.nativeElement.value = change.chosenLabel;
        }
      }
      if (this.overlayRef) {
        this.overlayRef.dispose();
      }
    });
    this.picker.firstMonthDayClass = this.firstMonthDayClass;
    this.picker.lastMonthDayClass = this.lastMonthDayClass;
    this.picker.emptyWeekRowClass = this.emptyWeekRowClass;
    this.picker.firstDayOfNextMonthClass = this.firstDayOfNextMonthClass;
    this.picker.lastDayOfPreviousMonthClass = this.lastDayOfPreviousMonthClass;
    this.picker.drops = this.drops;
    this.picker.opens = this.opens;
    this.localeDiffer = this.differs.find(this.locale).create();
  }

  createInjector(dataToPass): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(CONTAINER_DATA, dataToPass);

    return new PortalInjector(this.injector, injectorTokens);
  }

  @HostListener('blur')
  onBlur() {
    this._onTouched();
  }

  @HostListener('click')
  open(event?: any) {
    this.initPicker();
    this.picker.show(event);
    setTimeout(() => {
      this.setPosition();
    });
  }

  @HostListener('keyup.esc')
  hide(e?) {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }
  toggle(e?) {
    if (this.picker) {
      if (this.picker.isShown) {
        this.hide(e);
      } else {
        this.open(e);
      }
    }
  }

  clear() {
    if (this.picker) {
      this.picker.clear();
    }
  }

  writeValue(value) {
    this.value = value;
    this.setValue(value);
  }
  registerOnChange(fn) {
    this._onChange = fn;
  }
  registerOnTouched(fn) {
    this._onTouched = fn;
  }
  private setValue(val: any) {
    if (this.picker && val) {
      if (val[this._startKey]) {
        this.picker.setStartDate(val[this._startKey]);
      }
      if (val[this._endKey]) {
        this.picker.setEndDate(val[this._endKey]);
      }
    } else if (this.picker) {
      this.picker.clear();
    } else if (val && this.updateInputValue) {
      this._el.nativeElement.value = val.startDate.format(
        moment.localeData().longDateFormat('L'),
      );
    }
  }
  /**
   * Set position of the calendar
   */
  setPosition() {
    if (!this.picker) {
      return;
    }
    let style;
    let containerTop;
    const container = this.picker.pickerContainer.nativeElement;
    const element = this._el.nativeElement;
    if (this.drops && this.drops === 'up') {
      containerTop = element.offsetTop - container.clientHeight + 'px';
    } else {
      containerTop = 'auto';
    }
    if (this.opens === 'left') {
      style = {
        top: containerTop,
        left: element.offsetLeft - container.clientWidth + element.clientWidth + 'px',
        right: 'auto',
      };
    } else if (this.opens === 'center') {
      style = {
        top: containerTop,
        left:
          element.offsetLeft + element.clientWidth / 2 - container.clientWidth / 2 + 'px',
        right: 'auto',
      };
    } else {
      style = {
        top: containerTop,
        left: element.offsetLeft + 'px',
        right: 'auto',
      };
    }
    if (style) {
      this._renderer.setStyle(container, 'top', style.top);
      this._renderer.setStyle(container, 'left', style.left);
      this._renderer.setStyle(container, 'right', style.right);
    }
  }
}
