import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'rk-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
})
export class FormErrorComponent {
  @Input()
  errorMessage: string;

  @Input()
  classes: string;

  @HostBinding('class.relative')
  @Input()
  relativePlacement = false;
}
