<rk-modal-wrapper
  (cancelled)="onCancel()"
  (closed)="onCancel()"
  [showSubmitBlock]="false"
  [title]="'GLOBAL.MODAL.DISCOVER.TITLE' | translate"
>
  <div class="gap-6 flex flex-col">
    <div>
      <div class="section-title">
        <img src="/assets/icons/rocket-gradient.svg" />
        <span translate>GLOBAL.MODAL.DISCOVER.SPEED_UP</span>
      </div>
      <div class="section-description" translate>GLOBAL.MODAL.DISCOVER.SPEED_UP_DESC</div>
    </div>
    <div>
      <div class="section-title">
        <img src="/assets/icons/stat-gradient.svg" />
        <span translate>GLOBAL.MODAL.DISCOVER.STATS</span>
      </div>
      <div class="section-description" translate>GLOBAL.MODAL.DISCOVER.STATS_DESC</div>
    </div>
    <div>
      <div class="section-title">
        <img src="/assets/icons/mic-gradient.svg" />
        <span translate>GLOBAL.MODAL.DISCOVER.VOICE</span>
      </div>
      <div class="section-description" translate>GLOBAL.MODAL.DISCOVER.VOICE_DESC</div>
    </div>
    <div>
      <div class="section-title">
        <img src="/assets/icons/team-gradient.svg" />
        <span translate>GLOBAL.MODAL.DISCOVER.TEAM</span>
      </div>
      <div class="section-description" translate>GLOBAL.MODAL.DISCOVER.TEAM_DESC</div>
    </div>
    <div class="section-title" translate>GLOBAL.MODAL.DISCOVER.AND_MORE</div>
    <rk-button
      [label]="'GLOBAL.MODAL.DISCOVER.BUTTON' | translate"
      (click)="openUpgradeModal()"
    ></rk-button>
  </div>
</rk-modal-wrapper>
