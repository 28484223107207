import { Directive, ElementRef, HostBinding, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[rkInput]',
})
export class InputDirective {
  @HostBinding('style.max-width')
  maxWidth = '100%';

  constructor(
    @Optional()
    @Self()
    public ngControl: NgControl,
    public el: ElementRef,
  ) {}

  // inputType: 'text' | 'num' = 'text';
  @Input()
  set inputType(val: 'text' | 'num') {
    if (val === 'text') {
      this.maxWidth = '100%';
    }
    if (val === 'num') {
      this.maxWidth = '80px';
    }
  }
}
