import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthState } from '@app/core/states/auth.state';
import { Select } from '@ngxs/store';
import { isBoolean } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Directive({
  selector: '[rkIsSuperuser]',
})
export class IsSuperUserDirective {
  isViewDisplayed = false;

  @Input('rkIsSuperuser')
  set rkIsSuperuser(val: boolean) {
    if (isBoolean(val)) {
      this._showIfSuperuser = val;
    }
    this.setupObservable();
  }

  _showIfSuperuser = true;

  subscription: Subscription;

  @Select(AuthState.isSuperUser)
  isSuperUser$: Observable<boolean>;

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
  ) {
    this.setupObservable();
  }

  setupObservable() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.isSuperUser$
      .pipe(map(isSuperUser => (this._showIfSuperuser ? isSuperUser : true)))
      .subscribe(out => {
        if (out) {
          this.showContent();
        } else {
          this.hideContent();
        }
      });
  }

  showContent() {
    if (this.isViewDisplayed) {
      return;
    }
    this.isViewDisplayed = true;
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  hideContent() {
    if (!this.isViewDisplayed) {
      return;
    }
    this.isViewDisplayed = false;
    this.viewContainer.clear();
  }
}
