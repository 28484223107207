import { Injectable } from '@angular/core';
import { Genre } from '@app/core/models/Genre';
import { GenericDataService } from '@app/core/services/generic-data.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { GenresFailure, GenresRequest, GenresSuccess } from './genre.actions';

export class GenreStateModel {
  genres: Genre[];
}

@State<GenreStateModel>({
  name: 'genre',
  defaults: {
    genres: undefined,
  },
})
@Injectable()
export class GenreState {
  constructor(private readonly genericDataService: GenericDataService) {}

  @Selector()
  static genreList(state: GenreStateModel): Genre[] {
    return state.genres.filter(genre => genre.parentId !== null);
  }

  @Action(GenresRequest)
  getAllGenres(
    ctx: StateContext<GenreStateModel>,
  ): Observable<void | Observable<void>> | void {
    const genres = ctx.getState().genres;
    if (genres) {
      return;
    }

    return this.genericDataService.getAllGenres().pipe(
      map(data => ctx.dispatch(new GenresSuccess(data))),
      catchError(err => ctx.dispatch(new GenresFailure(err))),
    );
  }

  @Action(GenresSuccess)
  add(ctx: StateContext<GenreStateModel>, { genres }: GenresSuccess) {
    ctx.patchState({
      genres,
    });
  }
}
