import { NgModule, inject } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { environment } from '@env/environment';

import { AuthenticationGuard, Route } from './core';
import { ROLES } from './core/models/Preset';
import { RoleOperatorActions } from './core/services/role-helper.service';
import { DisabledRadioComponent } from './external-routes/components/pages/disabled-radio/disabled-radio.component';
import { NoRadioComponent } from './external-routes/components/pages/no-radio/no-radio.component';
import { StartupModalComponent } from './external-routes/components/pages/startup-modal/startup-modal.component';
import { AdminGuard } from './core/authentication/admin.guard';

const loginRoute = environment.auth.isOnManagerLoginEnable
  ? [
      {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
      },
    ]
  : [];

const routes: Routes = [
  ...loginRoute,
  {
    path: 'no-radio',
    component: NoRadioComponent,
  },
  { path: 'radio', redirectTo: '/radio/0', pathMatch: 'full' },
  {
    path: 'radio/:radioId',
    // component: RadioIdPageComponent,
    canActivate: [AuthenticationGuard], // RolesGuard
    canActivateChild: [AuthenticationGuard], // RolesGuard
    children: [
      {
        path: 'disabled',
        component: DisabledRadioComponent,
      },
      {
        path: 'configure-radio',
        component: StartupModalComponent,
      },
      // protected routes
      Route.withShell([
        {
          path: '',
          loadChildren: () =>
            import('@radioking/rights-router').then(m => m.RightsRouterModule),
          data: {},
        },
        {
          path: 'dashboard',
          loadChildren: () => import('@radioking/dashboard').then(m => m.DashboardModule),
          data: {
            title: marker('SIDENAV.MENU.DASHBOARD'),
            role: {
              type: RoleOperatorActions.ALL,
              roleIds: [ROLES.DASHBOARD],
            },
          },
        },
        {
          path: 'about',
          loadChildren: () => import('./about/about.module').then(m => m.AboutModule),
        },
        {
          path: 'media',
          loadChildren: () =>
            import('./library/library.module').then(m => m.LibraryModule),
        },
        {
          path: 'settings',
          loadChildren: () =>
            import('./settings/settings.module').then(m => m.SettingsModule),
          data: {
            title: marker('GLOBAL.WINDOW_TITLE.SETTINGS'),
          },
        },
        {
          path: 'history',
          loadChildren: () => import('@radioking/history').then(m => m.HistoryModule),
          data: {
            title: marker('GLOBAL.WINDOW_TITLE.HISTORY'),
            role: {
              type: RoleOperatorActions.ALL,
              roleIds: [ROLES.PLANNING],
            },
          },
        },
        {
          path: 'generated-planning',
          loadChildren: () =>
            import('@radioking/daily-generation').then(m => m.DailyGenerationModule),
          data: {
            title: marker('SIDENAV.MENU.DAILY_GENERATION'),
            role: {
              type: RoleOperatorActions.ALL,
              roleIds: [ROLES.PLANNING],
            },
          },
        },
        {
          path: 'scheduler',
          data: {
            role: {
              type: RoleOperatorActions.OR,
              operators: [
                {
                  type: RoleOperatorActions.ALL,
                  roleIds: [ROLES.PROGRAMS, ROLES.LIBRARY],
                },
                {
                  type: RoleOperatorActions.ALL,
                  roleIds: [ROLES.PROGRAMS, ROLES.LIBRARY, ROLES.SCHEDULE, ROLES.BREAKS],
                },
                {
                  type: RoleOperatorActions.ALL,
                  roleIds: [ROLES.PLANNING, ROLES.LIBRARY],
                },
              ],
            },
          },
          children: [
            {
              path: 'programs',
              loadChildren: () =>
                import('@radioking/programs').then(m => m.ProgramsModule),
              data: {
                title: marker('SIDENAV.MENU.PROGRAMS'),
                role: {
                  type: RoleOperatorActions.ALL,
                  roleIds: [ROLES.PROGRAMS, ROLES.LIBRARY],
                },
              },
            },
            {
              path: '',
              loadChildren: () => import('@radioking/break').then(m => m.BreakModule),
              data: {
                title: marker('SIDENAV.MENU.BREAKS'),
                roles: {
                  type: RoleOperatorActions.ALL,
                  roleIds: [ROLES.PROGRAMS, ROLES.LIBRARY, ROLES.SCHEDULE, ROLES.BREAKS],
                },
              },
            },
            {
              path: '',
              loadChildren: () =>
                import('@radioking/planning').then(m => m.PlanningModule),
              data: {
                title: marker('SIDENAV.MENU.PLANNING'),
                roles: {
                  type: RoleOperatorActions.ALL,
                  roleIds: [ROLES.PROGRAMS, ROLES.LIBRARY, ROLES.SCHEDULE, ROLES.BREAKS],
                },
              },
            },
          ],
        },
        {
          path: 'statistics',
          data: {
            role: ROLES.STATISTICS,
            title: marker('SIDENAV.MENU.STATISTICS_ITEM'),
          },
          loadChildren: () =>
            import('@radioking/statistics').then(m => m.StatisticsModule),
        },
        {
          path: 'live',
          data: {
            role: {
              type: RoleOperatorActions.INCLUDES,
              roleIds: [ROLES.LIVE, ROLES.LIVE_FEED],
            },
            title: marker('SIDENAV.MENU.DIRECT'),
          },
          loadChildren: () =>
            import('@radioking/live/live-info').then(m => m.LiveLiveInfoModule),
        },
        {
          path: 'team',
          loadChildren: () => import('@radioking/animators').then(m => m.AnimatorsModule),
          data: {
            role: ROLES.USERS,
            title: marker('SIDENAV.MENU.DJS'),
          },
        },
        {
          path: 'widgets',
          data: { role: ROLES.WIDGETS, title: marker('SIDENAV.MENU.SHARE') },

          loadChildren: () => import('@radioking/share').then(m => m.ShareModule),
        },
        {
          path: 'admin',
          loadChildren: () => import('@radioking/admin').then(m => m.AdminModule),
          data: {
            title: marker('GLOBAL.WINDOW_TITLE.ADMIN'),
          },
          canMatch: [() => inject(AdminGuard).canMatch()],
        },
        {
          path: '**',
          loadChildren: () => import('@radioking/page404').then(m => m.Page404Module),
        },
        {
          path: '404',
          loadChildren: () => import('@radioking/page404').then(m => m.Page404Module),
        },
      ]),
    ],
  },
  { path: '', redirectTo: '/radio/0', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
  providers: [AdminGuard],
})
export class AppRoutingModule {}
