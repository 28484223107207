import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { UiModalWrapperModule } from '@radioking/ui/modal-wrapper';

import { CanDeactivateModalGuard } from './can-deactivate-modal.guard';
import { UnsavedChangesModalComponent } from './unsaved-changes-modal/unsaved-changes-modal.component';

@NgModule({
  imports: [CommonModule, TranslateModule, UiModalWrapperModule],
  declarations: [UnsavedChangesModalComponent],
  providers: [CanDeactivateModalGuard],
  exports: [UnsavedChangesModalComponent],
})
export class SharedUnsavedChangesModule {}
