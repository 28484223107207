// Original source code: https://github.com/fetrarij/ngx-daterangepicker-material
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

import { DaterangepickerDirective } from './daterangepicker.directive';
import { DaterangepickerComponent } from './daterangepicker/daterangepicker.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatIconModule],
  declarations: [DaterangepickerComponent, DaterangepickerDirective],
  exports: [DaterangepickerComponent, DaterangepickerDirective],
})
export class UiDatepickerModule {}
