<rk-input-with-suggestions
  [label]="label"
  [placeholder]="placeholder"
  [debounceTime]="0"
  [canAddNew]="canAddNew"
  [minLengthTrigger]="0"
  [dataFetched]="filteredData"
  (changed)="searchData($event)"
  (submitted)="setData($event)"
  [currentValue]="value"
  [addNewCaseSensitive]="false"
  [renderLineTemplate]="
    renderLineTemplate ? renderLineTemplate : playlistTemplate ? renderItemTmpl : null
  "
  [hasIcon]="(!!iconTemplate || playlistTemplate) && !!value"
  [shouldClearOnSubmit]="false"
  [inModal]="modal"
  [isAbsolute]="isAbsolute"
  [hintQuestionMark]="hintQuestionMark"
  [disabled]="isDisabled"
  [displayIfEmpty]="displayIfEmpty"
></rk-input-with-suggestions>
<rk-playlist-icon
  class="dropdown-autocomplete--icon"
  *ngIf="playlistTemplate && !this.isSearching && value?.color"
  [playlist]="value"
></rk-playlist-icon>
<div
  class="dropdown-autocomplete--icon"
  *ngIf="iconTemplate && !this.isSearching && value?.color"
>
  <ng-template *ngTemplateOutlet="iconTemplate; context: { item: value }"></ng-template>
</div>

<ng-template let-playlist="item" #renderItemTmpl>
  <div class="playlist-line">
    <div class="playlist-color">
      <rk-playlist-icon [playlist]="playlist"></rk-playlist-icon>
    </div>
    <span class="title">{{ playlist.name }}</span>
    <span
      [style.left.px]="dropdownWidth - 30"
      class="right"
      *ngIf="shouldShowWarning(playlist.id)"
      [matTooltip]="'GLOBAL.TOOLTIP.DANGER' | translate"
      [matTooltipPosition]="'above'"
    >
      <mat-icon>error_outline</mat-icon>
    </span>
  </div>
</ng-template>
