import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { LiveTrackingState } from '@app/core/states/live-tracking.state';
import { UploadFileList } from '@app/core/states/upload.actions';
import { UploadState } from '@app/core/states/upload.state';
import { RootBac } from '@app/library/models/bac.model';
import { TranslateModule } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { UiButtonModule } from '@radioking/ui/button';
import { Observable } from 'rxjs';

@Component({
  selector: 'rk-mob-upload-track',
  templateUrl: './mob-upload-track.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiButtonModule, TranslateModule, AsyncPipe],
})
export class MobUploadTrackComponent {
  accept = '.mp3';

  @ViewChild('trackUploadFileInput', { static: true })
  trackUploadFileInput: ElementRef;

  @Select(LiveTrackingState.canUpload)
  canUpload$: Observable<boolean>;

  @Select(UploadState.hasUploads)
  hasUploads$: Observable<boolean>;

  @Select(UploadState.allCompleted)
  allCompleted$: Observable<boolean>;

  pulse = false;

  // Useful for mobile onboarding, we upload from dashboard not library
  defaultBox: RootBac = {
    get url(): string {
      return '';
    },
    id: 1,
    name: '__MUSIC__',
    count: 0,
    type: 'music',
    path: [],
    children: [],
    isLocked: false,
    translated$: null,
    isReadOnly: false,
    position: 60,
    icon: 'fa-music',
  };

  constructor(
    private readonly store: Store,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  selectTrack() {
    this.pulse = false;
    this.cdr.detectChanges();
    if (this.trackUploadFileInput) {
      this.trackUploadFileInput.nativeElement.click();
    }
  }

  async onFileSelect(event: any) {
    this.store.dispatch(new UploadFileList(event.target.files, this.defaultBox));
    this.trackUploadFileInput.nativeElement.value = '';
  }
}
