import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import SwiperCore, { Autoplay, SwiperOptions } from 'swiper';

declare const window: any;

interface review {
  author: string;
  score: number;
  review: string;
}
@Component({
  selector: 'rk-trustpilot',
  templateUrl: './trustpilot.component.html',
  styleUrls: ['./trustpilot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrustpilotComponent implements OnInit, AfterViewInit {
  @Input()
  userLang: string;

  trustpilotLocale = 'en-GB';
  truspilotLink = 'https://uk.trustpilot.com/review/radioking.com';

  swiperConfig: SwiperOptions = {
    modules: [Autoplay],
    direction: 'horizontal',
    slidesPerView: 4,
    navigation: false,
    autoplay: {
      delay: 1000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    speed: 5000,
    loop: true,
    breakpoints: {
      1280: {
        slidesPerView: 4,
      },
      992: {
        slidesPerView: 3,
      },
      640: {
        slidesPerView: 2,
      },
      320: {
        slidesPerView: 1,
      },
    },
  };

  reviews: review[][] = [
    [
      {
        author: marker('GLOBAL.MODAL.UPGRADE_OFFER.TRUSTPILOT_REVIEW.AUTHOR_1'),
        score: 5,
        review: marker('GLOBAL.MODAL.UPGRADE_OFFER.TRUSTPILOT_REVIEW.REVIEW_1'),
      },
      {
        author: marker('GLOBAL.MODAL.UPGRADE_OFFER.TRUSTPILOT_REVIEW.AUTHOR_2'),
        score: 5,
        review: marker('GLOBAL.MODAL.UPGRADE_OFFER.TRUSTPILOT_REVIEW.REVIEW_2'),
      },
    ],
    [
      {
        author: marker('GLOBAL.MODAL.UPGRADE_OFFER.TRUSTPILOT_REVIEW.AUTHOR_3'),
        score: 5,
        review: marker('GLOBAL.MODAL.UPGRADE_OFFER.TRUSTPILOT_REVIEW.REVIEW_3'),
      },
      {
        author: marker('GLOBAL.MODAL.UPGRADE_OFFER.TRUSTPILOT_REVIEW.AUTHOR_4'),
        score: 5,
        review: marker('GLOBAL.MODAL.UPGRADE_OFFER.TRUSTPILOT_REVIEW.REVIEW_4'),
      },
    ],
    [
      {
        author: marker('GLOBAL.MODAL.UPGRADE_OFFER.TRUSTPILOT_REVIEW.AUTHOR_5'),
        score: 5,
        review: marker('GLOBAL.MODAL.UPGRADE_OFFER.TRUSTPILOT_REVIEW.REVIEW_5'),
      },
      {
        author: marker('GLOBAL.MODAL.UPGRADE_OFFER.TRUSTPILOT_REVIEW.AUTHOR_6'),
        score: 4,
        review: marker('GLOBAL.MODAL.UPGRADE_OFFER.TRUSTPILOT_REVIEW.REVIEW_6'),
      },
    ],
    [
      {
        author: marker('GLOBAL.MODAL.UPGRADE_OFFER.TRUSTPILOT_REVIEW.AUTHOR_7'),
        score: 5,
        review: marker('GLOBAL.MODAL.UPGRADE_OFFER.TRUSTPILOT_REVIEW.REVIEW_7'),
      },
      {
        author: marker('GLOBAL.MODAL.UPGRADE_OFFER.TRUSTPILOT_REVIEW.AUTHOR_8'),
        score: 5,
        review: marker('GLOBAL.MODAL.UPGRADE_OFFER.TRUSTPILOT_REVIEW.REVIEW_8'),
      },
    ],
    [
      {
        author: marker('GLOBAL.MODAL.UPGRADE_OFFER.TRUSTPILOT_REVIEW.AUTHOR_9'),
        score: 5,
        review: marker('GLOBAL.MODAL.UPGRADE_OFFER.TRUSTPILOT_REVIEW.REVIEW_9'),
      },
      {
        author: marker('GLOBAL.MODAL.UPGRADE_OFFER.TRUSTPILOT_REVIEW.AUTHOR_10'),
        score: 5,
        review: marker('GLOBAL.MODAL.UPGRADE_OFFER.TRUSTPILOT_REVIEW.REVIEW_10'),
      },
    ],
  ];

  constructor() {
    SwiperCore.use([Autoplay]);
  }

  ngOnInit() {
    if (this.userLang === 'fr') {
      this.trustpilotLocale = 'fr-FR';
      this.truspilotLink = 'https://fr.trustpilot.com/review/radioking.com';
    }
  }

  ngAfterViewInit() {
    const trustboxRef = document.getElementById('trustbox');
    window.Trustpilot.loadFromElement(trustboxRef);
  }
}
