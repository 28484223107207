import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CoreModule } from '@app/core';
import { ExternalRoutesModule } from '@app/external-routes/external-routes.module';
import { SharedModule } from '@app/shared';
import { environment } from '@env/environment';
import { SharedPanelManagerModule } from '@radioking/shared/pannel-manager';
import * as Sentry from '@sentry/browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';

if (environment.sentry.isEnabled) {
  Sentry.init({
    dsn: environment.sentry.url,
    environment: environment.sentry.env,
    release: `radioking-manager@${environment.versionFull}`,
    ignoreErrors: [
      'Already completed',
      'ResizeObserver loop limit exceeded',
      'Non-Error exception captured with keys: error, headers, message, name, ok', // eg. HTTP 403, can happen for some disabled rights
      'Non-Error promise rejection captured with value: undefined',
    ],
  });
}

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 300,
    exitDuration: 0,
  },
};

const globalDialogConfig: MatDialogConfig = {
  hasBackdrop: true,
};

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    CoreModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    SharedModule,
    AppRoutingModule,
    ExternalRoutesModule,
    SharedPanelManagerModule,
  ],
  declarations: [AppComponent],
  providers: [
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: globalDialogConfig },
    { provide: 'googleTagManagerId', useValue: environment.gtm },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
