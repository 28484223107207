import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { convertToPlaylists, PlaylistApi } from '@app/library/models/playlist-api.model';
import { Playlist } from '@app/library/models/playlist.model';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  convertToSchedulerTrack,
  SchedulerTrackApi,
} from '../models/schedule-core-api.model';
import { SchedulerTrack } from '../models/scheduler-core.model';

const schedulerUrl = {
  getPlaylists: (id: number) => `${environment.urls.MAIN_API_V2}/radio/${id}/playlist`,
  getAllTracks: (id: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${id}/lite/track/all`,
};
@Injectable({
  providedIn: 'root',
})
export class SchedulerCoreService {
  constructor(private readonly httpClient: HttpClient) {}
  getAllTracks(radioId: number): Observable<SchedulerTrack[]> {
    return this.httpClient
      .get<SchedulerTrackApi[]>(schedulerUrl.getAllTracks(radioId))
      .pipe(map(t => t.reverse().map(convertToSchedulerTrack)));
  }

  getAllPlaylists(radioId: number): Observable<Playlist[]> {
    return this.httpClient
      .get<PlaylistApi[]>(schedulerUrl.getPlaylists(radioId))
      .pipe(map(convertToPlaylists));
  }
}
