import {
  CurrentTrackWidget,
  NextTrackWidget,
  PlayedTracksWidget,
  PlayerWidget,
  RadioPage,
  ShareLink,
  Smartlink,
  TopTracksWidget,
} from '../models/widgets.model';

const tag = '[Share]';

export class SmartlinkRequest {
  static readonly type = `${tag} smartlink request`;
}

export class SmartlinkSuccess {
  static readonly type = `${tag} smartlink success`;
  constructor(public smartlink: Smartlink) {}
}

export class SmartlinkFailure {
  static readonly type = `${tag} smartlink error`;
  constructor(public error: Error) {}
}

export class UpdateSmartlinkRequest {
  static readonly type = `${tag} update smartlink request`;
  constructor(public smartlink: Smartlink) {}
}

export class UpdateSmartlinkSuccess {
  static readonly type = `${tag} update smartlink success`;
  constructor(public smartlink: Smartlink) {}
}

export class UpdateSmartlinkFailure {
  static readonly type = `${tag} update smartlink error`;
  constructor(public error: Error) {}
}

export class PlayerRequest {
  static readonly type = `${tag} update player request`;
  constructor(
    public player: PlayerWidget,
    public showModal = true,
  ) {}
}

export class PlayerSuccess {
  static readonly type = `${tag} update player success`;
  constructor(public player: PlayerWidget) {}
}

export class PlayedTracksWidgetRequest {
  static readonly type = `${tag} update played tracks request`;
  constructor(
    public widget: PlayedTracksWidget,
    public showModal = true,
  ) {}
}

export class PlayedTracksWidgetSuccess {
  static readonly type = `${tag} update played tracks success`;
  constructor(public widget: PlayedTracksWidget) {}
}

export class TopTracksWidgetRequest {
  static readonly type = `${tag} update top tracks request`;
  constructor(
    public widget: TopTracksWidget,
    public showModal = true,
  ) {}
}

export class TopTracksWidgetSuccess {
  static readonly type = `${tag} update top tracks success`;
  constructor(public widget: TopTracksWidget) {}
}

export class CurrentTrackWidgetRequest {
  static readonly type = `${tag} update current track request`;
  constructor(
    public widget: CurrentTrackWidget,
    public showModal = true,
  ) {}
}

export class CurrentTrackWidgetSuccess {
  static readonly type = `${tag} update current track success`;
  constructor(public widget: CurrentTrackWidget) {}
}

export class NextTrackWidgetRequest {
  static readonly type = `${tag} update next track request`;
  constructor(
    public widget: NextTrackWidget,
    public showModal = true,
  ) {}
}

export class NextTrackWidgetSuccess {
  static readonly type = `${tag} update next track success`;
  constructor(public widget: NextTrackWidget) {}
}

export class LinksRequest {
  static readonly type = `${tag} request share links`;
}

export class LinksSuccess {
  static readonly type = `${tag} success share links`;
  constructor(public links: ShareLink[]) {}
}

export class LinksFailure {
  static readonly type = `${tag} error share links`;
  constructor(public error: Error) {}
}

export class RadioPageRequest {
  static readonly type = `${tag} request share radio page`;
}

export class RadioPageSuccess {
  static readonly type = `${tag} success share radio page`;
  constructor(public radioPage: RadioPage) {}
}

export class RadioPageFailure {
  static readonly type = `${tag} error share radio page`;
  constructor(public error: Error) {}
}

export class UpdateRadioPageRequest {
  static readonly type = `${tag} request update radio page`;
  constructor(
    public radioPage: RadioPage,
    public file?: File,
  ) {}
}

export class UpdateRadioPageSuccess {
  static readonly type = `${tag} success update radio page`;
  constructor(public radioPage: RadioPage) {}
}

export class UpdateRadioPageFailure {
  static readonly type = `${tag} error update radio page`;
  constructor(public error: Error) {}
}
