import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { UiButtonModule } from '@radioking/ui/button';
import { UiLoaderModule } from '@radioking/ui/loader';

import { UiHotkeysHelpModule } from '../../../hotkeys-help/src';
import { UiCodeModule } from '../../../mobile-close-button/src/lib/ui-code.module';
import { FooterActionsComponent } from './footer-actions/footer-actions.component';

import { CustomModalWrapperComponent } from './custom-modal-wrapper.component';

@NgModule({
  imports: [
    CommonModule,
    UiButtonModule,
    MatIconModule,
    TranslateModule,
    UiLoaderModule,
    UiHotkeysHelpModule,
    UiCodeModule,
  ],
  declarations: [CustomModalWrapperComponent, FooterActionsComponent],
  exports: [CustomModalWrapperComponent, FooterActionsComponent],
})
export class UiCustomModalWrapperModule {}
