import { ChangeDetectionStrategy, Component } from '@angular/core';
import { aLogEvent, AMPLITUDE_EVENT } from '@app/shared/amplitude-events';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

interface questions {
  question: string;
  answer: string;
}
@Component({
  selector: 'rk-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqComponent {
  questions: questions[] = [
    {
      question: marker('GLOBAL.MODAL.UPGRADE_OFFER.FAQ.QUESTION_1'),
      answer: marker('GLOBAL.MODAL.UPGRADE_OFFER.FAQ.ANSWER_1'),
    },
    {
      question: marker('GLOBAL.MODAL.UPGRADE_OFFER.FAQ.QUESTION_2'),
      answer: marker('GLOBAL.MODAL.UPGRADE_OFFER.FAQ.ANSWER_2'),
    },
    {
      question: marker('GLOBAL.MODAL.UPGRADE_OFFER.FAQ.QUESTION_3'),
      answer: marker('GLOBAL.MODAL.UPGRADE_OFFER.FAQ.ANSWER_3'),
    },
    {
      question: marker('GLOBAL.MODAL.UPGRADE_OFFER.FAQ.QUESTION_4'),
      answer: marker('GLOBAL.MODAL.UPGRADE_OFFER.FAQ.ANSWER_4'),
    },
    {
      question: marker('GLOBAL.MODAL.UPGRADE_OFFER.FAQ.QUESTION_5'),
      answer: marker('GLOBAL.MODAL.UPGRADE_OFFER.FAQ.ANSWER_5'),
    },
    {
      question: marker('GLOBAL.MODAL.UPGRADE_OFFER.FAQ.QUESTION_6'),
      answer: marker('GLOBAL.MODAL.UPGRADE_OFFER.FAQ.ANSWER_6'),
    },
    {
      question: marker('GLOBAL.MODAL.UPGRADE_OFFER.FAQ.QUESTION_7'),
      answer: marker('GLOBAL.MODAL.UPGRADE_OFFER.FAQ.ANSWER_7'),
    },
    {
      question: marker('GLOBAL.MODAL.UPGRADE_OFFER.FAQ.QUESTION_8'),
      answer: marker('GLOBAL.MODAL.UPGRADE_OFFER.FAQ.ANSWER_8'),
    },
  ];

  expandQuestion(id: number) {
    aLogEvent(AMPLITUDE_EVENT.UPGRADE_MODAL['FAQ_' + id]);
  }
}
