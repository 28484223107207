import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BigTimeFormatLettersPipe } from './big-time-format-letters.pipe';
import { FromNowPipe } from './from-now.pipe';
import { LocalizedDatePipe } from './localized-date.pipe';
import { SafePipe } from './safe.pipe';
import { ShortNumberPipe } from './short-number.pipe';
import { TimeFormatLettersPipe } from './time-format-letters.pipe';
import { TimeFormatPipe } from './time-format.pipe';
import { TimeFromDatePipe } from './time-from-date.pipe';
import { ToRadioTzPipe } from './to-radio-tz.pipe';

@NgModule({
  declarations: [
    SafePipe,
    LocalizedDatePipe,
    ShortNumberPipe,
    TimeFormatPipe,
    TimeFromDatePipe,
    ToRadioTzPipe,
    FromNowPipe,
    TimeFormatLettersPipe,
    BigTimeFormatLettersPipe,
  ],
  imports: [CommonModule],
  exports: [
    SafePipe,
    LocalizedDatePipe,
    ShortNumberPipe,
    TimeFormatPipe,
    TimeFromDatePipe,
    ToRadioTzPipe,
    FromNowPipe,
    TimeFormatLettersPipe,
    BigTimeFormatLettersPipe,
  ],
})
export class PipesModule {}
