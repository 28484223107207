import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { SharedModule } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import { UiInputModule } from '@radioking/ui/input';
import SwiperCore, { Navigation } from 'swiper';
import { SwiperModule } from 'swiper/angular';

import { BlurryBackgroundComponent } from './components/content/blurry-background/blurry-background.component';
import { DisabledRadioComponent } from './components/pages/disabled-radio/disabled-radio.component';
import { NoRadioComponent } from './components/pages/no-radio/no-radio.component';
import { StartupModalComponent } from './components/pages/startup-modal/startup-modal.component';

SwiperCore.use([Navigation]);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    MaterialModule,
    SwiperModule,
    UiInputModule,
  ],
  declarations: [
    NoRadioComponent,
    BlurryBackgroundComponent,
    DisabledRadioComponent,
    StartupModalComponent,
  ],
})
export class ExternalRoutesModule {}
