import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CountUpModule } from 'ngx-countup';

import { CountUpComponent } from './count-up.component';

@NgModule({
  imports: [CommonModule, CountUpModule],
  declarations: [CountUpComponent],
  exports: [CountUpComponent],
})
export class UiCountUpModule {}
