import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LightApiTrack } from '@app/core/models/LightTrack';
import { Preset, PresetApi, presetApiToPreset } from '@app/core/models/Preset';
import {
  ApollonVersion,
  Radio,
  RadioStatus,
  RadioSubscription,
  ShortRadio,
} from '@app/core/models/Radio';
import {
  ApollonVersionApiModel,
  convertToApollonVersion,
  convertToRadio,
  convertToRadioStatus,
  convertToRadioSubscription,
  RadioApiModel,
  RadioStatusApiModel,
  RadioSubscriptionApiModel,
  UserRadioApiModel,
} from '@app/core/models/radio-api.model';
import { PLACEHOLDER } from '@app/shared/constants';
import { environment } from '@env/environment';
import amplitude from 'amplitude-js';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface Countable {
  count: number;
}

export interface Readyable {
  ready: boolean;
}

export const radiosUrl = {
  allMyRadios: (userId: number) => `${environment.urls.MAIN_API_V2}/user/${userId}/radio`,
  radio: (id: number) => `${environment.urls.MAIN_API_V2}/radio/${id}`,
  stop: (id: number) => `${environment.urls.MAIN_API_V2}/radio/${id}/stop`,
  start: (id: number) => `${environment.urls.MAIN_API_V2}/radio/${id}/start`,
  restart: (id: number) => `${environment.urls.MAIN_API_V2}/radio/${id}/restart`,
  ready: (id: number) => `${environment.urls.MAIN_API_V2}/radio/${id}/ready`,
  getStatusById: (id: number) => `${environment.urls.MAIN_API_V2}/radio/${id}/status`,
  getListenersById: (id: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${id}/statistics/session/count`,
  getCurrentPlayingTrack: (slug: string) =>
    `${environment.urls.WIDGET_API_V2}/radio/${slug}/track/current`,
  getIncomingsTracks: (slug: string) =>
    `${environment.urls.WIDGET_API_V2}/radio/${slug}/track/next?limit=5`,
  getPreviousTracks: (slug: string) =>
    `${environment.urls.WIDGET_API_V2}/radio/${slug}/track/history?limit=5`,
  getPresets: (idRadio: number) =>
    `${environment.urls.MAIN_API_V2}/me/radio/${idRadio}/rights`,
  getRadioSubscription: (idRadio: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${idRadio}/subscription`,
  getApollonVersion: (id: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${id}/version`,
};

@Injectable({
  providedIn: 'root',
})
export class RadioService {
  constructor(private readonly httpClient: HttpClient) {}

  getAllMyRadios(userId: number): Observable<ShortRadio[]> {
    return this.httpClient
      .get<UserRadioApiModel[]>(radiosUrl.allMyRadios(userId))
      .pipe(map(data => data.map(this.convertToShortRadio)));
  }

  getPresetForRadio(idRadio: number): Observable<Preset[]> {
    return this.httpClient.get<PresetApi[]>(radiosUrl.getPresets(idRadio)).pipe(
      map(data => {
        if (!data) {
          return null;
        }

        return data.map(d => presetApiToPreset(d));
      }),
    );
  }

  getRadio(idRadio: number): Observable<Radio> {
    return this.httpClient
      .get<RadioApiModel>(radiosUrl.radio(idRadio))
      .pipe(map(convertToRadio));
  }

  stopRadio(idRadio: number): Observable<any> {
    amplitude.getInstance().logEvent('stop radio');

    return this.httpClient.post(radiosUrl.stop(idRadio), {}, { responseType: 'text' });
  }

  startRadio(idRadio: number): Observable<any> {
    amplitude.getInstance().logEvent('start radio');

    return this.httpClient.post(radiosUrl.start(idRadio), {}, { responseType: 'text' });
  }

  restartRadio(idRadio: number): Observable<any> {
    amplitude.getInstance().logEvent('restart radio');

    return this.httpClient.post(radiosUrl.restart(idRadio), {}, { responseType: 'text' });
  }

  isRadioReady(idRadio: number): Observable<Readyable> {
    return this.httpClient.get<Readyable>(radiosUrl.ready(idRadio));
  }

  getRadioStatus(idRadio: number): Observable<RadioStatus> {
    return this.httpClient
      .get<RadioStatusApiModel>(radiosUrl.getStatusById(idRadio))
      .pipe(map(convertToRadioStatus));
  }

  getListenerOfRadio(idRadio: number): Observable<number> {
    return this.httpClient
      .get<Countable>(radiosUrl.getListenersById(idRadio))
      .pipe(map(data => data.count));
  }

  getCurrentPlayingTrack(slug: string): Observable<LightApiTrack> {
    return this.httpClient
      .get<LightApiTrack>(radiosUrl.getCurrentPlayingTrack(slug))
      .pipe(map(this.mapToLightTrack));
  }

  getIncomingTracks(slug: string): Observable<LightApiTrack[]> {
    return this.httpClient
      .get<LightApiTrack[]>(radiosUrl.getIncomingsTracks(slug))
      .pipe(map(tracks => tracks.map(this.mapToLightTrack)));
  }

  getPreviousTracks(slug: string): Observable<LightApiTrack[]> {
    return this.httpClient
      .get<LightApiTrack[]>(radiosUrl.getPreviousTracks(slug))
      .pipe(map(tracks => tracks.map(this.mapToLightTrack)));
  }

  getRadioSubscription(id: number): Observable<RadioSubscription> {
    return this.httpClient
      .get<RadioSubscriptionApiModel>(radiosUrl.getRadioSubscription(id))
      .pipe(map(convertToRadioSubscription));
  }

  getApollonStatus(id: number): Observable<ApollonVersion> {
    return this.httpClient
      .get<ApollonVersionApiModel>(radiosUrl.getApollonVersion(id))
      .pipe(map(convertToApollonVersion));
  }

  mapToLightTrack(lightApiTrack: LightApiTrack): LightApiTrack {
    return {
      album: lightApiTrack.album,
      artist: lightApiTrack.artist,
      buy_link: lightApiTrack.buy_link,
      cover: lightApiTrack.cover || PLACEHOLDER,
      duration: lightApiTrack.duration,
      end_at: lightApiTrack.end_at,
      forced_title: lightApiTrack.forced_title,
      id: lightApiTrack.id,
      is_live: lightApiTrack.is_live,
      next_track: lightApiTrack.next_track,
      started_at: lightApiTrack.started_at,
      title: lightApiTrack.title,
    };
  }

  convertToShortRadio(apiRadio: UserRadioApiModel): ShortRadio {
    return {
      id: apiRadio.idradio,
      name: apiRadio.name,
      status: apiRadio.status,
      logo: apiRadio.logo,
      role: apiRadio.role,
    };
  }
}
