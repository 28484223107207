import { Tag, Track } from '@app/core/models/Track';
import { SugestionInput } from '@radioking/ui/input';

const tag = '[RadioTags]';

export class TagsListRequest {
  static readonly type = `${tag} request tag lists`;
  constructor(public force = false) {}
}

export class TagsListSuccess {
  static readonly type = `${tag} success tag lists`;
  constructor(public tags: Tag[], public isIgnoring: boolean) {}
}

export class TagsListFailure {
  static readonly type = `${tag} error tag lists`;
  constructor(public error: Error) {}
}

export class TagTracksRequest {
  static readonly type = `${tag} request tag tracks`;
  constructor(public tagObj: SugestionInput) {}
}

export class TagTracksSuccess {
  static readonly type = `${tag} success tag tracks`;
  constructor(public tracks: Track[]) {}
}

export class TagTracksFailure {
  static readonly type = `${tag} error tag tracks`;
  constructor(public error: Error) {}
}

export class RemoveTagFromTrackRequest {
  static readonly type = `${tag} request remove tag from track`;
  constructor(public track: Track) {}
}

export class RemoveTagFromTrackSuccess {
  static readonly type = `${tag} success remove tags from track`;
  constructor(public idTrack: number) {}
}

export class RemoveTagFromTrackFailure {
  static readonly type = `${tag} error remove tags from track`;
  constructor(public error: Error) {}
}

export class RemoveTagsFromMultipleTrackRequest {
  static readonly type = `${tag} request remove tags from multiple track`;
  constructor(public idTracks: number[], public idTags: number[]) {}
}

export class RemoveTagsFromMultipleTrackSuccess {
  static readonly type = `${tag} success remove tags from multiple track`;
  constructor(public idTracks: number[], public idTags: number[]) {}
}

export class RemoveTagsFromMultipleTrackFailure {
  static readonly type = `${tag} error remove tags from multiple track`;
  constructor(public error: Error) {}
}

export class EditTagRequest {
  static readonly type = `${tag} request edit tag`;
  constructor(public mTag: Partial<Tag>) {}
}

export class EditTagSuccess {
  static readonly type = `${tag} success edit tag`;
  constructor(public mTag: Tag) {}
}

export class EditTagFailure {
  static readonly type = `${tag} error edit tag`;
  constructor(public error: Error) {}
}

export class DeleteTagRequest {
  static readonly type = `${tag} request delete tag`;
  constructor(public mTag: Tag) {}
}

export class DeleteTagSuccess {
  static readonly type = `${tag} success delete tag`;
  constructor(public mTag: Tag) {}
}

export class DeleteTagFailure {
  static readonly type = `${tag} error delete tag`;
  constructor(public error: Error) {}
}

export class StartEditTag {
  static readonly type = `${tag} start edit tag`;
  constructor(public mTag: Tag) {}
}

export class EndEditTag {
  static readonly type = `${tag} end edit tag`;
}
