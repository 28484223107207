<div class="trustpilot text-center mb-8 mx-5">
  <div class="text-3.5xl font-bold mt-8 mb-4" translate>
    GLOBAL.MODAL.UPGRADE_OFFER.TRUSTPILOT_TITLE
  </div>
  <div
    id="trustbox"
    class="trustpilot-widget shadow-big max-w-[340px] m-auto pt-3 pl-3"
    [attr.data-locale]="trustpilotLocale"
    data-template-id="53aa8807dec7e10d38f59f32"
    data-businessunit-id="588869150000ff00059b6d21"
    data-style-height="125px"
    data-style-width="100%"
    data-theme="light"
  >
    <a [href]="truspilotLink" target="_blank" rel="noopener">Trustpilot</a>
  </div>
</div>

<swiper [config]="swiperConfig">
  <ng-container *ngFor="let reviewPair of reviews">
    <ng-template swiperSlide>
      <ng-container
        *ngTemplateOutlet="trustPilotCard; context: { review: reviewPair[0] }"
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="trustPilotCard; context: { review: reviewPair[1] }"
      ></ng-container>
    </ng-template>
  </ng-container>
</swiper>

<ng-template #trustPilotCard let-review="review">
  <div class="p-6 m-3 mt-6 border border-gray rounded flex flex-col">
    <img
      class="w-[150px] mb-2"
      [src]="'/assets/tp-stars-' + (review.score === 5 ? '5' : '4') + '.svg'"
    />
    <div
      class="author"
      translate
      [innerHTML]="
        'GLOBAL.MODAL.UPGRADE_OFFER.TRUSTPILOT_REVIEW.TITLE'
          | translate : { author: review.author | translate }
      "
    ></div>
    <div class="review" [innerHTML]="review.review | translate"></div>
  </div>
</ng-template>
