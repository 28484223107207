import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LiveTrackingState } from '@app/core/states/live-tracking.state';
import {
  RadioRestartRequest,
  RadioStartRequest,
  RadioStopRequest,
} from '@app/core/states/radio.actions';
import { RadioState } from '@app/core/states/radio.state';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { MobileSidenavService } from '@radioking/shared/common-services';
import { Observable } from 'rxjs';

@Component({
  selector: 'rk-mobile-radio-actions',
  templateUrl: './mobile-radio-actions.component.html',
  styleUrls: ['./mobile-radio-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileRadioActionsComponent {
  @Select(LiveTrackingState.isBroadcasting)
  isRadioStarted$: Observable<boolean>;

  @Select(RadioState.hasMoreThanOneRadio)
  hasMoreThanOneRadio$: Observable<boolean>;

  showChangeRadio = false;
  constructor(private readonly mobileSidenavService: MobileSidenavService) {}

  back() {
    this.mobileSidenavService.close();
  }

  openChangeRadio() {
    this.showChangeRadio = true;
    // Change radio
  }

  closeChangeRadio() {
    this.showChangeRadio = false;
  }

  @Dispatch()
  stopRadio() {
    this.back();

    return new RadioStopRequest();
  }

  @Dispatch()
  restartRadio() {
    this.back();

    return new RadioRestartRequest();
  }

  @Dispatch()
  startRadio() {
    this.back();

    return new RadioStartRequest();
  }
}
