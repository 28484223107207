import { Injectable } from '@angular/core';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';

export interface ErrorFormFormat {
  [key: string]: ValidationErrors;
}

@Injectable({
  providedIn: 'root',
})
export class FormHelperService {
  forceValidationTrigger(form: UntypedFormGroup): void {
    for (const i in form.controls) {
      if (form.controls.hasOwnProperty(i)) {
        form.controls[i].markAsDirty();
        form.controls[i].updateValueAndValidity();
      }
    }
  }

  updateErrorMapping(form: UntypedFormGroup, oldErrors: ErrorFormFormat) {
    for (const field in oldErrors) {
      if (!oldErrors.hasOwnProperty(field)) {
        continue;
      }

      // Clear previous errors
      oldErrors[field] = {};

      // Get the control
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        oldErrors[field] = control.errors;
      }
    }
  }
}
