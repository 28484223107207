import { Injectable } from '@angular/core';
import { LightApiTrack } from '@app/core/models/LightTrack';
import {
  ApollonVersion,
  RADIO_PLAN_DESC,
  RadioApollonStatus,
  RadioLiveBroadcaster,
  RadioLiveStatus,
  RadioPlan,
} from '@app/core/models/Radio';
import { RadioService } from '@app/core/services/radio.service';
import {
  RoleHelperService,
  RoleOperatorActions,
  RoleOperators,
} from '@app/core/services/role-helper.service';
import {
  RadioStartSuccess,
  RadioStopSuccess,
  SwitchToRadioRequest,
} from '@app/core/states/radio.actions';
import { RadioState } from '@app/core/states/radio.state';
import {
  Action,
  Actions,
  ofActionDispatched,
  Selector,
  State,
  StateContext,
  StateOperator,
  Store,
} from '@ngxs/store';
import { iif, patch } from '@ngxs/store/operators';
import { GtmService } from '@radioking/shared/common-services';
import { composeWithDeepEqual } from '@radioking/xs-state-operators';
import moment from 'moment-timezone';
import { combineLatest, interval, merge, Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import {
  ApollonStatusNeedsRestart,
  DisplayApollonUpdateModal,
  GetPreviousTrack,
  RadioStatusFailure,
  RadioStatusSuccess,
  StartLiveTrack,
  StartSoftLiveTrack,
  StartTrackApollonStatus,
  StopLiveTrack,
  StopSoftLiveTrack,
} from './live-tracking.actions';

export class LiveTrackingStateModel {
  currentTrack: LightApiTrack;
  nextTracks: LightApiTrack[];
  previousTracks: LightApiTrack[];
  numberListeners: number;
  status?: 'active' | 'inactive' | 'deleted';
  streamStatus?: 'started' | 'stopped';
  canUpload: boolean;
  radioPlan: RadioPlan;
  canDownloadChronics: boolean;
  demoRemainingDays: number;
  live?: RadioLiveStatus;
  radioApollonStatus: {
    [key: string]: RadioApollonStatus;
  };
  radioIdApollonTracking: number;
}

@State<LiveTrackingStateModel>({
  name: 'liveTracking',
  defaults: {
    currentTrack: undefined,
    nextTracks: [],
    previousTracks: [],
    numberListeners: 0,
    canUpload: true,
    live: undefined,
    radioPlan: {
      planId: null,
      description: null,
    },
    canDownloadChronics: true,
    demoRemainingDays: 0,
    radioApollonStatus: {},
    radioIdApollonTracking: 0,
  },
})
@Injectable()
export class LiveTrackingState {
  constructor(
    private readonly actions$: Actions,
    private readonly radioService: RadioService,
    private readonly store: Store,
    private readonly roleHelper: RoleHelperService,
    private readonly gtmService: GtmService,
  ) {}

  @Selector()
  static listeners(state: LiveTrackingStateModel): number {
    return state.numberListeners;
  }

  @Selector()
  static isLive(state: LiveTrackingStateModel): boolean {
    return !!state.live;
  }

  @Selector()
  static durationLive(state: LiveTrackingStateModel): number {
    if (!state.live) {
      return 0;
    }

    return moment(state.live.startedAt).valueOf();
  }

  @Selector()
  static isBroadcasting(state: LiveTrackingStateModel): boolean {
    return state.streamStatus === 'started';
  }

  @Selector()
  static status(state: LiveTrackingStateModel): string {
    return state.streamStatus;
  }

  @Selector()
  static broadcaster(state: LiveTrackingStateModel): RadioLiveBroadcaster {
    return state.live ? state.live.broadcaster : null;
  }

  @Selector()
  static currentTrack(state: LiveTrackingStateModel): LightApiTrack {
    return (
      state.currentTrack || {
        duration: 0,
        artist: '',
        title: '-',
        cover: '',
        album: '',
        buy_link: '',
        id: 0,
      }
    );
  }

  @Selector()
  static nextTracks(state: LiveTrackingStateModel): LightApiTrack[] {
    return state.nextTracks || [];
  }

  @Selector()
  static canUpload(state: LiveTrackingStateModel): boolean {
    return state.canUpload;
  }

  @Selector()
  static previousTrack(state: LiveTrackingStateModel): LightApiTrack {
    return state.previousTracks.length > 1 ? state.previousTracks[1] : null;
  }

  @Selector()
  static nextTrack(state: LiveTrackingStateModel): LightApiTrack {
    return state.nextTracks.length ? state.nextTracks[0] : null;
  }

  @Selector()
  static plan(state: LiveTrackingStateModel): RadioPlan {
    return state.radioPlan;
  }

  @Selector()
  static planId(state: LiveTrackingStateModel): number {
    return state.radioPlan?.planId;
  }

  @Selector()
  static planDesc(state: LiveTrackingStateModel): RADIO_PLAN_DESC {
    return state.radioPlan?.description;
  }

  @Selector()
  static canDownloadChronics(state: LiveTrackingStateModel): boolean {
    return state.canDownloadChronics;
  }

  @Selector()
  static demoRemainingDays(state: LiveTrackingStateModel): number {
    return state.demoRemainingDays;
  }

  @Selector()
  static radioStatus(state: LiveTrackingStateModel): string {
    return state.status;
  }

  @Selector()
  static radioRestartDeadline(state: LiveTrackingStateModel) {
    return (idRadio: number): moment.Moment => {
      const key = `radio${idRadio}`;

      return moment(state.radioApollonStatus[key].radioRestartDeadline);
    };
  }

  @Action(StartSoftLiveTrack)
  softTrackLive(
    ctx: StateContext<LiveTrackingStateModel>,
    { idRadio }: StartSoftLiveTrack,
  ) {
    const stopingConditions$ = merge(
      this.actions$.pipe(
        ofActionDispatched(
          RadioStartSuccess,
          StopSoftLiveTrack,
          StartSoftLiveTrack,
          StartLiveTrack,
        ),
      ),
    );

    return merge(interval(15000), of('')).pipe(
      takeUntil(stopingConditions$),
      switchMap(() => this.trackRadioStatus(ctx, idRadio)),
      tap(status => {
        if (status && LiveTrackingState.isBroadcasting(ctx.getState())) {
          ctx.dispatch(new StopSoftLiveTrack());
          ctx.dispatch(new StartLiveTrack(idRadio));
        }
      }),
    );
  }

  @Action(StartLiveTrack)
  trackLive(ctx: StateContext<LiveTrackingStateModel>, { idRadio }: StartLiveTrack) {
    const stopingConditions$ = this.actions$.pipe(
      ofActionDispatched(
        RadioStopSuccess,
        StopLiveTrack,
        StartLiveTrack,
        StartSoftLiveTrack,
      ),
    );

    const smallPeriods$ = merge(interval(15000), of('')).pipe(
      takeUntil(stopingConditions$),
      switchMap(() =>
        combineLatest([
          this.trackListeners(ctx, idRadio),
          this.trackRadioStatus(ctx, idRadio),
        ]),
      ),
    );

    const longerPeriods$ = merge(interval(30000), of('')).pipe(
      takeUntil(stopingConditions$),
      switchMap(() => {
        const slug = this.store.selectSnapshot(RadioState.currentRadioSlug);

        return this.incomingTracks(ctx, slug);
      }),
    );

    const trackPeriod$ = merge(interval(10000), of('')).pipe(
      takeUntil(stopingConditions$),
      switchMap(() => {
        const slug = this.store.selectSnapshot(RadioState.currentRadioSlug);

        return this.currentTrack(ctx, slug);
      }),
    );

    return combineLatest([smallPeriods$, longerPeriods$, trackPeriod$]).pipe(
      tap(([[, status], ,]) => {
        if (!LiveTrackingState.isBroadcasting(ctx.getState()) && status) {
          ctx.dispatch(new StopLiveTrack());
          ctx.dispatch(new StartSoftLiveTrack(idRadio));
        }
      }),
    );
  }

  @Action(StartTrackApollonStatus)
  startTrackApollonStatus(
    ctx: StateContext<LiveTrackingStateModel>,
    { id }: StartTrackApollonStatus,
  ) {
    const stoppingConditions$ = this.actions$.pipe(
      ofActionDispatched(
        RadioStopSuccess,
        StopLiveTrack,
        StartTrackApollonStatus,
        SwitchToRadioRequest,
      ),
    );

    return this.getStreamStatus().pipe(
      mergeMap(() =>
        merge(interval(60000 * 30), of('')).pipe(
          takeUntil(stoppingConditions$),
          switchMap(() => this.trackApollonStatus(ctx, id)),
        ),
      ),
      finalize(() => {
        ctx.patchState({ radioIdApollonTracking: 0 });
      }),
    );
  }

  @Action(GetPreviousTrack)
  getPreviousTrack(ctx: StateContext<LiveTrackingStateModel>) {
    const slug = this.store.selectSnapshot(RadioState.currentRadioSlug);

    return this.previousTracks(ctx, slug);
  }

  @Action(DisplayApollonUpdateModal)
  displayApollonUpdateModal(
    ctx: StateContext<LiveTrackingStateModel>,
    { id }: DisplayApollonUpdateModal,
  ) {
    const key = `radio${id}`;
    ctx.setState(
      patch({
        radioApollonStatus: this.patchKeyObj(key, { modalDisplayedDate: moment() }),
      }),
    );
  }

  currentTrack(ctx: StateContext<LiveTrackingStateModel>, slug: string): Observable<any> {
    return this.radioService.getCurrentPlayingTrack(slug).pipe(
      tap(track => {
        ctx.patchState({ currentTrack: track });
      }),
      map(() => true),
      catchError(() => of(false)),
    );
  }

  incomingTracks(
    ctx: StateContext<LiveTrackingStateModel>,
    slug: string,
  ): Observable<any> {
    return this.radioService.getIncomingTracks(slug).pipe(
      tap(tracks => {
        ctx.patchState({ nextTracks: tracks });
      }),
      map(() => true),
      catchError(() => of(false)),
    );
  }

  previousTracks(
    ctx: StateContext<LiveTrackingStateModel>,
    slug: string,
  ): Observable<any> {
    return this.radioService.getPreviousTracks(slug).pipe(
      tap(tracks => {
        ctx.setState(
          patch({
            previousTracks: tracks,
          }),
        );
      }),
      map(() => true),
      catchError(() => of(false)),
    );
  }

  trackListeners(
    ctx: StateContext<LiveTrackingStateModel>,
    idRadio: number,
  ): Observable<any> {
    return this.radioService.getListenerOfRadio(idRadio).pipe(
      tap(num => {
        ctx.patchState({ numberListeners: num });
      }),
      map(() => true),
      catchError(() => of(false)),
    );
  }

  trackRadioStatus(
    ctx: StateContext<LiveTrackingStateModel>,
    idRadio: number,
  ): Observable<any> {
    return this.radioService.getRadioStatus(idRadio).pipe(
      tap(data => {
        this.gtmService.pushToDataLayer({
          id_plan: data.radioPlan.planId,
          id_radio: idRadio,
          id_service: data.idService,
          demo_remaining_days: data.demoRemainingDays,
        });

        ctx.patchState({
          live: data.live,
          status: data.status,
          streamStatus: data.streamStatus,
          canUpload: data.canUpload,
          radioPlan: data.radioPlan,
          canDownloadChronics: data.downloadableChronics,
          demoRemainingDays: data.demoRemainingDays,
        });
        if (data.streamStatus === 'started') {
          if (ctx.getState().radioIdApollonTracking !== idRadio) {
            this.tryStartApollonTracking(ctx, idRadio);
          }
        }
        ctx.dispatch(new RadioStatusSuccess(idRadio, data));
      }),
      map(() => true),
      catchError(err => {
        ctx.dispatch(new RadioStatusFailure(err));

        return of(false);
      }),
    );
  }

  trackApollonStatus(ctx: StateContext<LiveTrackingStateModel>, idRadio: number) {
    const key = `radio${idRadio}`;

    return this.radioService.getApollonStatus(idRadio).pipe(
      tap((status: ApollonVersion) => {
        ctx.setState(
          patch({
            radioApollonStatus: this.patchKeyObj(key, {
              radioRestartDeadline: status.deadline,
            }),
          }),
        );

        if (status.deadline && status.current !== status.expected) {
          if (ctx.getState().radioApollonStatus[key].modalDisplayedDate) {
            const lastDisplayedDate = moment(
              ctx.getState().radioApollonStatus[key].modalDisplayedDate,
            );
            if (moment().diff(lastDisplayedDate) / 1000 < 60 * 60 * 4) {
              return;
            }
          }

          if (ctx.getState().streamStatus === 'started') {
            ctx.dispatch(new ApollonStatusNeedsRestart());
          }
        }
      }),
      map(() => true),
      catchError(() => of(false)),
    );
  }

  getStreamStatus(): Observable<string> {
    return this.store.select(LiveTrackingState.status).pipe(
      filter(data => !!data),
      take(1),
    );
  }

  patchKeyObj(
    idInsert: string,
    operatorOrValue:
      | Partial<RadioApollonStatus>
      | StateOperator<Partial<RadioApollonStatus>>,
  ): StateOperator<{ [key: string]: RadioApollonStatus }> {
    const creator = iif<{ [x: string]: RadioApollonStatus }>(
      val => !val[idInsert],
      // @ts-ignore
      patch({
        [idInsert]: {
          modalDisplayedDate: null,
          radioRestartDeadline: null,
        },
      }),
    );
    const applier: StateOperator<any> = patch<{
      [x: string]: Partial<RadioApollonStatus>;
    }>({
      [idInsert]: patch({
        ...operatorOrValue,
      }),
    });

    return composeWithDeepEqual<{ [x: string]: RadioApollonStatus }>(creator, applier);
  }

  tryStartApollonTracking(ctx: StateContext<LiveTrackingStateModel>, id: number) {
    const radioRole: RoleOperators = {
      type: RoleOperatorActions.SPECIFIC,
      roleId: '__radio__',
    };
    this.roleHelper
      .hasRole(radioRole)
      .pipe(take(1))
      .subscribe(hasRole => {
        const isCustomer = this.store.selectSnapshot(RadioState.isCustomer);
        const isOneOfMyRadios = this.store.selectSnapshot(RadioState.isOneOfMineRadios);
        if (isOneOfMyRadios && (isCustomer || hasRole)) {
          ctx.patchState({ radioIdApollonTracking: id });
          setTimeout(() => {
            const currentRadioId = this.store.selectSnapshot(RadioState.currentRadioId);
            if (currentRadioId === id) {
              ctx.dispatch(new StartTrackApollonStatus(id));
            }
          }, 60000);
        }
      });
  }
}
