import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/browser';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(error: any) {
    if (environment.sentry.isEnabled) {
      Sentry.captureException(error.originalError || error);
    }
    throw error;
  }
}
