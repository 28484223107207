import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PlaylistIconComponent } from './playlist-icon.component';

@NgModule({
  imports: [CommonModule],
  declarations: [PlaylistIconComponent],
  exports: [PlaylistIconComponent],
})
export class UiPlaylistIconModule {}
