import { Track } from '@app/core/models/Track';
import { TracksStateModel } from '@app/library/states/tracks.state';
import { StateOperator } from '@ngxs/store';
import {
  compose,
  iif,
  insertItem,
  patch,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';

export function setFetchingTracks(status: boolean): StateOperator<TracksStateModel> {
  return patch<TracksStateModel>({ isFetching: status });
}

function applyToUnfilteredBac(
  op: Track[] | StateOperator<Track[]>,
  bacKey: string,
): StateOperator<TracksStateModel> {
  const noNull = patch<TracksStateModel>({
    unfiltered: patch({
      [bacKey]: iif<Track[]>(val => val === null, []),
    }),
  });

  return compose(
    noNull,
    patch<TracksStateModel>({
      unfiltered: patch({
        [bacKey]: op,
      }),
    }),
  );
}
function applyToFilteredBac(
  op: Track[] | StateOperator<Track[]>,
  bacKey: string,
): StateOperator<TracksStateModel> {
  return patch<TracksStateModel>({
    filtered: patch({
      [bacKey]: op,
    }),
  });
}
function applyToFilteredBacIfFiltering(
  op: Track[] | StateOperator<Track[]>,
  bacKey: string,
): StateOperator<TracksStateModel> {
  return patch<TracksStateModel>({
    filtered: patch({
      [bacKey]: iif(state => state !== null, op),
    }),
  });
}

export function setUnfilteredTracks(
  tracks: Track[],
  bacKey: string,
): StateOperator<TracksStateModel> {
  return applyToUnfilteredBac(tracks, bacKey);
}

export function setFilteredTracks(
  tracks: Track[],
  bacKey: string,
): StateOperator<TracksStateModel> {
  return applyToFilteredBac(tracks, bacKey);
}

export function setTracks(
  tracks: Track[],
  bacKey: string,
  isFiltering: boolean,
): StateOperator<TracksStateModel> {
  return iif<TracksStateModel>(
    isFiltering,
    setFilteredTracks(tracks, bacKey),
    compose<TracksStateModel>(
      setUnfilteredTracks(tracks, bacKey),
      setFilteredTracks(null, bacKey),
    ),
  );
}

export function addTrack(track: Track, bacKey: string): StateOperator<TracksStateModel> {
  return applyToUnfilteredBac(insertItem(track), bacKey);
}

export function addMultipleTrack(
  tracks: Track[],
  bacKey: string,
): StateOperator<TracksStateModel> {
  return compose<TracksStateModel>(...tracks.map(track => addTrack(track, bacKey)));
}

export function updateTrack(
  track: Partial<Track>,
  bacKey: string,
): StateOperator<TracksStateModel> {
  const applier = updateItem<Track>(
    inTrack => inTrack.id === track.id,
    patch({ ...track }),
  );

  return compose<TracksStateModel>(
    applyToUnfilteredBac(applier, bacKey),
    applyToFilteredBacIfFiltering(applier, bacKey),
  );
}

export function archiveTrack(
  trackId: number,
  bacKey: string,
): StateOperator<TracksStateModel> {
  const applier = removeItem<Track>(track => track.id === trackId);

  return compose<TracksStateModel>(
    applyToUnfilteredBac(applier, bacKey),
    applyToFilteredBacIfFiltering(applier, bacKey),
  );
}

export function archiveMultipleTracks(
  tracksId: number[],
  bacKey: string,
): StateOperator<TracksStateModel> {
  return compose<TracksStateModel>(
    ...tracksId.map(trackId => archiveTrack(trackId, bacKey)),
  );
}
