import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  convertToSettingBroadcast,
  convertToSettingBroadcastApi,
  convertToSettingCoverAndLive,
  convertToSettingCoverAndLiveApi,
  convertToSettingMisc,
  convertToSettingMiscApi,
  convertToSettingSecurity,
  convertToSettingSecurityApi,
  convertToSettingSocial,
  convertToSettingSocialApi,
  convertToSettingStream,
  convertToSettingStreamApi,
  SettingBroadcastApiModel,
  SettingCoverAndLiveApiModel,
  SettingDirectoryApiModel,
  SettingMiscApiModel,
  SettingPlatformApiModel,
  SettingRadioApiModel,
  SettingSecurityApiModel,
  SettingSocialApiModel,
  SettingStreamApiModel,
} from '@app/library/models/setting-api.model';
import {
  SettingBroadcast,
  SettingCoverAndLive,
  SettingDirectory,
  SettingMisc,
  SettingRadio,
  SettingSecurity,
  SettingSocial,
  SettingStream,
} from '@app/library/models/setting.model';
import { environment } from '@env/environment';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

const urls = {
  settingRadio: (id: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${id}/setting/radio`,
  settingBroadcast: (id: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${id}/setting/broadcast`,
  settingStream: (id: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${id}/setting/stream`,
  settingCoverAndLive: (id: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${id}/setting/coverandlive`,
  settingPlatform: (id: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${id}/setting/platform`,
  settingDirectory: (id: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${id}/setting/directory`,
  settingSocial: (id: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${id}/setting/social`,
  settingSecurity: (id: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${id}/setting/security`,
  blacklist: (id: number) => `${environment.urls.MAIN_API_V2}/radio/${id}/blacklist`,
  settingMisc: (id: number) => `${environment.urls.MAIN_API_V2}/radio/${id}/setting/misc`,
  settingLogo: (id: number) => `${environment.urls.MAIN_API_V2}/radio/${id}/setting/logo`,
  settingDefaultCover: (id: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${id}/setting/defaultcover`,
  settingLiveCover: (id: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${id}/setting/livecover`,
  settingHeader: (id: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${id}/setting/header`,
  checkSlug: (id: number, slug: string) =>
    `${environment.urls.MAIN_API_V2}/radio/${id}/setting/slug/check?slug=${slug}`,
  loginTwitter: (id: number, bearer: string) =>
    `${environment.urls.MAIN_API_V2}/radio/${id}/oauth/twitter?token=${bearer}`,
  logoutTwitter: (id: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${id}/oauth/twitter/invalidate`,
};

@Injectable({
  providedIn: 'root',
})
export class SettingService {
  constructor(private readonly httpClient: HttpClient) {}

  getSettingRadio(idRadio: number): Observable<SettingRadio> {
    return this.httpClient.get<SettingRadioApiModel>(urls.settingRadio(idRadio));
  }

  updateSettingRadio(
    idRadio: number,
    setting: Partial<SettingRadio>,
  ): Observable<SettingRadio> {
    return this.httpClient.put<SettingRadioApiModel>(urls.settingRadio(idRadio), setting);
  }

  getSettingBroadcast(idRadio: number): Observable<SettingBroadcast> {
    return this.httpClient
      .get<SettingBroadcastApiModel>(urls.settingBroadcast(idRadio))
      .pipe(map(convertToSettingBroadcast));
  }

  updateSettingBroadcast(
    idRadio: number,
    setting: SettingBroadcast,
  ): Observable<SettingBroadcast> {
    return this.httpClient
      .put<SettingBroadcastApiModel>(
        urls.settingBroadcast(idRadio),
        convertToSettingBroadcastApi(setting),
      )
      .pipe(map(convertToSettingBroadcast));
  }

  getSettingStream(idRadio: number): Observable<SettingStream> {
    return this.httpClient
      .get<SettingStreamApiModel>(urls.settingStream(idRadio))
      .pipe(map(convertToSettingStream));
  }

  updateSettingStream(
    idRadio: number,
    setting: SettingStream,
  ): Observable<SettingStream> {
    return this.httpClient
      .put<SettingStreamApiModel>(
        urls.settingStream(idRadio),
        convertToSettingStreamApi(setting),
      )
      .pipe(map(convertToSettingStream));
  }

  getSettingCoverAndLive(idRadio: number): Observable<SettingCoverAndLive> {
    return this.httpClient
      .get<SettingCoverAndLiveApiModel>(urls.settingCoverAndLive(idRadio))
      .pipe(map(convertToSettingCoverAndLive));
  }

  updateSettingCoverAndLive(
    idRadio: number,
    setting: SettingCoverAndLive,
  ): Observable<SettingCoverAndLive> {
    return this.httpClient
      .put<SettingCoverAndLiveApiModel>(
        urls.settingCoverAndLive(idRadio),
        convertToSettingCoverAndLiveApi(setting),
      )
      .pipe(map(convertToSettingCoverAndLive));
  }

  getSettingDirectory(idRadio: number): Observable<SettingDirectory> {
    return combineLatest([
      this.httpClient.get<SettingDirectoryApiModel>(urls.settingDirectory(idRadio)),
      this.httpClient.get<SettingPlatformApiModel>(urls.settingPlatform(idRadio)),
    ]).pipe(
      map(([directory, platform]) => ({ ...directory, weloveradio: platform.public })),
    );
  }

  updateSettingDirectory(
    idRadio: number,
    setting: SettingDirectory,
  ): Observable<SettingDirectory> {
    return combineLatest([
      this.httpClient.put<SettingDirectoryApiModel>(
        urls.settingDirectory(idRadio),
        setting,
      ),
      this.httpClient.put<SettingPlatformApiModel>(urls.settingPlatform(idRadio), {
        public: setting.weloveradio,
      }),
    ]).pipe(
      map(([directory, platform]) => ({ ...directory, weloveradio: platform.public })),
    );
  }

  getSettingSocial(idRadio: number): Observable<SettingSocial> {
    return this.httpClient
      .get<SettingSocialApiModel>(urls.settingSocial(idRadio))
      .pipe(map(convertToSettingSocial));
  }

  updateSettingSocial(
    idRadio: number,
    setting: SettingSocial,
  ): Observable<SettingSocial> {
    return this.httpClient
      .put<SettingSocialApiModel>(
        urls.settingSocial(idRadio),
        convertToSettingSocialApi(setting),
      )
      .pipe(map(convertToSettingSocial));
  }

  getSettingSecurity(idRadio: number): Observable<SettingSecurity> {
    return this.httpClient
      .get<SettingSecurityApiModel>(urls.settingSecurity(idRadio))
      .pipe(map(convertToSettingSecurity));
  }

  updateSettingSecurity(
    idRadio: number,
    setting: SettingSecurity,
  ): Observable<SettingSecurity> {
    return this.httpClient
      .put<SettingSecurityApiModel>(
        urls.settingSecurity(idRadio),
        convertToSettingSecurityApi(setting),
      )
      .pipe(map(convertToSettingSecurity));
  }

  getSettingMisc(idRadio: number): Observable<SettingMisc> {
    return this.httpClient
      .get<SettingMiscApiModel>(urls.settingMisc(idRadio))
      .pipe(map(convertToSettingMisc));
  }

  updateSettingMisc(idRadio: number, setting: SettingMisc): Observable<SettingMisc> {
    return this.httpClient
      .put<SettingMiscApiModel>(
        urls.settingMisc(idRadio),
        convertToSettingMiscApi(setting),
      )
      .pipe(map(convertToSettingMisc));
  }

  checkSlug(id: number, slug: string): Observable<any> {
    return this.httpClient.get(urls.checkSlug(id, slug));
  }

  updateSettingRadioLogo(id: number, file: File): Observable<void> {
    return this.sendFile(urls.settingLogo(id), file);
  }

  updateSettingDefaultCover(id: number, file: File): Observable<void> {
    return this.sendFile(urls.settingDefaultCover(id), file);
  }

  deleteSettingDefaultCover(id: number): Observable<void> {
    return this.deleteFile(urls.settingDefaultCover(id));
  }

  updateSettingLiveCover(id: number, file: File): Observable<void> {
    return this.sendFile(urls.settingLiveCover(id), file);
  }

  deleteSettingLiveCover(id: number): Observable<void> {
    return this.deleteFile(urls.settingLiveCover(id));
  }

  updateSettingPlatformHeader(id: number, file: File): Observable<void> {
    return this.sendFile(urls.settingHeader(id), file);
  }

  deleteSettingPlatformHeader(id: number): Observable<void> {
    return this.deleteFile(urls.settingHeader(id));
  }

  private sendFile(url: string, file: File): Observable<void> {
    if (typeof file === 'string') {
      return of();
    }
    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.httpClient.post(url, formData).pipe(map(() => {}));
  }

  private deleteFile(url: string): Observable<void> {
    return this.httpClient.delete(url).pipe(map(() => {}));
  }
}
