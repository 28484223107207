<div class="bg overflow-hidden -mb-0.5">
  <img class="absolute -mt-4 md:-mt-8" src="/assets/clear-wave.svg" />
  <img class="relative z-10" src="/assets/dark-wave.png" />
</div>
<div class="faq-panel relative pt-10 pb-24 -mt-px px-6 sm:px-16 lg:px-32 xl:px-48">
  <h1 class="mb-7 text-white text-center" translate>
    GLOBAL.MODAL.UPGRADE_OFFER.FAQ.TITLE
  </h1>
  <mat-accordion>
    <ng-container *ngFor="let question of questions; let i = index">
      <mat-expansion-panel (afterExpand)="expandQuestion(i + 1)">
        <mat-expansion-panel-header>
          <mat-panel-title
            class="text-bold text-lg md:text-xl leading-6 md:leading-7 py-3 md:py-0 select-none"
          >
            <span [innerHTML]="question.question | translate"></span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-panel-description>
          <span [innerHTML]="question.answer | translate"></span>
        </mat-panel-description>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</div>
