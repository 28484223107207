<div class="root flex flex-col justify-end" [class.show]="show" (click)="close()">
  <div class="bg"></div>
  <div class="content">
    <div class="close" (click)="close()">
      <mat-icon fontSet="fa" fontIcon="fa-times"></mat-icon>
    </div>
    <div class="body px-5 py-7" (click)="touchBody($event)">
      <div class="font-bold text-2xl mb-1.5" translate>
        GLOBAL.MODAL.CHANGE_RADIO.TITLE
      </div>
      <div class="flex flex-col">
        <ng-container *ngFor="let radio of radios$ | async">
          <div
            class="flex items-center bg-white shadow-base mt-2.5 rounded"
            (click)="changeRadio(radio.id)"
            [class.current]="radio.id === (currentRadioId$ | async)"
          >
            <img class="w-16 h-16 mr-4.5" [src]="radio.logo" [alt]="radio.name" />
            <span class="font-bold text-lg">{{
              radio.name || ('GLOBAL.MODAL.CHANGE_RADIO.NONAME' | translate)
            }}</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
