<div class="form-input rk-input-wrapper">
  <div class="input-block">
    <div>
      <span class="label text-base md:text-sm">{{ label }}</span>
      <div
        class="question-mark"
        *ngIf="hintQuestionMark && hintLocation === 'label'"
        rkTooltip
        [tooltipDescription]="hintQuestionMark"
      >
        <mat-icon fontSet="fa" fontIcon="fa-question"></mat-icon>
      </div>
      <span class="hint-max-char" *ngIf="hintLabel && hintLocation !== 'between'"
        >({{ hintLabel }})</span
      >
      <span class="hint-max-char" *ngIf="chars"
        >({{ chars }} {{ 'SETTINGS.FORM.MAX_CHARS' | translate }})</span
      >
    </div>
    @if (hintLocation === 'between' && hintLabel) {
      <div class="text-gray-darkest text-base md:text-sm">{{ hintLabel }}</div>
    }
    <div class="input-container">
      <ng-content></ng-content>
      <mat-icon
        fontSet="fa"
        fontIcon="fa-exclamation-circle"
        class="error-icon"
      ></mat-icon>
      <span *ngIf="hintRight && hintRight.length > 0" class="hint-right">{{
        hintRight
      }}</span>
      <rk-question-mark
        class="mt-[3px]"
        *ngIf="hintQuestionMark && hintLocation === 'after'"
        [description]="hintQuestionMark"
      ></rk-question-mark>
    </div>
  </div>
  <div class="error-container"><ng-content select="rk-form-error"></ng-content></div>
</div>
