import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ChecklistStep } from '@radioking/onboarding-checklist';
import { Observable } from 'rxjs';

const checklistUrls = {
  checklist: (id: number) => `${environment.urls.MAIN_API_V2}/radio/${id}/onboarding`,
};

@Injectable({
  providedIn: 'root',
})
export class OnboardingChecklistService {
  constructor(private readonly httpClient: HttpClient) {}

  public getChecklist(radioId: number): Observable<string[]> {
    return this.httpClient.get<string[]>(checklistUrls.checklist(radioId));
  }

  public completeStep(radioId: number, steps: ChecklistStep[]) {
    return this.httpClient.put(checklistUrls.checklist(radioId), steps);
  }
}
