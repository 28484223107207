<rk-modal-wrapper
  [showSubmitBlock]="false"
  [showHeader]="false"
  [slideshow]="true"
  (closed)="close(true)"
>
  <div class="close" (click)="close(true)">
    <mat-icon fontSet="fa" fontIcon="fa-times"></mat-icon>
  </div>
  <div class="main">
    <div class="content">
      <ng-container *ngIf="step">
        <div class="slide flex flex-col items-center">
          <div class="gif">
            <img src="/assets/slideshow/{{ step.src }}" [alt]="step.title" />
          </div>
          <rk-step-indicator
            [steps]="steps.length"
            [activeStep]="currentStep"
          ></rk-step-indicator>
          <div class="slide-desc">
            <div class="slide-title mb-1">
              {{ step.title }}
            </div>
            <div class="slide-content" [innerHTML]="step.description"></div>
          </div>
        </div>
      </ng-container>
      <div class="menu-actions flex">
        <rk-button
          [label]="'SLIDESHOW.PREV' | translate"
          [btnStyle]="'orange'"
          [disabled]="currentStep === 0"
          [fullWidth]="true"
          (click)="prevStep()"
        ></rk-button>
        <rk-button
          *ngIf="currentStep === 0"
          [label]="'SLIDESHOW.NEXT' | translate"
          [fullWidth]="true"
          (click)="nextStep()"
        ></rk-button>
        <rk-button
          *ngIf="currentStep === 1"
          [label]="'SLIDESHOW.DISCOVER' | translate"
          [fullWidth]="true"
          (click)="close()"
        ></rk-button>
      </div>
    </div>
  </div>
</rk-modal-wrapper>
