import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { HotkeysService } from '@app/core/services/hotkeys.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rk-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('inputRef', { static: true })
  inputRef: ElementRef<HTMLInputElement>;

  @Input()
  disabled: boolean;

  @Input()
  type = 'media';

  @Input()
  inputType = 'text';

  @Input()
  customPlaceholder: string;

  @Output()
  readonly focused: EventEmitter<any> = new EventEmitter();

  @Output()
  readonly blurred: EventEmitter<any> = new EventEmitter();

  @Output()
  readonly inputted: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  readonly closed: EventEmitter<any> = new EventEmitter();

  @Output()
  readonly submitted: EventEmitter<any> = new EventEmitter();

  showCross = false;
  isFocused = false;

  @Input()
  autoFocus = false;

  @Input()
  set value(val: string) {
    if (this.inputRef) {
      this.inputRef.nativeElement.value = val;
    }
  }
  get value() {
    if (this.inputRef) {
      return this.inputRef.nativeElement.value;
    }

    return '';
  }

  subscription = new Subscription();

  constructor(private readonly hotkeys: HotkeysService) {}

  ngOnInit() {
    // Hotkeys
    this.subscription.add(
      this.hotkeys.fromConfigArray([
        { hotkey: 'search', handler: () => this.onSearchHotkey() },
        { hotkey: 'cancel', handler: () => this.onCancelHotkey() },
      ]),
    );
  }

  ngAfterViewInit(): void {
    if (this.inputRef && this.autoFocus) {
      this.inputRef.nativeElement.focus();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onClear() {
    this.inputRef.nativeElement.value = '';
    this.onChange(undefined);
    if (this.type !== 'media' && this.type !== 'program') {
      this.closed.emit();
    }
  }

  onFocus(event: Event) {
    this.isFocused = true;
    this.focused.emit(event);
    this.showCross = true;
  }

  onFocusOut() {
    this.showCross = false;
  }

  onBlur(event: Event) {
    this.isFocused = false;
    this.blurred.emit(event);
  }

  onChange(event: Event): void {
    const str = this.inputRef.nativeElement.value;
    this.inputted.emit(str);
  }

  getClasses(): any {
    return {
      [this.type]: true,
      focused: this.isFocused,
    };
  }

  onSearchHotkey() {
    if (this.type !== 'media' && this.type !== 'program') {
      return;
    }

    this.inputRef.nativeElement.focus();
  }

  onCancelHotkey() {
    if (this.isFocused) {
      if (this.value) {
        this.onClear();
      } else {
        this.inputRef.nativeElement.blur();
      }
    }
  }
}
