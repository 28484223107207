import { Injectable, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LOCKED_ROUTES } from '@app/core/models/PlanRestriction';
import { RADIO_PLAN_DESC } from '@app/core/models/Radio';
import { LiveTrackingState } from '@app/core/states/live-tracking.state';
import { planBigger } from '@app/shared/utils';
import { Select } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PlanRestrictionRedirectService {
  @Select(LiveTrackingState.planDesc)
  planDesc$: Observable<RADIO_PLAN_DESC>;

  constructor(private readonly router: Router, private readonly ngZone: NgZone) {}

  init() {
    combineLatest([
      this.router.events.pipe(
        filter(e => e instanceof NavigationEnd),
        startWith(this.router),
      ),
      this.planDesc$.pipe(filter(plan => !!plan)),
    ]).subscribe(([router, plan]) => {
      if (planBigger('Start', plan)) {
        // Only demo and discover have plan restrictions
        this.checkUrlAvailable((router as NavigationEnd).url, plan);
      }
    });
  }

  /*
   * Check if URL is available. If not, redirect to module available page
   */
  checkUrlAvailable(url: string, plan: RADIO_PLAN_DESC) {
    const urlArr = url.split('/');
    const baseUrl = urlArr.splice(0, 4); // Array looks like  ['', 'radio' ,'idradio', 'module']
    // urlArr is now the remaining part of the array

    const routePrefix = baseUrl[3]; // after radio/idradio
    const routeGroup = LOCKED_ROUTES.find(
      r =>
        r.prefix === routePrefix &&
        ((!r.targetPlan && plan === 'Discover') || r.targetPlan === plan),
    );
    if (routeGroup) {
      setTimeout(() => {
        if (urlArr.length) {
          const tmpUrl = urlArr.join('/');
          if (routeGroup.locked.indexOf(tmpUrl) > -1) {
            this.redirectTo(`${baseUrl.join('/')}/${routeGroup.redirectTo}`);
          }
        } else if (!routeGroup.redirectTo) {
          // Not a module with multiple page module, redirect to home
          this.redirectTo('radio');
        }
      }, 50);
    }
  }

  redirectTo(url: string) {
    this.ngZone.run(() => {
      this.router.navigateByUrl(url);
    });
  }
}
