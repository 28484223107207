import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { RADIO_PLAN_DESC } from '@app/core/models/Radio';
import { planBigger } from '@app/shared/utils';
import { slideInOutBlock } from '@radioking/ui/animations';
import { combineLatest, fromEvent } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

import { FuseNavigationService } from '../../navigation.service';

@Component({
  selector: 'rk-nav-vertical-collapse',
  templateUrl: './nav-vertical-collapse.component.html',
  styleUrls: ['./nav-vertical-collapse.component.scss'],
  animations: [slideInOutBlock],
})
export class RkNavVerticalCollapseComponent implements OnInit, AfterViewInit {
  @Input()
  item: any;

  @HostBinding('class')
  classes = 'nav-collapse nav-item';

  @HostBinding('class.open')
  public isOpen = false;

  @HostBinding('class.has-child-open')
  public hasChildOpen = false;

  @Input()
  radioId: number;

  @Input()
  onMobile: boolean;

  @Input()
  planDesc: RADIO_PLAN_DESC;

  openBefore = this.isOpen;

  constructor(
    private readonly elRef: ElementRef,
    private readonly navigationService: FuseNavigationService,
    private readonly router: Router,
  ) {
    // Listen for route changes
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Check if the url can be found in
        // one of the children of this item
        if (this.isUrlInChildren(this.item, event.urlAfterRedirects)) {
          this.expand();
          this.hasChildOpen = true;
        } else {
          this.collapse();
          this.hasChildOpen = false;
        }
      }
    });

    // Listen for collapsing of any navigation item
    this.navigationService.onItemCollapsed.subscribe((clickedItem: any) => {
      if (clickedItem && clickedItem.children) {
        // Check if the clicked item is one
        // of the children of this item
        if (this.isChildrenOf(this.item, clickedItem)) {
          return;
        }

        // Check if the url can be found in
        // one of the children of this item
        if (this.isUrlInChildren(this.item, this.router.url)) {
          return;
        }

        // If the clicked item is not this item, collapse...
        if (this.item !== clickedItem) {
          this.collapse();
        }
      }
    });
  }

  ngOnInit() {
    // Check if the url can be found in
    // one of the children of this item
    if (this.isUrlInChildren(this.item, this.router.url)) {
      this.hasChildOpen = true;
      this.expand();
    } else {
      this.hasChildOpen = false;
      this.collapse();
    }
  }

  ngAfterViewInit() {
    const mainElem = this.elRef.nativeElement.querySelector('.nav-link');
    if (this.elRef.nativeElement && mainElem) {
      combineLatest([
        fromEvent(mainElem, 'touchstart').pipe(
          tap(
            elem =>
              (this.openBefore = ((elem as TouchEvent).target as HTMLElement)
                .closest('rk-nav-vertical-collapse')
                .classList.contains('open')),
          ),
        ),
        fromEvent(mainElem, 'touchend'),
      ])
        .pipe(
          takeUntil(fromEvent(mainElem, 'touchmove').pipe(tap(() => 'STOP MOVE'))),
          debounceTime(100),
        )
        .subscribe(([, elem]) => {
          if (
            this.openBefore ===
            ((elem as TouchEvent).target as HTMLElement)
              .closest('rk-nav-vertical-collapse')
              .classList.contains('open')
          ) {
            this.toggleOpen(null);
          }
        });
    }
  }

  /**
   * Toggle collapse
   */
  toggleOpen(ev: Event) {
    ev?.preventDefault();

    this.isOpen = !this.isOpen;

    // Navigation collapse toggled...
    this.navigationService.onItemCollapsed.next(this.item);
    this.navigationService.onItemCollapseToggled.next();
  }

  /**
   * Expand the collapsable navigation
   */
  expand() {
    if (this.isOpen) {
      return;
    }

    this.isOpen = true;
    this.navigationService.onItemCollapseToggled.next();
  }

  /**
   * Collapse the collapsable navigation
   */
  collapse() {
    if (!this.isOpen) {
      return;
    }

    this.isOpen = false;
    this.navigationService.onItemCollapseToggled.next();
  }

  /**
   * Check if the given parent has the
   * given item in one of its children
   */
  isChildrenOf(parent: any, item: any): boolean {
    if (!parent.children) {
      return false;
    }

    if (parent.children.indexOf(item) !== -1) {
      return true;
    }

    for (const children of parent.children) {
      if (children.children) {
        return this.isChildrenOf(children, item);
      }
    }

    return false;
  }

  /**
   * Check if the given url can be found
   * in one of the given parent's children
   */
  isUrlInChildren(parent: any, url: string) {
    if (!parent.children) {
      return false;
    }

    for (const child of parent.children) {
      if (child.children) {
        if (this.isUrlInChildren(child, url)) {
          return true;
        }
      }

      if (child.url === url || url.includes(child.url)) {
        return true;
      }
    }

    return false;
  }

  planBigger(plan: RADIO_PLAN_DESC, compareTo: RADIO_PLAN_DESC, orEquals = false) {
    return planBigger(plan, compareTo, orEquals);
  }
}
