<rk-form-field [label]="label" [hintQuestionMark]="hintQuestionMark">
  <div class="flex flex-1 md:flex-row" [class.flex-col]="!disableOpen">
    <input type="text" rkInput disabled [value]="value" />
    <rk-button
      data-cy="input-copy-copy"
      faIcon="fa-copy"
      btnStyle="orange"
      class="ml-2 hidden md:inline"
      (click)="copyLink()"
    ></rk-button>
    <rk-button
      *ngIf="!disableOpen; else inline"
      data-cy="input-copy-copy"
      faIcon="fa-copy"
      class="ml-2 my-2 block md:hidden"
      [fullWidth]="true"
      [label]="'GLOBAL.FORM.INPUT_COPY.COPY_LINK' | translate"
      (click)="copyLink()"
    ></rk-button>
    <ng-template #inline>
      <rk-button
        data-cy="input-copy-copy"
        faIcon="fa-copy"
        btnStyle="orange"
        class="ml-2 inline md:hidden"
        (click)="copyLink()"
      ></rk-button>
    </ng-template>
    <rk-button
      data-cy="input-copy-open"
      faIcon="fa-external-link-alt"
      btnStyle="orange"
      class="ml-2 hidden md:inline"
      [pulse]="pulse"
      (click)="openURL()"
      *ngIf="!disableOpen"
    ></rk-button>
    <rk-button
      data-cy="input-copy-open"
      faIcon="fa-external-link-alt"
      btnStyle="orange"
      class="ml-2 block md:hidden"
      [fullWidth]="true"
      [label]="'GLOBAL.FORM.INPUT_COPY.OPEN_LINK' | translate"
      (click)="openURL()"
      *ngIf="!disableOpen"
    ></rk-button>
  </div>
</rk-form-field>
