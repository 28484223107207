import { Injectable } from '@angular/core';
import { Preset } from '@app/core/models/Preset';
import { AuthorizationState } from '@app/core/states/authorization.state';
import { Select } from '@ngxs/store';
import { combineLatest, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

export enum RoleOperatorActions {
  SPECIFIC,
  INCLUDES,
  ALL,
  OR,
}

interface SpecificOperator {
  type: RoleOperatorActions.SPECIFIC;
  roleId: string;
}

interface IncludesOperator {
  type: RoleOperatorActions.INCLUDES;
  roleIds: string[];
}

interface AllOperator {
  type: RoleOperatorActions.ALL;
  roleIds: string[];
}

export type OperatorsOr = SpecificOperator | IncludesOperator | AllOperator;

interface OrOperator {
  type: RoleOperatorActions.OR;
  operators: OperatorsOr[];
}

export type RoleOperators =
  | SpecificOperator
  | IncludesOperator
  | AllOperator
  | OrOperator;

@Injectable({
  providedIn: 'root',
})
export class RoleHelperService {
  @Select(AuthorizationState.isRoleApplicable)
  isApplicable$: Observable<boolean>;

  @Select(AuthorizationState.roles)
  roles$: Observable<Preset[]>;

  hasRole(roleRule: RoleOperators): Observable<boolean> {
    switch (roleRule.type) {
      case RoleOperatorActions.SPECIFIC: {
        return this.specificRole(roleRule);
      }
      case RoleOperatorActions.ALL: {
        return this.allRules(roleRule);
      }
      case RoleOperatorActions.INCLUDES: {
        return this.someRules(roleRule);
      }
      case RoleOperatorActions.OR: {
        return this.orRules(roleRule);
      }
    }
  }

  orRules(roleRule: OrOperator): Observable<boolean> {
    return this.handleApplicable(
      combineLatest(roleRule.operators.map(rule => this.hasRole(rule))).pipe(
        map(arr =>
          arr.reduce((pre, current) => {
            if (current) {
              return true;
            }
            if (pre) {
              return true;
            }

            return pre;
          }),
        ),
      ),
    );
  }

  specificRole(roleRule: SpecificOperator): Observable<boolean> {
    return this.handleApplicable(
      this.roles$.pipe(
        map(roles => roles.filter(preset => preset.keyString === roleRule.roleId)),
        map(data => data.length === 1),
      ),
    );
  }

  allRules(roleRule: AllOperator): Observable<boolean> {
    return this.handleApplicable(
      this.roles$.pipe(
        map(roles => roles.filter(preset => roleRule.roleIds.includes(preset.keyString))),
        map(data => data.length === roleRule.roleIds.length),
      ),
    );
  }

  someRules(roleRule: IncludesOperator): Observable<boolean> {
    return this.handleApplicable(
      this.roles$.pipe(
        map(roles => roles.filter(preset => roleRule.roleIds.includes(preset.keyString))),
        map(data => data.length > 0),
      ),
    );
  }

  private handleApplicable(obs$: Observable<boolean>): Observable<boolean> {
    return this.isApplicable$.pipe(
      mergeMap(isApplicable => {
        if (!isApplicable) {
          return of(true);
        }

        return obs$;
      }),
    );
  }
}
