import { BacType } from '@app/library/models/bac-api.model';
import { Observable, of } from 'rxjs';

export class BacGroup {
  constructor(public ownerId: number, public boxes: RootBac[]) {}
}

export abstract class Bac {
  protected constructor(
    public id: number,
    public name: string,
    public count: number,
    public type: BacType,
    public path: number[],
    public children: Bac[] = [],
    public isLocked: boolean,
    public translated$: Observable<string>,
    public isReadOnly = false,
  ) {}

  get url() {
    return `bac/${this.id}`;
  }
}

export class ChildBac extends Bac {
  constructor(
    public id: number,
    public name: string,
    public count: number,
    public isLocked: boolean,
    // extra ui variables
    public isReadOnly = false,
  ) {
    super(id, name, count, 'children', [id], [], isLocked, of(name), isReadOnly);
  }
}

export class RootBac extends Bac {
  constructor(
    public id: number,
    public name: string,
    public count: number,
    public type: BacType,
    public isLocked: boolean,
    // extra ui variables
    public position?: number,
    public icon?: string,
    public isReadOnly = false,
  ) {
    super(id, name, count, type, [], [], isLocked, of(name), isReadOnly);
  }
}
