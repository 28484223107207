import { Injectable } from '@angular/core';
import { Track, TrackPlaylist, TrackTags } from '@app/core/models/Track';
import { LiveTrackingState } from '@app/core/states/live-tracking.state';
import { AutoGenerateAndStartRadio } from '@app/core/states/radio.actions';
import { RadioState } from '@app/core/states/radio.state';
import { TagsService } from '@app/library/services/tags.service';
import { TrackService } from '@app/library/services/track.service';
import { BacsState } from '@app/library/states/bacs.state';
import { ResetEditingTracks } from '@app/library/states/editing-track.actions';
import { FiltersState } from '@app/library/states/filters.state';
import { RemoveTagsFromMultipleTrackSuccess } from '@app/library/states/radio-tags.actions';
import { UpdateTrackCuesSuccess } from '@app/library/states/track-mix-points.actions';
import {
  addMultipleTrack,
  addTrack,
  archiveMultipleTracks,
  setFetchingTracks,
  setTracks,
  setUnfilteredTracks,
  updateTrack,
} from '@app/library/states/tracks.state-operators';
import { AddedToTrashCount } from '@app/library/states/trash.actions';
import { PLACEHOLDER } from '@app/shared/constants';
import { BacName, bacNameFromDbName } from '@app/shared/utils';
import { Action, Selector, State, StateContext, StateOperator, Store } from '@ngxs/store';
import { compose, patch } from '@ngxs/store/operators';
import { ChecklistStep, OnboardingChecklistState } from '@radioking/onboarding-checklist';
import { Logger } from '@radioking/shared/logger';
import amplitude from 'amplitude-js';
import { pick, unionBy } from 'lodash';
import moment from 'moment-timezone';
import { combineLatest, Observable, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, startWith, take, tap } from 'rxjs/operators';

import {
  AddDefaultTracksFailure,
  AddDefaultTracksRequest,
  AddDefaultTracksSuccess,
  AllTracksForPlaylistRequest,
  BacNewCount,
  PlaylistOfTrackFailure,
  PlaylistOfTrackRequest,
  PlaylistOfTrackSuccess,
  TrackArchiveFailure,
  TrackArchiveMultiBacSuccess,
  TrackArchiveRequest,
  TrackArchiveSuccess,
  TrackBuyLinkLookupFailure,
  TrackBuyLinkLookupRequest,
  TrackBuyLinkLookupSuccess,
  TrackCoverDeleteFailure,
  TrackCoverDeleteRequest,
  TrackCoverDeleteSuccess,
  TrackCoverLookupFailure,
  TrackCoverLookupRequest,
  TrackCoverLookupSuccess,
  TrackCoverMultipleSetFailure,
  TrackCoverMultipleSetRequest,
  TrackCoverMultipleSetSuccess,
  TrackCoverSingleSetFailure,
  TrackCoverSingleSetRequest,
  TrackCoverSingleSetSuccess,
  TrackEditFailure,
  TrackEditRequest,
  TrackEditSuccess,
  TrackMoveFailure,
  TrackMoveRequest,
  TrackMoveSuccess,
  TrackMultipleSetTagsFailure,
  TrackRestoreSuccess,
  TracksAddToPlaylistSuccess,
  TracksFailure,
  TracksRequest,
  TracksSuccess,
  TracksTagsSaveRequest,
  TracksTagsSaveSuccess,
  TracksUploaded,
  TrackTagsFailure,
  TrackTagsRequest,
  TrackTagsSaveFailure,
  TrackTagsSaveRequest,
  TrackTagsSaveSuccess,
  TrackTagsSuccess,
  UpdateTrackFilter,
  UpdateTracksFilter,
} from './tracks.actions';

const log = new Logger('tracks store');

export class TracksStateModel {
  filtered: {
    music: Track[];
    identification: Track[];
    podcast: Track[];
    ad: Track[];
    chronic: Track[];
    dedication: Track[];
  };
  unfiltered: {
    music: Track[];
    identification: Track[];
    podcast: Track[];
    ad: Track[];
    chronic: Track[];
    dedication: Track[];
  };
  isFetching: boolean;
  isFetchingAll: boolean;
  lastRadioFetchedAll: number;
}

@State<TracksStateModel>({
  name: 'tracks',
  defaults: {
    filtered: {
      music: null,
      identification: null,
      podcast: null,
      ad: null,
      chronic: null,
      dedication: null,
    },
    unfiltered: {
      music: null,
      identification: null,
      podcast: null,
      ad: null,
      chronic: null,
      dedication: null,
    },
    isFetching: false,
    isFetchingAll: false,
    lastRadioFetchedAll: 0,
  },
})
@Injectable()
export class TracksState {
  constructor(
    private readonly store: Store,
    private readonly trackService: TrackService,
    private readonly tagsService: TagsService,
  ) {}
  @Selector()
  static isFetchingAll(state: TracksStateModel): boolean {
    return state.isFetchingAll;
  }

  @Selector()
  static tracksBac(state: TracksStateModel) {
    return (name: string): Track[] => {
      if (state.filtered[name]) {
        return state.filtered[name];
      }

      return state.unfiltered[name];
    };
  }

  @Selector()
  static trackBacDbNameUnfiltered(state: TracksStateModel) {
    return (name: string) => {
      const bacName = bacNameFromDbName(name);

      return state.unfiltered[bacName];
    };
  }

  @Selector()
  static tracksBacTime(state: TracksStateModel) {
    return (name: string): number =>
      state.unfiltered[name]
        ? state.unfiltered[name].reduce(
            (pre: number, current: Track) => pre + (current.timeSeconds || 0),
            0,
          )
        : 0;
  }

  /**
   * Retrieve a track in state by its id
   *
   * Perf notes when selected with "selectSnapshot":
   * - Avoid usage in a loop because it'd create a new lambda for each iteration
   * - Prefer storing the returned lambda in a variable before usage in a loop
   * - Ideally, use "select" and an observable instead
   */
  @Selector()
  static trackWithId(state: TracksStateModel): (trackId: number) => Track {
    // Memoize all tracks as a Map
    const tracks = [
      ...(state.unfiltered.music ?? []),
      ...(state.unfiltered.identification ?? []),
      ...(state.unfiltered.podcast ?? []),
      ...(state.unfiltered.dedication ?? []),
      ...(state.unfiltered.ad ?? []),
      ...(state.unfiltered.chronic ?? []),
    ];
    const tracksMap = new Map(tracks.map(t => [t.id, t]));

    return trackId => tracksMap.get(trackId);
  }

  @Selector()
  static trackCoverWithId(state: TracksStateModel): (trackId: number) => string {
    return trackId => {
      const track = TracksState.trackWithId(state)(trackId);
      if (track) {
        return track.cover;
      }

      return null;
    };
  }

  @Selector()
  static allTracksForDropdown(state: TracksStateModel): Track[] {
    return [
      ...[],
      ...(state.unfiltered.music ?? []),
      ...(state.unfiltered.identification ?? []),
      ...(state.unfiltered.podcast ?? []),
      ...(state.unfiltered.dedication ?? []),
      ...(state.unfiltered.ad ?? []),
      ...(state.unfiltered.chronic ?? []),
    ];
  }

  @Selector()
  static allTracksButChronicsForDropdown(state: TracksStateModel): Track[] {
    return [
      ...[],
      ...(state.unfiltered.music ?? []),
      ...(state.unfiltered.identification ?? []),
      ...(state.unfiltered.podcast ?? []),
      ...(state.unfiltered.dedication ?? []),
      ...(state.unfiltered.ad ?? []),
    ];
  }

  @Selector()
  static tracksButChronicsCount(state: TracksStateModel): number {
    return [
      ...[],
      ...(state.unfiltered.music ?? []),
      ...(state.unfiltered.identification ?? []),
      ...(state.unfiltered.podcast ?? []),
      ...(state.unfiltered.dedication ?? []),
      ...(state.unfiltered.ad ?? []),
    ].length;
  }

  @Selector()
  static musicBoxEmpty(state: TracksStateModel): boolean {
    return !state.unfiltered.music.length;
  }

  @Action(TracksRequest)
  getTracks(ctx: StateContext<TracksStateModel>, { bac, forceNoFilter }: TracksRequest) {
    /*
    if (ctx.getState().isFetching) {
      return this.action$.pipe(ofActionDispatched(TracksFailure, TracksSuccess));
    }
    */
    const isFiltering =
      this.store.selectSnapshot(FiltersState.hasBacAnyFilter)(bac.id) && !forceNoFilter;
    ctx.setState(setFetchingTracks(true));

    return this.getCurrentRadioId().pipe(
      mergeMap(id => {
        if (isFiltering) {
          const filters = this.store.selectSnapshot(FiltersState.getBacFilters)(bac.id);

          return this.trackService.getRadioTracksWithFilter(id, bac.id, filters);
        }

        return this.trackService.getRadioTracks(id, bac.id);
      }),
      mergeMap(data => ctx.dispatch(new TracksSuccess(data, bac.name, isFiltering))),
      catchError(err => ctx.dispatch(new TracksFailure(err))),
    );
  }

  @Action(TracksFailure)
  logError(ctx: StateContext<TracksStateModel>, { error }: TracksFailure) {
    log.error(error);
  }

  @Action(TracksSuccess)
  getTracksSuccess(
    ctx: StateContext<TracksStateModel>,
    { tracks, name, isFiltered }: TracksSuccess,
  ) {
    const trackKey = bacNameFromDbName(name);

    ctx.setState(
      compose(setTracks(tracks, trackKey, isFiltered), setFetchingTracks(false)),
    );
    if (isFiltered) {
      return;
    }
    ctx.dispatch(
      new BacNewCount(
        tracks.length,
        this.store.selectSnapshot(BacsState.bacs).find(bac => bac.name === name),
      ),
    );
  }

  @Action(TracksUploaded)
  addUploadedTrack(ctx: StateContext<TracksStateModel>, { track, box }: TracksUploaded) {
    this.checkFirstUpload(ctx);
    const bacName = bacNameFromDbName(box);
    if (
      bacName === BacName.none ||
      ctx.getState().unfiltered[bacName]?.find(t => t.id === track.id)
    ) {
      return;
    }
    track.addedToLibraryDate = moment();
    ctx.setState(addTrack(track, bacName));
  }

  @Action(AddDefaultTracksSuccess)
  addFirstTracks(ctx: StateContext<TracksStateModel>) {
    this.checkFirstUpload(ctx);
  }

  @Action(UpdateTrackFilter)
  updateTrackFilter(
    ctx: StateContext<TracksStateModel>,
    { track, box }: UpdateTrackFilter,
  ) {
    const bacName = bacNameFromDbName(box);
    ctx.setState(updateTrack(track, bacName));
  }

  @Action(UpdateTracksFilter)
  updateTracksFilter(
    ctx: StateContext<TracksStateModel>,
    { tracks }: UpdateTracksFilter,
  ) {
    const operators = tracks.map(track => {
      const bacName = bacNameFromDbName(track.box);

      return updateTrack(track.track, bacName);
    });

    ctx.setState(compose(...operators));
  }

  @Action(TrackEditRequest)
  trackEditRequest(
    ctx: StateContext<TracksStateModel>,
    { tracksId, track }: TrackEditRequest,
  ) {
    const radioId = this.store.selectSnapshot(RadioState.currentRadioId);

    return this.trackService.editTracks(radioId, tracksId, track).pipe(
      mergeMap(data =>
        ctx.dispatch(new TrackEditSuccess(data, { count: tracksId.length })),
      ),
      catchError(err => ctx.dispatch(new TrackEditFailure(err))),
    );
  }

  @Action(TrackEditSuccess)
  trackEditSuccess(ctx: StateContext<TracksStateModel>, { tracks }: TrackEditSuccess) {
    amplitude.getInstance().logEvent('track edited');

    const bacById = this.store.selectSnapshot(BacsState.bacById);
    const updatedTracks = tracks.map(tr => {
      const track = this.trackService.convertToTrack(tr);
      const bac = bacById(tr.idbox);

      return { track, box: bac.name };
    });

    ctx.dispatch(new UpdateTracksFilter(updatedTracks));
  }

  @Action(UpdateTrackCuesSuccess)
  updateCuesSuccess(
    ctx: StateContext<TracksStateModel>,
    { track, cues }: UpdateTrackCuesSuccess,
  ) {
    amplitude.getInstance().logEvent('mixpoints edited');
    const bac = this.store.selectSnapshot(BacsState.bacById)(track.idTrackbox);
    const n = { ...track };

    n.timeSeconds = cues.playtime;

    delete n['position'];

    ctx.dispatch(new UpdateTrackFilter(n, bac.name));
  }

  @Action(TrackCoverDeleteRequest)
  trackCoverDeleteRequest(
    ctx: StateContext<TracksStateModel>,
    { track }: TrackCoverDeleteRequest,
  ) {
    const radioId = this.store.selectSnapshot(RadioState.currentRadioId);

    return this.trackService.deleteTrackCover(radioId, track.id).pipe(
      mergeMap(url => ctx.dispatch(new TrackCoverDeleteSuccess(track, url))),
      catchError(err => ctx.dispatch(new TrackCoverDeleteFailure(err))),
    );
  }

  @Action(TrackCoverLookupRequest)
  trackCoverLookupRequest(
    ctx: StateContext<TracksStateModel>,
    { track }: TrackCoverLookupRequest,
  ) {
    const radioId = this.store.selectSnapshot(RadioState.currentRadioId);

    return this.trackService.lookupTrackCover(radioId, track.id).pipe(
      mergeMap(url => ctx.dispatch(new TrackCoverLookupSuccess(track, url))),
      catchError(err => ctx.dispatch(new TrackCoverLookupFailure(err))),
    );
  }

  @Action(TrackCoverSingleSetRequest)
  trackCoverSetSingleRequest(
    ctx: StateContext<TracksStateModel>,
    { track, file }: TrackCoverSingleSetRequest,
  ) {
    const radioId = this.store.selectSnapshot(RadioState.currentRadioId);

    return this.trackService.setSingleCover(radioId, track.id, file).pipe(
      mergeMap(url => ctx.dispatch(new TrackCoverSingleSetSuccess(track, url))),
      catchError(err => ctx.dispatch(new TrackCoverSingleSetFailure(err))),
    );
  }

  @Action(TrackCoverMultipleSetRequest)
  trackCoverSetMultipleRequest(
    ctx: StateContext<TracksStateModel>,
    { tracks, file }: TrackCoverMultipleSetRequest,
  ) {
    const radioId = this.store.selectSnapshot(RadioState.currentRadioId);

    return this.trackService
      .setMultipleCover(
        radioId,
        tracks.map(track => track.id),
        file,
      )
      .pipe(
        mergeMap(tracksCover =>
          ctx.dispatch(new TrackCoverMultipleSetSuccess(tracks, tracksCover)),
        ),
        catchError(err => ctx.dispatch(new TrackCoverMultipleSetFailure(err))),
      );
  }

  @Action([TrackCoverSingleSetSuccess])
  setCustomCover(
    ctx: StateContext<TracksStateModel>,
    { imgUrl, track }: TrackCoverSingleSetSuccess,
  ) {
    const bacName = this.getBacNameFromTrack(track);
    ctx.setState(
      updateTrack(
        { id: track.id, cover: imgUrl ? imgUrl : PLACEHOLDER, isCustomCover: true },
        bacName,
      ),
    );
  }

  @Action([TrackCoverLookupSuccess])
  setCoverFromApi(
    ctx: StateContext<TracksStateModel>,
    { imgUrl, track }: TrackCoverLookupSuccess,
  ) {
    const bacName = this.getBacNameFromTrack(track);
    ctx.setState(
      updateTrack({ id: track.id, cover: imgUrl ? imgUrl : PLACEHOLDER }, bacName),
    );
  }

  @Action(TrackCoverDeleteSuccess)
  updateCustomCoverState(
    ctx: StateContext<TracksStateModel>,
    { track, imgUrl }: TrackCoverDeleteSuccess,
  ) {
    const bacName = this.getBacNameFromTrack(track);
    ctx.setState(
      updateTrack(
        { id: track.id, cover: imgUrl ? imgUrl : PLACEHOLDER, isCustomCover: false },
        bacName,
      ),
    );
  }

  @Action(TrackCoverMultipleSetSuccess)
  setMultipleCoverFromApi(
    ctx: StateContext<TracksStateModel>,
    { tracks, tracksCover }: TrackCoverMultipleSetSuccess,
  ) {
    const operators = tracks.map((track, idx) => {
      const bacName = this.getBacNameFromTrack(track);

      return updateTrack(
        { id: track.id, cover: tracksCover[idx].cover_url, isCustomCover: true },
        bacName,
      );
    });
    ctx.setState(compose(...operators));
  }

  @Action(TrackEditFailure)
  trackEditFailure(ctx: StateContext<TracksStateModel>, { error }: TrackEditFailure) {
    if (error.status === 304) {
      this.store.dispatch(new ResetEditingTracks());
    }
  }

  @Action(TracksTagsSaveRequest)
  setMultipleTags(
    ctx: StateContext<TracksStateModel>,
    { tags, tracksId }: TracksTagsSaveRequest,
  ) {
    const idRadio = this.store.selectSnapshot(RadioState.currentRadioId);

    return this.tagsService.setMultipleTags(idRadio, tracksId, tags).pipe(
      mergeMap((data: TrackTags[]) =>
        ctx.dispatch(new TracksTagsSaveSuccess(tags, data)),
      ),
      catchError(err => ctx.dispatch(new TrackMultipleSetTagsFailure(err))),
    );
  }

  @Action(TrackArchiveRequest)
  trackArchiveRequest(
    ctx: StateContext<TracksStateModel>,
    { tracksId, bac }: TrackArchiveRequest,
  ) {
    const radioId = this.store.selectSnapshot(RadioState.currentRadioId);

    return this.trackService.archiveTracks(radioId, tracksId).pipe(
      mergeMap(() => {
        if (bac) {
          return ctx.dispatch(
            new TrackArchiveSuccess(tracksId, bac, { count: tracksId.length }),
          );
        } else {
          return ctx.dispatch(
            new TrackArchiveMultiBacSuccess(tracksId, { count: tracksId.length }),
          );
        }
      }),
      catchError(err => ctx.dispatch(new TrackArchiveFailure(err))),
    );
  }

  @Action(TrackArchiveSuccess)
  trackArchiveSuccess(
    ctx: StateContext<TracksStateModel>,
    { tracksId, bac }: TrackArchiveSuccess,
  ) {
    const key = bacNameFromDbName(bac.name);
    ctx.setState(archiveMultipleTracks(tracksId, key));
    const newTracksLength = ctx.getState().unfiltered[key].length;
    ctx.dispatch(new BacNewCount(newTracksLength || 0, bac));
    ctx.dispatch(new AddedToTrashCount(tracksId.length));
  }

  @Action(TrackArchiveMultiBacSuccess)
  trackArchiveMultiBacSuccess(
    ctx: StateContext<TracksStateModel>,
    { tracksId }: TrackArchiveMultiBacSuccess,
  ) {
    const tracks: Track[] = [];
    const trackWithId = this.store.selectSnapshot(TracksState.trackWithId);
    tracksId.forEach(trackId => {
      const track = trackWithId(trackId);

      if (track) {
        tracks.push(track);
      }
    });
    const bacs = this.store.selectSnapshot(BacsState.bacs);
    bacs.forEach(bac => {
      const selectedTracksOfBac = tracks.filter(tr => tr.idTrackbox === bac.id);
      const key = bacNameFromDbName(bac.name);
      ctx.setState(
        archiveMultipleTracks(
          selectedTracksOfBac.map(t => t.id),
          key,
        ),
      );
      const newTracksLength = ctx.getState().unfiltered[key].length;
      ctx.dispatch(new BacNewCount(newTracksLength || 0, bac));
      ctx.dispatch(new AddedToTrashCount(selectedTracksOfBac.length));
    });
  }

  @Action(TrackMoveRequest)
  trackMoveRequest(
    ctx: StateContext<TracksStateModel>,
    { tracksId, targetBac }: TrackMoveRequest,
  ) {
    const radioId = this.store.selectSnapshot(RadioState.currentRadioId);

    return this.trackService.moveTracks(radioId, tracksId, targetBac.id).pipe(
      mergeMap(() =>
        ctx.dispatch(
          new TrackMoveSuccess(tracksId, targetBac, { count: tracksId.length }),
        ),
      ),
      catchError(err => ctx.dispatch(new TrackMoveFailure(err))),
    );
  }

  @Action(TrackMoveSuccess)
  trackMoveSuccess(
    ctx: StateContext<TracksStateModel>,
    { tracksId, targetBac }: TrackMoveSuccess,
  ) {
    const trackWithId = TracksState.trackWithId(ctx.getState());
    const movedTracks = tracksId.map(id => trackWithId(id)).filter(t => !!t);

    const sourceBac = this.store.selectSnapshot(BacsState.bacById)(
      movedTracks[0].idTrackbox,
    );
    const targetBacKey = bacNameFromDbName(targetBac.name);
    const sourceBacKey = bacNameFromDbName(sourceBac.name);

    ctx.setState(
      compose(
        archiveMultipleTracks(tracksId, sourceBacKey),
        addMultipleTrack(movedTracks, targetBacKey),
      ),
    );

    const sourceNewLength = ctx.getState().unfiltered[sourceBacKey].length;

    ctx.dispatch(new BacNewCount(targetBac.count + tracksId.length, targetBac));
    ctx.dispatch(new BacNewCount(sourceNewLength, sourceBac));
  }

  @Action(TrackRestoreSuccess)
  trackRestoreSuccess(
    ctx: StateContext<TracksStateModel>,
    { restoredTracks, targetBac }: TrackRestoreSuccess,
  ) {
    const targetBacKey = bacNameFromDbName(targetBac.name);

    ctx.setState(addMultipleTrack(restoredTracks, targetBacKey));
    ctx.dispatch(new BacNewCount(targetBac.count + restoredTracks.length, targetBac));
  }

  @Action(TrackTagsRequest)
  trackById(ctx: StateContext<TracksStateModel>, { track }: TrackTagsRequest) {
    const radioId = this.store.selectSnapshot(RadioState.currentRadioId);

    return this.trackService.getTrackById(radioId, track.id).pipe(
      mergeMap(data => ctx.dispatch(new TrackTagsSuccess(data, track.idTrackbox))),
      catchError(err => ctx.dispatch(new TrackTagsFailure(err))),
    );
  }

  @Action(TrackTagsSuccess)
  trackByIdSuccess(ctx: StateContext<TracksStateModel>, { track }: TrackTagsSuccess) {
    const bacName = this.getBacNameFromTrack(track);
    ctx.setState(updateTrack(track, bacName));
  }

  @Action(TrackTagsSaveRequest)
  saveTagsRequest(
    ctx: StateContext<TracksStateModel>,
    { id, tags }: TrackTagsSaveRequest,
  ) {
    const radioId = this.store.selectSnapshot(RadioState.currentRadioId);

    return this.tagsService.editTags(radioId, id, tags).pipe(
      mergeMap(data => ctx.dispatch(new TrackTagsSaveSuccess(id, data, { count: 1 }))),
      catchError(err => ctx.dispatch(new TrackTagsSaveFailure(err))),
    );
  }

  @Action(TrackTagsSaveSuccess)
  updateTrackTags(
    ctx: StateContext<TracksStateModel>,
    { id, tags }: TrackTagsSaveSuccess,
  ) {
    const track = TracksState.trackWithId(ctx.getState())(id);
    if (!track) {
      return;
    }
    track.tags = tags;
    const bac = this.store.selectSnapshot(BacsState.bacById)(track.idTrackbox);
    ctx.dispatch(new UpdateTrackFilter(track, bac.name));
  }

  @Action(TracksTagsSaveSuccess)
  updateTracksTags(
    ctx: StateContext<TracksStateModel>,
    { trackTags }: TracksTagsSaveSuccess,
  ) {
    const trackWithId = TracksState.trackWithId(ctx.getState());
    const bacById = this.store.selectSnapshot(BacsState.bacById);
    const updatedTracks = trackTags
      .map(tt => ({ tt, track: trackWithId(tt.id) }))
      .filter(({ track }) => !!track)
      .map(({ tt, track }) => {
        track.tags = tt.tags;
        const bac = bacById(track.idTrackbox);

        return { track, box: bac.name };
      });

    ctx.dispatch(new UpdateTracksFilter(updatedTracks));
  }

  @Action(RemoveTagsFromMultipleTrackSuccess)
  removeTagsFromTracks(
    ctx: StateContext<TracksStateModel>,
    { idTracks, idTags }: RemoveTagsFromMultipleTrackSuccess,
  ) {
    const trackWithId = TracksState.trackWithId(ctx.getState());
    const bacById = this.store.selectSnapshot(BacsState.bacById);
    const updatedTracks = idTracks
      .map(id => trackWithId(id))
      .filter(track => !!track)
      .map(track => {
        track.tags = track.tags.filter(tg => !idTags.includes(tg.id));
        const bac = bacById(track.idTrackbox);

        return { track, box: bac.name };
      });

    ctx.dispatch(new UpdateTracksFilter(updatedTracks));
  }

  @Action(TrackBuyLinkLookupRequest)
  buyLinkRequest(
    ctx: StateContext<TracksStateModel>,
    { track }: TrackBuyLinkLookupRequest,
  ) {
    const radioId = this.store.selectSnapshot(RadioState.currentRadioId);

    return this.trackService.lookupBuyLink(radioId, track.id).pipe(
      mergeMap(url => ctx.dispatch(new TrackBuyLinkLookupSuccess(track, url))),
      catchError(err => ctx.dispatch(new TrackBuyLinkLookupFailure(err))),
    );
  }

  @Action(TrackBuyLinkLookupSuccess)
  buyLinkSuccess(
    ctx: StateContext<TracksStateModel>,
    { track, link }: TrackBuyLinkLookupSuccess,
  ) {
    const bacName = this.getBacNameFromTrack(track);
    ctx.setState(updateTrack({ id: track.id, buyLink: link }, bacName));
  }

  @Action(AllTracksForPlaylistRequest)
  getAllTracks(ctx: StateContext<TracksStateModel>): Observable<boolean[]> | void {
    const setLoading: StateOperator<any> = (val: boolean) =>
      patch({ isFetchingAll: val });
    const radioId = this.store.selectSnapshot(RadioState.currentRadioId);
    const lastRadioGet = ctx.getState().lastRadioFetchedAll;

    if (lastRadioGet === radioId) {
      return;
    }

    ctx.setState(compose(patch({ lastRadioFetchedAll: radioId }), setLoading(true)));

    return combineLatest([
      this.getCurrentRadioId(),
      this.store.select(BacsState.bacs),
    ]).pipe(
      filter(([id, bacs]) => !!id && bacs && bacs.length > 0),
      take(1),
      mergeMap(([id, boxList]) =>
        combineLatest(
          boxList.map(box =>
            this.trackService.getRadioTracks(id, box.id).pipe(
              tap(tracks =>
                ctx.setState(setUnfilteredTracks(tracks, bacNameFromDbName(box.name))),
              ),
              map(() => true),
              startWith(false),
            ),
          ),
        ),
      ),
      filter(arr => arr.reduce((prev, curr) => prev && curr, true)),
      tap(() => ctx.setState(setLoading(false))),
      catchError(err => {
        ctx.setState(setLoading(false));

        return throwError(err);
      }),
    );
  }

  @Action(PlaylistOfTrackRequest)
  playlistOfTrack(
    ctx: StateContext<TracksStateModel>,
    { idTrack }: PlaylistOfTrackRequest,
  ) {
    return this.getCurrentRadioId().pipe(
      mergeMap(id => this.trackService.getPlaylistOfTracks(id, idTrack)),
      mergeMap(data => ctx.dispatch(new PlaylistOfTrackSuccess(data))),
      catchError(err => ctx.dispatch(new PlaylistOfTrackFailure(err))),
    );
  }

  @Action(TracksAddToPlaylistSuccess)
  updateTracksPlaylists(
    ctx: StateContext<TracksStateModel>,
    { playlist, tracksId }: TracksAddToPlaylistSuccess,
  ) {
    const trackWithId = TracksState.trackWithId(ctx.getState());
    const bacById = this.store.selectSnapshot(BacsState.bacById);
    const updatedTracks = tracksId
      .map(trackId => trackWithId(trackId))
      .filter(track => !!track)
      .map(track => {
        track.playlists = unionBy<TrackPlaylist>(
          track.playlists,
          [pick(playlist, ['id', 'name', 'color', 'type'])],
          'id',
        ).sort((a, b) => a.name.localeCompare(b.name));
        const bac = bacById(track.idTrackbox);

        return { track, box: bac.name };
      });

    ctx.dispatch(new UpdateTracksFilter(updatedTracks));
  }

  @Action(AddDefaultTracksRequest)
  addDefaultTracks(ctx: StateContext<TracksStateModel>) {
    return this.getCurrentRadioId().pipe(
      mergeMap(id => this.trackService.addDefaultTracks(id)),
      mergeMap(() => ctx.dispatch(new AddDefaultTracksSuccess())),
      catchError(err => ctx.dispatch(new AddDefaultTracksFailure(err))),
    );
  }

  checkFirstUpload(ctx: StateContext<TracksStateModel>) {
    if (
      !this.store.selectSnapshot(TracksState.tracksButChronicsCount) &&
      !this.store.selectSnapshot(LiveTrackingState.isBroadcasting) &&
      !this.store.selectSnapshot(OnboardingChecklistState.stepCompleted)(
        ChecklistStep.LIBRARY_ADD_TRACK,
      ) &&
      !this.store.selectSnapshot(OnboardingChecklistState.stepCompleted)(
        ChecklistStep.REGENERATE,
      )
    ) {
      ctx.dispatch(new AutoGenerateAndStartRadio());
    }
  }

  getBacNameFromTrack(track: Track): string {
    const box = this.store.selectSnapshot(BacsState.bacById)(track.idTrackbox);

    return bacNameFromDbName(box.name);
  }

  getCurrentRadioId(): Observable<number> {
    return this.store.select(RadioState.currentRadioId).pipe(
      filter(data => !!data),
      take(1),
    );
  }
}
