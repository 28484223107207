import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { UiLoaderModule } from '@radioking/ui/loader';
import { CountUpModule } from 'ngx-countup';

import { UiCountUpModule } from '../../../count-up/src';

import { StepIndicatorComponent } from './step-indicator.component';

@NgModule({
  imports: [
    CommonModule,
    CountUpModule,
    TranslateModule,
    UiLoaderModule,
    UiCountUpModule,
  ],
  declarations: [StepIndicatorComponent],
  exports: [StepIndicatorComponent],
})
export class UiStepIndicatorModule {}
