import { Component, forwardRef, Input, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { replaceAccents } from '@app/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { SugestionInput } from '@radioking/ui/input';

@Component({
  selector: 'rk-dropdown-autocomplete',
  templateUrl: './dropdown-autocomplete.component.html',
  styleUrls: ['./dropdown-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownAutocompleteComponent),
      multi: true,
    },
  ],
})
export class DropdownAutocompleteComponent implements ControlValueAccessor {
  @Input()
  label: string;

  @Input()
  placeholder = '';

  @Input()
  hintQuestionMark: string;

  @Input()
  modal = false;

  @Input()
  canAddNew = false;

  @Input()
  isTrack = false;

  @Input()
  dataIncludedForWarning: number[];

  @Input()
  playlistTemplate = false;

  @Input()
  dropdownWidth: number;

  @Input()
  renderLineTemplate?: TemplateRef<any>;

  @Input()
  iconTemplate?: TemplateRef<any>;

  @Input()
  isDisabled: boolean;

  @Input()
  isAbsolute = false;

  @Input()
  displayIfEmpty = true;

  private _data: SugestionInput[];

  @Input()
  set data(data: SugestionInput[]) {
    this._data = data;
    if (this.inputVal) {
      this.searchData(this.inputVal);
    } else {
      this.filteredData = data;
    }
  }

  get data() {
    return this._data;
  }

  filteredData: SugestionInput[] = [];

  value: SugestionInput;

  inputVal: string;

  isSearching = false;

  constructor(private readonly translateService: TranslateService) {}

  shouldShowWarning(val: number): boolean {
    return this.dataIncludedForWarning
      ? this.dataIncludedForWarning.includes(val)
      : false;
  }

  searchData(val: string) {
    this.isSearching = true;
    this.inputVal = val;
    const searchTerm = replaceAccents(val.toLowerCase());
    this.filteredData = [...this.data].filter(data => {
      return data.translation
        ? replaceAccents(
            this.translateService.instant(data.translation).toLowerCase(),
          ).includes(searchTerm)
        : replaceAccents(data.name.toLowerCase()).includes(searchTerm);
    });

    if (this.filteredData.length < 2 && !this.canAddNew) {
      const maybe = this.filteredData.find(data => {
        return data.translation
          ? this.translateService
              .instant(data.translation)
              .toLowerCase()
              .includes(val.toLowerCase())
          : data.name.toLowerCase().includes(val.toLowerCase());
      });

      if (maybe) {
        this.setData(maybe);
      }
    }
  }

  setData(val: SugestionInput) {
    this.isSearching = false;
    this.value = val;
    this.propagateChange(this.value);
    this.filteredData = this.data;
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {}

  writeValue(value: SugestionInput): void {
    this.isSearching = false;

    if (value !== undefined) {
      this.value = value;
    }
  }
}
