import { Injectable } from '@angular/core';
import { tokenPath, tokenRefreshToken } from '@app/core/ngxs/Constantes';
import { environment } from '@env/environment';
import { StorageEngine } from '@ngxs/storage-plugin';
import { Logger } from '@radioking/shared/logger';
import Cookies from 'js-cookie';

const setCookieSettings = {
  ...environment.cookies.config,
};

const log = new Logger('STORAGE');

/* 'app.token', 'app.refreshToken' */
@Injectable()
export class StorageEngineNgxs implements StorageEngine {
  getItem(name: any): any {
    if (name === tokenPath) {
      return this.getFromCookie(name, environment.cookies.tokenKey);
    }
    if (name === tokenRefreshToken) {
      return this.getFromCookie(name, environment.cookies.refreshTokenKey);
    }

    return localStorage.getItem(name);
  }

  private getFromCookie(name: string, key: string): string {
    const value = JSON.stringify(Cookies.get(key) || '');
    localStorage.setItem(name, value);

    return value;
  }

  setItem(key: any, value: any) {
    try {
      if (key === tokenPath) {
        this.writeLogicForCookie(value, environment.cookies.tokenKey);
      } else if (key === tokenRefreshToken) {
        this.writeLogicForCookie(value, environment.cookies.refreshTokenKey);
      }

      if (!localStorage.getItem(key) && value === undefined) {
        return;
      }

      localStorage.setItem(key, value);
    } catch (e) {
      log.error(e);
    }
  }

  /**
   * Do the extra logic needed since the engine store as string like '"token"'
   * This function doses the needed steps to ensure the variable is compatible with other services.
   * @param value the value to set
   * @param key the cookie key
   */
  private writeLogicForCookie(value: string, key: string) {
    if (!value) {
      Cookies.remove(key, setCookieSettings);

      return;
    }
    const realValue = JSON.parse(value);
    if (!realValue || realValue.length === 0) {
      Cookies.remove(key, setCookieSettings);

      return;
    }
    Cookies.set(key, realValue, setCookieSettings);
  }

  get length(): number {
    return localStorage.length;
  }

  clear(): void {
    localStorage.clear();
  }

  key(val: number): string {
    return localStorage.key(val);
  }

  removeItem(key: any): void {
    localStorage.removeItem(key);
  }
}
