import { Injectable } from '@angular/core';
import { RadioState } from '@app/core/states/radio.state';
import { Bac, BacGroup, RootBac } from '@app/library/models/bac.model';
import { BacApiService, ownerMain } from '@app/library/services/bac-api.service';
import {
  BacsFailure,
  BacsRequest,
  BacsSuccess,
  ClearBacFailure,
  ClearBacNotPossible,
  ClearBacRequest,
  ClearBacSuccess,
  CloseBac,
  OpenBac,
  ToggleBac,
} from '@app/library/states/bacs.actions';
import { BacNewCount, TracksUploaded } from '@app/library/states/tracks.actions';
import { uniqFilter } from '@app/shared/utils';
import {
  Action,
  Actions,
  ofActionDispatched,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { SugestionInput } from '@radioking/ui/input';
import { merge } from 'rxjs';
import { catchError, filter, map, mergeMap, take } from 'rxjs/operators';

export interface BacsStateModel {
  isLoading: boolean;
  bacsOpen: number[];
  bacsGroup?: BacGroup[];
  totalCountTracksBoxesNotLocked: number;
}

@State<BacsStateModel>({
  name: 'bacs',
  defaults: {
    isLoading: false,
    bacsOpen: [],
    bacsGroup: [],
    totalCountTracksBoxesNotLocked: 0,
  },
})
@Injectable()
export class BacsState {
  constructor(
    private readonly bacApiService: BacApiService,
    private readonly actions$: Actions,
    private readonly store: Store,
  ) {}
  @Selector()
  static bacs(state: BacsStateModel): RootBac[] {
    return state.bacsGroup.reduce((acc, curr) => [...acc, ...curr.boxes], []);
  }

  @Selector()
  static unlockedBacsForSelect(
    state: BacsStateModel,
  ): (current: number) => SugestionInput[] {
    return current =>
      state.bacsGroup
        .reduce((acc, curr) => [...acc, ...curr.boxes], [])
        .filter(data => !data.isLocked)
        .filter(data => data.id !== current)
        .map((data: RootBac) => {
          let translated = '';
          data.translated$.subscribe((res: any) => (translated = res));

          return { id: data.id, name: translated };
        });
  }

  @Selector()
  static allBoxesForSelect(state: BacsStateModel): SugestionInput[] {
    return state.bacsGroup
      .reduce((acc, curr) => [...acc, ...curr.boxes], [])
      .map((data: RootBac) => {
        let translated = '';
        data.translated$.subscribe((res: any) => (translated = res));

        return { id: data.id, name: data.name, translation: translated };
      });
  }

  @Selector()
  static allUnlockedBoxesForSelect(state: BacsStateModel): SugestionInput[] {
    return state.bacsGroup
      .reduce((acc, curr) => [...acc, ...curr.boxes], [])
      .filter(data => !data.isLocked)
      .map((data: RootBac) => {
        let translated = '';
        data.translated$.subscribe((res: any) => (translated = res));

        return {
          id: data.id,
          name: data.name,
          translation: translated,
          extra: {
            icon: data.icon,
          },
        };
      });
  }

  @Selector()
  static firstBox(state: BacsStateModel): SugestionInput {
    return state.bacsGroup
      .reduce((acc, curr) => [...acc, ...curr.boxes], [])
      .map((data: RootBac) => {
        let translated = '';
        data.translated$.subscribe((res: any) => (translated = res));

        return { id: data.id, name: translated };
      })[0];
  }

  @Selector()
  static bacsGroup(state: BacsStateModel): BacGroup[] {
    let group: BacGroup;
    const rest = state.bacsGroup.filter((bac: BacGroup) => {
      if (bac.ownerId === ownerMain) {
        group = bac;

        return false;
      }

      return true;
    });
    if (group) {
      return [group, ...rest];
    }

    return state.bacsGroup;
  }

  @Selector()
  static bacsFiltered(state: BacsStateModel) {
    return (withChronics: boolean) => {
      const bg = state.bacsGroup
        .reduce((acc, curr) => [...acc, ...curr.boxes], [])
        .filter(data => (withChronics ? true : data.name !== '__CHRONIC__'));

      return bg;
    };
  }

  @Selector()
  static isLoading(state: BacsStateModel): boolean {
    return state.isLoading;
  }

  @Selector()
  static hasAnyBacs(state: BacsStateModel): boolean {
    return BacsState.bacs(state).length > 0;
  }

  @Selector()
  static totalCountTracks(state: BacsStateModel): number {
    return BacsState.bacs(state)
      .map(bac => bac.count)
      .reduce((a, b) => a + b, 0);
  }

  @Selector()
  static totalCountTracksBoxesNotLocked(state: BacsStateModel): number {
    return state.totalCountTracksBoxesNotLocked;
  }

  @Selector()
  static isBacOpen(state: BacsStateModel) {
    return (id: number): boolean => (state.bacsOpen || []).includes(id);
  }

  @Selector()
  static bacById(state: BacsStateModel) {
    return (id: number): Bac => BacApiService.findBacWithIdInAll(state.bacsGroup, id);
  }

  @Selector()
  static rootBacById(state: BacsStateModel) {
    return (id: number): RootBac => BacsState.bacs(state).find(bac => bac.id === id);
  }

  @Action(BacsRequest)
  requestBacs(ctx: StateContext<BacsStateModel>) {
    if (ctx.getState().isLoading) {
      return merge(
        this.actions$.pipe(ofActionDispatched(BacsSuccess)),
        this.actions$.pipe(ofActionDispatched(BacsFailure)),
      ).pipe(take(1));
    }
    ctx.patchState({ isLoading: true });

    return this.store.select(RadioState.currentRadioId).pipe(
      filter(val => val > 0),
      take(1),
      mergeMap(idRadio => this.bacApiService.getAll(idRadio)),
      map(data => ctx.dispatch(new BacsSuccess(data))),
      catchError(error => ctx.dispatch(new BacsFailure(error))),
    );
  }

  @Action(BacsSuccess)
  successBacs(ctx: StateContext<BacsStateModel>, { bacsGroup }: BacsSuccess) {
    const sortedBacsGroup = bacsGroup.slice(0);

    // New total count
    const totalCountTracksBoxesNotLocked = sortedBacsGroup
      .reduce<RootBac[]>((acc, curr) => [...acc, ...curr.boxes], [])
      .filter(bac => !bac.isLocked)
      .map(bac => bac.count)
      .reduce((a, b) => a + b, 0);

    ctx.patchState({
      bacsGroup: sortedBacsGroup,
      isLoading: false,
      totalCountTracksBoxesNotLocked,
    });
  }

  @Action(BacsFailure)
  errorBacs(ctx: StateContext<BacsStateModel>) {
    ctx.patchState({ isLoading: false });
  }

  @Action(OpenBac)
  openBac(ctx: StateContext<BacsStateModel>, { id }: OpenBac) {
    const opens = [...ctx.getState().bacsOpen, id].filter(uniqFilter);
    ctx.patchState({ bacsOpen: opens });
  }

  @Action(CloseBac)
  closeBac(ctx: StateContext<BacsStateModel>, { id }: CloseBac) {
    const opens = ctx
      .getState()
      .bacsOpen.filter((i: number) => i !== id)
      .filter(uniqFilter);
    ctx.patchState({ bacsOpen: opens });
  }

  @Action(ToggleBac)
  toggleBac(ctx: StateContext<BacsStateModel>, { id }: ToggleBac) {
    const ids = ctx.getState().bacsOpen;
    if (ids.includes(id)) {
      return ctx.dispatch(new CloseBac(id));
    }

    return ctx.dispatch(new OpenBac(id));
  }

  @Action(ClearBacRequest)
  clearBacRequest(ctx: StateContext<BacsStateModel>, { id }: ClearBacRequest) {
    const bac = BacsState.bacById(ctx.getState())(id);
    if (!bac) {
      // we want to clear a bac we don't have :(
      return ctx.dispatch(new ClearBacNotPossible());
    }
    if (bac.isReadOnly) {
      // we can't clear a bac we can't write to :(
      return ctx.dispatch(new ClearBacNotPossible());
    }

    return this.bacApiService.clearBac(bac.id).pipe(
      map(() => ctx.dispatch(new ClearBacSuccess(id))),
      catchError(err => ctx.dispatch(new ClearBacFailure(err))),
    );
  }

  @Action(ClearBacSuccess)
  clearBacSuccess(ctx: StateContext<BacsStateModel>, { id }: ClearBacSuccess) {
    const bacs = ctx.getState();
    const bac = BacsState.bacById(bacs)(id);
    const previousCount = bac.count;
    bac.count = 0;
    ctx.patchState({
      bacsGroup: bacs.bacsGroup,
      totalCountTracksBoxesNotLocked: bacs.totalCountTracksBoxesNotLocked - previousCount,
    });
  }

  @Action(TracksUploaded)
  addUploadedTrack(ctx: StateContext<BacsStateModel>, { box }: TracksUploaded) {
    ctx.setState((state: BacsStateModel) => {
      state.bacsGroup[0].boxes.filter(val => val.name === box)[0].count += 1;

      return state;
    });

    // New total count
    const totalCountTracksBoxesNotLocked =
      ctx.getState().totalCountTracksBoxesNotLocked + 1;
    ctx.setState(
      patch({
        totalCountTracksBoxesNotLocked,
      }),
    );
  }

  @Action(BacNewCount)
  handleDeletedTracks(ctx: StateContext<BacsStateModel>, { bac, trackNmb }: BacNewCount) {
    const bacObj = BacApiService.findBacWithNameInAll(ctx.getState().bacsGroup, bac.name);
    const previousCount = bacObj.count;

    // New bac count
    bacObj.count = trackNmb;

    // New total count
    const totalCountTracksBoxesNotLocked =
      ctx.getState().totalCountTracksBoxesNotLocked - previousCount + trackNmb;
    ctx.setState(
      patch({
        totalCountTracksBoxesNotLocked,
      }),
    );
  }
}
