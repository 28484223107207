import { Injectable, NgZone } from '@angular/core';
import { PlayError } from '@app/core/states/audio.actions';
import { AuthState } from '@app/core/states/auth.state';
import { SavedToLocalstorage } from '@app/shared/constants';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PlayerService {
  private readonly onEnd$ = new BehaviorSubject<void>(null);
  private readonly onStop$ = new BehaviorSubject<void>(null);
  private readonly onPlay$ = new BehaviorSubject<void>(null);
  private readonly onPause$ = new BehaviorSubject<void>(null);
  private readonly onDuration$ = new BehaviorSubject<number>(0);
  private readonly onMaxDuration$ = new BehaviorSubject<number>(0);

  private readonly playing$ = new BehaviorSubject<boolean>(false);

  private isPLaying: boolean;

  private isStoping = false;

  private htmlAudio: HTMLAudioElement;

  constructor(
    private readonly ngZone: NgZone,
    private readonly store: Store,
  ) {
    this.isPLaying = false;
  }

  getInitialVolume(): number {
    const preferredVolume = this.getPreferredVolume();

    if (preferredVolume !== null) {
      return preferredVolume;
    }

    return 1;
  }

  getPreferredVolume(): number | null {
    const volume = localStorage.getItem(SavedToLocalstorage.PREFERENCE_VOLUME);

    if (typeof volume === 'string') {
      return JSON.parse(volume) as number;
    }

    return volume;
  }

  setPreferredVolume(volume: number) {
    localStorage.setItem(SavedToLocalstorage.PREFERENCE_VOLUME, JSON.stringify(volume));
  }

  public listenToAudioFile(url: string): void {
    this.stopMusic();
    const authToken = this.store.selectSnapshot(AuthState.getAccessToken);
    const playURL = `${url}?token=${authToken}`;
    this.htmlAudio = new Audio(playURL);
    this.htmlAudio.volume = this.getInitialVolume();
    this.htmlAudio.play();

    this.setupListenersForSound(false);
  }

  public listenToRadio(url: string): void {
    this.isStoping = true;
    this.stopMusic();
    this.isStoping = false;
    this.htmlAudio = new Audio(url);
    this.htmlAudio.volume = this.getInitialVolume();
    this.htmlAudio.play();

    this.setupListenersForSound();
  }

  private setupListenersForSound(isRadio = true): void {
    this.htmlAudio.onended = () => {
      if (this.isStoping) {
        return;
      }
      this.isPLaying = false;
      this.ngZone.run(() => this.onEnd$.next(null));
      this.ngZone.run(() => this.playing$.next(this.isPLaying));
    };
    this.htmlAudio.onloadeddata = () => {
      if (this.isStoping) {
        return;
      }
      this.isPLaying = true;
      this.ngZone.run(() => this.onPlay$.next(null));
      this.ngZone.run(() => this.playing$.next(this.isPLaying));
    };

    this.htmlAudio.onpause = () => {
      if (this.isStoping) {
        return;
      }
      this.isPLaying = false;
      this.ngZone.run(() => this.onPause$.next(null));
      this.ngZone.run(() => this.playing$.next(this.isPLaying));
    };

    this.htmlAudio.onerror = () => {
      this.store.dispatch(new PlayError());
      if (this.isStoping) {
        return;
      }
      this.isPLaying = false;
      this.ngZone.run(() => this.onStop$.next(null));
      this.ngZone.run(() => this.playing$.next(this.isPLaying));
    };

    if (!isRadio) {
      this.htmlAudio.ontimeupdate = () => {
        this.ngZone.run(() => this.onDuration$.next(this.getCurrentDuration()));
        this.ngZone.run(() => this.onMaxDuration$.next(this.getMaxDuration()));
      };
    }

    this.onDuration$.next(0);
  }

  getCurrentDuration(): number {
    return Math.round(this.htmlAudio.currentTime * 100) / 100;
  }

  getMaxDuration(): number {
    if (!this.htmlAudio) {
      return 0;
    }

    return Math.round(this.htmlAudio.duration * 10) / 10;
  }

  public pauseMusic(): void {
    if (this.htmlAudio) {
      this.htmlAudio.pause();
    }
  }

  public stopMusic(): void {
    if (this.htmlAudio) {
      this.htmlAudio.pause();
      this.htmlAudio = new Audio();
    }
  }

  public resume(): void {
    if (this.htmlAudio) {
      this.htmlAudio.play();
    }
  }

  public getVolume(): number {
    if (this.htmlAudio.volume === 0) {
      return -60;
    }

    return 20 * Math.log10(this.htmlAudio.volume);
  }

  public setVolume(volume: number): void {
    let logVol = Math.pow(10, volume / 20);
    if (volume === -60) {
      logVol = 0;
    }
    this.htmlAudio.volume = logVol;
    this.setPreferredVolume(logVol);
  }

  public seekTo(secs: number): void {
    if (this.htmlAudio && isFinite(secs)) {
      this.htmlAudio.currentTime = secs;
    }
  }

  public onEnd(): Observable<void> {
    return this.onEnd$.asObservable();
  }

  public onPlay(): Observable<void> {
    return this.onPlay$.asObservable();
  }

  public onPause(): Observable<void> {
    return this.onPause$.asObservable();
  }

  public onDuration(): Observable<number> {
    return this.onDuration$.asObservable().pipe(filter(data => !isNaN(data)));
  }

  public onMaxDuration(): Observable<number> {
    return this.onMaxDuration$.asObservable().pipe(filter(data => !isNaN(data)));
  }
}
