import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MobileCloseButtonComponent } from './mobile-close-button.component';

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [MobileCloseButtonComponent],
  exports: [MobileCloseButtonComponent],
})
export class UiCodeModule {}
