import { PlaylistTrack, Track } from '@app/core/models/Track';
import { ArchivedTrack } from '@app/library/models/trash.model';

const tag = '[SelectedTracks]';

export class SelectTrack {
  static readonly type = `${tag} select single track`;

  constructor(public track: Track, public clicked = false) {}
}

export class UnselectTrack {
  static readonly type = `${tag} unselect single track`;

  constructor(public track: Track) {}
}

export class SelectMultipleTracks {
  static readonly type = `${tag} select multiple tracks`;

  constructor(public tracks: Track[]) {}
}

export class ToggleSelectTrack {
  static readonly type = `${tag} toggle select track`;

  constructor(public track: Track, public clicked = false) {}
}

export class SelectPlayTrack {
  static readonly type = `${tag} select single play track`;

  constructor(public track: PlaylistTrack, public clicked = false) {}
}

export class UnselectPlayTrack {
  static readonly type = `${tag} unselect single play track`;

  constructor(public track: PlaylistTrack) {}
}

export class SelectMultiplePlayTracks {
  static readonly type = `${tag} select multiple play tracks`;

  constructor(public tracks: PlaylistTrack[]) {}
}

export class ToggleSelectPlayTrack {
  static readonly type = `${tag} toggle select play track`;

  constructor(public track: PlaylistTrack, public clicked = false) {}
}

export class SelectArchivedTrack {
  static readonly type = `${tag} select single archived track`;

  constructor(public archivedTrack: ArchivedTrack, public clicked = false) {}
}

export class UnselectArchivedTrack {
  static readonly type = `${tag} unselect single archived track`;

  constructor(public archivedTrack: ArchivedTrack) {}
}

export class SelectMultipleArchivedTracks {
  static readonly type = `${tag} select multiple archived tracks`;

  constructor(public archivedTracks: ArchivedTrack[]) {}
}

export class ToggleSelectArchivedTrack {
  static readonly type = `${tag} toggle select archived track`;

  constructor(public archivedTrack: ArchivedTrack, public clicked = false) {}
}

export class UnselectAll {
  static readonly type = `${tag} unselect all`;
}
