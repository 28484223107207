import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BacApi, BacType } from '@app/library/models/bac-api.model';
import { Bac, BacGroup, ChildBac, RootBac } from '@app/library/models/bac.model';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';

export const ownerMain = 100;

const bacsUrls = {
  getBacsByRadio: (id: number) => `${environment.urls.MAIN_API_V2}/radio/${id}/box`,
};

@Injectable({
  // providedIn: LibraryModule,
  providedIn: 'root',
})
export class BacApiService {
  constructor(
    private readonly translate: TranslateService,
    private readonly httpClient: HttpClient,
  ) {}
  static findBacWithIdInAll(groups: BacGroup[], id: number): Bac {
    for (const group of groups) {
      const bac = BacApiService.findBacWithId(group.boxes, id);
      if (bac) {
        return bac;
      }
    }

    return null;
  }

  static findBacWithId(bacs: Bac[] | RootBac[] | ChildBac[], id: number): Bac {
    for (const bac of bacs) {
      if (bac.id === id) {
        return bac;
      }
      if (bac.children && bac.children.length > 0) {
        const res = BacApiService.findBacWithId(bac.children, id);
        if (res) {
          return res;
        }
      }
    }

    return null;
  }

  static findBacWithNameInAll(groups: BacGroup[], name: string): Bac {
    for (const group of groups) {
      const bac = BacApiService.findBacWithName(group.boxes, name);
      if (bac) {
        return bac;
      }
    }

    return null;
  }

  static findBacWithName(bacs: Bac[] | RootBac[] | ChildBac[], name: string): Bac {
    for (const bac of bacs) {
      if (bac.name === name) {
        return bac;
      }
      if (bac.children && bac.children.length > 0) {
        const res = BacApiService.findBacWithName(bac.children, name);
        if (res) {
          return res;
        }
      }
    }

    return null;
  }

  getAll(radioId: number): Observable<BacGroup[]> {
    return this.httpClient
      .get<BacApi[]>(bacsUrls.getBacsByRadio(radioId))
      .pipe(map(data => this.convertArrayResponse(data, radioId)));
  }

  clearBac(id: number): Observable<boolean> {
    return of(id).pipe(
      delay(500),
      map(number => number > 0.6),
      tap(hasError => {
        if (hasError) {
          throw new Error('Fake api error');
        }
      }),
    );
  }

  convertArrayResponse(res: BacApi[], radioId: number): BacGroup[] {
    const arr: BacGroup[] = [];
    const bacs: RootBac[] = this.convertBacResponce(res);
    arr.push(new BacGroup(radioId, bacs));

    return arr;
  }

  convertBacResponce(res: BacApi[]): RootBac[] {
    const arr: RootBac[] = [];
    if (!res || !res.length) {
      return [];
    }
    for (const item of res) {
      arr.push(this.convertItemFromServer(item));
    }
    arr.sort((a, b) => (a.position > b.position ? -1 : a.position < b.position ? 1 : 0));

    return arr;
  }

  /**
   * Convert a returned JSON object to Bac.
   */
  private convertItemFromServer(bacApi: BacApi): RootBac {
    const copy: RootBac = this.apiToRootBac(bacApi);
    switch (copy.type) {
      case 'music':
        copy.icon = 'fa-music';
        copy.translated$ = this.translate.stream(marker('MEDIA.BACS.TITLE.MUSIC'));
        copy.position = 60;
        break;
      case 'id':
        copy.icon = 'fa-bullhorn';
        copy.translated$ = this.translate.stream(
          marker('MEDIA.BACS.TITLE.IDENTIFICATION'),
        );
        copy.position = 50;
        break;
      case 'show':
        copy.icon = 'fa-rss';
        copy.translated$ = this.translate.stream(marker('MEDIA.BACS.TITLE.PODCAST'));
        copy.position = 40;
        break;
      case 'ad':
        copy.icon = 'fa-dollar-sign';
        copy.translated$ = this.translate.stream(marker('MEDIA.BACS.TITLE.AD'));
        copy.position = 30;
        break;
      case 'shoutout':
        copy.icon = 'fa-quote-right';
        copy.translated$ = this.translate.stream(marker('MEDIA.BACS.TITLE.SHOUT_OUT'));
        copy.position = 20;
        break;
      case 'chronic':
        copy.icon = 'fa-newspaper';
        copy.translated$ = this.translate.stream(marker('MEDIA.BACS.TITLE.CHRONICS'));
        copy.position = 10;
        break;
    }
    // copy.children = this.convertArrayChildrenResponse(bacApi.children);

    return copy;
  }

  /*
  private convertArrayChildrenResponse(res: BacApi[]): ChildBac[] {
    const arr: ChildBac[] = [];
    if (!res || !res.length) {
      return [];
    }
    for (let i = 0; i < res.length; i++) {
      arr.push(this.convertChildBacFromServer(res[i]));
    }
    return arr;
  }

  private convertChildBacFromServer(bacApi: BacApi): ChildBac {
    const cpy: ChildBac = this.apiToChildBac(bacApi);
    // const cpy: ChildBac = Object.assign({}, bacApi);
    cpy.children = this.convertArrayChildrenResponse(bacApi.children);
    return cpy;
  }
  */

  apiToRootBac(bacApi: BacApi): RootBac {
    const type: BacType = this.getBacTypeAcordingToName(bacApi);

    return new RootBac(
      bacApi.idtrackbox,
      bacApi.name,
      bacApi.track_count,
      type,
      bacApi.locked,
    );
  }
  /*
  apiToChildBac(bacApi: BacApi): ChildBac {
    return new ChildBac(
      bacApi.id,
      bacApi.name,
      bacApi.tracks_count,
      bacApi.type,
      bacApi.path,
      [],
      bacApi.owner_id,
    );
  }
  */

  getBacTypeAcordingToName(bacApi: BacApi): BacType {
    switch (bacApi.name) {
      case '__MUSIC__':
        return 'music';
      case '__IDENTIFICATION__':
        return 'id';
      case '__PODCAST__':
        return 'show';
      case '__AD__':
        return 'ad';
      case '__CHRONIC__':
        return 'chronic';
      case '__DEDICATION__':
        return 'shoutout';
      default:
        return 'children';
    }
  }
}
