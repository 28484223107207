import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { SwloggerService } from '../services/swlogger.service';

import { SetOnMobile, SetOnTablet, UpdateAvailable } from './app.actions';

export interface AppStateModel {
  hasUpdateAvailable: boolean;
  historyStack: string[];
  mobile: boolean;
  tablet: boolean;
}

@State<AppStateModel>({
  name: 'app',
  defaults: {
    hasUpdateAvailable: false,
    historyStack: [],
    mobile: null,
    tablet: false,
  },
})
@Injectable()
export class AppState {
  constructor(private readonly swLogger: SwloggerService) {
    if (environment.production) {
      // Disable service worker in localhost
      this.swLogger.watchForUpdate();
    }
  }
  @Selector()
  static history(state: AppStateModel): string[] {
    return state.historyStack;
  }

  @Selector()
  static hasUpdateAvailable(state: AppStateModel): boolean {
    return state.hasUpdateAvailable;
  }

  @Selector()
  static onMobile(state: AppStateModel): boolean {
    return state.mobile;
  }

  @Selector()
  static onTablet(state: AppStateModel): boolean {
    return state.tablet;
  }

  @Action(UpdateAvailable)
  updateAvailable(ctx: StateContext<AppStateModel>) {
    ctx.patchState({ hasUpdateAvailable: true });
  }

  @Action(RouterNavigation)
  handleNavigation(ctx: StateContext<AppStateModel>, { event }: RouterNavigation) {
    const newHistory = [...ctx.getState().historyStack, event.urlAfterRedirects];
    ctx.patchState({ historyStack: newHistory });
  }

  @Action(SetOnMobile)
  setOnMobile(ctx: StateContext<AppStateModel>, { mobile }: SetOnMobile) {
    ctx.patchState({ mobile });
  }

  @Action(SetOnTablet)
  setOnTablet(ctx: StateContext<AppStateModel>, { tablet }: SetOnTablet) {
    ctx.patchState({ tablet });
  }
}
