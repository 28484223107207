import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { UiAvatarModule } from '@radioking/ui/avatar';

import { MobileUserMenuComponent } from './mobile-user-menu.component';

@NgModule({
  imports: [CommonModule, MatIconModule, TranslateModule, UiAvatarModule],
  declarations: [MobileUserMenuComponent],
  exports: [MobileUserMenuComponent],
})
export class UiMobileUserMenuModule {}
