import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Logger } from '@radioking/shared/logger';
import { Observable } from 'rxjs';

const log = new Logger('ApiDataTransformerInterceptor');

@Injectable()
export class ApiDataTransformerInterceptor implements HttpInterceptor {
  private static readonly UPDATE_HTTP_URLS = [
    environment.urls.MAIN_API_V2,
    environment.urls.WIDGET_API,
    environment.urls.WIDGET_API_V2,
    environment.urls.WIDGETS,
    environment.urls.WIDGETS_V2,
  ];
  private static readonly UPDATE_HTTP_METHODS = ['post', 'put', 'patch'];
  private static readonly UPDATE_HTTP_CONTENT_TYPE = 'application/json';

  /**
   * Transform a json request body, applying the following transformations:
   *
   * - Trim strings
   */
  static jsonReplacer(k: string, v: unknown): unknown {
    // Trim string
    if (typeof v === 'string') {
      return ApiDataTransformerInterceptor.trimString(k, v);
    }

    return v;
  }

  /**
   * Trim a string if needed
   *
   * See {@link jsonReplacer} for usage
   */
  static trimString(k: string, v: string): string {
    const trimmed = v.trim();

    if (v !== trimmed) {
      log.debug(`Transforming json property "${k}" from [${v}] to [${trimmed}]`);
    }

    return trimmed;
  }

  /**
   * Intercept http requests and apply some transformations on its body
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.matchRequest(request)) {
      log.debug(
        `Transforming json payload for ${request.method} ${request.urlWithParams}`,
      );

      const newRequest = request.clone({
        body: this.transformJson(request.body),
      });

      return next.handle(newRequest);
    }

    return next.handle(request);
  }

  /**
   * Whether specified request matches rules to apply transformations
   */
  matchRequest(request: HttpRequest<any>): boolean {
    return (
      // Http method matches
      ApiDataTransformerInterceptor.UPDATE_HTTP_METHODS.includes(
        request.method.toLowerCase(),
      ) &&
      // Request has body
      request.body &&
      // Matches api urls
      ApiDataTransformerInterceptor.UPDATE_HTTP_URLS.some(url =>
        request.url.startsWith(url),
      ) &&
      // Body is a json payload
      request.detectContentTypeHeader() ===
        ApiDataTransformerInterceptor.UPDATE_HTTP_CONTENT_TYPE
    );
  }

  /**
   * Transform a json request body
   *
   * See {@link jsonReplacer} for details
   */
  transformJson(json: any): any {
    try {
      return JSON.parse(JSON.stringify(json, ApiDataTransformerInterceptor.jsonReplacer));
    } catch (e) {
      return json;
    }
  }
}
