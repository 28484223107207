import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PLACEHOLDER } from '@app/shared/constants';
import { environment } from '@env/environment';
import moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  Consumption,
  DashboardOverview,
  Followers,
  Peak,
  Ranking,
  Schedule,
  Timeline,
  TracksWithSchedule,
} from '../models/Dashboard';
import {
  convertToDashboardOverview,
  DashboardOverviewApiModel,
} from '../models/dashboard-api.model';

export interface ConsumptionDataApiModel {
  usage: number;
  limit: number;
  usage_percent: number;
}

export interface ListenerTimeApiModel {
  count: number;
  date: string;
}

export interface ConsumptionApiModel {
  listening: ConsumptionDataApiModel;
  disk: ConsumptionDataApiModel;
  bandwidth: ConsumptionDataApiModel;
}

export interface PeakApiModel {
  peak: number;
  at: string;
}

interface FollowersApiModel {
  count: number;
  old_count: number;
  variation: number;
}

interface RankingApiModel {
  old_rank: number;
  rank: number;
  variation: number;
}

interface ScheduleApiModel {
  id: string;
  name: string;
  color: string;
  type: string;
  start_date?: string;
}

interface TracksWithScheduleApiModel {
  start_date: string;
  end_date: string;
  artist: string;
  title: string;
  cover_url: string;
  schedule: ScheduleApiModel;
}

interface TimelineApiModel {
  next_schedule: ScheduleApiModel;
  tracks: TracksWithScheduleApiModel[];
}

export const dashboardUrl = {
  getConsumption: (id: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${id}/consumption`,
  getListeners: (id: number, from: string) =>
    `${environment.urls.MAIN_API_V2}/radio/${id}/statistics/session/history?from=${from}&by=minute`,
  getPeak: (id: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${id}/statistics/session/peak`,
  getFollowers: (id: number) => `${environment.urls.MAIN_API_V2}/radio/${id}/follower`,
  getRanking: (id: number) => `${environment.urls.MAIN_API_V2}/radio/${id}/rank`,
  getOverview: (id: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${id}/statistics/overview/dashboard`,
  getTimeline: (id: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${id}/planning/timeline`,
};

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private readonly httpClient: HttpClient) {}

  getConsumption(radioId: number): Observable<Consumption> {
    return this.httpClient
      .get<ConsumptionApiModel>(dashboardUrl.getConsumption(radioId))
      .pipe(map(this.convertToConsumption));
  }

  getListeners(radioId: number, from: string): Observable<ListenerTimeApiModel[]> {
    return this.httpClient.get<ListenerTimeApiModel[]>(
      dashboardUrl.getListeners(radioId, encodeURIComponent(from)),
    );
  }

  getPeak(radioId: number): Observable<Peak> {
    return this.httpClient.get<PeakApiModel>(dashboardUrl.getPeak(radioId)).pipe(
      map(data => {
        return {
          listeners: data.peak,
          at: moment(data.at),
        };
      }),
    );
  }

  getFollowers(idRadio: number): Observable<Followers> {
    return this.httpClient
      .get<FollowersApiModel>(dashboardUrl.getFollowers(idRadio))
      .pipe(map(followers => this.convertToFollowers(followers)));
  }

  getRanking(idRadio: number): Observable<Ranking> {
    return this.httpClient
      .get<RankingApiModel>(dashboardUrl.getRanking(idRadio))
      .pipe(map(ranking => this.convertToRanking(ranking)));
  }

  getOverview(idRadio: number): Observable<DashboardOverview> {
    return this.httpClient
      .get<DashboardOverviewApiModel>(dashboardUrl.getOverview(idRadio))
      .pipe(
        map(overview => {
          return convertToDashboardOverview(overview);
        }),
      );
  }

  getTimeline(idRadio: number): Observable<Timeline | null> {
    return this.httpClient
      .get<TimelineApiModel>(dashboardUrl.getTimeline(idRadio))
      .pipe(map(data => this.convertToTimeline(data)));
  }

  convertToConsumption(data: ConsumptionApiModel): Consumption {
    return {
      listening: {
        limit: data.listening.limit,
        usage: data.listening.usage,
        usagePercent: data.listening.usage_percent,
      },
      bandwidth: {
        limit: data.bandwidth.limit,
        usage: data.bandwidth.usage,
        usagePercent: data.bandwidth.usage_percent,
      },
      disk: {
        limit: data.disk.limit,
        usage: data.disk.usage,
        usagePercent: data.disk.usage_percent,
      },
    };
  }

  convertToFollowers(followers: FollowersApiModel): Followers {
    return {
      followers: followers.count,
      oldFollowers: followers.old_count,
      variation: followers.variation,
    };
  }

  convertToRanking(ranking: RankingApiModel): Ranking {
    return {
      rank: ranking.rank,
      oldRank: ranking.old_rank,
      variation: ranking.variation,
    };
  }

  convertToTimeline(data: TimelineApiModel): Timeline | null {
    if (!data.tracks || data.tracks.length < 3) {
      return null;
    }

    return {
      isFake: false,
      nextSchedule: data.next_schedule && {
        id: data.next_schedule.id,
        name: data.next_schedule.name,
        color: data.next_schedule.color,
        type: data.next_schedule.type,
        startDate: moment(data.next_schedule.start_date),
      },
      previousTracks: data.tracks
        .slice(0, 2)
        .map(track => this.convertToTrackWithSchedule(track)),
      currentTrack: this.convertToTrackWithSchedule(data.tracks[2]),
      nextTracks: data.tracks
        .slice(3, 6)
        .map(track => this.convertToTrackWithSchedule(track)),
      schedules: this.convertToScheduleArray(data.tracks),
    };
  }

  convertToTrackWithSchedule(track: TracksWithScheduleApiModel): TracksWithSchedule {
    return {
      title: track.title,
      artist: track.artist,
      startDate: moment(track.start_date),
      endDate: moment(track.end_date),
      coverUrl: track.cover_url || PLACEHOLDER,
      duration: moment(track.end_date).diff(moment(track.start_date), 'seconds'),
      schedule: {
        ...track.schedule,
        startDate: moment(track.schedule.start_date),
      },
    };
  }

  convertToScheduleArray(tracks: TracksWithScheduleApiModel[]): Schedule[] {
    if (tracks) {
      const schedule: Schedule[] = [tracks[0].schedule];
      let scheduleSize = 0;
      let gridStartIndex = 1;
      tracks.forEach(track => {
        const lastSchedule = schedule[schedule.length - 1];
        if (track.schedule.id !== lastSchedule.id) {
          schedule[schedule.length - 1] = this.convertLastSchedule(
            lastSchedule,
            gridStartIndex,
            scheduleSize,
          );
          schedule.push(track.schedule);
          gridStartIndex = gridStartIndex + scheduleSize;
          scheduleSize = 1;
        } else {
          scheduleSize = scheduleSize + 1;
        }
      });
      if (gridStartIndex + scheduleSize < 7) {
        scheduleSize = 7 - gridStartIndex;
      }
      schedule[schedule.length - 1] = this.convertLastSchedule(
        schedule[schedule.length - 1],
        gridStartIndex,
        scheduleSize,
      );

      return schedule;
    }

    return null;
  }

  convertLastSchedule(
    schedule: ScheduleApiModel,
    startIndex: number,
    size: number,
    added = 0,
  ): Schedule {
    const newSchedule: Schedule = schedule;
    newSchedule.color = newSchedule.color
      ? newSchedule.color
      : newSchedule.type === 'break'
      ? '#9246FF'
      : '#508dff';
    newSchedule.repeat = size;
    newSchedule.class = `${startIndex} / span ${size + added}`;

    return newSchedule;
  }
}
