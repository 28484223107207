import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { UiAvatarModule } from '@radioking/ui/avatar';
import { UiButtonModule } from '@radioking/ui/button';
import { UiCustomModalWrapperModule } from '@radioking/ui/custom-modal-wrapper';
import { UiLoaderModule } from '@radioking/ui/loader';
import { UiMobileUserMenuModule } from '@radioking/ui/mobile-user-menu';

import { MobileSideHeaderComponent } from './mobile-side-header.component';

@NgModule({
  imports: [
    CommonModule,
    UiButtonModule,
    MatIconModule,
    TranslateModule,
    UiLoaderModule,
    UiMobileUserMenuModule,
    UiCustomModalWrapperModule,
    UiAvatarModule,
  ],
  declarations: [MobileSideHeaderComponent],
  exports: [MobileSideHeaderComponent],
})
export class UiMobileSideHeaderModule {}
