import { Injectable } from '@angular/core';
import { Radio, RadioSubscription, ShortRadio } from '@app/core/models/Radio';
import { whmcsCurrency } from '@app/core/models/radio-api.model';
import { RadioService, Readyable } from '@app/core/services/radio.service';
import { Stop } from '@app/core/states/audio.actions';
import { DisplaySlideshow } from '@app/core/states/auth.actions';
import {
  StartLiveTrack,
  StartSoftLiveTrack,
  StopSoftLiveTrack,
} from '@app/core/states/live-tracking.actions';
import {
  StartupRadioSuccess,
  UpdateSettingRadioSuccess,
} from '@app/core/states/setting.actions';
import { environment } from '@env/environment';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { GtmService, MobileSidenavService } from '@radioking/shared/common-services';
import moment from 'moment-timezone';
import { interval } from 'rxjs';
import {
  catchError,
  delay,
  filter,
  map,
  mergeMap,
  take,
  takeWhile,
  tap,
} from 'rxjs/operators';

import { ConsumptionRequest } from '../../../../../../libs/dashboard/src/lib/states/dashboard.actions';

import {
  ForceRefethingData,
  GetRadioFailure,
  GetRadioRequest,
  GetRadioSuccess,
  MyRadiosFailure,
  MyRadiosRequest,
  MyRadiosSuccess,
  RadioRestartRequest,
  RadioStartFailure,
  RadioStartRequest,
  RadioStartSuccess,
  RadioStopFailure,
  RadioStopRequest,
  RadioStopSuccess,
  RadioSubscriptionFailure,
  RadioSubscriptionRequest,
  RadioSubscriptionSuccess,
  SwitchToRadioRequest,
  SwitchToRadioRequestWithoutToast,
  UseSpecificRadio,
} from './radio.actions';

export class RadioStateModel {
  radioList: ShortRadio[];
  currentRadio: Radio;
  currentRadioId: number;
  isRadioReady: boolean;
  hasRadiosBeenFetched: boolean;
  subscription: RadioSubscription;
}

@State<RadioStateModel>({
  name: 'radio',
  defaults: {
    currentRadio: null,
    currentRadioId: 0,
    isRadioReady: true,
    radioList: [],
    hasRadiosBeenFetched: false,
    subscription: null,
  },
})
@Injectable()
export class RadioState {
  constructor(
    private readonly radioService: RadioService,
    private readonly gtmService: GtmService,
    private readonly mobileSidenavService: MobileSidenavService,
  ) {}
  @Selector()
  static radios(state: RadioStateModel): ShortRadio[] {
    return state.radioList || [];
  }

  @Selector()
  static firstRadioOwned(state: RadioStateModel): ShortRadio {
    return state.radioList.find(r => r.role === 'customer');
  }

  @Selector()
  static hasRadiosBeenFetched(state: RadioStateModel): boolean {
    return state.hasRadiosBeenFetched;
  }

  @Selector()
  static currentRadio(state: RadioStateModel): Radio {
    return state.currentRadio;
  }

  @Selector()
  static currentRadioId(state: RadioStateModel): number {
    return state.currentRadioId || undefined;
  }
  @Selector()
  static currentRadioSlug(state: RadioStateModel): string {
    return state.currentRadio?.slug;
  }
  @Selector()
  static currentRadioTimezone(state: RadioStateModel): string {
    return state.currentRadio?.timezone;
  }

  @Selector()
  static currentRadioStreamUrl(state: RadioStateModel): string {
    return `${environment.urls.LISTEN}${state.currentRadio?.slug}`;
  }

  @Selector()
  static currentRadioLogo(state: RadioStateModel): string {
    return state.currentRadio?.logo;
  }

  @Selector()
  static isOneOfMineRadios(state: RadioStateModel): boolean {
    return !state.radioList || state.currentRadioId === 0
      ? true
      : state.radioList.reduce<boolean>(
          (a, b) => a || b.id === state.currentRadioId,
          false,
        );
  }

  @Selector()
  static hasMoreThanOneRadio(state: RadioStateModel): boolean {
    return state.radioList && state.radioList.length > 1;
  }

  @Selector()
  static isRadioReady(state: RadioStateModel): boolean {
    return state.isRadioReady;
  }

  @Selector()
  static isCustomer(state: RadioStateModel): boolean {
    return (
      state.radioList.find(radio => radio.id === state.currentRadioId).role === 'customer'
    );
  }

  @Selector()
  static billing(state: RadioStateModel): string {
    return state.subscription.billing;
  }

  @Selector()
  static currency(state: RadioStateModel): whmcsCurrency {
    return state.subscription.currency;
  }

  @Selector()
  static radioPageAvailable(state: RadioStateModel): boolean {
    return state.subscription.options.radioPage;
  }

  @Selector()
  static updateOfferUrl(state: RadioStateModel): string {
    return `${environment.urls.WHMCS}/upgrade.php?type=package&id=${state.subscription.serviceId}`;
  }

  @Selector()
  static changeOptionsUrl(state: RadioStateModel): string {
    return `${environment.urls.WHMCS}/upgrade.php?type=configoptions&id=${state.subscription.serviceId}`;
  }

  @Selector()
  static newRadio(state: RadioStateModel): boolean {
    const createdAt = state.currentRadio?.createdAt;
    if (createdAt) {
      return moment().diff(createdAt, 'days') < 31;
    }

    return false;
  }

  @Selector()
  static demoPeriodRadio(state: RadioStateModel): boolean {
    const createdAt = state.currentRadio?.createdAt;
    if (createdAt) {
      return moment().diff(createdAt, 'days') < 15;
    }

    return false;
  }

  @Selector()
  static firstHour(state: RadioStateModel): boolean {
    const createdAt = state.currentRadio?.createdAt;
    if (createdAt) {
      return moment().diff(createdAt, 'hours') < 1;
    }

    return false;
  }

  @Selector()
  static currentRadioLifetime(state: RadioStateModel): number {
    const createdAt = state.currentRadio?.createdAt;
    if (createdAt) {
      return moment().diff(createdAt, 'days');
    }

    return 0;
  }

  @Selector()
  static currentRadioSameMonth(state: RadioStateModel): boolean {
    const createdAt = state.currentRadio?.createdAt;
    if (createdAt) {
      return moment().isSame(createdAt, 'month');
    }

    return true;
  }

  @Action(MyRadiosRequest)
  getMyRadios(ctx: StateContext<RadioStateModel>, { userId }: MyRadiosRequest) {
    return this.radioService.getAllMyRadios(userId).pipe(
      mergeMap(data => ctx.dispatch(new MyRadiosSuccess(data))),
      catchError(err => ctx.dispatch(new MyRadiosFailure(err))),
    );
  }

  @Action(MyRadiosSuccess)
  getMyRadioSuccess(ctx: StateContext<RadioStateModel>, { radios }: MyRadiosSuccess) {
    ctx.patchState({
      radioList: radios.sort(this.sortRadios),
      hasRadiosBeenFetched: true,
    });
    if (!radios || radios.length === 0) {
      ctx.dispatch(new Navigate(['/no-radio'], {}, { replaceUrl: true }));
    }
  }

  @Action(StartupRadioSuccess)
  startupRadioSuccess(
    ctx: StateContext<RadioStateModel>,
    { id, setting }: StartupRadioSuccess,
  ) {
    ctx.setState(
      patch({
        radioList: ctx.getState().radioList.map(shortRadio => {
          shortRadio.name = id === shortRadio.id ? setting.name : shortRadio.name;

          return shortRadio;
        }),
        currentRadio: patch({
          name: setting.name,
          timezone: setting.timezone,
          slug: setting.slug,
        }),
      }),
    );
    if (setting) {
      ctx.dispatch(new Navigate([`/radio/${id}/dashboard`], {}, { replaceUrl: true }));
      setTimeout(() => ctx.dispatch(new DisplaySlideshow()), 500);
    }
  }

  @Action([SwitchToRadioRequest, SwitchToRadioRequestWithoutToast])
  switchToRadio(
    ctx: StateContext<RadioStateModel>,
    { idRadio, goToHome }: SwitchToRadioRequest | SwitchToRadioRequestWithoutToast,
  ) {
    ctx.patchState({ currentRadioId: idRadio });
    ctx.dispatch(new ForceRefethingData());
    if (goToHome) {
      ctx.dispatch(new Navigate(['radio', idRadio]));
    }
    this.mobileSidenavService.close();

    return ctx.dispatch(new UseSpecificRadio(idRadio));
  }

  @Action(UseSpecificRadio)
  setCurrentRadio(ctx: StateContext<RadioStateModel>, { idRadio }: UseSpecificRadio) {
    ctx.patchState({ currentRadioId: idRadio });
    ctx.dispatch(new Stop());
    ctx.dispatch(new ConsumptionRequest());
    ctx.dispatch(new RadioSubscriptionRequest(idRadio));

    return ctx.dispatch(new GetRadioRequest(idRadio));
  }

  @Action(GetRadioRequest)
  getRadio(ctx: StateContext<RadioStateModel>, { id }: GetRadioRequest) {
    return this.radioService.getRadio(id).pipe(
      mergeMap(radio => ctx.dispatch(new GetRadioSuccess(id, radio))),
      catchError(err => ctx.dispatch(new GetRadioFailure(err))),
    );
  }

  @Action(GetRadioSuccess)
  getRadioSuccess(ctx: StateContext<RadioStateModel>, { id, radio }: GetRadioSuccess) {
    this.gtmService.pushToDataLayer({
      date_creation_id_radio: radio.createdAt.format(),
    });
    ctx.patchState({
      isRadioReady: true,
      currentRadio: radio,
    });
    ctx.dispatch(new StartLiveTrack(id));
  }

  @Action(UpdateSettingRadioSuccess)
  updateSettingRadioSuccess(
    ctx: StateContext<RadioStateModel>,
    { setting }: UpdateSettingRadioSuccess,
  ) {
    ctx.setState(
      patch({
        currentRadio: patch({
          name: setting.name,
          logo: setting.logo,
          slug: setting.slug,
          timezone: setting.timezone,
        }),
      }),
    );
  }

  @Action(RadioSubscriptionRequest)
  getRadioSubscription(
    ctx: StateContext<RadioStateModel>,
    { id }: RadioSubscriptionRequest,
  ) {
    return this.radioService.getRadioSubscription(id).pipe(
      mergeMap(data => ctx.dispatch(new RadioSubscriptionSuccess(data))),
      catchError(err => ctx.dispatch(new RadioSubscriptionFailure(err))),
    );
  }

  @Action(RadioSubscriptionSuccess)
  getRadioSubscriptionSuccess(
    ctx: StateContext<RadioStateModel>,
    { subscription }: RadioSubscriptionSuccess,
  ) {
    ctx.setState(
      patch({
        subscription,
      }),
    );
  }

  @Action(RadioStartRequest)
  startRadioRequest(ctx: StateContext<RadioStateModel>) {
    const radioId = ctx.getState().currentRadioId;
    ctx.patchState({ isRadioReady: false });

    return this.radioService.startRadio(radioId).pipe(
      tap(() => {
        this.checkReady(ctx, radioId, 1);
      }),
      catchError(err => ctx.dispatch(new RadioStartFailure(err))),
    );
  }

  checkReady(ctx: StateContext<RadioStateModel>, radioID: number, attempt: number) {
    if (attempt > 10) {
      ctx.dispatch(new RadioStartSuccess(radioID));

      return;
    }
    this.radioService
      .isRadioReady(radioID)
      .pipe(delay(2000), take(1))
      .subscribe(val => {
        if (val.ready) {
          ctx.dispatch(new RadioStartSuccess(radioID));
        } else {
          this.checkReady(ctx, radioID, attempt + 1);
        }
      });
  }

  @Action(RadioStartSuccess)
  startRadioSuccess(ctx: StateContext<RadioStateModel>, { idRadio }: RadioStartSuccess) {
    ctx.patchState({ isRadioReady: true });
    ctx.dispatch(new StartLiveTrack(idRadio));
  }

  @Action(RadioStopRequest)
  stopRadio(ctx: StateContext<RadioStateModel>) {
    ctx.dispatch(new Stop());
    const radioId = ctx.getState().currentRadioId;

    return this.radioService.stopRadio(radioId).pipe(
      mergeMap(() => ctx.dispatch(new RadioStopSuccess())),
      catchError(err => ctx.dispatch(new RadioStopFailure(err))),
    );
  }

  @Action(RadioStopSuccess)
  stopRadioSuccess(ctx: StateContext<RadioStateModel>) {
    ctx.dispatch(new StartSoftLiveTrack(ctx.getState().currentRadioId));
  }

  @Action(RadioRestartRequest)
  restartRadio(ctx: StateContext<RadioStateModel>) {
    let isActive = false;
    let tries = 0;
    const radioId = ctx.getState().currentRadioId;
    ctx.patchState({ isRadioReady: false });
    ctx.dispatch(new StopSoftLiveTrack());

    return this.radioService.restartRadio(radioId).pipe(
      mergeMap(() => interval(2000)),
      takeWhile(() => !isActive),
      mergeMap(() => this.radioService.isRadioReady(radioId)),
      map((val: Readyable) => {
        tries = tries + 1;
        if (tries > 10) {
          return true;
        }

        return val.ready;
      }),
      filter(val => val),
      tap(() => {
        isActive = true;
      }),
      mergeMap(() =>
        ctx.dispatch([new RadioStartSuccess(radioId), new StartSoftLiveTrack(radioId)]),
      ),
      catchError(err => ctx.dispatch(new RadioStartFailure(err))),
    );
  }

  sortRadios(next: ShortRadio, prev: ShortRadio) {
    if (prev.role !== 'customer' && next.role === 'customer') {
      return -1;
    }
    if (prev.role === 'customer' && next.role !== 'customer') {
      return 1;
    }

    return 0;
  }
}
