<div class="arrow-info-onboarding">
  <svg viewBox="0 0 73 140" xmlns="http://www.w3.org/2000/svg">
    <path
      class="arrow-onboarding arrow-main-branche"
      d="M2 6.18359C29.4117 12.8388 80.4237 47.8085 65.1776 134.445"
    />
    <line
      class="arrow-onboarding arrow-right-branche"
      x1="1.318"
      y1="5.622"
      x2="7.622"
      y2="1.682"
    />
    <line
      class="arrow-onboarding arrow-left-branche"
      x1="1.31365"
      y1="6.52375"
      x2="4.24972"
      y2="13.3534"
    />
  </svg>
  <div class="arrow-info-onboarding-content text-black-new">
    <p class="arrow-info-onboarding-text" [innerHTML]="description"></p>
  </div>
</div>
