import { marker } from '@colsen1991/ngx-translate-extract-marker';

export interface Genre {
  id: number;
  name: string;
  slug: string;
  parentId: number;
  translation: string;
}

export function getGenreTranslationKey(slug: string): string {
  switch (slug) {
    case 'classic-blues':
      return marker('GENRE.BLUES.CLASSIC');
    case 'acoustic-blues':
      return marker('GENRE.BLUES.ACOUSTIC');
    case 'blues-rock':
      return marker('GENRE.BLUES.ROCK');
    case 'chicago-blues':
      return marker('GENRE.BLUES.CHICAGO');
    case 'country-blues':
      return marker('GENRE.BLUES.COUNTRY');
    case 'delta-blues':
      return marker('GENRE.BLUES.DELTA');
    case 'baroque':
      return marker('GENRE.CLASSIC.BAROQUE');
    case 'medieval':
      return marker('GENRE.CLASSIC.MEDIEVAL');
    case 'modern':
      return marker('GENRE.CLASSIC.MODERN');
    case 'opera':
      return marker('GENRE.CLASSIC.OPERA');
    case 'renaissance':
      return marker('GENRE.CLASSIC.REBIRTH');
    case 'romantic':
      return marker('GENRE.CLASSIC.ROMANTIC');
    case 'bluegrass':
      return marker('GENRE.COUNTRY.BLUEGRASS');
    case 'alternative-country':
      return marker('GENRE.COUNTRY.ALTERNATIVE');
    case 'classic-country':
      return marker('GENRE.COUNTRY.CLASSIC');
    case 'pop-country':
      return marker('GENRE.COUNTRY.POP');
    case 'rockabilly':
      return marker('GENRE.COUNTRY.ROCKABILLY');
    case 'other':
      return marker('GENRE.MISC.OTHER');
    case 'youth':
      return marker('GENRE.MISC.YOUNTH');
    case 'love-slow':
      return marker('GENRE.MISC.LOVE_SLOW');
    case 'film-music':
      return marker('GENRE.MISC.FILM_MUSIC');
    case 'vintage':
      return marker('GENRE.MISC.VINTAGE');
    case 'zen':
      return marker('GENRE.MISC.ZEN');
    case 'danse-clubbing':
      return marker('GENRE.ELECTRO.CLUBBING');
    case 'electro':
      return marker('GENRE.ELECTRO.ELECTRO');
    case 'house':
      return marker('GENRE.ELECTRO.HOUSE');
    case 'lounge-ambiant':
      return marker('GENRE.ELECTRO.LOUNGE');
    case 'techno':
      return marker('GENRE.ELECTRO.TECHNO');
    case 'trip-hop':
      return marker('GENRE.ELECTRO.TRIP_HOP');
    case 'gold':
      return marker('GENRE.HITS.GOLD');
    case 'hits':
      return marker('GENRE.HITS.HITS');
    case 'hits-60s':
      return marker('GENRE.HITS.60');
    case 'hits-70s':
      return marker('GENRE.HITS.70');
    case 'hits-80s':
      return marker('GENRE.HITS.80');
    case 'hits-90s':
      return marker('GENRE.HITS.90');
    case 'hits-french':
      return marker('GENRE.HITS.FRENCH');
    case 'hits-uk-usa':
      return marker('GENRE.HITS.UK_USA');
    case 'news':
      return marker('GENRE.INFOS.NEWS');
    case 'culture':
      return marker('GENRE.INFOS.CULTURE');
    case 'humor':
      return marker('GENRE.INFOS.HUMOR');
    case 'local':
      return marker('GENRE.INFOS.LOCAL');
    case 'politic':
      return marker('GENRE.INFOS.POLITIC');
    case 'religion':
      return marker('GENRE.INFOS.RELIGION');
    case 'health':
      return marker('GENRE.INFOS.HEALTH');
    case 'sport':
      return marker('GENRE.INFOS.SPORT');
    case 'bebop':
      return marker('GENRE.JAZZ.BEBOP');
    case 'big-band':
      return marker('GENRE.JAZZ.BIG_BAND');
    case 'contemporary-jazz':
      return marker('GENRE.JAZZ.CONTEMPORARY');
    case 'instrumental-jazz':
      return marker('GENRE.JAZZ.INSTRUMENTAL');
    case 'vocal-jazz':
      return marker('GENRE.JAZZ.VOCAL');
    case 'smooth-jazz':
      return marker('GENRE.JAZZ.SMOOTH');
    case 'swing':
      return marker('GENRE.JAZZ.SWING');
    case 'black-metal':
      return marker('GENRE.METAL.BLACK');
    case 'doom-metal':
      return marker('GENRE.METAL.DOOM');
    case 'heavy-metal':
      return marker('GENRE.METAL.HEAVY');
    case 'industrial-metal':
      return marker('GENRE.METAL.INDUSTRIAL');
    case 'progressive-metal':
      return marker('GENRE.METAL.PROGRESSIVE');
    case 'power-metal':
      return marker('GENRE.METAL.POWER');
    case 'speed-metal':
      return marker('GENRE.METAL.SPEED');
    case 'britpop':
      return marker('GENRE.POP_ROCK.BRITPOP');
    case 'folk':
      return marker('GENRE.POP_ROCK.FOLK');
    case 'indie':
      return marker('GENRE.POP_ROCK.INDIE');
    case 'new-wave':
      return marker('GENRE.POP_ROCK.NEW_WAVE');
    case 'pop':
      return marker('GENRE.POP_ROCK.POP');
    case 'french-pop-rock':
      return marker('GENRE.POP_ROCK.FRENCH');
    case 'rock':
      return marker('GENRE.POP_ROCK.ROCK');
    case 'classic-rock':
      return marker('GENRE.POP_ROCK.CLASSIC_ROCK');
    case 'dancehall':
      return marker('GENRE.REGGAE.DANCEHALL');
    case 'dub':
      return marker('GENRE.REGGAE.DUB');
    case 'reggae':
      return marker('GENRE.REGGAE.REGGAE');
    case 'ska':
      return marker('GENRE.REGGAE.SKA');
    case 'disco':
      return marker('GENRE.URBAIN.DISCO');
    case 'funk':
      return marker('GENRE.URBAIN.FUNK');
    case 'gospel':
      return marker('GENRE.URBAIN.GOSPEL');
    case 'hip-hop':
      return marker('GENRE.URBAIN.HIP_HOP');
    case 'rap':
      return marker('GENRE.URBAIN.RAP');
    case 'rnb':
      return marker('GENRE.URBAIN.RNB');
    case 'soul':
      return marker('GENRE.URBAIN.SOUL');
    case 'afric':
      return marker('GENRE.WORLD.AFRICA');
    case 'central-america':
      return marker('GENRE.WORLD.CENTRAL_AMERICA');
    case 'north-america':
      return marker('GENRE.WORLD.NORTH_AMERICA');
    case 'south-america':
      return marker('GENRE.WORLD.SOUTH_AMERICA');
    case 'asia':
      return marker('GENRE.WORLD.ASIA');
    case 'australia-pacifica':
      return marker('GENRE.WORLD.PACIFICA');
    case 'brasil':
      return marker('GENRE.WORLD.BRASIL');
    case 'china':
      return marker('GENRE.WORLD.CHINA');
    case 'europe':
      return marker('GENRE.WORLD.EUROPE');
    case 'india':
      return marker('GENRE.WORLD.INDIA');
    case 'maghreb':
      return marker('GENRE.WORLD.MAGHREB');
    case 'middle-east':
      return marker('GENRE.WORLD.MIDDLE_EAST');
  }

  return slug;
}
