import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ArchivedTrackApiModel } from '@app/library/models/trash-api.model';
import { ArchivedTrack } from '@app/library/models/trash.model';
import { PLACEHOLDER } from '@app/shared/constants';
import { environment } from '@env/environment';
import moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const trashUrls = {
  getArchivedTracks: (radioId: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${radioId}/trash`,
  deleteArchivedTracks: (radioId: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${radioId}/trash/remove`,
  restoreArchivedTracks: (radioId: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${radioId}/trash/restore`,
};

@Injectable({
  providedIn: 'root',
})
export class TrashService {
  constructor(private readonly httpClient: HttpClient) {}

  getArchivedTracks(radioId: number): Observable<ArchivedTrack[]> {
    return this.httpClient
      .get<ArchivedTrackApiModel[]>(trashUrls.getArchivedTracks(radioId))
      .pipe(
        map(apiArchivedTracks => this.convertMultipleToArchivedTrack(apiArchivedTracks)),
      );
  }

  deleteArchivedTracks(idRadio: number, idArchivedTracks: number[]): Observable<any> {
    return this.httpClient.post(trashUrls.deleteArchivedTracks(idRadio), {
      tracks: idArchivedTracks,
    });
  }

  restoreArchivedTracks(idRadio: number, idArchivedTracks: number[]): Observable<any> {
    return this.httpClient.post(trashUrls.restoreArchivedTracks(idRadio), {
      tracks: idArchivedTracks,
    });
  }

  convertMultipleToArchivedTrack(
    apiArchivedTracks: ArchivedTrackApiModel[],
  ): ArchivedTrack[] {
    return apiArchivedTracks.map(apiArchivedTrack =>
      this.convertToArchivedTrack(apiArchivedTrack),
    );
  }

  convertToArchivedTrack(archivedTrack: ArchivedTrackApiModel): ArchivedTrack {
    return {
      idTrackbox: archivedTrack.idbox,
      id: archivedTrack.idtrack,
      title: archivedTrack.title || '',
      artist: archivedTrack.artist || '',
      album: archivedTrack.album || '',
      cover: archivedTrack.cover_url || PLACEHOLDER,
      isCustomCover: archivedTrack.is_custom_cover,
      file: archivedTrack.audio_url,
      year: archivedTrack.year,
      buyLink: archivedTrack.buy_link,
      bpm: archivedTrack.bpm,
      uploadDate: moment(archivedTrack.upload_date),
      bitrate: archivedTrack.bitrate,
      timeSeconds: archivedTrack.playtime,
      locked: archivedTrack.locked,
      tags:
        archivedTrack.tags?.map(data => ({
          id: data.idtag,
          name: data.name,
          color: data.color,
        })) ?? [],
      playlists:
        archivedTrack.playlists?.map(data => ({
          id: data.idplaylist,
          name: data.name,
          color: data.color,
          type: data.type,
        })) ?? [],
      archivedAt: moment(archivedTrack.archived_at),
    };
  }
}
