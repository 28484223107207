import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { LiveTrackingState } from '@app/core/states/live-tracking.state';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import {
  RADIO_PLAN_DESC,
  RADIO_PLAN_ORDER,
} from '../../apps/manager/src/app/core/models/Radio';

interface InputCondition {
  plan: RADIO_PLAN_DESC;
  condition: 'greater' | 'less' | 'equal' | 'different' | 'less_equal';
}

@Directive({
  selector: '[rkHasPlan]',
})
export class HasPlanDirective implements OnInit, OnDestroy {
  @Input('rkHasPlan')
  inputCondition: InputCondition;

  private _otherTemplate: TemplateRef<any>;

  subscription: Subscription;

  isShown = false;

  hasNewElse = false;

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly store: Store,
  ) {}

  ngOnInit(): void {
    this.subscription = this.store.select(LiveTrackingState.planDesc).subscribe(plan => {
      const selectedPlanOrder = RADIO_PLAN_ORDER.indexOf(this.inputCondition.plan);
      const radioPlanOrder = RADIO_PLAN_ORDER.indexOf(plan);
      switch (this.inputCondition.condition || 'equal') {
        case 'equal': {
          if (plan === this.inputCondition.plan) {
            this.showContent();
          } else {
            this.hideContent();
          }
          break;
        }
        case 'greater': {
          if (radioPlanOrder > selectedPlanOrder) {
            this.showContent();
          } else {
            this.hideContent();
          }
          break;
        }
        case 'less': {
          if (radioPlanOrder < selectedPlanOrder) {
            this.showContent();
          } else {
            this.hideContent();
          }
          break;
        }

        case 'less_equal': {
          if (radioPlanOrder <= selectedPlanOrder) {
            this.showContent();
          } else {
            this.hideContent();
          }
          break;
        }
        case 'different': {
          if (radioPlanOrder !== selectedPlanOrder) {
            this.showContent();
          } else {
            this.hideContent();
          }
          break;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  showContent() {
    if (this.isShown) {
      return;
    }
    this.isShown = true;
    if (this._otherTemplate) {
      this.viewContainer.clear();
    }
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  hideContent() {
    if (!this.isShown && !this.hasNewElse) {
      return;
    }
    this.isShown = false;
    this.viewContainer.clear();
    if (this._otherTemplate) {
      this.viewContainer.createEmbeddedView(this._otherTemplate);
    }
  }
}
