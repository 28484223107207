import { CdkScrollable } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SugestionInput } from '@radioking/ui/input';
import { isEqual } from 'lodash';
import { BehaviorSubject, fromEvent, merge, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
  selector: 'rk-input-with-suggestions',
  templateUrl: './input-with-suggestions.component.html',
  styleUrls: ['./input-with-suggestions.component.scss'],
})
export class InputWithSuggestionsComponent implements OnInit, AfterViewInit {
  @ViewChild('inputRef', { static: true })
  inputRef: ElementRef<HTMLInputElement>;

  @ViewChild('dropdownRef', { static: true })
  dropdownRef: ElementRef<HTMLDivElement>;

  @ViewChild(CdkScrollable) cdkScrollable: CdkScrollable;

  @Input()
  renderLineTemplate: TemplateRef<any>;

  @Input()
  label: string;

  @Input()
  placeholder = '';

  @Input()
  disabled: boolean;

  @Input()
  inModal = false;

  @Input()
  isAbsolute = false;

  @Input()
  shouldClearOnSubmit = true;

  @Input()
  addNewCaseSensitive = false;

  @Input()
  debounceTime = 300;

  @Input()
  minLengthTrigger = 0;

  @Input()
  isLoadingData = false;

  @Input()
  maxInputLength = 524288;

  @Input()
  hintLabel: string;

  @Input()
  hintQuestionMark: string;

  @Input()
  displayIfEmpty = true;

  @Input()
  isTrack = false;

  @Input()
  displayClearButton = false;

  @Input()
  hasIcon = false;

  @Input()
  set dataFetched(data: any[]) {
    if (isEqual(this._dataFeteched, data)) {
      return;
    }
    this._dataFeteched = data;
    this.minHeight = `${Math.min(data.length * 30, 190)}px`;
    if (data && data.length === 0 && this.canAddNew) {
      this.currentSelected = -1;
    } else {
      this.currentSelected = 0;
    }
  }

  get dataFetched(): any[] {
    return this._dataFeteched;
  }

  @Input()
  renderInInputFn: (data: any) => string;

  @Input()
  canAddNew = true;

  @Input()
  isTag = false;

  @Output()
  readonly submitted = new EventEmitter<any>();

  @Output()
  readonly changed = new EventEmitter<string>();

  @Output()
  readonly blurred = new EventEmitter<any>();

  tagColors = [
    '#FF7F50',
    '#FF4757',
    '#70A1FF',
    '#5352ED',
    '#7BED9F',
    '#2ED573',
    '#FD79A8',
    '#FFA801',
    '#0FBCF9',
    '#9F6DF3',
    '#5019AC',
    '#FFC862',
  ];

  @Input()
  set currentValue(data: SugestionInput | any) {
    this.inputRef.nativeElement.value = this.renderInInputFn(data);
    this.inputRef.nativeElement.blur();
  }

  @HostBinding('class.should-fake-hover')
  shouldFakeHover = true;

  canShowDrop = true;

  minHeight = '0px';

  currentSelected = 0;

  private _dataFeteched: SugestionInput[];
  private edited = false;

  inputWidth$: Observable<string>;

  private readonly inputWidth = new BehaviorSubject<number>(0);

  constructor(private readonly translateService: TranslateService) {
    if (!this.renderInInputFn) {
      this.renderInInputFn = (data: SugestionInput) => {
        if (!data) {
          return '-';
        }

        return data.translation
          ? this.translateService.instant(data.translation)
          : data.name;
      };
    }
  }

  ngOnInit() {
    this.initInputEvents();
    this.initDropdownEvents();
    this.inputWidth$ = this.inputWidth.asObservable().pipe(
      distinctUntilChanged(),
      debounceTime(100),
      map(data => `${data}px`),
    );
  }

  ngAfterViewInit() {
    this.inputWidth.next(this.inputRef.nativeElement.offsetWidth);
    setTimeout(() => {
      this.inputWidth.next(this.inputRef.nativeElement.offsetWidth);
    }, 1000);
  }

  focusInput() {
    this.inputWidth.next(this.inputRef.nativeElement.offsetWidth);
  }

  private initInputEvents() {
    const inputEvents$ = fromEvent(this.inputRef.nativeElement, 'keyup').pipe(
      filter((evt: Event) => evt.target === document.activeElement),
      map<any, string>((evt: any) => evt.target.value),
      filter(res => res.length >= this.minLengthTrigger),
    );

    // Show drop on input, but not trigger on the empty state, and on focus
    merge(
      inputEvents$.pipe(filter(val => val !== '')),
      fromEvent(this.inputRef.nativeElement, 'focus'),
    ).subscribe(() => {
      this.canShowDrop = true;
    });

    // Send event to parent with debounce time
    inputEvents$
      .pipe(debounceTime(this.debounceTime), distinctUntilChanged())
      .subscribe(txt => {
        this.changed.emit(txt);
        if (this.cdkScrollable) {
          this.cdkScrollable.scrollTo({ top: 0 });
        }
      });
  }

  public onType() {
    this.edited = true;
  }

  private initDropdownEvents() {
    fromEvent(this.dropdownRef.nativeElement, 'mouseenter').subscribe(() => {
      this.shouldFakeHover = false;
    });
    fromEvent(this.dropdownRef.nativeElement, 'mouseleave').subscribe(() => {
      this.shouldFakeHover = true;
    });
  }

  onArrowUp() {
    let scrollOffset = 0;
    if (this.currentSelected === 0) {
      if (this.canAddNew && this.showAddNewBlock(this.inputRef.nativeElement.value)) {
        this.currentSelected = -1;
      } else {
        this.currentSelected = this.dataFetched.length - 1;
      }
      scrollOffset = this.dataFetched.length * 28;
    } else if (this.currentSelected === -1) {
      this.currentSelected = this.dataFetched.length - 1;
    } else {
      this.currentSelected = this.currentSelected - 1;
      scrollOffset = (this.currentSelected - 1) * 30;
    }
    if (this.cdkScrollable) {
      this.cdkScrollable.scrollTo({ top: scrollOffset });
    }
  }

  onArrowDown() {
    let scrollOffset = 0;
    if (this.currentSelected === this.dataFetched.length - 1) {
      if (this.canAddNew && this.showAddNewBlock(this.inputRef.nativeElement.value)) {
        this.currentSelected = -1;
      } else {
        this.currentSelected = 0;
      }
    } else {
      this.currentSelected = this.currentSelected + 1;
      scrollOffset = (this.currentSelected - 1) * 30;
    }
    if (this.cdkScrollable) {
      this.cdkScrollable.scrollTo({ top: scrollOffset });
    }
  }

  focusToSpecific(id: number) {
    this.currentSelected = id;
  }

  showAddNewBlock(val: string) {
    if (!val || val.trim().length === 0) {
      return false;
    }
    if (this.dataFetched) {
      if (!this.addNewCaseSensitive) {
        return !this.dataFetched
          .map(this.renderInInputFn)
          .map(a => a.toLowerCase())
          .includes(val.toLowerCase());
      }

      return !this.dataFetched.map(this.renderInInputFn).includes(val);
    }

    return this.canAddNew;
  }

  addSpecificItem(item: SugestionInput): void {
    this.emitSelectedInput(item);
  }

  addCurrentInput(event: Event) {
    event.stopPropagation();
    event.preventDefault();

    const inputValue = this.inputValue;

    if (!inputValue && !this.allowEmpty()) {
      return;
    }

    if (this.currentSelected === -1 && this.canAddNew) {
      this.emitSelectedInput(
        this.isTag
          ? {
              color: this.getRandomColor(),
              name: inputValue,
              id: 0,
            }
          : {
              name: inputValue,
              id: 0,
            },
      );
    } else if (this.dataFetched && this.dataFetched.length > 0) {
      this.emitSelectedInput(this.dataFetched[this.currentSelected]);
    } else {
      return;
    }

    if (!this.shouldClearOnSubmit) {
      this.inputRef.nativeElement.blur();
    }
  }

  addFromPlusButton() {
    if (this.canAddNew) {
      this.emitSelectedInput(
        this.isTag
          ? {
              color: this.getRandomColor(),
              name: this.inputValue,
              id: 0,
            }
          : {
              name: this.inputValue,
              id: 0,
            },
      );
    }
  }

  canShowDropdown(inputValue: string): boolean {
    if (!this.canShowDrop) {
      return false;
    }
    if (!inputValue && !this.displayIfEmpty) {
      return false;
    }
    if (!this.displayIfEmpty && !this.edited) {
      return false;
    }
    if (this.dataFetched && this.dataFetched.length > 0) {
      return true;
    }
    if (this.canAddNew && this.showAddNewBlock(inputValue)) {
      return true;
    }

    return false;
  }

  private emitSelectedInput(val: SugestionInput): void {
    if (this.shouldClearOnSubmit) {
      this.inputRef.nativeElement.value = '';
    } else {
      this.inputRef.nativeElement.value = this.renderInInputFn(val);
    }
    this.submitted.emit(val);
    this.canShowDrop = false;
    this.edited = false;
  }

  public get inputValue() {
    return this.inputRef.nativeElement.value.trim();
  }

  forceFocusInput() {
    this.inputRef.nativeElement.focus();
  }

  clearInput() {
    this.inputRef.nativeElement.value = '';
    this.inputRef.nativeElement.focus();
    this.inputRef.nativeElement.blur();
  }

  getRandomColor(): string {
    return this.tagColors[Math.floor(Math.random() * this.tagColors.length)];
  }

  allowEmpty(): boolean {
    return !this.isTag;
  }
}
