import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { SharedUnsavedChangesModule } from '@radioking/shared/unsaved-changes';
import { UiButtonModule } from '@radioking/ui/button';
import { UiLoaderModule } from '@radioking/ui/loader';
import { UiMobileSideHeaderModule } from '@radioking/ui/mobile-side-header';
import { UiPerfectScrollBarModule } from '@radioking/ui/perfect-scroll-bar';

import { DirectivesModule } from '../../../../directives/directives.module';
import { UiHotkeysHelpModule } from '../../../../ui/hotkeys-help/src';

import { MobileRightPageWrapperComponent } from './components/mobile-right-page-wrapper/mobile-right-page-wrapper.component';
import { PanelContainerComponent } from './components/panel-container/panel-container.component';
import { PanelOutsideContentComponent } from './components/panel-outside-content/panel-outside-content.component';
import { SidenavActionWrapperComponent } from './components/sidenav-action-wrapper/sidenav-action-wrapper.component';
import { DynamicPanelAnchorDirective } from './directive/dynamic-panel-anchor.directive';
import { PanelsState } from './states/panels.state';

@NgModule({
  declarations: [
    DynamicPanelAnchorDirective,
    PanelContainerComponent,
    PanelOutsideContentComponent,
    SidenavActionWrapperComponent,
    MobileRightPageWrapperComponent,
  ],
  imports: [
    CommonModule,
    SharedUnsavedChangesModule,
    TranslateModule,
    UiPerfectScrollBarModule,
    MatSidenavModule,
    MatIconModule,
    UiButtonModule,
    UiLoaderModule,
    NgxsModule.forFeature([PanelsState]),
    DirectivesModule,
    UiHotkeysHelpModule,
    UiMobileSideHeaderModule,
  ],
  exports: [
    DynamicPanelAnchorDirective,
    PanelContainerComponent,
    PanelOutsideContentComponent,
    SidenavActionWrapperComponent,
    MobileRightPageWrapperComponent,
  ],
})
export class SharedPanelManagerModule {}
