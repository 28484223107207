<div
  class="onboarding-checklist"
  [class.hide]="currentPanel$ | async"
  [class.reduced]="!openedAnimation$?.value"
>
  <mat-accordion>
    <mat-expansion-panel
      #mainPanel
      class="parent-panel"
      hideToggle
      [expanded]="opened$ | async"
      (afterExpand)="afterExpand()"
      (afterCollapse)="afterCollapse()"
    >
      <mat-expansion-panel-header (click)="startOpen()">
        <div class="header">
          <div class="title text-lg flex items-center">
            {{ 'CHECKLIST.HEADER.START' | translate }}
            <span
              class="text-black-new bg-white text-sm font-normal py-0.5 px-2.5 rounded-full ml-1.5"
            >
              {{ (steps$ | async).length - (notCompletedSteps$ | async) }}/{{
                (steps$ | async).length
              }}
            </span>
          </div>
          <div class="flex items-center">
            <rk-icon-button
              class="pointer-events-none"
              [faIcon]="(opened$ | async) ? 'fa-angle-down' : 'fa-angle-up'"
              color="white"
              [size]="26"
            ></rk-icon-button>
            <rk-icon-button
              *ngIf="(progression$ | async) > 99 && (opened$ | async)"
              [faIcon]="'fa-times'"
              color="white"
              [size]="26"
              (click)="destroyChecklist(false)"
            ></rk-icon-button>
          </div>
        </div>
      </mat-expansion-panel-header>
      <div
        class="content-header relative p-5"
        [class.completed]="(progression$ | async) === 100"
      >
        <ng-container>
          <div class="header-title text-xl font-bold">
            {{ 'SLIDESHOW.WELCOME' | translate }} {{ firstName$ | async }} ! 👋
          </div>
          <div class="header-description mb-3" translate>
            CHECKLIST.HEADER.DESCRIPTION
          </div>
          <mat-progress-bar
            class="gen-track-progression"
            [value]="progression$ | async"
          ></mat-progress-bar>
          <div class="flex items-center mt-2 text-xs text-black-new/40">
            <div class="progress-infos">
              {{
                'CHECKLIST.HEADER.CURRENT_COMPLETED'
                  | translate : { count: progression$ | async }
              }}
            </div>
            <div #congrats class="congrats">- {{ encouragement }}</div>
          </div>
          <ng-container *ngTemplateOutlet="headerCompleted"></ng-container>
        </ng-container>
      </div>
      <mat-accordion>
        <ng-container *ngFor="let step of steps$ | async; let i = index">
          <mat-expansion-panel
            #panel
            [class.completed]="step.completed"
            [expanded]="(stepOpened$ | async) === step.step"
            (afterExpand)="afterExpandStep(step.step)"
          >
            <mat-expansion-panel-header>
              <mat-panel-title class="text-bold text-base select-none">
                <div class="dot w-5 h-5 mr-1.5 rounded-full" *ngIf="step.completed">
                  <mat-icon fontSet="fa" fontIcon="fa-check-circle"></mat-icon>
                </div>
                {{ step.title | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="content flex flex-col items-baseline text-base text-black-new/70">
              <span class="description font-normal">
                {{ step.description | translate }}
              </span>
              <ng-container *ngIf="!step.completed">
                <div class="flex mt-4">
                  <rk-button
                    *ngIf="step.firstAction"
                    class="mr-3"
                    [disabled]="step.firstButtonDisabled"
                    [label]="translate.instant(step.firstButton)"
                    (click)="
                      buttonAction(step.firstAction, step.url, step.firstButtonEvent)
                    "
                    [matTooltip]="step.firstButtonDisabledTooltip | translate"
                    [matTooltipDisabled]="!step.firstButtonDisabled"
                  ></rk-button>
                  <rk-button
                    *ngIf="step.secondAction"
                    [btnStyle]="'flat'"
                    [disabled]="step.secondButtonDisabled"
                    [label]="translate.instant(step.secondButton)"
                    (click)="
                      buttonAction(step.secondAction, step.url, step.secondButtonEvent)
                    "
                    [matTooltip]="step.secondButtonDisabledTooltip | translate"
                    [matTooltipDisabled]="!step.secondButtonDisabled"
                    [matTooltipPosition]="'above'"
                  ></rk-button>
                </div>
              </ng-container>
            </div>
          </mat-expansion-panel>
        </ng-container>
        <div
          *ngIf="checklistCompleted$ | async"
          class="checklist-done absolute inset-0 bg-white px-5 flex flex-col justify-center text-center"
        >
          <div class="emoji">🎉</div>
          <div class="text-2xl font-bold mt-7 mb-1" translate>SLIDESHOW.CONGRATS</div>
          <div
            class="text-base"
            translate
            [innerHTML]="'CHECKLIST.FOOTER.COMPLETED' | translate"
          ></div>
        </div>
      </mat-accordion>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<ng-template #headerCompleted>
  <div *ngIf="(progression$ | async) === 100" class="congrats-header absolute inset-5">
    <div class="header-title text-xl font-bold">
      {{ 'CHECKLIST.HEADER.CONGRATS' | translate : { name: (firstName$ | async) } }}
    </div>
    <div class="header-description" translate>CHECKLIST.HEADER.CONGRATS_DESCRIPTION</div>
    <rk-button
      [label]="'CHECKLIST.HEADER.CLOSE_PERMANENTLY' | translate"
      (click)="destroyChecklist()"
    ></rk-button>
  </div>
</ng-template>
