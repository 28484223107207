import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DisplayApollonUpdateModal } from '@app/core/states/live-tracking.actions';
import { LiveTrackingState } from '@app/core/states/live-tracking.state';
import { RadioRestartRequest } from '@app/core/states/radio.actions';
import { RadioState } from '@app/core/states/radio.state';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select, Store } from '@ngxs/store';
import moment from 'moment-timezone';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
  selector: 'rk-apollon-update-modal',
  templateUrl: './apollon-update-modal.component.html',
  styleUrls: ['./apollon-update-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApollonUpdateModalComponent implements OnInit, OnDestroy {
  radioRestartDeadline$: Observable<moment.Moment>;

  @Select(RadioState.currentRadioId)
  idRadio$: Observable<number>;

  subscription = new Subscription();

  constructor(
    public readonly dialogRef: MatDialogRef<ApollonUpdateModalComponent>,
    private readonly store: Store,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.idRadio$
        .pipe(
          filter(id => !!id),
          distinctUntilChanged(),
        )
        .subscribe(id => {
          this.radioRestartDeadline$ = this.store
            .select(LiveTrackingState.radioRestartDeadline)
            .pipe(map(fn => fn(id)));
          this.modalDisplayed(id);
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  askLater() {
    this.dialogRef.close(false);
  }

  @Dispatch()
  modalDisplayed(id: number) {
    return new DisplayApollonUpdateModal(id);
  }

  @Dispatch()
  restartNow() {
    this.dialogRef.close();

    return new RadioRestartRequest();
  }
}
