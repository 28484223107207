import { Playlist } from '@app/library/models/playlist.model';

import { SchedulerTrack } from '../models/scheduler-core.model';

const tag = '[SchedulerCore]';

export class SchedulerAllTracksRequest {
  static readonly type = `${tag} request scheduler all tracks`;
  constructor(public radioId: number) {}
}

export class SchedulerAllTracksSuccess {
  static readonly type = `${tag} success scheduler all tracks`;
  constructor(public tracks: SchedulerTrack[]) {}
}

export class SchedulerAllTracksFailure {
  static readonly type = `${tag} error scheduler all tracks`;
  constructor(public error: Error) {}
}

export class SchedulerAllPlaylistsRequest {
  static readonly type = `${tag} request scheduler all playlists`;
  constructor(public radioId: number) {}
}

export class SchedulerAllPlaylistsSuccess {
  static readonly type = `${tag} success scheduler all playlists`;
  constructor(public playlists: Playlist[]) {}
}

export class SchedulerAllPlaylistsFailure {
  static readonly type = `${tag} error scheduler all playlists`;
  constructor(public error: Error) {}
}

export class ResetAllTracks {
  static readonly type = `${tag} reset tracks to force new fetch`;
}
