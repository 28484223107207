import moment from 'moment-timezone';

export interface ListenerTime {
  count: number;
  date: string;
}

export interface ConsumptionData {
  usage: number;
  limit: number;
  usagePercent: number;
}
export interface Consumption {
  listening: ConsumptionData;
  disk: ConsumptionData;
  bandwidth: ConsumptionData;
}

export interface Peak {
  listeners: number;
  at: moment.Moment;
}

export interface Followers {
  followers: number;
  oldFollowers: number;
  variation: number;
}

export interface Ranking {
  oldRank: number;
  rank: number;
  variation: number;
}

export interface DashboardOverview {
  today: number;
  yesterday: number;
  lastSevenDays: number;
  lastThirtyDays: number;
  thirtyDaysBefore: number;
  percentageRaise: number;
  lastMonth: number;
}

export interface Schedule {
  id: string;
  name: string;
  color: string;
  type: string;
  startDate?: moment.Moment;
  repeat?: number;
  class?: string;
}

export interface TracksWithSchedule {
  startDate: moment.Moment;
  endDate: moment.Moment;
  artist: string;
  title: string;
  coverUrl: string;
  schedule: Schedule;
  duration: number;
}

export interface Timeline {
  nextSchedule: Schedule;
  previousTracks: TracksWithSchedule[];
  currentTrack: TracksWithSchedule;
  nextTracks: TracksWithSchedule[];
  schedules: Schedule[];
  isFake: boolean;
}

export const fakeTimeline: Timeline = {
  isFake: true,
  nextSchedule: null,
  previousTracks: [
    {
      title: 'Crazy in Love',
      artist: 'Beyonce & Jay-Z',
      startDate: moment('2023-04-24T10:07:55.325Z'),
      endDate: moment('2023-04-24T10:11:17.325Z'),
      coverUrl: 'assets/placeholder-square.png',
      duration: 202,
      schedule: {
        id: 'filler',
        name: 'filler',
        color: null,
        type: 'filler',
        startDate: moment('2023-04-24T10:15:13.505'),
      },
    },
    {
      title: 'Clocks',
      artist: 'Coldplay',
      startDate: moment('2023-04-24T10:11:17.325'),
      endDate: moment('2023-04-24T10:14:49.325Z'),
      coverUrl: 'assets/placeholder-square.png',
      duration: 212,
      schedule: {
        id: 'filler',
        name: 'filler',
        color: null,
        type: 'filler',
        startDate: moment('2023-04-24T10:15:13.505'),
      },
    },
  ],
  currentTrack: {
    title: 'Cry Me A River',
    artist: 'Justin Timberlake',
    startDate: moment('2023-04-24T10:14:49.325'),
    endDate: moment('2023-04-24T10:19:57.325Z'),
    coverUrl: 'assets/placeholder-square.png',
    duration: 308,
    schedule: {
      id: 'filler',
      name: 'filler',
      color: null,
      type: 'filler',
      startDate: moment('2023-04-24T10:15:13.505'),
    },
  },
  nextTracks: [
    {
      title: 'Bohemian Rhapsody',
      artist: 'Queen',
      startDate: moment('2023-04-24T10:19:57.325'),
      endDate: moment('2023-04-24T10:23:02.825Z'),
      coverUrl: 'assets/placeholder-square.png',
      duration: 185,
      schedule: {
        id: 'filler',
        name: 'filler',
        color: null,
        type: 'filler',
        startDate: moment('2023-04-24T10:15:13.505'),
      },
    },
    {
      title: 'One',
      artist: 'U2',
      startDate: moment('2023-04-24T10:23:02.825'),
      endDate: moment('2023-04-24T10:27:23.325Z'),
      coverUrl: 'assets/placeholder-square.png',
      duration: 260,
      schedule: {
        id: 'filler',
        name: 'filler',
        color: null,
        type: 'filler',
        startDate: moment('2023-04-24T10:15:13.506'),
      },
    },
    {
      title: 'Billie Jean',
      artist: 'Michael Jackson',
      startDate: moment('2023-04-24T10:27:23.325'),
      endDate: moment('2023-04-24T10:31:12.825Z'),
      coverUrl: 'assets/placeholder-square.png',
      duration: 229,
      schedule: {
        id: 'filler',
        name: 'filler',
        color: null,
        type: 'filler',
        startDate: moment('2023-04-24T10:15:13.506'),
      },
    },
  ],
  schedules: [
    {
      id: 'filler',
      name: 'filler',
      color: '#508dff',
      type: 'filler',
      repeat: 6,
      class: '1 / span 6',
    },
  ],
};
