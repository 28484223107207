import { DashboardOverview } from './Dashboard';

export interface DashboardOverviewApiModel {
  dashboard_overview: {
    today: number;
    yesterday: number;
    last_seven_days: number;
    last_thirty_days: number;
    thirty_days_before: number;
    percentage_raise?: number;
    last_month: number;
  };
}

export function convertToDashboardOverview({
  dashboard_overview,
}: DashboardOverviewApiModel): DashboardOverview {
  return {
    today: dashboard_overview.today || 0,
    yesterday: dashboard_overview.yesterday,
    lastSevenDays: dashboard_overview.last_seven_days,
    lastThirtyDays: dashboard_overview.last_thirty_days,
    thirtyDaysBefore: dashboard_overview.thirty_days_before,
    percentageRaise: dashboard_overview.percentage_raise,
    lastMonth: dashboard_overview.last_month || null,
  };
}
