import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Track } from '@app/core/models/Track';
import { HotkeysService } from '@app/core/services/hotkeys.service';
import { PlayerService } from '@app/core/services/player.service';
import { Resume, Stop } from '@app/core/states/audio.actions';
import { AudioState } from '@app/core/states/audio.state';
import { MIXPOINT_MODAL_ID } from '@app/shared/constants';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';

@Component({
  selector: 'rk-header-player',
  templateUrl: './header-player.component.html',
  styleUrls: ['./header-player.component.scss'],
})
export class HeaderPlayerComponent implements OnInit {
  @Select(AudioState.isAudioPlayerCurrent)
  isAudioPlayerCurrent$: Observable<boolean>;

  @Select(AudioState.isPlaying)
  isListening$: Observable<boolean>;

  @Select(AudioState.isAudioLoading)
  isAudioLoading$: Observable<boolean>;

  @Select(AudioState.currentTrackPlaying)
  currentTrack$: Observable<Track>;

  @Input()
  mobilePlayer = false;

  seekInputSubject$: BehaviorSubject<number>;

  isSeeking: boolean;

  volumeBeforeMute: number;

  isMuted: boolean;

  percentagePlayed$: Observable<number>;
  percentagePlayedRenderBars$: Observable<string>;

  constructor(
    public readonly playerService: PlayerService,
    private readonly store: Store,
    private readonly dialog: MatDialog,
    private readonly hotkeys: HotkeysService,
  ) {
    this.seekInputSubject$ = new BehaviorSubject(0);
    this.isSeeking = false;
    this.volumeBeforeMute = 0;
    this.isMuted = false;
    this.percentagePlayed$ = combineLatest([
      this.playerService.onDuration(),
      this.playerService.onMaxDuration(),
    ]).pipe(
      map(([dur, max]) => {
        if (isNaN(max)) {
          return 0;
        }
        const nmb = (dur * 100) / max / 100;
        if (isNaN(nmb)) {
          return 0;
        }
        if (!nmb) {
          return 0;
        }
        if (nmb === Infinity) {
          return 0;
        }

        return nmb;
      }),
    );

    this.percentagePlayedRenderBars$ = this.percentagePlayed$.pipe(
      mergeMap(percentage => {
        if (this.isSeeking) {
          return this.seekInputSubject$.asObservable();
        }

        return of(percentage);
      }),
      map(t => `${Math.round(t * 10000) / 100}%`),
    );
  }

  ngOnInit() {
    // Hotkeys
    this.hotkeys.fromConfigArray([
      { hotkey: 'playPause', handler: () => this.onPlayPauseHotkey() },
    ]);
  }

  onPlayPauseHotkey() {
    if (this.dialog.getDialogById(MIXPOINT_MODAL_ID)) {
      return;
    }

    combineLatest([this.isAudioPlayerCurrent$, this.isListening$])
      .pipe(
        take(1),
        filter(([audio]) => !!audio),
        map(([, listening]) => listening),
      )
      .subscribe(listening => {
        if (listening) {
          this.pause();
        } else {
          this.resume();
        }
      });
  }

  pause(): void {
    this.store.dispatch(new Stop());
  }

  resume(): void {
    this.store.dispatch(new Resume());
  }

  getVolume(): number {
    return this.playerService.getVolume();
  }

  setVolume(vol: number): void {
    this.playerService.setVolume(vol);
    if (vol > -60) {
      this.isMuted = false;
    } else if (vol === -60) {
      this.isMuted = true;
    }
  }

  toggleMute(): void {
    if (!this.isMuted) {
      this.volumeBeforeMute = this.getVolume() || 0;
      this.setVolume(-60);
      this.isMuted = true;
    } else {
      this.setVolume(this.volumeBeforeMute || 0);
      this.isMuted = false;
    }
  }

  getIconAccordingToVolume(): string {
    const v = this.getVolume();
    if (v === -60) {
      return 'fa-volume-off';
    }
    if (v <= -20) {
      return 'fa-volume-down';
    }

    return 'fa-volume-up';
  }

  changeSeekUpdate(e: EventTarget) {
    this.seekInputSubject$.next(Number.parseFloat((e as HTMLInputElement).value));
  }

  startSeek() {
    this.isSeeking = true;
  }

  endSeek() {
    this.isSeeking = false;
    combineLatest([
      this.playerService.onMaxDuration(),
      this.seekInputSubject$.asObservable(),
    ])
      .pipe(take(1))
      .subscribe(([max, percentage]) => {
        this.playerService.seekTo(max * percentage);
      });
  }
}
