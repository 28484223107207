<div
  class="flex items-center"
  *ngIf="currentTrack$ | async as currentTrack"
  [class.mobile]="mobilePlayer"
>
  <div class="play-button">
    <button mat-icon-button *ngIf="isListening$ | async" (click)="pause()">
      <mat-icon fontSet="fa" fontIcon="fa-pause" color="primary"></mat-icon>
    </button>
    <button mat-icon-button *ngIf="!(isListening$ | async)" (click)="resume()">
      <mat-icon fontSet="fa" fontIcon="fa-play" color="primary"></mat-icon>
    </button>
  </div>

  <div class="main-audio-block flex flex-col justify-around">
    <div class="flex justify-between items-baseline">
      <div class="tracks-info flex">
        <span>{{ currentTrack.title }}</span>
        <span [class.hidden]="!currentTrack.artist">{{ currentTrack.artist }}</span>
      </div>
      <div class="duration-info flex justify-end items-baseline">
        <span>{{ playerService.onDuration() | async | timeFormat }}</span> <span>/</span>
        <span>{{ playerService.onMaxDuration() | async | timeFormat }}</span>
      </div>
    </div>

    <div class="seek">
      <input
        type="range"
        min="0"
        max="1"
        step="any"
        value="0.45"
        [value]="percentagePlayed$ | async"
        (input)="changeSeekUpdate($event.target)"
        (mousedown)="startSeek()"
        (touchstart)="startSeek()"
        (mouseup)="endSeek()"
        (touchend)="endSeek()"
      />

      <svg width="100%" height="3px">
        <defs>
          <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stop-color="#FF7F50" />
            <stop offset="100%" stop-color="#FF3648" />
          </linearGradient>
        </defs>
        <g>
          <rect fill="#eee" width="100%" height="3" rx="2" ry="2"></rect>
          <rect
            fill="url(#grad1)"
            attr.width="{{ percentagePlayedRenderBars$ | async }}"
            height="3"
            rx="2"
            ry="2"
          ></rect>
        </g>
      </svg>
    </div>
  </div>

  <div class="audio fix-slider hidden md:flex justify-center items-center">
    <button mat-icon-button (click)="toggleMute()" class="icon-button">
      <mat-icon fontSet="fa" [fontIcon]="getIconAccordingToVolume()"></mat-icon>
    </button>
    <mat-slider color="primary" [max]="0" [min]="-60" [step]="2" #ngSlider
      ><input
        matSliderThumb
        [value]="getVolume()"
        (input)="
          setVolume(
            { source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value }.value
          )
        "
        #ngSliderThumb="matSliderThumb"
    /></mat-slider>
  </div>
</div>
