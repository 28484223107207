import { StateOperator } from '@ngxs/store';
import { isStateOperator } from '@ngxs/store/operators';
import { Predicate } from '@ngxs/store/operators';

import { composeWithDeepEqual } from './composeWithDeepEqual';

export function updateMultipleItems<T extends { id: number }>(
  selector: Predicate<T>,
  operatorOrValue: any | StateOperator<any>,
): StateOperator<any[]> {
  return composeWithDeepEqual<any[]>(arr =>
    arr.map(item => {
      if (!selector(item)) {
        return item;
      }
      if (isStateOperator(operatorOrValue)) {
        return operatorOrValue(item);
      }

      return operatorOrValue;
    }),
  );
}
