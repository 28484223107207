<rk-modal-wrapper [showSubmitBlock]="false" [showHeader]="false" [larger]="true">
  <img src="/assets/update-radio.svg" alt="Update radio" />
  <div class="new-update" translate>GLOBAL.MODAL.APOLLON.NEW_UPDATE</div>
  <div translate>GLOBAL.MODAL.APOLLON.UPDATE_DEPLOYED</div>
  <div translate>GLOBAL.MODAL.APOLLON.YOU_MUST_RESTART</div>
  <rk-button
    [label]="'GLOBAL.MODAL.APOLLON.RESTART_MY_RADIO' | translate"
    (click)="restartNow()"
  ></rk-button>
  <div class="later">
    <span translate (click)="askLater()">GLOBAL.MODAL.APOLLON.ASK_LATER</span>
  </div>
  <div
    *ngIf="radioRestartDeadline$ | async as date"
    class="date-restart"
    translate
    [translateParams]="{ date: date.format('LL') }"
  >
    GLOBAL.MODAL.APOLLON.DATE_RESTART
  </div>
</rk-modal-wrapper>
