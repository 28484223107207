import { Component, Input } from '@angular/core';

@Component({
  selector: 'rk-hotkeys-help-tooltip',
  templateUrl: './hotkeys-help-tooltip.component.html',
  styleUrls: ['./hotkeys-help-tooltip.component.scss'],
})
export class HotkeysHelpTooltipComponent {
  @Input()
  disable = false;
}
