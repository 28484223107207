import { Directive, HostListener } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[routerLink]',
})
export class LinkWithoutDragDirective {
  @HostListener('mousedown')
  onMouseDown() {
    return false;
  }
}
