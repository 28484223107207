import { Component, EventEmitter, Input, Output } from '@angular/core';
import { hexToRGBA } from '@app/shared/utils';

@Component({
  selector: 'rk-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent {
  @Input()
  label: string;

  @Input()
  color: string;

  @Input()
  canRemove = true;

  @Output()
  readonly remove = new EventEmitter();

  @Input()
  hover: string;

  clickRemove() {
    this.remove.emit(this.label);
  }

  getSlotStyle(): object {
    if (this.color) {
      return {
        color: this.color,
        background: hexToRGBA(this.color, 0.1),
        boxShadow: 'none',
      };
    }

    return {
      color: '#474747',
      background: '#FFFFFF',
    };
  }
}
