import { ChecklistStep } from '../onboarding-checklist.model';

const tag = '[OnboardingChecklist]';

export class ChecklistRequest {
  static readonly type = `${tag} request get checklist`;
}

export class ChecklistSuccess {
  static readonly type = `${tag} success get checklist`;
  constructor(public completed: string[]) {}
}

export class ChecklistFailure {
  static readonly type = `${tag} error get checklist`;
  constructor(public error: Error) {}
}

export class PutChecklistSuccess {
  static readonly type = `${tag} success put checklist`;
  constructor() {}
}

export class PutChecklistFailure {
  static readonly type = `${tag} error put checklist`;
  constructor(public error: Error) {}
}

export class CompleteStep {
  static readonly type = `${tag} complete step`;
  constructor(public step: ChecklistStep) {}
}

export class ToggleChecklist {
  static readonly type = `${tag} toggle checklist`;
  constructor(public opened: boolean) {}
}

export class ToggleChecklistStep {
  static readonly type = `${tag} toggle checklist step`;
  constructor(public stepOpened: ChecklistStep) {}
}

export class CheckListAddTrack {
  static readonly type = `${tag} checklist add track`;
}

export class CheckListListenToRadio {
  static readonly type = `${tag} checklist listen to radio`;
}

export class CheckListAddPlaylist {
  static readonly type = `${tag} checklist add playlist`;
}

export class CheckListPlanning {
  static readonly type = `${tag} checklist planning`;
}

export class CheckListGenerate {
  static readonly type = `${tag} checklist generate`;
}

export class CheckListShare {
  static readonly type = `${tag} checklist share`;
}

export class CheckListHelpCenter {
  static readonly type = `${tag} checklist help center`;
}

export class ToggleFirstButton {
  static readonly type = `${tag} toggle first button`;
  constructor(public step: ChecklistStep, public disabled: boolean) {}
}

export class DestroyChecklist {
  static readonly type = `${tag} destroy checklist`;
  constructor() {}
}
