import { StateOperator } from '@ngxs/store';
import { Predicate, removeItem } from '@ngxs/store/operators';

import { isNumber } from './utils';

export function removeItemWithId<T extends { id: number }>(
  item: T | number,
): StateOperator<any[]> {
  let selector: Predicate<T>;
  if (isNumber(item)) {
    selector = (obj: any) => obj.id === item;
  } else {
    selector = (obj: any) => obj.id === item.id;
  }

  return removeItem<T>(selector);
}

export function removeItemWithIdOrPosition<T extends { id: number; position: number }>(
  item: T,
): StateOperator<any[]> {
  let selector: Predicate<T>;

  selector = (obj: any) => obj.id === item.id && obj.position === item.position;

  return removeItem<T>(selector);
}
