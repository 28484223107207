import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  convertToRadioPage,
  convertToRadioPageApi,
  RadioPage,
  RadioPageApi,
  ShareLink,
  Smartlink,
} from './models/widgets.model';

export interface LinkApiModel {
  format: string;
  bitrate: number;
  url: string;
  type: string;
}

const shareUrls = {
  smartlink: (id: number) => `${environment.urls.MAIN_API_V2}/radio/${id}/smartlink`,
  getLinks: (id: number) => `${environment.urls.MAIN_API_V2}/radio/${id}/link`,
  radioPage: (id: number) => `${environment.urls.MAIN_API_V2}/radio/${id}/radiopage`,
};

@Injectable({
  providedIn: 'root',
})
export class ShareService {
  constructor(private readonly httpClient: HttpClient) {}

  public getSmartlink(idRadio: number): Observable<Smartlink> {
    return this.httpClient.get<Smartlink>(shareUrls.smartlink(idRadio));
  }

  public updateSmartlink(idRadio: number, smartlink: Smartlink): Observable<any> {
    return this.httpClient.put<Smartlink>(shareUrls.smartlink(idRadio), smartlink);
  }

  public getLinks(idRadio: number): Observable<ShareLink[]> {
    return this.httpClient.get<LinkApiModel[]>(shareUrls.getLinks(idRadio));
  }

  public getRadioPage(idRadio: number): Observable<RadioPage> {
    return this.httpClient
      .get<RadioPageApi>(shareUrls.radioPage(idRadio))
      .pipe(map(convertToRadioPage));
  }

  public updateRadioPage(
    idRadio: number,
    radioPage: RadioPage,
    file: File,
  ): Observable<RadioPage> {
    const payload = convertToRadioPageApi(radioPage);
    const formData: FormData = new FormData();

    formData.append('settings', JSON.stringify(payload));
    if (file !== null && typeof file !== 'string') {
      formData.append('background_image', file);
    }

    return this.httpClient
      .put<RadioPageApi>(shareUrls.radioPage(idRadio), formData)
      .pipe(map(convertToRadioPage));
  }
}
