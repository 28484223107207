import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ColoredHintComponent } from './colored-hint.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ColoredHintComponent],
  exports: [ColoredHintComponent],
})
export class UiColoredHintModule {}
