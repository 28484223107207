import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

const uploadsUrl = {
  upload: (idRadio: number, idBox: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${idRadio}/track?idbox=${idBox}`,
  uploadPlaylist: (idRadio: number, idBox: number, idPlaylist: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${idRadio}/track?idbox=${idBox}&idplaylist=${idPlaylist}`,
};

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private readonly httpClient: HttpClient) {}

  uploadFile(
    file: File,
    idRadio: number,
    idBox: number,
    playlistID: number,
  ): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const uploadUrl = playlistID
      ? uploadsUrl.uploadPlaylist(idRadio, idBox, playlistID)
      : uploadsUrl.upload(idRadio, idBox);
    const req = new HttpRequest('POST', uploadUrl, formData, {
      reportProgress: true,
    });

    return this.httpClient.request(req);
  }
}
