import { Injectable } from '@angular/core';
import { Country } from '@app/core/models/Country';
import { GenericDataService } from '@app/core/services/generic-data.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SugestionInput } from '@radioking/ui/input';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { CountriesFailure, CountriesRequest, CountriesSuccess } from './country.actions';

export class CountryStateModel {
  countries: Country[];
}

@State<CountryStateModel>({
  name: 'country',
  defaults: {
    countries: undefined,
  },
})
@Injectable()
export class CountryState {
  constructor(private readonly genericDataService: GenericDataService) {}

  @Selector()
  static countryListWithoutUnknown(state: CountryStateModel): Country[] {
    return state.countries.filter(country => country.code !== 'O1');
  }

  @Selector()
  static countryListWithoutUnknownForInput(state: CountryStateModel): SugestionInput[] {
    return state.countries
      .filter(country => country.code !== 'O1')
      .map(c => ({ id: c.code, name: c.name }));
  }

  @Selector()
  static countryList(state: CountryStateModel): Country[] {
    return state.countries;
  }

  @Selector()
  static countryListForChips(state: CountryStateModel): SugestionInput[] {
    return state.countries.map(country => {
      if (country.code === 'O1') {
        return {
          id: country.code,
          name: country.name,
          translation: marker('SETTINGS.FORM.UNKNOWN_LOCATION'),
        };
      }

      return {
        id: country.code,
        name: country.name,
      };
    });
  }

  @Action(CountriesRequest)
  getAllCountries(
    ctx: StateContext<CountryStateModel>,
  ): Observable<void | Observable<void>> | void {
    const countries = ctx.getState().countries;
    if (countries) {
      return;
    }

    return this.genericDataService.getCountries().pipe(
      map(data => ctx.dispatch(new CountriesSuccess(data))),
      catchError(err => ctx.dispatch(new CountriesFailure(err))),
    );
  }

  @Action(CountriesSuccess)
  add(ctx: StateContext<CountryStateModel>, { countries }: CountriesSuccess) {
    ctx.patchState({
      countries,
    });
  }
}
