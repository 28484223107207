import { BacGroup } from '@app/library/models/bac.model';

const tag = '[Bacs]';

export class BacsRequest {
  static readonly type = `${tag} request bacs for current radio`;
}

export class BacsFailure {
  static readonly type = `${tag} error fetching bacs`;

  constructor(public error: Error) {}
}

export class BacsSuccess {
  static readonly type = `${tag} success fetching bacs`;

  constructor(public bacsGroup: BacGroup[]) {}
}

export class ClearBacRequest {
  static readonly type = `${tag} request clear a specific bac`;

  constructor(public id: number) {}
}

export class ClearBacSuccess {
  static readonly type = `${tag} success clear a specific bac`;

  constructor(public id: number) {}
}

export class ClearBacFailure {
  static readonly type = `${tag} error clear a specific bac`;

  constructor(public error: Error) {}
}

export class ClearBacNotPossible {
  static readonly type = `${tag} cant clear a specific bac`;
}

/* UI related */

export class OpenBac {
  static readonly type = `${tag} open bac with id`;

  constructor(public id: number) {}
}

export class CloseBac {
  static readonly type = `${tag} close bac with id`;

  constructor(public id: number) {}
}

export class ToggleBac {
  static readonly type = `${tag} toggle bac with id`;

  constructor(public id: number) {}
}
