<ng-template #radioOffline>
  <div
    class="radio-off flex-col"
    *ngIf="(radioStatus$ | async) === 'stopped'; else basicLoader"
  >
    <div
      class="radio-off-notice text-base md:text-sm"
      *ngIf="!(isRadioStarting$ | async)"
      translate
    >
      SIDEBAR.OFF.NOTICE
    </div>
    <div
      class="radio-off-notice text-base md:text-sm"
      *ngIf="isRadioStarting$ | async"
      translate
    >
      SIDEBAR.OFF.STARTING
    </div>
    <div>
      <rk-audio-loader
        *ngIf="isRadioStarting$ | async"
        color="white"
        [size]="30"
      ></rk-audio-loader>
      <rk-advanced-tooltip [isListeningTimeExceeded]="listeningTimeExceeded$ | async">
        <button
          mat-button
          (click)="startBroadcastRadio()"
          *ngIf="!(isRadioStarting$ | async)"
          class="disable-mat-button-focus-overlay"
          [ngClass]="{
            disabled: (listeningTimeExceeded$ | async) || (isLibraryEmpty$ | async)
          }"
          rkTooltip
          [tooltipDescription]="'GLOBAL.DISABLED.NO_TRACKS_TOOLTIP_CONTENT' | translate"
          [tooltipDisabled]="!(isLibraryEmpty$ | async)"
        >
          <mat-icon fontSet="fa" fontIcon="fa-play"></mat-icon>
          <span class="label-button">
            {{ 'SIDEBAR.OFF.START_RADIO' | translate }}
          </span>
        </button>
      </rk-advanced-tooltip>
      <rk-animated-arrow
        class="checklist-play-radio checklist-start-radio"
        *ngIf="pulse && !(isRadioStarting$ | async)"
        [description]="'CHECKLIST.ARROW.LISTEN_RADIO_DESCRIPTION' | translate"
      ></rk-animated-arrow>
    </div>
  </div>
</ng-template>

<ng-template #basicLoader>
  <div class="radio-off">
    <div><rk-audio-loader color="white" [size]="30"></rk-audio-loader></div>
  </div>
</ng-template>

<ng-template #radioStopping>
  <div class="radio-off flex-col">
    <div translate>SIDEBAR.STOPING.NOTICE</div>
    <div><rk-audio-loader color="white" [size]="30"></rk-audio-loader></div>
  </div>
</ng-template>

<ng-template #radioRestarting>
  <div class="radio-off flex-col">
    <div translate>SIDEBAR.OFF.RESTARTING</div>
    <div><rk-audio-loader color="white" [size]="30"></rk-audio-loader></div>
  </div>
</ng-template>

<ng-container
  *ngIf="(isRadioStarted$ | async) && !(isRadioStarting$ | async); else radioOffline"
>
  <ng-container *ngIf="!(isRadioRestarting$ | async); else radioRestarting">
    <ng-container *ngIf="!(isRadioStopping$ | async); else radioStopping">
      <div>
        <div *ngIf="isListening$ | async" class="duotone">
          <svg class="svg" viewBox="0 0 400 400">
            <defs>
              <filter id="duotone-filter-post-one">
                <feColorMatrix
                  type="matrix"
                  attr.values="{{
                    getColorMatrix([
                      [255, 157, 0],
                      [8, 19, 119]
                    ])
                  }}"
                ></feColorMatrix>
              </filter>
            </defs>
            <image
              class="image"
              attr.xlink:href="{{ (currentTrack$ | async).cover }}"
              filter="url(#duotone-filter-post-one)"
              preserveAspectRatio="xMidYMin slice"
              width="400"
              height="400"
            ></image>
          </svg>
        </div>

        <div class="main">
          <div class="listening-header move-front flex items-center justify-between">
            <div class="listeners">
              <div *ngIf="(isCustomer$ | async) || (hasStatisticsRole() | async)">
                <mat-icon fontSet="fa" fontIcon="fa-headphones"></mat-icon>
                <span class="sidebar-listener-count-fold">{{
                  listeners$ | async | shortNumber
                }}</span>
                <span
                  class="sidebar-listener-count-unfold"
                  data-cy="sidebar-listener-count"
                  >{{ listeners$ | async }}</span
                >
              </div>
            </div>
            <rk-icon-button
              data-cy="button-next-tracks"
              [svgIcon]="'next-titles'"
              color="white"
              class="button-next-tracks"
              [matMenuTriggerFor]="nextSongsMenu"
              [size]="24"
              [style]="{ padding: '5px' }"
            ></rk-icon-button>
          </div>

          <div class="main-listening move-front flex items-center" data-tour="play-zone">
            <button
              #playRadioBtn
              mat-icon-button
              *ngIf="!(isListening$ | async) && !(isAudioLoading$ | async)"
              (click)="playRadio()"
              class="button-play"
            >
              <mat-icon fontSet="fa" fontIcon="fa-play"></mat-icon>
            </button>
            <rk-animated-arrow
              class="checklist-play-radio"
              *ngIf="pulse"
              [description]="'CHECKLIST.ARROW.LISTEN_RADIO_DESCRIPTION' | translate"
            ></rk-animated-arrow>
            <div *ngIf="isAudioLoading$ | async" class="loader">
              <rk-audio-loader
                color="white"
                [size]="(onMobile$ | async) ? 44 : 30"
              ></rk-audio-loader>
            </div>
            <button
              mat-icon-button
              *ngIf="isListening$ | async"
              (click)="stopRadio()"
              class="button-play button-pause"
            >
              <mat-icon fontSet="fa" fontIcon="fa-stop"></mat-icon>
            </button>

            <div class="current-info">
              <span data-cy="sidebar-current-track-title">{{
                (currentTrack$ | async).title
              }}</span>
              <span data-cy="sidebar-current-track-artist">
                {{ (currentTrack$ | async).artist }}
              </span>
            </div>
            <div *ngIf="isListening$ | async" class="volume-container hidden md:block">
              <rk-icon-button
                [faIcon]="isMuted ? 'fa-volume-mute' : 'fa-volume-down'"
                color="white"
                class="volume-button"
                (click)="toggleVolume()"
                [disableHover]="true"
                [size]="22"
              ></rk-icon-button>
              <div
                class="volume-slider-container"
                [ngClass]="{ visible: isChangingVolume, live: isLive$ | async }"
              >
                <mat-slider
                  color="primary"
                  [max]="0"
                  [min]="-60"
                  [step]="2"
                  #ngSlider
                  [class.min-value]="getVolume() === -60"
                  ><input
                    matSliderThumb
                    [value]="getVolume()"
                    (input)="
                      setVolume(
                        {
                          source: ngSliderThumb,
                          parent: ngSlider,
                          value: ngSliderThumb.value
                        }.value
                      )
                    "
                    #ngSliderThumb="matSliderThumb"
                    (change)="finSetVolume()"
                /></mat-slider>
              </div>
            </div>
          </div>

          <div
            *ngIf="isLive$ | async; else noLive"
            class="live-banner move-front flex justify-between items-center"
          >
            <div class="flex items-center">
              <div class="dot"></div>
              <span class="live-text" translate>SIDEBAR.LIVE_TEXT</span>
            </div>
            <span class="live-duration">{{ liveDuration$ | async | timeFormat }}</span>
            <div class="broadcaster">{{ (broadcaster$ | async).username }}</div>
          </div>

          <ng-template #noLive><div></div></ng-template>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<!-- Menu incomming tracks -->
<mat-menu
  #nextSongsMenu="matMenu"
  class="radio-incoming-songs-playing-menu"
  [overlapTrigger]="false"
>
  <div class="header-menu-nexts flex justify-between items-baseline">
    <span translate>SIDEBAR.UPCOMING.TITLE</span>
    <button
      mat-button
      color="primary"
      *ngIf="(nextTracks$ | async).length > 0"
      (click)="goToGeneration()"
    >
      {{ 'SIDEBAR.UPCOMING.SEE_MORE' | translate }}
    </button>
  </div>
  <div
    *ngFor="let next of nextTracks$ | async"
    class="titles-incoming"
    data-cy="incoming-item"
  >
    <div class="artist-and-title-incoming">
      <span data-cy="incoming-item-title">{{ next.title }}</span>
      <span data-cy="incoming-item-artist">{{ next.artist }}</span>
    </div>
    <div>
      <span>{{ next.started_at | toRadioTz | amDateFormat : 'LT' }}</span>
    </div>
  </div>
  <div
    *ngIf="(nextTracks$ | async).length === 0"
    class="no-titles-incoming flex flex-col"
  >
    <span translate>SIDEBAR.UPCOMING.NOTRACKS</span>
    <span>
      {{ 'SIDEBAR.UPCOMING.YOUCAN' | translate }}
      <span class="gene-link" (click)="goToGeneration()" translate
        >SIDEBAR.UPCOMING.GENERATE_DAY</span
      >
    </span>
  </div>
</mat-menu>
