import { Injectable } from '@angular/core';

declare class sendinblue {}

@Injectable({
  providedIn: 'root',
})
export class BrevoService {
  private properties = {};

  constructor() {}

  setUserProperties(properties: object) {
    // Replace name by user because brevo use name for event name
    properties['user'] = properties['name'];
    delete properties['name'];
    this.properties = { ...this.properties, ...properties };
    typeof sendinblue !== 'undefined' &&
      (sendinblue as any).identify(properties['email']);
  }

  setRadioProperties(properties: object) {
    this.properties = { ...this.properties, ...properties };
  }

  trackEvent(event: string) {
    typeof sendinblue !== 'undefined' &&
      (sendinblue as any).track(event, this.properties);
  }
}
