import { StateOperator } from '@ngxs/store';
import { iif, patch } from '@ngxs/store/operators';

import { composeWithDeepEqual } from './composeWithDeepEqual';

export function patchKey<T>(
  idInsert: string,
  operatorOrValue: T[] | StateOperator<T[]>,
): StateOperator<{ [key: string]: T[] }> {
  const creator = iif<{ [x: string]: T[] }>(
    val => !val[idInsert],
    // @ts-ignore
    patch({ [idInsert]: [] }),
  );
  const applier = patch<{ [x: string]: T[] }>({
    [idInsert]: operatorOrValue,
  });

  return composeWithDeepEqual<{ [x: string]: T[] }>(creator, applier);
}
