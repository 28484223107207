import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'rk-unsaved-changes-modal',
  templateUrl: './unsaved-changes-modal.component.html',
  styleUrls: ['./unsaved-changes-modal.component.scss'],
})
export class UnsavedChangesModalComponent {
  constructor(
    public dialogRef: MatDialogRef<UnsavedChangesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public canSave: boolean,
  ) {}

  onSubmit() {
    this.dialogRef.close(true);
  }
  onCancel() {
    this.dialogRef.close(false);
  }
}
