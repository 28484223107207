import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { MainSideNavService } from '@app/core/services/main-side-nav.service';
import { Logout } from '@app/core/states/auth.actions';
import { AuthState } from '@app/core/states/auth.state';
import { SetLang } from '@app/core/states/i18n.actions';
import { I18nState } from '@app/core/states/i18n.state';
import { LiveTrackingState } from '@app/core/states/live-tracking.state';
import { SwitchToRadioRequest } from '@app/core/states/radio.actions';
import { RadioState } from '@app/core/states/radio.state';
import { aLogEvent, AMPLITUDE_EVENT } from '@app/shared/amplitude-events';
import { UpgradeOfferModalComponent } from '@app/shared/modals/upgrade-offer/components/upgrade-offer-modal/upgrade-offer-modal.component';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select, Store } from '@ngxs/store';
import { MobileSidenavService } from '@radioking/shared/common-services';
import { TryActionUnlessPanelIsBlocking } from '@radioking/shared/pannel-manager';
import { MobileUserMenuComponent } from '@radioking/ui/mobile-user-menu';
import amplitude from 'amplitude-js';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'rk-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  canShowI18nChange = environment.show18nSwitch;

  @Select(RadioState.isOneOfMineRadios)
  isOneOfMineRadios$: Observable<boolean>;

  @Select(AuthState.currentUsername)
  username$: Observable<string>;

  @Select(AuthState.currentEmail)
  email$: Observable<string>;

  @Select(AuthState.currentProfilePic)
  profilePic$: Observable<string>;

  @Select(AuthState.userLang)
  userLang$: Observable<string>;

  @Select(I18nState.supportedLang)
  supportedLangs$: Observable<string[]>;

  @Select(LiveTrackingState.planId)
  radioPlan$: Observable<number>;

  @Select(RadioState.isCustomer)
  isCustomer$: Observable<boolean>;

  @Select(RadioState.newRadio)
  newRadio$: Observable<boolean>;

  clientAreaUrl = `${environment.urls.WHMCS}/clientarea.php`;
  isMobile = false;
  headwayEn = '7N2LA7';
  headwayFr = 'xYOvAJ';
  subscription = new Subscription();

  constructor(
    private readonly titleService: Title,
    private readonly store: Store,
    private readonly mainSideNavService: MainSideNavService,
    private readonly translateService: TranslateService,
    private readonly dialog: MatDialog,
    private readonly mobileSidenavService: MobileSidenavService,
  ) {}

  ngOnInit() {
    this.setIsMobile(window.innerWidth);
    this.subscription.add(
      fromEvent(window, 'resize')
        .pipe(debounceTime(100))
        .subscribe(w => {
          if (w && w.target) {
            this.setIsMobile((w.target as Window).innerWidth);
          }
        }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setIsMobile(w: number) {
    this.isMobile = w < 1081;
  }

  setLanguage(language: string) {
    this.store.dispatch(new SetLang(language));
  }

  logout() {
    this.store.dispatch(new Logout());
  }

  toggleSideNav() {
    this.mainSideNavService.toggleSideNav();
  }

  openKnowledgeBase() {
    amplitude.getInstance().logEvent('show knowledge center');
  }

  get title(): string {
    return this.titleService.getTitle();
  }

  updateOffer(): void {
    aLogEvent(AMPLITUDE_EVENT.ONBOARDING_DASHBOARD.CTA_CONVERSION_HEADER);
    this.dialog.open(UpgradeOfferModalComponent, { data: 'header' });
  }

  ngAfterViewInit(): void {
    this.userLang$.subscribe(lang => {
      const config = {
        selector: '.headway-widget', // CSS selector where to inject the badge
        account: lang !== 'fr' ? this.headwayEn : this.headwayFr,
        translations: {
          title: this.translateService.instant('GLOBAL.CHANGELOG.TITLE'),
          readMore: this.translateService.instant('GLOBAL.CHANGELOG.READ_MORE'),
          labels: {
            new: this.translateService.instant('GLOBAL.CHANGELOG.NEW'),
            improvement: this.translateService.instant('GLOBAL.CHANGELOG.IMPROVEMENT'),
            fix: this.translateService.instant('GLOBAL.CHANGELOG.FIXES'),
          },
          footer: this.translateService.instant('GLOBAL.CHANGELOG.READ_MORE'),
        },
      };
      let tries = 0;
      const itv = setInterval(() => {
        // @ts-ignore
        if (typeof Headway !== 'undefined') {
          // @ts-ignore
          Headway.init(config);
          clearInterval(itv);
        }
        tries += 1;
        if (tries === 10) {
          clearInterval(itv);
        }
      }, 500);
    });
  }

  @Dispatch()
  goBackToMyRadio() {
    const myRadio = this.store.selectSnapshot(RadioState.firstRadioOwned);

    return new TryActionUnlessPanelIsBlocking(new SwitchToRadioRequest(myRadio.id));
  }

  upgradeLabel() {
    const plan = this.store.selectSnapshot(LiveTrackingState.planDesc);
    switch (plan) {
      case 'Demo':
        return marker('HEADER.OFFER.SHOW_OFFERS');
      default:
        return marker('HEADER.OFFER.UPDATE');
    }
  }

  openPanel() {
    this.mobileSidenavService.open(MobileUserMenuComponent, { from: 'right' });
  }

  myProfile() {
    return this.translateService.instant('HEADER.USER.PROFILE_URL', {
      domain: environment.urls.RADIOKING_DOMAIN,
    });
  }
}
