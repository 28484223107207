import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthState } from '@app/core/states/auth.state';
import { aLogEvent, AMPLITUDE_EVENT } from '@app/shared/amplitude-events';
import { TranslateService } from '@ngx-translate/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { ToggleChecklist } from '@radioking/onboarding-checklist';
import { combineLatest, Observable } from 'rxjs';

export interface OnboardingStep {
  title: string;
  description: string;
  src: string;
  amplitudeEvent?: string;
}
@Component({
  selector: 'rk-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlideshowComponent implements OnInit {
  @Select(AuthState.currentFirstname)
  user$: Observable<string>;

  @Select(AuthState.userLang)
  userLang$: Observable<string>;

  currentStep = 0;

  steps: OnboardingStep[];
  step: OnboardingStep;

  constructor(
    public readonly dialogRef: MatDialogRef<SlideshowComponent>,
    private readonly translate: TranslateService,
  ) {}

  ngOnInit(): void {
    combineLatest([this.user$, this.userLang$]).subscribe(([user, lang]) =>
      this.setSteps(user, lang),
    );
    aLogEvent(AMPLITUDE_EVENT.ONBOARDING_SLIDESHOW.NEW);
  }

  setSteps(user: string, lang: string) {
    const langExt = lang === 'fr' ? 'fr-' : '';
    this.steps = [
      {
        title: this.translate.instant('SLIDESHOW.WELCOME') + ' ' + user,
        description: this.translate.instant('SLIDESHOW.WELCOME_DESC'),
        src: 'hello.gif',
      },
      {
        title: this.translate.instant('SLIDESHOW.START_ADVENTURE'),
        description: this.translate.instant('SLIDESHOW.START_ADVENTURE_DESC'),
        src: langExt + 'step-2.png',
      },
    ];
    this.step = this.steps[0];
  }

  nextStep() {
    if (this.currentStep === 0) {
      aLogEvent(AMPLITUDE_EVENT.ONBOARDING_SLIDESHOW.LAUNCH);
    }
    this.currentStep++;
    this.step = this.steps[this.currentStep];
  }

  prevStep() {
    this.currentStep--;
    this.step = this.steps[this.currentStep];
  }

  close(quit = false) {
    this.dialogRef.close(quit);

    if (!quit) {
      aLogEvent(AMPLITUDE_EVENT.ONBOARDING_SLIDESHOW.DISCOVER);
      this.openChecklist();
    }
  }

  @Dispatch()
  openChecklist = () => new ToggleChecklist(true);
}
