import { ArchivedTrack } from '@app/library/models/trash.model';
import { TrashStateModel } from '@app/library/states/trash.state';
import { StateOperator } from '@ngxs/store';
import { compose, iif, patch, removeItem } from '@ngxs/store/operators';

function applyToTrash(
  op: ArchivedTrack[] | StateOperator<ArchivedTrack[]>,
): StateOperator<TrashStateModel> {
  const noNull = patch<TrashStateModel>({
    archivedTracks: iif<ArchivedTrack[]>(val => val === null, []),
  });

  return compose(
    noNull,
    patch<TrashStateModel>({
      archivedTracks: op,
    }),
  );
}

export function removeTrack(trackId: number): StateOperator<TrashStateModel> {
  const applier = removeItem<ArchivedTrack>(track => track.id === trackId);

  return compose<TrashStateModel>(applyToTrash(applier));
}

export function removeMultipleTracks(tracksId: number[]): StateOperator<TrashStateModel> {
  return compose<TrashStateModel>(...tracksId.map(trackId => removeTrack(trackId)));
}
