import { AfterContentInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawerMode } from '@angular/material/sidenav';
import { PLANNING_MIN_WIDTH_SIDE } from '@app/shared/constants';
import { Actions, ofActionDispatched, Select, Store } from '@ngxs/store';
import { UnsavedChangesModalComponent } from '@radioking/shared/unsaved-changes';
import { BehaviorSubject, from, Observable, of, Subscription } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';

import { DynamicPanelAnchorDirective } from '../../directive/dynamic-panel-anchor.directive';
import { PanelRegistryService } from '../../services/panel-registry.service';
import {
  CloseCurrentPanel,
  FailTryActionUnlessPanelIsBlocking,
  SetPanelActive,
  SetPanelUnactivated,
  TryActionUnlessPanelIsBlocking,
} from '../../states/panels.actions';
import { PanelsState } from '../../states/panels.state';
import {
  DynamicEnabledPanel,
  PanelContainerInput,
} from '../../types/dynamic-panel-types';

const PANELS_SIDE_MODE = ['planning-slot-add'];

@Component({
  selector: 'rk-panel-container',
  template: `
    <mat-sidenav-container class="container-full" [hasBackdrop]="false">
      <mat-sidenav
        #sidenav
        [mode]="mode"
        [opened]="isShowingSomething"
        [autoFocus]="false"
        position="end"
        disableClose="true"
      >
        <ng-template rkDynamicPanelAnchor #container></ng-template>
      </mat-sidenav>
      <mat-sidenav-content>
        <ng-content select="rk-panel-outside-content"></ng-content>
      </mat-sidenav-content>
    </mat-sidenav-container>
  `,
  styles: [``],
})
export class PanelContainerComponent implements OnInit, AfterContentInit, OnDestroy {
  @ViewChild(DynamicPanelAnchorDirective, { static: true })
  dynamicTabPlaceholder: DynamicPanelAnchorDirective;

  @Select(PanelsState.currentPanel)
  currentPanel$: Observable<string>;

  isSideOpenedSubject = new BehaviorSubject<boolean>(false);

  subscription = new Subscription();

  isShowingSomething = true;

  // @Input()
  // items: PanelContainerInput[] = [];

  currentShown: DynamicEnabledPanel;

  mode: MatDrawerMode = 'over';

  constructor(
    private readonly action$: Actions,
    private readonly store: Store,
    private readonly dialog: MatDialog,
    private readonly panelRegistry: PanelRegistryService,
  ) {}

  ngOnInit() {
    this.store.dispatch(new SetPanelActive());
    this.setupConfirmModalSystem();
    this.currentPanel$.subscribe(cp => {
      this.mode =
        PANELS_SIDE_MODE.indexOf(cp) > -1 && window.innerWidth > PLANNING_MIN_WIDTH_SIDE
          ? 'side'
          : 'over';
    });
  }

  // contentChildren are set
  ngAfterContentInit() {
    this.setupPanelShowingStrategy();
  }

  setupPanelShowingStrategy() {
    const currentPanelSubscription = this.currentPanel$.subscribe(val => {
      if (val === '') {
        this.hideTab();

        return;
      }
      const found = this.panelRegistry.getPanelByKey(val);
      if (!found) {
        return;
      }
      this.showTab(found);
    });
    this.subscription.add(currentPanelSubscription);
  }

  setupConfirmModalSystem() {
    // [action, shouldFire, hasToCloseCurrent, shouldShowConfirmModal]
    const tryingActionSubscription = this.action$
      .pipe(ofActionDispatched(TryActionUnlessPanelIsBlocking))
      .pipe(
        map(({ action }) => {
          if (!this.currentShown) {
            return [action, true, false, false];
          }
          if (
            !this.currentShown.hasToShowConfirmOnExit() ||
            !this.currentShown.isDirty()
          ) {
            return [action, true, true, false];
          }

          return [action, false, true, true];
        }),
        mergeMap(([action, shouldFire, hasToCloseCurrent, shouldShowConfirmModal]) => {
          if (!shouldShowConfirmModal) {
            return from([[action, shouldFire, hasToCloseCurrent, false]]);
          }

          return this.dialog
            .open(UnsavedChangesModalComponent, {
              data: this.currentShown.canSave && this.currentShown.canSave(),
            })
            .afterClosed()
            .pipe(
              take(1),
              map(res => [action, res, hasToCloseCurrent, true]),
            );
        }),
        mergeMap(([action, shouldFire, hasToCloseCurrent, actionFromModal]) => {
          if (!shouldFire) {
            return this.store.dispatch(new FailTryActionUnlessPanelIsBlocking());
          }
          if (actionFromModal && this.currentShown && this.currentShown.closeAction) {
            this.currentShown.closeAction(action);

            return of('');
          }
          if (hasToCloseCurrent) {
            return this.store
              .dispatch(new CloseCurrentPanel())
              .pipe(mergeMap(() => this.store.dispatch(action)));
          }

          return this.store.dispatch(action);
        }),
      )
      .subscribe();
    this.subscription.add(tryingActionSubscription);
  }

  showTab<T extends DynamicEnabledPanel>(key: PanelContainerInput<T>) {
    if (this.isShowingSomething) {
      this.hideTab();
    }
    this.isShowingSomething = true;
    this.isSideOpenedSubject.next(true);
    const componentFactory = key.factory.resolveComponentFactory(key.val);

    const viewContainerRef = this.dynamicTabPlaceholder.viewContainer;

    // create a component instance
    const componentRef = viewContainerRef.createComponent(componentFactory);

    // set the according properties on our component instance
    const instance: T = componentRef.instance;

    this.currentShown = instance;
  }

  hideTab() {
    if (!this.isShowingSomething) {
      return;
    }
    this.isShowingSomething = false;
    this.isSideOpenedSubject.next(false);
    const closeAnimation = this.store.selectSnapshot(PanelsState.closeAnimation);

    if (closeAnimation) {
      setTimeout(() => {
        this.dynamicTabPlaceholder.viewContainer.remove();
      }, 300);
    } else {
      this.dynamicTabPlaceholder.viewContainer.remove();
    }
    this.currentShown = undefined;
  }

  ngOnDestroy(): void {
    this.store.dispatch(new SetPanelUnactivated());
  }
}
