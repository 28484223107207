import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Logout } from '@app/core/states/auth.actions';
import { AuthState } from '@app/core/states/auth.state';
import { environment } from '@env/environment';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { MobileSidenavService } from '@radioking/shared/common-services';
import amplitude from 'amplitude-js';
import { Observable } from 'rxjs';

@Component({
  selector: 'rk-mobile-user-menu',
  templateUrl: './mobile-user-menu.component.html',
  styleUrls: ['./mobile-user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileUserMenuComponent {
  @Select(AuthState.currentUsername)
  username$: Observable<string>;

  @Select(AuthState.currentEmail)
  email$: Observable<string>;

  @Select(AuthState.currentProfilePic)
  profilePic$: Observable<string>;

  clientAreaUrl = `${environment.urls.WHMCS}/clientarea.php`;

  constructor(private readonly mobileSidenavService: MobileSidenavService) {}

  openKnowledgeBase() {
    amplitude.getInstance().logEvent('show knowledge center');
  }

  closeUserMenu() {
    this.mobileSidenavService.close();
  }

  rkDomain = () => environment.urls.RADIOKING_DOMAIN;

  @Dispatch()
  logout() {
    return new Logout();
  }
}
