<input
  #trackUploadFileInput
  type="file"
  [accept]="accept"
  [multiple]="true"
  (change)="onFileSelect($event)"
  class="hidden input-file"
/>
<div class="block">
  <rk-button
    class="w-full"
    [label]="'MEDIA.ACTIONS.ADD_TRACKS' | translate"
    faIcon="fa-plus"
    (click)="selectTrack()"
    [disabled]="!(canUpload$ | async)"
    [loading]="(hasUploads$ | async) && !(allCompleted$ | async)"
    [fullWidth]="true"
    data-tour="upload"
    [pulse]="pulse"
  ></rk-button>
</div>
