import { AfterViewInit, Component, ElementRef, HostBinding, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MainSideNavService } from '@app/core/services/main-side-nav.service';
import { PageNotAvailable } from '@app/core/states/app.actions';
import { LogNav, SetEventProperties } from '@app/core/states/events-tracking.actions';
import { EventProperties } from '@app/core/states/events-tracking.state';
import { DisplayUpgradeDiscoverModal } from '@app/core/states/live-tracking.actions';
import { AMPLITUDE_EVENT } from '@app/shared/amplitude-events';
import { formatTrackingNavName } from '@app/shared/utils';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import {
  CheckListGenerate,
  ChecklistStep,
  CompleteStep,
  OnboardingChecklistState,
} from '@radioking/onboarding-checklist';
import amplitude from 'amplitude-js';
import { combineLatest, fromEvent } from 'rxjs';
import {
  debounceTime,
  filter,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

@Component({
  selector: 'rk-nav-vertical-item',
  templateUrl: './nav-vertical-item.component.html',
  styleUrls: ['./nav-vertical-item.component.scss'],
})
export class RkNavVerticalItemComponent implements AfterViewInit {
  @HostBinding('class')
  classes = 'nav-item';

  @Input()
  radioId: number;

  @Input()
  item: any;

  @Input()
  isActive: boolean;

  @Input()
  isLocked = false;

  @Input()
  isSubMenu = false;

  pulse = false;

  constructor(
    private readonly mainSideNavService: MainSideNavService,
    private readonly elRef: ElementRef,
    private readonly router: Router,
    private readonly actions$: Actions,
    private readonly store: Store,
  ) {}

  ngAfterViewInit() {
    if (this.elRef.nativeElement) {
      combineLatest([
        fromEvent(this.elRef.nativeElement, 'touchstart'),
        fromEvent(this.elRef.nativeElement, 'touchend'),
      ])
        .pipe(
          takeUntil(
            fromEvent(this.elRef.nativeElement, 'touchmove').pipe(tap(() => 'STOP MOVE')),
          ),
          withLatestFrom(
            this.router.events.pipe(filter(event => event instanceof NavigationEnd)),
          ),
          debounceTime(100),
        )
        .subscribe(([[, elem]]) => {
          const linkElem = ((elem as TouchEvent).target as HTMLElement).closest('a');
          if (window.location.pathname.indexOf(linkElem.getAttribute('href')) < 0) {
            linkElem.click();
          }
        });
    }
    if (this.item?.id === 'generation') {
      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(() => {
          this.pulse = false;
        });
      this.actions$
        .pipe(ofActionSuccessful(CompleteStep))
        .pipe(
          filter(
            ({ step }) =>
              step === ChecklistStep.ADD_TO_PLANNING &&
              !this.store.selectSnapshot(OnboardingChecklistState.stepCompleted)(
                ChecklistStep.REGENERATE,
              ),
          ),
          take(1),
        )
        .subscribe(() => {
          this.pulse = true;
        });
    }
  }

  clickNavigation(item: any) {
    this.trackClickNavigation(item.id);
    if (!this.isActive) {
      if (
        Array.from(this.elRef.nativeElement.classList).indexOf('mob-hidden') > -1 &&
        window.innerWidth < 768
      ) {
        this.toastPageNotAvailable();
      } else {
        this.displayUpgradeDiscoverModal({
          origin: item.id,
        });
      }

      return;
    }
    if (item.id === 'generation' && this.pulse) {
      setTimeout(() => this.checkListGeneration(), 1000);
    }
    if (window.innerWidth < 1025) {
      this.mainSideNavService.toggleSideNav();
    }
  }

  @Dispatch()
  toastPageNotAvailable = () => new PageNotAvailable();

  @Dispatch()
  displayUpgradeDiscoverModal = (props: EventProperties) =>
    new DisplayUpgradeDiscoverModal(props);

  @Dispatch()
  checkListGeneration = () => new CheckListGenerate();

  trackClickNavigation(id: string) {
    if (this.isSubMenu) {
      this.store.dispatch(
        new SetEventProperties({
          name: formatTrackingNavName('subnav-' + id),
        }),
      );
      this.store.dispatch(new LogNav(AMPLITUDE_EVENT.NAV.CLICK_SUBNAV));
    } else {
      this.store.dispatch(
        new SetEventProperties({
          name: formatTrackingNavName('nav-' + id),
        }),
      );
      this.store.dispatch(new LogNav(AMPLITUDE_EVENT.NAV.CLICK_NAV, false));
    }

    // Don't track stats pages before new stats
    if (id.indexOf('statistics-') === -1 && id.indexOf('admin_') === -1) {
      const eventProperties = {
        tab_name: id,
      };
      amplitude.getInstance().logEvent('click_tab', eventProperties);
    }
  }

  getUrl() {
    return this.isActive ? '/radio/' + this.radioId + this.item.url : null;
  }
}
