import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { UnsavedChangesModalComponent } from './unsaved-changes-modal/unsaved-changes-modal.component';

export interface CanComponentDeactivate {
  canDeactivate(): boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateModalGuard {
  constructor(private readonly dialog: MatDialog) {}

  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (component.canDeactivate && !component.canDeactivate()) {
      return this.dialog.open(UnsavedChangesModalComponent).afterClosed();
    }

    return true;
  }
}
