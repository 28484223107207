import { FilterObject } from '@app/library/models/filters.model';

const tag = '[Filters]';

export class SetFilterForBacCurrentlyEditing {
  static readonly type = `${tag} set filter for bac`;

  constructor(public filters: FilterObject) {}
}

export class ResetFilterForBacCurrentlyEditing {
  static readonly type = `${tag} reset all filter for bac`;

  constructor(public bacId?: number) {}
}

export class OpenFilterEditPanel {
  static readonly type = `${tag} open filter edit panel`;

  constructor(public bacId: number) {}
}
