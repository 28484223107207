import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { UiButtonModule } from '@radioking/ui/button';

import { DirectivesModule } from '../../../../directives/directives.module';

import { AdvancedTooltipComponent } from './advanced-tooltip.component';

@NgModule({
  imports: [CommonModule, UiButtonModule, TranslateModule, DirectivesModule],
  declarations: [AdvancedTooltipComponent],
  exports: [AdvancedTooltipComponent],
})
export class UiAdvancedTooltipModule {}
