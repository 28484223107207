import { StateOperator } from '@ngxs/store';
import { ExistingState } from '@ngxs/store/operators';
import deepEqual from 'fast-deep-equal';

export function composeWithDeepEqual<T>(...operators: StateOperator<T>[]) {
  return function composeOperator(existing: Readonly<T>): T {
    const final = operators.reduce(
      (accumulator, operator) => operator(accumulator as ExistingState<T>),
      existing,
    );
    if (deepEqual(final, existing)) {
      return existing;
    }

    return final;
  };
}
