import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Radio, ShortRadio } from '@app/core/models/Radio';
import { LiveTrackingState } from '@app/core/states/live-tracking.state';
import { SwitchToRadioRequest } from '@app/core/states/radio.actions';
import { RadioState } from '@app/core/states/radio.state';
import { UpgradeOfferModalComponent } from '@app/shared/modals/upgrade-offer/components/upgrade-offer-modal/upgrade-offer-modal.component';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { ToastableItem, ToasterService } from '@radioking/shared/common-services';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { Autoplay, Navigation, SwiperOptions } from 'swiper';
import { SwiperEvents } from 'swiper/types';

@Component({
  selector: 'rk-disabled-radio',
  templateUrl: './disabled-radio.component.html',
  styleUrls: ['./disabled-radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisabledRadioComponent implements OnInit, OnDestroy {
  @Select(RadioState.currentRadio)
  radio$: Observable<Radio>;

  @Select(RadioState.currentRadioId)
  radioId$: Observable<number>;

  @Select(RadioState.radios)
  radios$: Observable<ShortRadio[]>;

  @Select(LiveTrackingState.planId)
  radioPlan$: Observable<number>;

  isLoading$: Observable<boolean>;

  swiperConfig: SwiperOptions;

  activeIndex = 0;

  subscription = new Subscription();

  toasts: ToastableItem[] = [
    {
      action: SwitchToRadioRequest,
      level: 'success',
      translationKey: marker('TOAST.RADIO.CHANGE'),
    },
  ];
  constructor(
    private readonly toaster: ToasterService,
    private readonly cdr: ChangeDetectorRef,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.swiperConfig = {
      modules: [Autoplay, Navigation],
      direction: 'horizontal',
      slidesPerView: 4,
      navigation: {
        prevEl: '.arrow-left',
        nextEl: '.arrow-right',
      },
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      loop: true,
    };
    const datas$ = combineLatest([
      this.radio$.pipe(filter(data => !!data)),
      this.radios$.pipe(filter(data => !!data)),
    ]);
    this.isLoading$ = datas$.pipe(
      map(() => false),
      startWith(true),
    );
    this.subscription.add(
      combineLatest([this.isLoading$, this.radio$]).subscribe(([loading]) => {
        if (!loading) {
          this.cdr.detectChanges();
        }
      }),
    );
    this.toaster.registerToasts(this.toasts);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  slideChange([swiper]: Parameters<SwiperEvents['slideChange']>) {
    this.activeIndex = swiper.activeIndex;
    this.cdr.detectChanges();
  }

  updateOffer() {
    this.dialog.open(UpgradeOfferModalComponent, { data: 'radio_disabled_modal' });
  }

  @Dispatch()
  switchToRadio(radioId: number) {
    return new SwitchToRadioRequest(radioId);
  }
}
