<div class="mobile-top-bar flex md:hidden">
  <img src="assets/rk-logo.png" alt="RadioKing logo" />
  <div class="flex p-4 mt-px" (click)="toggleSideNav.emit()">
    <mat-icon fontSet="fa" fontIcon="fa-times"></mat-icon>
  </div>
</div>
<div
  class="top-radio-sidebar"
  [class.disabled]="!((isRadioStarted$ | async) || (hasMoreThanOneRadio$ | async))"
  *ngIf="!(onMobile$ | async)"
  (click)="placeMatMenu()"
  [matMenuTriggerFor]="appMenu"
>
  <ng-container *ngTemplateOutlet="topBar"></ng-container>
</div>
<div
  class="top-radio-sidebar"
  *ngIf="onMobile$ | async"
  [class.disabled]="!((isRadioStarted$ | async) || (hasMoreThanOneRadio$ | async))"
  (click)="openRadioActionsModal()"
>
  <ng-container *ngTemplateOutlet="topBar"></ng-container>
</div>

<ng-template #topBar>
  <div class="background"></div>
  <div class="top-radio-bar flex items-center">
    <div>
      <img
        class="h-[50px] w-[50px] md:h-[30px] md:w-[30px] mr-3 md:mr-2"
        [src]="(currentRadio$ | async)?.logo"
        data-cy="radio-sidebar-logo-img"
      />
    </div>
    <div class="radio-name-ellipsis">
      <span data-cy="radio-sidebar-radio-name">
        {{ (currentRadio$ | async)?.name }}
      </span>
    </div>
    @if ((isRadioStarted$ | async) || (hasMoreThanOneRadio$ | async)) {
      <div>
        <rk-icon-button
          class="rounded-full mr-3.5 md:mr-0"
          [svgIcon]="'dot-standing'"
          color="white"
          [size]="(onMobile$ | async) ? 32 : 26"
          [style]="{
            'pointer-events': 'none',
            padding: (onMobile$ | async) ? '6px' : '0',
            'margin-top': (onMobile$ | async) ? '-3px' : '0'
          }"
        ></rk-icon-button>
      </div>
    }
  </div>
</ng-template>

<mat-menu #appMenu="matMenu">
  @if (isRadioStarted$ | async) {
    <ng-container *rkHasRole="'__radio__'">
      <button mat-menu-item (click)="restartRadio()">
        <mat-icon fontSet="fa" fontIcon="fa-sync-alt"></mat-icon>
        <span translate>SIDEBAR.MENU.RESTART_RADIO</span>
      </button>
      <button mat-menu-item (click)="stopRadio()">
        <mat-icon fontSet="fa" fontIcon="fa-power-off"></mat-icon>
        <span translate>SIDEBAR.MENU.STOP_RADIO</span>
      </button>
      <div *ngIf="hasMoreThanOneRadio$ | async" class="menu-separator"></div>
    </ng-container>
  }

  <ng-container *ngIf="hasMoreThanOneRadio$ | async">
    <button mat-menu-item (click)="openDialogChangeRadio()">
      <mat-icon fontSet="fa" fontIcon="fa-exchange-alt"></mat-icon>
      <span translate>SIDEBAR.MENU.CHANGE_RADIO</span>
    </button>
  </ng-container>
</mat-menu>
