import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ApiDataTransformerInterceptor } from '@app/core/http/api-data-transformer.interceptor';
import { BearerInterceptor } from '@app/core/http/bearer.interceptor';
import { SAVED_TO_LOCAL_STORAGE_NGXS } from '@app/core/ngxs/Constantes';
import { SentryErrorHandler } from '@app/core/SentryError';
import { AppState } from '@app/core/states/app.state';
import { AudioState } from '@app/core/states/audio.state';
import { AuthState } from '@app/core/states/auth.state';
import { AuthorizationState } from '@app/core/states/authorization.state';
import { CountryState } from '@app/core/states/country.state';
import { EventsTrackingState } from '@app/core/states/events-tracking.state';
import { GenreState } from '@app/core/states/genre.state';
import { I18nState } from '@app/core/states/i18n.state';
import { LiveTrackingState } from '@app/core/states/live-tracking.state';
import { RadioState } from '@app/core/states/radio.state';
import { SettingState } from '@app/core/states/setting.state';
import { ShoutOutsState } from '@app/core/states/shout-outs.state';
import { TimezoneState } from '@app/core/states/timezone.state';
import { UploadState } from '@app/core/states/upload.state';
import { StorageEngineNgxs } from '@app/core/StorageEngineNgxs';
import { BacsState } from '@app/library/states/bacs.state';
import { TrashState } from '@app/library/states/trash.state';
import { MaterialModule } from '@app/material.module';
import { SharedModule } from '@app/shared';
import { TranslateCompiler, TranslateModule } from '@ngx-translate/core';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule, STORAGE_ENGINE } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import {
  OnboardingChecklistModule,
  OnboardingChecklistState,
} from '@radioking/onboarding-checklist';
import { SchedulerCoreState } from '@radioking/scheduler-core';
import { SharedUnsavedChangesModule } from '@radioking/shared/unsaved-changes';
import { ToastrModule } from 'ngx-toastr';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

import { DashboardState } from '../../../../../libs/dashboard/src/lib/states/dashboard.state';
import { DirectivesModule } from '../../../../../libs/directives/directives.module';
import { ShareState } from '../../../../../libs/share/src/lib/states/share.state';

import { AuthenticationGuard } from './authentication/authentication.guard';
import { ErrorHandlerInterceptor } from './http/error-handler.interceptor';
import { I18nService } from './services/i18n.service';
import { ShellComponent } from './shell/shell.component';

export function TranslateMessageFormatCompilerFactory() {
  return new TranslateMessageFormatCompiler();
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule,
    MaterialModule,
    RouterModule,
    SharedUnsavedChangesModule,
    OnboardingChecklistModule,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useFactory: TranslateMessageFormatCompilerFactory,
      },
    }),
    SharedModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    ServiceWorkerModule,
    NgxsModule.forRoot([
      AppState,
      AuthState,
      AudioState,
      I18nState,
      RadioState,
      LiveTrackingState,
      GenreState,
      CountryState,
      TimezoneState,
      AuthorizationState,
      ShoutOutsState,
      UploadState,
      SettingState,
      DashboardState,
      TrashState,
      BacsState,
      OnboardingChecklistState,
      SchedulerCoreState,
      EventsTrackingState,
      ShareState,
    ]),
    NgxsStoragePluginModule.forRoot({
      key: SAVED_TO_LOCAL_STORAGE_NGXS,
    }),
    NgxsDispatchPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsFormPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    DirectivesModule,
  ],
  declarations: [ShellComponent],
  providers: [
    AuthenticationGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BearerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiDataTransformerInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    {
      provide: STORAGE_ENGINE,
      useClass: StorageEngineNgxs,
    },
    {
      provide: LOCALE_ID,
      deps: [I18nService],
      useFactory: (i18nService: I18nService) => i18nService.language,
    },
  ],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule,
  ) {
    // Import guard
    if (parentModule) {
      throw new Error(
        `${parentModule} has already been loaded. Import Core module in the AppModule only.`,
      );
    }
  }
}
