import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'rk-mobile-close-button',
  templateUrl: './mobile-close-button.component.html',
  styleUrls: ['./mobile-close-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MobileCloseButtonComponent {
  @Output()
  click: EventEmitter<Event> = new EventEmitter();
}
