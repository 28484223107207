<ng-content></ng-content>
<ng-container *ngIf="isListeningTimeExceeded">
  <div class="invisible-box"></div>
  <div class="tooltip">
    <div class="title" translate>GLOBAL.DISABLED.EXCEEDED_TOOLTIP_TITLE</div>
    <div class="content" translate>GLOBAL.DISABLED.EXCEEDED_TOOLTIP_CONTENT</div>
    <rk-button
      *rkIsOwner
      [btnStyle]="'orange'"
      (click)="updateOffer()"
      [label]="'GLOBAL.DISABLED.EXCEEDED_TOOLTIP_BUTTON' | translate"
    ></rk-button>
  </div>
</ng-container>
