<rk-blurry-background>
  <div class="noradio-modal">
    <h1 translate>GLOBAL.NORADIO.TITLE</h1>

    <div class="modal-line mb-20" translate>GLOBAL.NORADIO.NOW</div>
    <div class="modal-line" translate>GLOBAL.NORADIO.CREATE</div>
    <rk-button
      [label]="'GLOBAL.NORADIO.CREATE_BUTTON' | translate"
      [style]="{ 'margin-bottom': '11px' }"
      [btnBig]="true"
      (click)="createRadio()"
    ></rk-button>
    <br />
    <rk-button
      [btnStyle]="'link'"
      [label]="'GLOBAL.NORADIO.BACK_TO_HOME' | translate"
      [style]="{ 'font-size': '16px' }"
      (click)="backToHome()"
    ></rk-button>
  </div>
</rk-blurry-background>
