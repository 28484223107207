import { Criterion } from '@app/library/models/criterias.model';
import { Playlist } from '@app/library/models/playlist.model';

export interface PlaylistApi {
  idplaylist: number;
  idradio: number;
  name: string;
  color: string;
  type: 'manual' | 'dynamic';
  nbtracks: number;
  playlist_length: number;
  playtime_seconds: number;
  criteria?: Criterion[];
  process?: string;
}

export function convertToPlaylist(playlist: PlaylistApi): Playlist {
  return {
    id: playlist.idplaylist,
    name: playlist.name,
    color: playlist.color,
    idRadio: playlist.idradio,
    type: playlist.type,
    nbTracks: playlist.nbtracks,
    criteria: playlist.criteria?.filter(c => c.field !== 'favorite'),
    process: playlist.process,
    duration: playlist.playtime_seconds,
  };
}

export function convertToPlaylists(playlists: PlaylistApi[]): Playlist[] {
  return playlists.map(playlist => convertToPlaylist(playlist));
}
