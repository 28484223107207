import { Injectable } from '@angular/core';
import { Actions, ofActionDispatched } from '@ngxs/store';
import { merge, Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UiStoreHelperService {
  constructor(private readonly actions$: Actions) {}

  getActionMappedTo<T>(action: any[] | any, mapTo: T): Observable<T> {
    if (Array.isArray(action)) {
      return this.actions$.pipe(
        ofActionDispatched(...action),
        map(() => mapTo),
      );
    }

    return this.actions$.pipe(
      ofActionDispatched(action),
      map(() => mapTo),
    );
  }

  getLoadingState(
    actionRequest: any[] | any,
    actionDone: any[] | any,
    defaultVal = false,
  ): Observable<boolean> {
    return merge(
      this.getActionMappedTo(actionRequest, true),
      this.getActionMappedTo(actionDone, false),
    ).pipe(startWith(defaultVal), distinctUntilChanged());
  }

  getErrorState(
    actionRequest: any[] | any,
    actionError: any[] | any,
  ): Observable<boolean> {
    return merge(
      this.getActionMappedTo(actionRequest, false),
      this.getActionMappedTo(actionError, true),
    ).pipe(startWith(false), distinctUntilChanged());
  }
}
