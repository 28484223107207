import { Component } from '@angular/core';
import { AppState } from '@app/core/states/app.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'rk-update-available',
  templateUrl: './update-available.component.html',
  styleUrls: ['./update-available.component.scss'],
})
export class UpdateAvailableComponent {
  @Select(AppState.hasUpdateAvailable)
  updateAvailable$: Observable<boolean>;

  update() {
    window.location.reload();
  }
}
