import { whmcsCurrency } from '@app/core/models/radio-api.model';
import moment from 'moment-timezone';

export interface Radio {
  name: string;
  slug: string;
  logo: string;
  timezone: string;
  createdAt: moment.Moment;
}

export interface ShortRadio {
  id: number;
  name: string;
  status: 'active' | 'inactive' | 'deleted';
  logo: string;
  role: 'user' | 'customer';
}

export interface RadioLiveInfo {
  serverUrl: string;
  port: number;
  mountPoint: string;
}

export interface RadioLiveBroadcaster {
  username: string;
}

export interface RadioLiveStatus {
  startedAt: string;
  duration: number;
  broadcaster: RadioLiveBroadcaster;
}

export interface RadioStatus {
  status: 'active' | 'inactive' | 'deleted';
  streamStatus: 'started' | 'stopped';
  canUpload: boolean;
  radioPlan: RadioPlan;
  idService: number;
  downloadableChronics: boolean;
  demoRemainingDays: number;
  live?: RadioLiveStatus;
}

export type RADIO_PLAN_DESC =
  | 'Discover'
  | 'Demo'
  | 'Start'
  | 'Pro'
  | 'Business'
  | 'No Limit';

export const RADIO_PLAN_ORDER: RADIO_PLAN_DESC[] = [
  'Discover',
  'Demo',
  'Start',
  'Pro',
  'Business',
  'No Limit',
];

export interface RadioPlan {
  planId: number;
  description: RADIO_PLAN_DESC;
}

export type Billing = 'Annually' | 'Monthly';
export interface RadioSubscription {
  plan: RadioPlan;
  serviceId: number;
  billing: Billing;
  currency: whmcsCurrency;
  options: {
    radioPage: boolean;
  };
}

export interface RadioApollonStatus {
  radioRestartDeadline: moment.Moment;
  modalDisplayedDate: moment.Moment;
}

export interface ApollonVersion {
  current: string;
  expected: string;
  deadline: moment.Moment;
}
