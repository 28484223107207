import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Genre } from '@app/core/models/Genre';
import { Timezone } from '@app/core/models/Timezone';
import { AuthState } from '@app/core/states/auth.state';
import { GenresRequest } from '@app/core/states/genre.actions';
import { GenreState } from '@app/core/states/genre.state';
import { RadioState } from '@app/core/states/radio.state';
import {
  StartupRadioFailure,
  StartupRadioRequest,
  StartupRadioSuccess,
} from '@app/core/states/setting.actions';
import { TimezonesRequest } from '@app/core/states/timezone.actions';
import { TimezoneState } from '@app/core/states/timezone.state';
import { SettingRadio } from '@app/library/models/setting.model';
import { aLogEvent } from '@app/shared/amplitude-events';
import { mSanitize } from '@app/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import {
  ErrorFormFormat,
  FormHelperService,
  UiStoreHelperService,
} from '@radioking/shared/common-services';
import { SugestionInput } from '@radioking/ui/input';
import moment from 'moment-timezone';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'rk-startup-modal',
  templateUrl: './startup-modal.component.html',
  styleUrls: ['./startup-modal.component.scss'],
})
export class StartupModalComponent implements OnInit, OnDestroy {
  @ViewChild('tzModal') tzModal: TemplateRef<any>;

  startupForm: UntypedFormGroup;
  startupErrors: ErrorFormFormat;
  sampleRadioName: string;

  @Select(TimezoneState.timezoneListForInput)
  timezones$: Observable<SugestionInput[]>;

  @Select(RadioState.currentRadioTimezone)
  radioTimezone$: Observable<string>;

  @Select(GenreState.genreList)
  genres$: Observable<Genre[]>;
  @Select(AuthState.userLang)
  userLang$: Observable<string>;

  isSaving$: Observable<boolean>;

  userTimezone: Timezone;
  subscription = new Subscription();
  detectedTz: string;

  constructor(
    private readonly store: Store,
    private readonly formHelper: FormHelperService,
    private readonly dialog: MatDialog,
    private readonly uiHelper: UiStoreHelperService,
    private readonly cdr: ChangeDetectorRef,
    private readonly translate: TranslateService,
  ) {}

  ngOnInit() {
    aLogEvent('open radio name modal');
    this.store.dispatch(new TimezonesRequest());
    this.store.dispatch(new GenresRequest());

    this.isSaving$ = this.uiHelper.getLoadingState(
      [StartupRadioRequest],
      [StartupRadioSuccess, StartupRadioFailure],
    );

    this.detectedTz = moment.tz.guess();
    if (!this.detectedTz) {
      this.detectedTz = 'Europe/Paris';
    }
    this.createForm();

    this.subscription.add(
      combineLatest([this.timezones$, this.radioTimezone$])
        .pipe(filter(([tzs, rtz]) => !!tzs && !!rtz))
        .subscribe(([tzs, rtz]) => {
          let userSI = tzs.find(({ name }) => name.includes(this.detectedTz));
          if (!userSI) {
            userSI = tzs.find(({ name }) => name.includes(rtz));
          }
          if (!userSI) {
            userSI = tzs.find(({ name }) => name.includes('Europe/Paris'));
          }
          if (userSI) {
            this.userTimezone = { code: userSI.id, name: userSI.name };
            this.updateForm(tzs);
          }
        }),
    );

    this.subscription.add(
      combineLatest([
        this.genres$.pipe(filter(g => !!g)),
        this.userLang$.pipe(filter(g => !!g)),
      ]).subscribe(([genres, lang]) => {
        // Remove "Country/regions" and "talks" genres
        const filteredGenre = genres.filter(g => g.parentId !== 92 && g.parentId !== 45);
        const randomGenre =
          filteredGenre[Math.floor(Math.random() * filteredGenre.length)];
        this.sampleRadioName = this.translate
          .instant(randomGenre.translation)
          .split(' ')
          .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
        if (lang === 'fr') {
          this.sampleRadioName = `Radio ${this.sampleRadioName}`;
        } else {
          this.sampleRadioName = `${this.sampleRadioName} Radio`;
        }
        this.startupForm.get('name').setValue(this.sampleRadioName);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  createForm() {
    this.startupErrors = {
      name: {},
    };
    const detectedTzObj: Timezone = { name: this.detectedTz, code: this.detectedTz };

    this.startupForm = new UntypedFormBuilder().group({
      name: ['', [Validators.required, Validators.maxLength(150)]],
      timezone: [detectedTzObj],
    });
    this.startupForm.valueChanges.subscribe(() => {
      this.formHelper.updateErrorMapping(this.startupForm, this.startupErrors);
    });
    this.cdr.detectChanges();
  }

  updateForm(timezones: SugestionInput[]) {
    const tz = timezones.find(timezone => timezone.id === this.userTimezone.code);
    this.startupForm.patchValue({ timezone: tz || timezones[0] });
  }

  editTimezoneModal() {
    this.dialog.open(this.tzModal);
  }

  closeDialog() {
    this.startupForm.patchValue({ timezone: this.userTimezone });
    this.dialog.closeAll();
  }

  editTimezone() {
    this.userTimezone = this.startupForm.value['timezone'];
    this.dialog.closeAll();
  }

  submit() {
    this.formHelper.forceValidationTrigger(this.startupForm);
    if (this.startupForm.valid) {
      aLogEvent('set radio name');
      const radioData: Partial<SettingRadio> = {
        name: mSanitize(this.startupForm.value.name),
        timezone: this.startupForm.value.timezone.code,
      };
      this.store.dispatch(new StartupRadioRequest(radioData));
    }
  }
}
