<ng-container *ngIf="!item.hidden">
  <ng-container *rkHasRole="item.roles || null">
    <ng-container *rkIsSuperuser="item.isSuperuserRoute || false">
      <a
        class="nav-link"
        *ngIf="item.url"
        [routerLink]="getUrl()"
        [routerLinkActive]="isActive ? 'active' : ''"
        attr.data-navkey="{{ item.id }}"
        [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
        (click)="clickNavigation(item)"
        [class.locked]="isLocked"
      >
        <mat-icon
          class="nav-link-icon"
          *ngIf="item.icon"
          fontSet="fa"
          [fontIcon]="item.icon"
        ></mat-icon>
        <span class="nav-link-title">{{
          item.translate ? (item.translate | translate) : item.title
        }}</span>
        @if (item.notOnMobile) {
          <mat-icon
            class="right-icon !inline-block md:!hidden"
            fontSet="fa"
            fontIcon="fa-desktop"
          ></mat-icon>
        }
        @if (item.lockedUntilPlan) {
          <rk-locked-icon
            class="right-icon"
            [class.has-desktop-icon]="item.notOnMobile"
            *rkHasPlan="{ plan: item.lockedUntilPlan, condition: 'less_equal' }"
            icon="fa-crown"
          ></rk-locked-icon>
        }
      </a>
      <rk-animated-arrow
        *ngIf="pulse && item?.id === 'generation'"
        [description]="'CHECKLIST.ARROW.APPLY_PLANNING_DESCRIPTION' | translate"
      ></rk-animated-arrow>

      <span class="nav-link" *ngIf="item.function" (click)="item.function()">
        <mat-icon
          class="nav-link-icon"
          *ngIf="item.icon"
          fontSet="fa"
          [fontIcon]="item.icon"
        ></mat-icon>
        <span class="nav-link-title">{{
          item.translate ? (item.translate | translate) : item.title
        }}</span>
      </span>
    </ng-container>
  </ng-container>
</ng-container>
