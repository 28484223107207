import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { UiButtonModule } from '@radioking/ui/button';
import { UiLoaderModule } from '@radioking/ui/loader';

import { UiHotkeysHelpModule } from '../../../hotkeys-help/src';

import { ModalWrapperComponent } from './modal-wrapper.component';

@NgModule({
  imports: [
    CommonModule,
    UiButtonModule,
    MatIconModule,
    TranslateModule,
    UiLoaderModule,
    UiHotkeysHelpModule,
  ],
  declarations: [ModalWrapperComponent],
  exports: [ModalWrapperComponent],
})
export class UiModalWrapperModule {}
