<div id="main-navigation" class="nav vertical">
  <!-- Vertical Navigation Layout -->
  <ng-container *ngIf="currentRadioId$ | async as radioId">
    <ng-container *ngFor="let item of navigation">
      <ng-container *ngIf="!item.hidden">
        <rk-nav-vertical-collapse
          [class.mob-hidden]="item.notOnMobile"
          *ngIf="item.type === 'collapse'"
          [item]="item"
          [radioId]="radioId"
          [onMobile]="onMobile$ | async"
          [planDesc]="planDesc$ | async"
        ></rk-nav-vertical-collapse>
        <rk-nav-vertical-item
          [class.mob-hidden]="item.notOnMobile"
          *ngIf="item.type === 'item'"
          [item]="item"
          [radioId]="radioId"
          [isActive]="
            ((onMobile$ | async) !== true || !item.notOnMobile) &&
            (!item.lockedUntilPlan || planBigger(planDesc$ | async, item.lockedUntilPlan))
          "
          [isLocked]="
            !!item.lockedUntilPlan &&
            planBigger(item.lockedUntilPlan, planDesc$ | async, true)
          "
        ></rk-nav-vertical-item>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- / Vertical Navigation Layout -->
</div>
