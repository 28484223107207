<div [hidden]="!(hasUploads$ | async)" class="percentage-container">
  <div class="percentage" (click)="toggleUploadList($event)">
    <mat-progress-spinner
      [mode]="'determinate'"
      [value]="uploadPercentage$ | async"
      [diameter]="35"
      [strokeWidth]="1"
    >
    </mat-progress-spinner>
    <div
      class="percentage-value"
      *ngIf="(uploadPercentage$ | async) === 100 && (complete$ | async)"
    >
      <img src="assets/icons/upload-complete.svg" />
    </div>
    <div
      class="percentage-value"
      *ngIf="!(complete$ | async)"
      [class.finished]="(uploadPercentage$ | async) === 100"
    >
      {{ uploadPercentage$ | async }} <span class="percentage-character">%</span>
    </div>
    <div *ngIf="hasErrors$ | async" class="error-icon top-0 md:top-0.5">
      <mat-icon class="pt-px" fontSet="fa" fontIcon="fa-exclamation-circle"></mat-icon>
    </div>
  </div>
  <div class="uploads" [ngClass]="{ opened: showUploadsList }">
    <div class="uploads-header">
      <span translate>HEADER.UPLOAD.UPLOADS</span>
      <span class="uploads-actions">
        <span class="" (click)="clearUploadList()" translate>HEADER.UPLOAD.CLEAR</span>
        <span class="clear-uploads" (click)="toggleUploadList($event)" translate
          >HEADER.UPLOAD.REDUCE</span
        >
      </span>
    </div>
    <cdk-virtual-scroll-viewport
      [ngStyle]="{ minHeight: minHeight }"
      itemSize="70"
      minBufferPx="400"
      maxBufferPx="900"
      class="lines-container"
    >
      <rk-header-upload-line
        *cdkVirtualFor="let group of uploadsArray$ | async; trackBy: trackByFn"
        [upload]="group"
      ></rk-header-upload-line>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
