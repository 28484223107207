import { Radio, RadioSubscription, ShortRadio } from '@app/core/models/Radio';

const tag = '[Radio]';

export class MyRadiosRequest {
  static readonly type = `${tag} request my radios`;
  constructor(public userId: number) {}
}

export class MyRadiosSuccess {
  static readonly type = `${tag} success my radios`;
  constructor(public radios: ShortRadio[]) {}
}

export class MyRadiosFailure {
  static readonly type = `${tag} error my radios`;
  constructor(public error: Error) {}
}

export class GetRadioRequest {
  static readonly type = `${tag} request get radio`;
  constructor(public id: number) {}
}

export class GetRadioSuccess {
  static readonly type = `${tag} success get radio`;
  constructor(public id: number, public radio: Radio) {}
}

export class GetRadioFailure {
  static readonly type = `${tag} error get radio`;
  constructor(public error: Error) {}
}

export class UseSpecificRadio {
  static readonly type = `${tag} set specific radio`;
  constructor(public idRadio: number) {}
}

export class SwitchToRadioRequest {
  static readonly type = `${tag} request switch to radio`;
  constructor(public idRadio: number, public goToHome = false) {}
}

export class SwitchToRadioRequestWithoutToast {
  static readonly type = `${tag} request switch to radio without a toast`;

  constructor(public idRadio: number, public goToHome = false) {}
}

export class ForceRefethingData {
  static readonly type = `${tag} request to refetch all radio specific data`;
}

export class RadioStopRequest {
  static readonly type = `${tag} request stop radio`;
}

export class RadioStopSuccess {
  static readonly type = `${tag} success stop radio`;
}

export class RadioStopFailure {
  static readonly type = `${tag} error stop radio`;
  constructor(public error: Error) {}
}

export class RadioStartRequest {
  static readonly type = `${tag} request start radio`;
}

export class RadioStartSuccess {
  static readonly type = `${tag} success start radio`;
  constructor(public idRadio: number) {}
}

export class RadioStartFailure {
  static readonly type = `${tag} error start radio`;
  constructor(public error: Error) {}
}

export class RadioRestartRequest {
  static readonly type = `${tag} request restart radio`;
}

export class RadioRestartSuccess {
  static readonly type = `${tag} success restart radio`;
}

export class RadioRestartFailure {
  static readonly type = `${tag} error restart radio`;
  constructor(public error: Error) {}
}

export class RadioSubscriptionRequest {
  static readonly type = `${tag} request radio subscription`;
  constructor(public id: number) {}
}

export class RadioSubscriptionSuccess {
  static readonly type = `${tag} success radio subscription`;
  constructor(public subscription: RadioSubscription) {}
}

export class RadioSubscriptionFailure {
  static readonly type = `${tag} error radio subscription`;
  constructor(public error: Error) {}
}

export class AutoGenerateAndStartRadio {
  static readonly type = `${tag} auto generate and start after first track uploaded`;
}
