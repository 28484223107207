<h1>{{ plan }}</h1>
<div class="price-line mb-7.5">
  <div class="price">
    <span class="price-value">{{ getPricing() }}</span>
    <span class="per-month">{{
      (billing === 'Annually'
        ? 'GLOBAL.MODAL.UPGRADE_OFFER.PER_YEAR'
        : 'GLOBAL.MODAL.UPGRADE_OFFER.PER_MONTH'
      ) | translate
    }}</span>
  </div>
</div>
@if (billing === 'Annually') {
  <div class="monthly -mt-6.5 mb-7.5">
    <div class="line-through text-center text-lg">
      {{ getNoDiscountPricing()
      }}<span class="per-month" translate>GLOBAL.MODAL.UPGRADE_OFFER.PER_YEAR</span>
    </div>
  </div>
}
<div class="upgrade-button">
  <rk-button
    (click)="openUpgradePage()"
    [btnStyle]="recommended ? 'main' : 'orange'"
    [label]="
      (planDesc$ | async) === plan
        ? ('GLOBAL.MODAL.UPGRADE_OFFER.MY_OFFER' | translate)
        : ('GLOBAL.MODAL.UPGRADE_OFFER.CHOOSE' | translate)
    "
    [semiBig]="true"
    [fullWidth]="true"
    [disabled]="(planDesc$ | async) === plan"
  ></rk-button>
</div>
<div class="offer-details mt-5">
  <div class="limit">
    <div
      class="value vl-1"
      [innerHTML]="
        'GLOBAL.MODAL.UPGRADE_OFFER.HOURS'
          | translate: { hours: listeningHours | number: '' : listeningLocale }
      "
    ></div>
  </div>
  <div class="limit">
    <div
      class="value inline"
      [innerHTML]="
        'GLOBAL.MODAL.UPGRADE_OFFER.DISK_SPACE' | translate: { disk: diskSpace }
      "
    ></div>
    <mat-icon
      rkTooltip
      [tooltipDescription]="'GLOBAL.MODAL.UPGRADE_OFFER.DISK_SPACE_TOOLTIP' | translate"
      [hideTooltip$]="focused$"
      fontSet="fa"
      fontIcon="fa-info-circle"
    ></mat-icon>
  </div>
  <div class="limit">
    <div
      class="value vl-2"
      [innerHTML]="
        (plan === 'Discover'
          ? 'GLOBAL.MODAL.UPGRADE_OFFER.LISTENERS_50'
          : 'GLOBAL.MODAL.UPGRADE_OFFER.LISTENERS'
        ) | translate
      "
    ></div>
  </div>
  <div class="limit">
    <div
      class="value"
      [innerHTML]="'GLOBAL.MODAL.UPGRADE_OFFER.ADVANCED_SCHEDULING' | translate"
    ></div>
  </div>
  <div class="limit">
    <div
      class="value"
      [innerHTML]="'GLOBAL.MODAL.UPGRADE_OFFER.AUTO_BROADCASTING' | translate"
    ></div>
  </div>
  <div class="limit">
    <div
      class="value"
      [innerHTML]="'GLOBAL.MODAL.UPGRADE_OFFER.SHARE_SMARTLINK' | translate"
    ></div>
  </div>
  <div class="limit">
    <div
      class="value inline vl-3"
      [innerHTML]="'GLOBAL.MODAL.UPGRADE_OFFER.STREAMS' | translate: { plan }"
    ></div>
    <mat-icon
      rkTooltip
      [tooltipTitle]="
        planBigger(plan, 'Start')
          ? ('GLOBAL.MODAL.UPGRADE_OFFER.STREAMS_TITLE_TOOLTIP' | translate)
          : ''
      "
      [tooltipDescription]="getStreamsDescription() | translate: { plan }"
      [hideTooltip$]="focused$"
      fontSet="fa"
      fontIcon="fa-info-circle"
    ></mat-icon>
  </div>
  <div class="limit">
    <div
      class="value inline"
      [innerHTML]="'GLOBAL.MODAL.UPGRADE_OFFER.NORMALIZE' | translate: { plan }"
    ></div>
    <mat-icon
      rkTooltip
      [tooltipDescription]="
        'GLOBAL.MODAL.UPGRADE_OFFER.NORMALIZE_TOOLTIP' | translate: { plan }
      "
      [hideTooltip$]="focused$"
      fontSet="fa"
      fontIcon="fa-info-circle"
    ></mat-icon>
  </div>
  <div class="limit">
    <div
      class="value"
      [innerHTML]="
        (plan === 'Discover'
          ? 'GLOBAL.MODAL.UPGRADE_OFFER.REAL_TIME_STATS'
          : 'GLOBAL.MODAL.UPGRADE_OFFER.REAL_TIME_STATS_HISTORY'
        ) | translate
      "
    ></div>
  </div>
  <div class="limit" *ngIf="planBigger(plan, 'Discover')">
    <div
      class="value"
      [innerHTML]="'GLOBAL.MODAL.UPGRADE_OFFER.BROADCAST_LIVE' | translate"
    ></div>
  </div>
  <div class="limit" *ngIf="planBigger(plan, 'Discover')">
    <div
      class="value"
      [innerHTML]="'GLOBAL.MODAL.UPGRADE_OFFER.PLAYER_WIDGETS' | translate"
    ></div>
  </div>
  <div class="limit" *ngIf="planBigger(plan, 'Discover')">
    <div class="value" [innerHTML]="'GLOBAL.MODAL.UPGRADE_OFFER.DJS' | translate"></div>
  </div>
  <div class="limit" *ngIf="planBigger(plan, 'Discover')">
    @if (plan === 'Start') {
      @if (['Demo', 'Discover'].includes(planDesc$ | async)) {
        <div
          class="value inline"
          [innerHTML]="'GLOBAL.MODAL.UPGRADE_OFFER.OPTION_RADIO_PAGE' | translate"
        ></div>
        <mat-icon
          rkTooltip
          [tooltipDescription]="
            'GLOBAL.MODAL.UPGRADE_OFFER.RADIO_PAGE_TOOLTIP'
              | translate: { pricing: getOptionPricing() }
          "
          [hideTooltip$]="focused$"
          fontSet="fa"
          fontIcon="fa-info-circle"
        ></mat-icon>
      } @else if (radioPageAvailable$ | async) {
        <div class="value" translate>GLOBAL.MODAL.UPGRADE_OFFER.RADIO_PAGE_ENABLED</div>
      } @else {
        <div class="value">
          <rk-button
            btnStyle="orange"
            [btnBig]="true"
            [fullWidth]="true"
            [label]="
              'GLOBAL.MODAL.UPGRADE_OFFER.RADIO_PAGE_CTA'
                | translate: { pricing: getOptionPricing() }
            "
            (click)="openChangeOptionsPage()"
          >
          </rk-button>
        </div>
      }
    } @else {
      <div
        class="value"
        [innerHTML]="'GLOBAL.MODAL.UPGRADE_OFFER.RADIO_PAGE' | translate"
      ></div>
    }
  </div>
  <div class="limit" *ngIf="planBigger(plan, 'Start')">
    <div
      class="value inline"
      [innerHTML]="'GLOBAL.MODAL.UPGRADE_OFFER.AUDIO_SHOUTOUTS' | translate"
    ></div>
    <mat-icon
      rkTooltip
      [tooltipDescription]="
        'GLOBAL.MODAL.UPGRADE_OFFER.AUDIO_SHOUTOUTS_TOOLTIP' | translate: { plan }
      "
      [hideTooltip$]="focused$"
      fontSet="fa"
      fontIcon="fa-info-circle"
    ></mat-icon>
  </div>
  <div class="limit" *ngIf="planBigger(plan, 'Start')">
    <div
      class="value inline"
      [innerHTML]="'GLOBAL.MODAL.UPGRADE_OFFER.WEEKLY_REPORTS' | translate"
    ></div>
    <mat-icon
      rkTooltip
      [tooltipDescription]="
        'GLOBAL.MODAL.UPGRADE_OFFER.WEEKLY_REPORTS_TOOLTIP' | translate: { plan }
      "
      [hideTooltip$]="focused$"
      fontSet="fa"
      fontIcon="fa-info-circle"
    ></mat-icon>
  </div>
  <div class="limit" *ngIf="planBigger(plan, 'Pro')">
    <div
      class="value inline"
      [innerHTML]="'GLOBAL.MODAL.UPGRADE_OFFER.ANDROID_APP' | translate"
    ></div>
    <mat-icon
      rkTooltip
      [tooltipDescription]="'GLOBAL.MODAL.UPGRADE_OFFER.ANDROID_TOOLTIP' | translate"
      [hideTooltip$]="focused$"
      fontSet="fa"
      fontIcon="fa-info-circle"
    ></mat-icon>
  </div>
</div>
