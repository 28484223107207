import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Select } from '@ngxs/store';
import { interval, Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { OnboardingChecklistState } from '../states/onboarding-checklist.state';

@Component({
  selector: 'rk-confetti',
  templateUrl: './confetti.component.html',
  styleUrls: ['./confetti.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfettiComponent implements OnInit {
  @Select(OnboardingChecklistState.completed)
  checklistCompleted$: Observable<boolean>;

  constructor() {}

  ngOnInit(): void {
    this.checklistCompleted$.pipe(filter(completed => completed)).subscribe(() => {
      this.confetti();
    });
  }

  confetti() {
    document.getElementsByClassName('confetti')[0]?.remove();
    const confettiItems = document.createElement('div');
    confettiItems.classList.add('confetti');
    const height = 20;
    const width = 20;

    for (let i = 0; i < 300; i++) {
      const scale = Math.random() * 1.75 + 1;
      const confItem = document
        .getElementsByClassName('base-confetti')[0]
        .cloneNode(true) as HTMLElement;
      confItem.classList.remove('base-confetti');
      confItem.style.setProperty('animation-duration', `${1 + Math.random() * 3}s`);
      confItem.style.setProperty('transform', `scale(${Math.max(0.5, Math.random())})`);

      const svgChild = confItem.firstChild as HTMLElement;
      svgChild.style.setProperty(
        'animation',
        `${2 + Math.random() * 5}s rotate linear infinite`,
      );
      confItem.style.setProperty('left', `${Math.random() * 100}vw`);
      confItem.style.setProperty('top', `-20px`);
      confItem.style.setProperty('width', `${width * scale}px`);
      confItem.style.setProperty('height', `${height * scale}px`);

      confettiItems.append(confItem);
    }

    // Allows to show the confettis only when user comes back to manager after tutorial step
    interval(500)
      .pipe(
        filter(() => !document.hidden),
        first(),
      )
      .subscribe(() => {
        document.body.append(confettiItems);
      });
  }
}
