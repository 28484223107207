import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RadioState } from '@app/core/states/radio.state';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { catchError, filter, mergeMap, take } from 'rxjs/operators';

import {
  DashboardOverview,
  fakeTimeline,
  Followers,
  Peak,
  Ranking,
  Timeline,
  TracksWithSchedule,
} from '../models/Dashboard';
import { DashboardService } from '../services/dashboard.service';

import {
  ConsumptionFailure,
  ConsumptionRequest,
  ConsumptionSuccess,
  DashboardOverviewFailure,
  DashboardOverviewRequest,
  DashboardOverviewSuccess,
  FollowersFailure,
  FollowersRequest,
  FollowersSuccess,
  ListenersPeakFailure,
  ListenersPeakRequest,
  ListenersPeakSuccess,
  ListenersPeriodFailure,
  ListenersPeriodRequest,
  ListenersPeriodSuccess,
  RankingFailure,
  RankingRequest,
  RankingSuccess,
  TimelineFailure,
  TimelineRequest,
  TimelineSuccess,
  TimelineUpdateRequest,
  UpdatePeak,
} from './dashboard.actions';

export class DashboardStateModel {
  peak: Peak;
  listeningTimeHour: number;
  listeningTimeLimitHour: number;
  listeningTimePercent: number;
  diskSpaceGo: number;
  diskSpaceLimitGo: number;
  diskSpacePercent: number;
  bandwidthGo: number;
  bandwidthLimitGo: number;
  bandwidthPercent: number;
  followers: Followers;
  ranking: Ranking;
  overview: DashboardOverview;
  timeline: Timeline;
}

@State<DashboardStateModel>({
  name: 'dashboard',
  defaults: {
    peak: null,
    listeningTimeHour: 0,
    listeningTimeLimitHour: 0,
    listeningTimePercent: 0,
    diskSpaceGo: 0,
    diskSpaceLimitGo: 0,
    diskSpacePercent: 0,
    bandwidthGo: 0,
    bandwidthLimitGo: 0,
    bandwidthPercent: 0,
    followers: { followers: 0, oldFollowers: 0, variation: 0 },
    ranking: { oldRank: 0, rank: 0, variation: 0 },
    overview: null,
    timeline: null,
  },
})
@Injectable()
export class DashboardState {
  constructor(
    private readonly dashboardService: DashboardService,
    private readonly store: Store,
  ) {}

  @Selector()
  static diskSpace(ctx: DashboardStateModel): number {
    return ctx.diskSpaceGo;
  }

  @Selector()
  static diskSpaceLimit(ctx: DashboardStateModel): number {
    return ctx.diskSpaceLimitGo;
  }

  @Selector()
  static diskSpacePercent(ctx: DashboardStateModel): number {
    return ctx.diskSpacePercent;
  }

  @Selector()
  static bandwidthUsage(ctx: DashboardStateModel): number {
    return ctx.bandwidthGo;
  }

  @Selector()
  static listeningTime(ctx: DashboardStateModel): number {
    return ctx.listeningTimeHour;
  }

  @Selector()
  static listeningTimeLimit(ctx: DashboardStateModel): number {
    return ctx.listeningTimeLimitHour;
  }

  @Selector()
  static listeningTimePercent(ctx: DashboardStateModel): number {
    return ctx.listeningTimePercent;
  }

  @Selector()
  static listeningTimeExceeded(ctx: DashboardStateModel): boolean {
    return ctx.listeningTimePercent >= 100;
  }

  @Selector()
  static listenersPeak(ctx: DashboardStateModel): Peak {
    return ctx.peak;
  }

  @Selector()
  static followers(ctx: DashboardStateModel): Followers {
    return ctx.followers;
  }

  @Selector()
  static ranking(ctx: DashboardStateModel): Ranking {
    return ctx.ranking;
  }

  @Selector()
  static overview(ctx: DashboardStateModel): DashboardOverview {
    return ctx.overview;
  }

  @Selector()
  static timeline(ctx: DashboardStateModel): Timeline {
    return ctx.timeline ?? fakeTimeline;
  }

  @Selector()
  static timelinePreviousTracks(ctx: DashboardStateModel): TracksWithSchedule[] {
    return ctx.timeline ? ctx.timeline.previousTracks : fakeTimeline.previousTracks;
  }

  @Selector()
  static timelineCurrentTrack(ctx: DashboardStateModel): TracksWithSchedule {
    return ctx.timeline ? ctx.timeline.currentTrack : fakeTimeline.currentTrack;
  }

  @Selector()
  static timelineNextTracks(ctx: DashboardStateModel): TracksWithSchedule[] {
    return ctx.timeline ? ctx.timeline.nextTracks : fakeTimeline.nextTracks;
  }

  @Action(ConsumptionRequest)
  getConsumption(ctx: StateContext<DashboardStateModel>) {
    return this.getCurrentRadioId().pipe(
      mergeMap(id => {
        return this.dashboardService.getConsumption(id);
      }),
      mergeMap(data => ctx.dispatch(new ConsumptionSuccess(data))),
      catchError(err => {
        if (err.status === 404) {
          return null;
        }

        return ctx.dispatch(new ConsumptionFailure(err));
      }),
    );
  }

  @Action(ConsumptionSuccess)
  getConsumptionSuccess(
    ctx: StateContext<DashboardStateModel>,
    { consumption }: ConsumptionSuccess,
  ) {
    ctx.setState(
      patch({
        listeningTimeHour: consumption.listening.usage,
        listeningTimeLimitHour: consumption.listening.limit,
        listeningTimePercent: consumption.listening.usagePercent,
        diskSpaceGo: consumption.disk.usage,
        diskSpaceLimitGo: consumption.disk.limit,
        diskSpacePercent: consumption.disk.usagePercent,
        bandwidthGo: consumption.bandwidth.usage,
        bandwidthLimitGo: consumption.bandwidth.limit,
        bandwidthPercent: consumption.bandwidth.usagePercent,
      }),
    );
  }

  @Action(ListenersPeriodRequest)
  getListeners(ctx: StateContext<DashboardStateModel>, { from }: ListenersPeriodRequest) {
    return this.getCurrentRadioId().pipe(
      mergeMap(id => {
        return this.dashboardService.getListeners(id, from);
      }),
      mergeMap(data => ctx.dispatch(new ListenersPeriodSuccess(data))),
      catchError(err => ctx.dispatch(new ListenersPeriodFailure(err))),
    );
  }

  @Action(ListenersPeakRequest)
  getListenersPeak(ctx: StateContext<DashboardStateModel>) {
    return this.getCurrentRadioId().pipe(
      mergeMap(id => {
        return this.dashboardService.getPeak(id);
      }),
      mergeMap(data => ctx.dispatch(new ListenersPeakSuccess(data))),
      catchError(err => ctx.dispatch(new ListenersPeakFailure(err))),
    );
  }

  @Action([ListenersPeakSuccess, UpdatePeak])
  getListenersPeakSuccess(
    ctx: StateContext<DashboardStateModel>,
    { peak }: ListenersPeakSuccess | UpdatePeak,
  ) {
    ctx.setState(
      patch({
        peak,
      }),
    );
  }

  @Action(FollowersRequest)
  getRadioFollowers(ctx: StateContext<DashboardStateModel>) {
    return this.getCurrentRadioId().pipe(
      mergeMap(id => {
        return this.dashboardService.getFollowers(id);
      }),
      mergeMap(data => ctx.dispatch(new FollowersSuccess(data))),
      catchError(err => ctx.dispatch(new FollowersFailure(err))),
    );
  }

  @Action(FollowersSuccess)
  getRadioFollowersSuccess(
    ctx: StateContext<DashboardStateModel>,
    { followers }: FollowersSuccess,
  ) {
    ctx.setState(
      patch({
        followers,
      }),
    );
  }

  @Action(RankingRequest)
  getRadioRanking(ctx: StateContext<DashboardStateModel>) {
    return this.getCurrentRadioId().pipe(
      mergeMap(id => {
        return this.dashboardService.getRanking(id);
      }),
      mergeMap(data => ctx.dispatch(new RankingSuccess(data))),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 404) {
          return null;
        }

        return ctx.dispatch(new RankingFailure(err));
      }),
    );
  }

  @Action(RankingSuccess)
  getRadioRankingSuccess(
    ctx: StateContext<DashboardStateModel>,
    { ranking }: RankingSuccess,
  ) {
    ctx.setState(
      patch({
        ranking,
      }),
    );
  }

  @Action(DashboardOverviewRequest)
  getDashboardOverview(
    ctx: StateContext<DashboardStateModel>,
    {}: DashboardOverviewRequest,
  ) {
    return this.getCurrentRadioId().pipe(
      mergeMap(id => {
        return this.dashboardService.getOverview(id);
      }),
      mergeMap(overview => {
        return ctx.dispatch(new DashboardOverviewSuccess(overview));
      }),
      catchError(err => ctx.dispatch(new DashboardOverviewFailure(err))),
    );
  }

  @Action(DashboardOverviewSuccess)
  getDashboardOverviewSuccess(
    ctx: StateContext<DashboardStateModel>,
    { overview }: DashboardOverviewSuccess,
  ) {
    ctx.setState(
      patch({
        overview,
      }),
    );
  }

  @Action([TimelineRequest, TimelineUpdateRequest])
  getTimeline(
    ctx: StateContext<DashboardStateModel>,
    {}: TimelineRequest | TimelineUpdateRequest,
  ) {
    return this.getCurrentRadioId().pipe(
      mergeMap(id => {
        return this.dashboardService.getTimeline(id);
      }),
      mergeMap(data => {
        return ctx.dispatch(new TimelineSuccess(data));
      }),
      catchError(err => ctx.dispatch(new TimelineFailure(err))),
    );
  }

  @Action(TimelineSuccess)
  getTimelineSuccess(
    ctx: StateContext<DashboardStateModel>,
    { timeline }: TimelineSuccess,
  ) {
    ctx.setState(
      patch({
        timeline,
      }),
    );
  }

  @Action(TimelineFailure)
  getTimelineError(ctx: StateContext<DashboardStateModel>) {
    ctx.setState(
      patch({
        timeline: null,
      }),
    );
  }

  getCurrentRadioId(): Observable<number> {
    return this.store.select(RadioState.currentRadioId).pipe(
      filter(data => !!data),
      take(1),
    );
  }
}
