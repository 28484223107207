import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { UiMobileChangeRadioModule } from '@radioking/ui/mobile-change-radio';
import { UiMobileSideHeaderModule } from '@radioking/ui/mobile-side-header';

import { MobileRadioActionsComponent } from './mobile-radio-actions.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    UiMobileSideHeaderModule,
    UiMobileChangeRadioModule,
  ],
  declarations: [MobileRadioActionsComponent],
  exports: [MobileRadioActionsComponent],
})
export class UiMobileRadioActionsModule {}
