import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'rk-colored-hint',
  templateUrl: './colored-hint.component.html',
  styleUrls: ['./colored-hint.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ColoredHintComponent {
  @Input()
  type: 'warning' | 'info' | 'error' = 'warning';

  @Input()
  inline = false;
}
