<div
  class="root"
  [class.show]="show"
  (click)="close($event)"
  [class]="options.from"
  [class.full-height]="options.bottomFullHeight"
  [class.no-scroll]="options.noScroll"
>
  <div class="bg" [class.no-bg]="!options.hasBackdrop"></div>
  <div class="content">
    <rk-mobile-close-button
      *ngIf="options.from === 'bottom'"
      (click)="close($event)"
    ></rk-mobile-close-button>
    <div
      class="body"
      (click)="touchBody($event)"
      [class.no-padding]="!options.hasPadding"
      [style]="options.style"
      [class.gray]="options.bgType === 'gray'"
    >
      <ng-template #container></ng-template>
    </div>
  </div>
</div>
