import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Country } from '@app/core/models/Country';
import { Genre, getGenreTranslationKey } from '@app/core/models/Genre';
import { Timezone } from '@app/core/models/Timezone';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ApiGenre {
  idgenre: number;
  name: string;
  slug: string;
  parent_id: number;
}

export interface ApiTimezone {
  code: string;
  label: string;
}

export interface ApiCountry {
  code: string;
  label: string;
}

const genericURL = {
  allGenres: () => `${environment.urls.MAIN_API_V2}/misc/radio/genre`,
  allTimezones: () => `${environment.urls.MAIN_API_V2}/misc/timezone`,
  allCountries: () => `${environment.urls.MAIN_API_V2}/misc/country`,
};

@Injectable({
  providedIn: 'root',
})
export class GenericDataService {
  constructor(private readonly httpClient: HttpClient) {}
  static convertToGenre(genre: ApiGenre[]): Genre[] {
    return genre.map(g => ({
      id: g.idgenre,
      name: g.name,
      slug: g.slug,
      parentId: g.parent_id,
      translation: getGenreTranslationKey(g.slug),
    }));
  }

  static convertToTimezone(timezone: ApiTimezone[]): Timezone[] {
    return timezone.map(t => ({ code: t.code, name: t.label }));
  }

  static convertToCountry(country: ApiCountry[]): Country[] {
    return country.map(t => ({ code: t.code, name: t.label }));
  }

  getAllGenres(): Observable<Genre[]> {
    return this.httpClient
      .get<ApiGenre[]>(genericURL.allGenres())
      .pipe(map(GenericDataService.convertToGenre));
  }

  getTimezones(): Observable<Timezone[]> {
    return this.httpClient
      .get<ApiTimezone[]>(genericURL.allTimezones())
      .pipe(map(GenericDataService.convertToTimezone));
  }

  getCountries(): Observable<Country[]> {
    return this.httpClient
      .get<ApiCountry[]>(genericURL.allCountries())
      .pipe(map(GenericDataService.convertToCountry));
  }
}
