import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  MobileSidenavOptions,
  MobileSidenavService,
} from '@radioking/shared/common-services';
import { fromEvent, Subscription, zip } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'rk-custom-modal-wrapper',
  templateUrl: './custom-modal-wrapper.component.html',
  styleUrls: ['./custom-modal-wrapper.component.scss'],
})
export class CustomModalWrapperComponent implements OnInit, AfterViewInit, OnDestroy {
  show = false;

  defaultOptions: MobileSidenavOptions = {
    from: 'bottom',
    hasBackdrop: true,
    hasPadding: true,
    bgType: 'white',
    bottomFullHeight: false,
    noScroll: false,
    style: null,
  };

  options: MobileSidenavOptions = { ...this.defaultOptions };

  @Output()
  readonly cancelled = new EventEmitter();

  @Output()
  readonly submitted = new EventEmitter();

  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;

  subscription = new Subscription();

  constructor(
    private readonly modalService: MobileSidenavService,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly host: ElementRef,
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.modalService.openedDialog().subscribe(dialog => {
        if (dialog) {
          this.options = { ...this.defaultOptions, ...dialog.options };
          const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
            dialog.dialog,
          );
          this.options.bottomFullHeight =
            dialog.options?.bottomFullHeight ?? this.defaultOptions.bottomFullHeight;

          const componentRef = this.container.createComponent(componentFactory);
          componentRef.instance.mobileData = { mobile: true, ...this.options.data };
          // Wait to enable animation first time
          setTimeout(() => {
            this.show = true;
          }, 100);
        } else {
          this.show = false;
          // Wait hide animation to finish
          setTimeout(() => {
            this.container.clear();
          }, 300);
        }
      }),
    );
  }

  ngAfterViewInit() {
    this.subscription.add(
      zip(
        fromEvent<TouchEvent>(this.host.nativeElement, 'touchstart'),
        fromEvent<TouchEvent>(this.host.nativeElement, 'touchend').pipe(
          withLatestFrom(fromEvent<TouchEvent>(this.host.nativeElement, 'touchmove')),
        ),
      ).subscribe({
        next: ([touchstartEvent, [_, touchendEvent]]) => {
          const xDiff =
            touchstartEvent.touches[0].clientX - touchendEvent.touches[0].clientX;
          if (Math.abs(xDiff) > 0.25 * document.body.clientWidth) {
            if (this.options.from === 'right' && xDiff < 0) {
              // Swipe left to right
              this.modalService.close();
            } else if (this.options.from === 'left' && xDiff > 0) {
              // Swipe right to left
              this.modalService.close();
            }
          }
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /*
   * Prevent from closing when touch into modal content
   */
  touchBody(e: Event) {
    e.stopPropagation();
  }

  close(e: Event) {
    e.stopPropagation();
    this.modalService.close();
  }
}
