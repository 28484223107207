<div class="header">
  <div (click)="closeUserMenu()" class="flex items-center gap-3">
    <rk-avatar
      [image]="profilePic$ | async"
      [name]="username$ | async"
      [size]="30"
      data-cy="user-icon"
    ></rk-avatar>
    <mat-icon fontSet="fa" fontIcon="fa-chevron-up"></mat-icon>
  </div>
</div>

<div class="content">
  <div class="user">
    <rk-avatar
      [image]="profilePic$ | async"
      [name]="username$ | async"
      [size]="70"
    ></rk-avatar>
    <div>
      <span data-cy="user-username">{{ username$ | async }}</span>
      <span data-cy="user-email">{{ email$ | async }}</span>
    </div>
  </div>

  <div class="links">
    <a
      target="_blank"
      [href]="'HEADER.USER.PROFILE_URL' | translate: { domain: rkDomain() }"
      translate
      >HEADER.USER.PROFILE</a
    >
    <a target="_blank" [href]="clientAreaUrl" translate>HEADER.USER.CUSTOMER_AREA</a>
    <a
      target="_blank"
      (click)="openKnowledgeBase()"
      href="https://www.radioking.com/on/knowledgebase/9/Radio-Manager"
      translate
      >HEADER.USER.HELP</a
    >
    <a target="_blank" [href]="'HEADER.USER.CHANGELOG_URL' | translate" translate
      >HEADER.USER.CHANGELOG</a
    >
    <div class="logout" (click)="logout()" translate>HEADER.USER.LOGOUT</div>
  </div>
</div>
