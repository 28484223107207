import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'rk-mobile-right-page-wrapper',
  templateUrl: './mobile-right-page-wrapper.component.html',
  styleUrls: ['./mobile-right-page-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileRightPageWrapperComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('container')
  container: ElementRef<HTMLDivElement>;
  @Input()
  fullWidth = true;

  @Input()
  title: string;

  @Input()
  isContentLoading$: Observable<boolean>;

  @Input()
  enableScrollbar = true;

  @Output()
  readonly cancelled = new EventEmitter();

  @Output()
  readonly actionInsteadOfCancel = new EventEmitter();

  @Output()
  readonly submitted = new EventEmitter();

  @ViewChild('mainContent', { static: true })
  mainContent: ElementRef;

  windowHeight = window.innerHeight;
  subscription = new Subscription();

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.isContentLoading$) {
      this.subscription.add(
        this.isContentLoading$.subscribe(data => {
          if (data && this.mainContent) {
            this.mainContent.nativeElement.scrollTop = 0;
          }
        }),
      );
    }
  }

  ngAfterViewInit(): void {
    this.getWindowHeight();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getWindowHeight(): void {
    this.windowHeight = window.innerHeight;
    this.cdr.detectChanges();
  }
}
