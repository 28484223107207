import { Error500, PageNotAvailable } from '@app/core/states/app.actions';
import { PlayError } from '@app/core/states/audio.actions';
import { RadioStatusFailure } from '@app/core/states/live-tracking.actions';
import {
  GetRadioFailure,
  MyRadiosFailure,
  RadioStartFailure,
  RadioStopFailure,
  SwitchToRadioRequest,
} from '@app/core/states/radio.actions';
import {
  EnableShoutOutsFailure,
  EnableShoutOutSuccess,
} from '@app/core/states/shout-outs.actions';
import { DiskSpaceExceeded } from '@app/core/states/upload.actions';
import {
  AddDefaultTracksSuccess,
  TracksUploaded,
} from '@app/library/states/tracks.actions';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { ToastableItem } from '@radioking/shared/common-services';

import { LiveInfoFailure } from '../../../../libs/live/live-info/src/lib/states/live-info.actions';

export const appToasts: ToastableItem[] = [
  {
    action: MyRadiosFailure,
    level: 'error',
    translationKey: marker('TOAST.RADIO.FETCH_RADIOS_ERROR'),
  },
  {
    action: GetRadioFailure,
    level: 'error',
    translationKey: marker('TOAST.RADIO.FAIL_STATUS'),
  },
  {
    action: RadioStatusFailure,
    level: 'error',
    translationKey: marker('TOAST.RADIO.FAIL_STATUS'),
  },
  {
    action: RadioStartFailure,
    level: 'error',
    translationKey: marker('TOAST.RADIO.FAIL_START'),
  },
  {
    action: RadioStopFailure,
    level: 'error',
    translationKey: marker('TOAST.RADIO.FAIL_STOP'),
  },
  {
    action: SwitchToRadioRequest,
    level: 'success',
    translationKey: marker('TOAST.RADIO.CHANGE'),
  },
  {
    // Needed globally because shout-outs can be enabled in library and share modules
    action: EnableShoutOutSuccess,
    level: 'success',
    translationKey: marker('TOAST.SHARE.ENABLE_SHOUTOUTS'),
  },
  {
    // Needed globally because shout-outs can be enabled in library and share modules
    action: EnableShoutOutsFailure,
    level: 'error',
    translationKey: marker('TOAST.SHARE.ENABLE_SHOUTOUTS_ERROR'),
  },
  {
    action: Error500,
    level: 'error',
    translationKey: marker('TOAST.ERROR.GENERIC_ERROR_500'),
  },
  {
    action: DiskSpaceExceeded,
    level: 'error',
    translationKey: marker('TOAST.ERROR.DISK_SPACE_EXCEEDED'),
  },
  {
    action: PlayError,
    level: 'error',
    translationKey: marker('TOAST.ERROR.COULD_NOT_LISTEN_RADIO'),
  },
  {
    action: LiveInfoFailure,
    level: 'error',
    translationKey: marker('TOAST.LIVE_INFO.FETCH_ERROR'),
  },
  {
    action: PageNotAvailable,
    level: 'info',
    translationKey: marker('TOAST.ERROR.PAGE_NOT_AVAILABLE'),
  },
  {
    action: AddDefaultTracksSuccess,
    level: 'success',
    translationKey: marker('TOAST.TRACK.DEFAULT_SUCCESS'),
  },
  {
    action: TracksUploaded,
    level: 'success',
    translationKey: marker('TOAST.TRACK.UPLOAD_SUCCESS'),
  },
];
