import { Injectable } from '@angular/core';
import { RadioState, RadioStateModel } from '@app/core/states/radio.state';
import { FilterObject, isFilterObjectFiltering } from '@app/library/models/filters.model';
import { Action, Selector, State, StateContext, StateOperator, Store } from '@ngxs/store';
import { compose, iif, patch } from '@ngxs/store/operators';
import {
  OpenSpecificPanel,
  PANELS_KEYS,
  TryActionUnlessPanelIsBlocking,
} from '@radioking/shared/pannel-manager';
import { get } from 'lodash';

import {
  OpenFilterEditPanel,
  ResetFilterForBacCurrentlyEditing,
  SetFilterForBacCurrentlyEditing,
} from './filters.actions';

export class FiltersStateModel {
  edit: {
    bacIdEditing: number;
  };
  filters: {
    [radioId: string]: {
      [key: string]: {
        rules?: FilterObject;
      };
    };
  };
}

function setRules(
  rules: FilterObject,
  radioId: number,
  bacId: number,
): StateOperator<FiltersStateModel> {
  const radioSelector = `radio${radioId}`;
  const bacSelector = `bac${bacId}`;

  const createRadioIfNone = patch<FiltersStateModel>({
    filters: iif(val => !val[radioSelector], patch({ [radioSelector]: {} })),
  });

  const createBacIfNone = patch<FiltersStateModel>({
    filters: patch({
      [radioSelector]: iif(val => !val[bacSelector], patch({ [bacSelector]: {} })),
    }),
  });

  const updateRules = patch<FiltersStateModel>({
    filters: patch({
      [radioSelector]: patch({
        [bacSelector]: patch({
          rules,
        }),
      }),
    }),
  });

  return compose(createRadioIfNone, createBacIfNone, updateRules);
}

@State<FiltersStateModel>({
  name: 'filters',
  defaults: {
    filters: {},
    edit: {
      bacIdEditing: 0,
    },
  },
})
@Injectable()
export class FiltersState {
  constructor(private readonly store: Store) {}
  @Selector([RadioState])
  static getBacFilters(
    state: FiltersStateModel,
    radioState: RadioStateModel,
  ): (bacId: number) => FilterObject {
    return bacId =>
      get(state, `filters.radio${radioState.currentRadioId}.bac${bacId}.rules`);
  }

  @Selector()
  static getCurrentEditingBacId(state: FiltersStateModel): number {
    return state.edit.bacIdEditing || 0;
  }

  @Selector([RadioState])
  static getFilterOfCurrentEditing(
    state: FiltersStateModel,
    radioState: RadioStateModel,
  ): FilterObject {
    return FiltersState.getBacFilters(state, radioState)(state.edit.bacIdEditing);
  }

  @Selector([RadioState])
  static hasBacAnyFilter(
    state: FiltersStateModel,
    radioState: RadioStateModel,
  ): (bacId: number) => boolean {
    return bacId => {
      const val = FiltersState.getBacFilters(state, radioState)(bacId);
      if (val === undefined) {
        return false;
      }

      return isFilterObjectFiltering(val);
    };
  }

  @Action(SetFilterForBacCurrentlyEditing)
  setBacFilter(
    ctx: StateContext<FiltersStateModel>,
    { filters }: SetFilterForBacCurrentlyEditing,
  ) {
    const radioId = this.store.selectSnapshot(RadioState.currentRadioId);
    ctx.setState(setRules(filters, radioId, ctx.getState().edit.bacIdEditing));
  }

  @Action(ResetFilterForBacCurrentlyEditing)
  resetFiltersBac(
    ctx: StateContext<FiltersStateModel>,
    { bacId }: ResetFilterForBacCurrentlyEditing,
  ) {
    const radioId = this.store.selectSnapshot(RadioState.currentRadioId);
    ctx.setState(setRules(undefined, radioId, bacId || ctx.getState().edit.bacIdEditing));
  }

  @Action(OpenFilterEditPanel)
  openPanel(ctx: StateContext<FiltersStateModel>, { bacId }: OpenFilterEditPanel) {
    ctx.dispatch(
      new TryActionUnlessPanelIsBlocking(new OpenSpecificPanel(PANELS_KEYS.FILTER_EDIT)),
    );

    ctx.setState(
      patch({
        edit: patch({
          bacIdEditing: bacId,
        }),
      }),
    );
  }
}
