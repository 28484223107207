import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { ShortRadio } from '@app/core/models/Radio';
import { SwitchToRadioRequest } from '@app/core/states/radio.actions';
import { RadioState } from '@app/core/states/radio.state';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'rk-mobile-change-radio',
  templateUrl: './mobile-change-radio.component.html',
  styleUrls: ['./mobile-change-radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileChangeRadioComponent implements OnDestroy {
  @Select(RadioState.radios)
  radios$: Observable<ShortRadio[]>;

  @Select(RadioState.currentRadioId)
  currentRadioId$: Observable<number>;

  @Input()
  show = false;

  @Output()
  readonly closeThis = new EventEmitter();

  subscription = new Subscription();

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @Dispatch()
  changeRadio = (radioId: number) => {
    this.close();

    return new SwitchToRadioRequest(radioId);
  };

  /*
   * Prevent from closing when touch into modal content
   */
  touchBody(e: Event) {
    e.stopPropagation();
  }

  close() {
    this.closeThis.emit();
  }
}
