import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  TooltipPosition,
  TooltipArrowPosition,
} from '../../../tooltip/src/lib/tooltip.model';

@Component({
  selector: 'rk-question-mark',
  templateUrl: './question-mark.component.html',
  styleUrls: ['./question-mark.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionMarkComponent {
  @Input()
  description: string;

  @Input()
  tooltipPosition: TooltipPosition = 'top';

  @Input()
  arrowPosition: TooltipArrowPosition = 'left';
}
