<rk-blurry-background>
  <ng-container *ngIf="!(isLoading$ | async)">
    <div class="disabled-modal" *ngIf="radios$ | async as radios">
      <mat-icon
        class="icon-mic"
        fontSet="fa"
        fontIcon="fa-microphone-alt-slash"
      ></mat-icon>
      <h1>
        {{ 'GLOBAL.DISABLED.TITLE' | translate : { name: (radio$ | async).name } }}
      </h1>

      <div class="modal-line mb-20" translate>GLOBAL.DISABLED.INACTIVE</div>
      <div *ngIf="(radioPlan$ | async) < 2" class="modal-line mb-20" translate>
        GLOBAL.DISABLED.EXPIRED
      </div>
      <rk-button
        [label]="'GLOBAL.NOACCESS.UPGRADE' | translate"
        [style]="{ 'margin-bottom': radios.length ? '40px' : '0' }"
        [btnBig]="true"
        (click)="updateOffer()"
      ></rk-button>
      <br />
      <div class="modal-line mb-20" translate *ngIf="radios.length > 1">
        GLOBAL.DISABLED.OTHER_RADIO
      </div>

      <ng-container *ngIf="radios.length > 1">
        <div class="other-radios-container">
          <div class="other-radios">
            <span
              *ngIf="radios.length > 5"
              class="arrow arrow-left"
              [class.disabled]="activeIndex === 0"
            >
              <mat-icon fontSet="fa" fontIcon="fa-angle-left"></mat-icon>
            </span>
            <swiper [config]="swiperConfig" (slideChange)="slideChange($event)">
              <ng-container *ngFor="let radio of radios">
                <ng-template swiperSlide *ngIf="(radioId$ | async) !== radio.id">
                  <div (click)="switchToRadio(radio.id)" class="other-radios-zone">
                    <img [src]="radio.logo" />
                    <div>
                      {{ radio.name || ('GLOBAL.MODAL.CHANGE_RADIO.NONAME' | translate) }}
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </swiper>
            <span
              *ngIf="radios.length > 5"
              class="arrow arrow-right"
              [class.disabled]="activeIndex >= radios.length - 5"
            >
              <mat-icon fontSet="fa" fontIcon="fa-angle-right"></mat-icon>
            </span>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</rk-blurry-background>
