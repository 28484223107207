import { Component, HostListener, Input } from '@angular/core';
import { EventProperties } from '@app/core/states/events-tracking.state';
import { DisplayUpgradeDiscoverModal } from '@app/core/states/live-tracking.actions';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

@Component({
  selector: 'rk-locked-icon',
  templateUrl: './locked-icon.component.html',
  styleUrls: ['./locked-icon.component.scss'],
})
export class LockedIconComponent {
  @Input()
  icon: 'fa-crown' | 'fa-lock' = 'fa-lock';

  @Input()
  props: EventProperties;

  @HostListener('click', ['$event'])
  onClick(e: Event) {
    if (this.props) {
      if (e) {
        e.stopPropagation();
      }
      this.displayUpgradeDiscoverModal(this.props);
    }
  }

  tooltipDisabled(): boolean {
    return window.innerWidth < 768;
  }

  @Dispatch()
  displayUpgradeDiscoverModal = (eventProperties: EventProperties) =>
    new DisplayUpgradeDiscoverModal(eventProperties);
}
