import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UpgradeOfferModalComponent } from '@app/shared/modals/upgrade-offer/components/upgrade-offer-modal/upgrade-offer-modal.component';

@Component({
  selector: 'rk-no-access-container',
  templateUrl: './no-access-container.component.html',
  styleUrls: ['./no-access-container.component.scss'],
})
export class NoAccessContainerComponent {
  @Input()
  title: string;

  @Input()
  content: string;

  @Input()
  url: string;

  @Input()
  imageAbove: string;

  @Input()
  imageBelow: string;

  constructor(private readonly dialog: MatDialog) {}

  updateOffer(): void {
    this.dialog.open(UpgradeOfferModalComponent, { data: 'no_access_container' });
  }
}
