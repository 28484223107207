import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Radio } from '@app/core/models/Radio';
import { AppState } from '@app/core/states/app.state';
import { LiveTrackingState } from '@app/core/states/live-tracking.state';
import { RadioRestartRequest, RadioStopRequest } from '@app/core/states/radio.actions';
import { RadioState } from '@app/core/states/radio.state';
import { ChangeRadioModalComponent } from '@app/shared/modals/change-radio-modal/change-radio-modal.component';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { MobileSidenavService } from '@radioking/shared/common-services';
import { MobileRadioActionsComponent } from '@radioking/ui/mobile-radio-actions';
import amplitude from 'amplitude-js';
import { Observable } from 'rxjs';

@Component({
  selector: 'rk-radio-sidebar-header',
  templateUrl: './radio-sidebar-header.component.html',
  styleUrls: ['./radio-sidebar-header.component.scss'],
})
export class RadioSidebarHeaderComponent {
  @Select(RadioState.hasMoreThanOneRadio)
  hasMoreThanOneRadio$: Observable<boolean>;

  @Select(RadioState.currentRadioId)
  currentRadioId$: Observable<number>;

  @Select(RadioState.currentRadio)
  currentRadio$: Observable<Radio>;

  @Select(LiveTrackingState.isBroadcasting)
  isRadioStarted$: Observable<boolean>;

  @Select(AppState.onMobile)
  onMobile$: Observable<boolean>;

  @Output()
  readonly toggleSideNav = new EventEmitter();

  constructor(
    private readonly store: Store,
    private readonly dialog: MatDialog,
    private readonly mobileSidenavService: MobileSidenavService,
  ) {}

  openDialogChangeRadio() {
    this.dialog.open(ChangeRadioModalComponent);
  }

  stopRadio() {
    this.store.dispatch(new RadioStopRequest());
  }

  restartRadio() {
    this.store.dispatch(new RadioRestartRequest());
  }

  @Dispatch()
  goToSettings(radioId: number) {
    const eventProperties = {
      tab_name: 'settings',
    };
    amplitude.getInstance().logEvent('click_tab', eventProperties);

    return new Navigate(['radio', radioId, 'settings']);
  }

  placeMatMenu() {
    const matMenu: HTMLElement = document.querySelector('.mat-mdc-menu-panel');
    matMenu.style['position'] = 'absolute';
    matMenu.style.left = '230px';
    matMenu.style.top = '-50px';
  }

  openRadioActionsModal() {
    this.mobileSidenavService.open(MobileRadioActionsComponent, {
      from: 'left',
      hasBackdrop: false,
    });
  }
}
