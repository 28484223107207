import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'rk-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent {
  @Input()
  disabled: boolean;

  @Input()
  style: any;

  @Input()
  faIcon: string;

  @Input()
  svgIcon: string;

  @Input()
  bordered = false;

  @Input()
  color: 'gray-darkest' | 'white' | 'black' | 'brand' = 'black';

  @Input()
  disableHover = false;

  @Input()
  size = 22;

  // tslint:disable-next-line:no-output-native
  @Output()
  readonly click: EventEmitter<any> = new EventEmitter();

  @Output()
  readonly focused: EventEmitter<any> = new EventEmitter();

  @Output()
  readonly blurred: EventEmitter<any> = new EventEmitter();

  onClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.click.emit(event);
  }

  getStyleIcon() {
    let padding = 8;
    if (this.bordered) {
      padding = 12;
    }
    const iconSize = `${this.size - padding}px`;

    return {
      'font-size': iconSize,
      'line-height': iconSize,
      'min-height': iconSize,
      'min-width': iconSize,
      height: iconSize,
      width: iconSize,
    };
  }

  getSvgColorClass(): string {
    if (this.color === 'white') {
      return '';
    }
    if (this.color === 'brand') {
      return 'svg-brand';
    }

    return 'svg-black';
  }
}
