import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Tag, TrackTags } from '@app/core/models/Track';
import { TagApi, TrackTagsApi } from '@app/library/services/track.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const tagsUrl = {
  getTagsOfRadio: (radioId: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${radioId}/tag`,
  editTag: (radioId: number, tagId: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${radioId}/tag/${tagId}`,
  deleteTag: (radioId: number, tagId: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${radioId}/tag/${tagId}`,
  removeTags: (radioId: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${radioId}/batch/track/tag`,
  saveTags: (radioId: number, trackId: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${radioId}/track/${trackId}/tag`,
  setMultipleTags: (radioId: number) =>
    `${environment.urls.MAIN_API_V2}/radio/${radioId}/batch/track/tag`,
};

export function convertToTag(tag: TagApi): Tag {
  return {
    id: tag.idtag,
    name: tag.name,
    color: tag.color,
  };
}

@Injectable({
  providedIn: 'root',
})
export class TagsService {
  constructor(private readonly httpClient: HttpClient) {}

  getRadioTags(idRadio: number): Observable<Tag[]> {
    return this.httpClient
      .get<TagApi[]>(tagsUrl.getTagsOfRadio(idRadio))
      .pipe(map(data => data.map(d => convertToTag(d))));
  }

  editTag(idRadio: number, idTag: number, tag: Partial<Tag>): Observable<Tag> {
    return this.httpClient
      .put<TagApi>(tagsUrl.editTag(idRadio, idTag), tag)
      .pipe(map(data => convertToTag(data)));
  }

  deleteTag(idRadio: number, idTag: number) {
    return this.httpClient.delete(tagsUrl.deleteTag(idRadio, idTag));
  }

  setMultipleTags(
    idRadio: number,
    idTracks: number[],
    tags: Tag[],
  ): Observable<TrackTags[]> {
    return this.httpClient
      .put<TrackTagsApi[]>(tagsUrl.setMultipleTags(idRadio), {
        tags,
        tracks: idTracks,
      })
      .pipe(map(resArray => resArray.map(data => this.convertToTrackTags(data))));
  }

  editTags(idRadio: number, idTrack: number, tags: Tag[]): Observable<Tag[]> {
    return this.httpClient
      .post<TagApi[]>(tagsUrl.saveTags(idRadio, idTrack), {
        tags,
      })
      .pipe(map(tagsArray => tagsArray.map(tag => convertToTag(tag))));
  }

  removeTags(idRadio: number, idTracks: number[], idTags: number[]) {
    const payload = {
      tracks: idTracks,
      tags: idTags,
    };

    return this.httpClient.request('DELETE', tagsUrl.removeTags(idRadio), {
      body: payload,
    });
  }

  convertToTrackTags(trackTags: TrackTagsApi): TrackTags {
    return {
      id: trackTags.idtrack,
      tags: trackTags.tags.map(tag => convertToTag(tag)),
    };
  }
}
