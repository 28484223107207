import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Actions, getActionTypeFromInstance } from '@ngxs/store';
import { ActionStatus } from '@ngxs/store/src/actions-stream';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { filter, map } from 'rxjs/operators';

export interface ToastableItem {
  translationKey: string;
  action: any;
  level: 'success' | 'info' | 'error';
}

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  override: Partial<IndividualConfig> = {
    toastClass: 'toast custom-toast',
    easeTime: 0,
    timeOut: 4000,
    enableHtml: true,
  };
  sideOverride: Partial<IndividualConfig> = {
    toastClass: 'toast custom-toast side-toast',
    easeTime: 0,
    timeOut: 4000,
    positionClass: 'toast-bottom-right',
  };

  sideToasts: ToastableItem[] = [];
  toasts: ToastableItem[] = [];

  constructor(
    private readonly toastr: ToastrService,
    private readonly actions$: Actions,
    private readonly translate: TranslateService,
  ) {}

  public setupToasters() {
    this.setupNewToastWay();
  }

  /*
   * Setup toaster
   * translateParams must be a parameter in action constructor
   */
  private setupNewToastWay() {
    this.actions$
      .pipe(
        filter(item => item.status === ActionStatus.Dispatched),
        map(item => item.action),
        map(item => {
          const itemKey = getActionTypeFromInstance(item);
          const found = this.toasts.find(
            toast => getActionTypeFromInstance(toast.action) === itemKey,
          );
          if (!found) {
            const maybeSide = this.sideToasts.find(
              toast => getActionTypeFromInstance(toast.action) === itemKey,
            );

            return [item, maybeSide, true];
          }

          return [item, found, false];
        }),
        filter(([item, found, isSideToast]) => !!found),
      )
      .subscribe(([item, found, isSideToast]) => {
        setTimeout(() => {
          if (found.level === 'success') {
            this.success(
              found.translationKey,
              item.translateParams ? item.translateParams : null,
              isSideToast,
            );
          } else if (found.level === 'error') {
            this.error(
              found.translationKey,
              item.translateParams ? item.translateParams : null,
              isSideToast,
            );
          } else if (found.level === 'info') {
            this.info(
              found.translationKey,
              item.translateParams ? item.translateParams : null,
              isSideToast,
            );
          }
        });
      });
  }

  public registerToasts(toastRegistered: ToastableItem[]) {
    this.toasts = [...this.toasts, ...toastRegistered];
  }

  public registerSideToasts(toastRegistered: ToastableItem[]) {
    this.sideToasts = [...this.sideToasts, ...toastRegistered];
  }

  public success(translationKey: string, params?: object, sideToast?: false) {
    setTimeout(() => {
      const text = this.translate.instant(translationKey, params);
      this.toastr.success(
        text,
        undefined,
        sideToast && window.innerWidth > 767 ? this.sideOverride : this.override,
      );
    });
  }

  public successNoTranslate(text: string, sideToast?: false) {
    setTimeout(() => {
      this.toastr.success(text, undefined, sideToast ? this.sideOverride : this.override);
    });
  }

  public error(translationKey: string, plural?: object, sideToast?: false) {
    setTimeout(() => {
      const text = this.translate.instant(translationKey, plural);

      this.toastr.error(
        text,
        undefined,
        sideToast && window.innerWidth > 767 ? this.sideOverride : this.override,
      );
    });
  }

  public errorNoTranslate(text: string, sideToast?: false) {
    setTimeout(() => {
      this.toastr.error(text, undefined, sideToast ? this.sideOverride : this.override);
    });
  }

  public info(translationKey: string, plural?: object, sideToast?: false) {
    setTimeout(() => {
      const text = this.translate.instant(translationKey, plural);

      this.toastr.info(text, undefined, sideToast ? this.sideOverride : this.override);
    });
  }
}
