import { Injectable } from '@angular/core';
import { RadioState } from '@app/core/states/radio.state';
import { ArchivedTrack } from '@app/library/models/trash.model';
import { TrashService } from '@app/library/services/trash.service';
import { BacsState } from '@app/library/states/bacs.state';
import { UnselectAll } from '@app/library/states/selected-tracks.actions';
import { TrackRestoreSuccess } from '@app/library/states/tracks.actions';
import {
  ArchivedTrackDeleteFailure,
  ArchivedTrackDeleteRequest,
  ArchivedTrackDeleteSuccess,
  ArchivedTrackRestoreFailure,
  ArchivedTrackRestoreRequest,
  ArchivedTrackRestoreSuccess,
} from '@app/library/states/trash.actions';
import { removeMultipleTracks } from '@app/library/states/trash.state-operators';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Logger } from '@radioking/shared/logger';
import amplitude from 'amplitude-js';
import { Observable } from 'rxjs';
import { catchError, filter, mergeMap, take } from 'rxjs/operators';

import {
  AddedToTrashCount,
  ArchivedTracksFailure,
  ArchivedTracksRequest,
  ArchivedTracksSuccess,
} from './trash.actions';

const log = new Logger('trash store');

export class TrashStateModel {
  archivedTracks: ArchivedTrack[];
  count: number;
}

@State<TrashStateModel>({
  name: 'trash',
  defaults: {
    archivedTracks: [],
    count: 0,
  },
})
@Injectable()
export class TrashState {
  constructor(
    private readonly trashService: TrashService,
    private readonly store: Store,
  ) {}
  @Selector()
  static archivedTracks(state: TrashStateModel): ArchivedTrack[] {
    return state.archivedTracks;
  }

  @Selector()
  static archivedTracksLength(state: TrashStateModel): number {
    return state.count;
  }

  @Selector()
  static archivedTrackWithId(
    state: TrashStateModel,
  ): (archivedTrackId: number) => ArchivedTrack {
    return archivedTrackId => {
      const allArchivedTracks = [...state.archivedTracks];

      return allArchivedTracks.find(
        archivedTrack => archivedTrack && archivedTrack.id === archivedTrackId,
      );
    };
  }

  getCurrentRadioId(): Observable<number> {
    return this.store.select(RadioState.currentRadioId).pipe(
      filter(data => !!data),
      take(1),
    );
  }

  @Action(ArchivedTracksRequest)
  getArchivedTracks(ctx: StateContext<TrashStateModel>) {
    return this.getCurrentRadioId().pipe(
      mergeMap(radioId => this.trashService.getArchivedTracks(radioId)),
      mergeMap(data => ctx.dispatch(new ArchivedTracksSuccess(data))),
      catchError(err => ctx.dispatch(new ArchivedTracksFailure(err))),
    );
  }

  @Action(ArchivedTracksSuccess)
  getArchivedTracksSuccess(
    ctx: StateContext<TrashStateModel>,
    { archivedTracks }: ArchivedTracksSuccess,
  ) {
    ctx.patchState({ archivedTracks, count: archivedTracks.length });
  }

  @Action(ArchivedTracksFailure)
  getArchivedTracksFailure(
    ctx: StateContext<TrashStateModel>,
    { error }: ArchivedTracksFailure,
  ) {
    log.error(error);
  }

  @Action(ArchivedTrackDeleteRequest)
  trackDeleteRequest(
    ctx: StateContext<TrashStateModel>,
    { archivedTracksId }: ArchivedTrackDeleteRequest,
  ) {
    const radioId = this.store.selectSnapshot(RadioState.currentRadioId);

    return this.trashService.deleteArchivedTracks(radioId, archivedTracksId).pipe(
      mergeMap(() =>
        ctx.dispatch(
          new ArchivedTrackDeleteSuccess(archivedTracksId, {
            count: archivedTracksId.length,
          }),
        ),
      ),
      catchError(err => ctx.dispatch(new ArchivedTrackDeleteFailure(err))),
    );
  }

  @Action(ArchivedTrackDeleteSuccess)
  trackDeleteSuccess(
    ctx: StateContext<TrashStateModel>,
    { archivedTracksId }: ArchivedTrackDeleteSuccess,
  ) {
    ctx.setState(removeMultipleTracks(archivedTracksId));
    const newArchivedTracksLength = ctx.getState().archivedTracks.length;
    ctx.patchState({
      count: newArchivedTracksLength,
    });
    ctx.dispatch(new UnselectAll());
  }

  @Action(ArchivedTrackRestoreRequest)
  trackRestoreRequest(
    ctx: StateContext<TrashStateModel>,
    { archivedTracksId }: ArchivedTrackRestoreRequest,
  ) {
    amplitude.getInstance().logEvent('restore_track');
    const radioId = this.store.selectSnapshot(RadioState.currentRadioId);

    return this.trashService.restoreArchivedTracks(radioId, archivedTracksId).pipe(
      mergeMap(() =>
        ctx.dispatch(
          new ArchivedTrackRestoreSuccess(archivedTracksId, {
            count: archivedTracksId.length,
          }),
        ),
      ),
      catchError(err => ctx.dispatch(new ArchivedTrackRestoreFailure(err))),
    );
  }

  @Action(ArchivedTrackRestoreSuccess)
  trackRestoreSuccess(
    ctx: StateContext<TrashStateModel>,
    { archivedTracksId }: ArchivedTrackRestoreSuccess,
  ) {
    // Update bacs
    const archivedTrackWithId = this.store.selectSnapshot(TrashState.archivedTrackWithId);
    const archivedTracks = archivedTracksId
      .map(archivedTrackId => archivedTrackWithId(archivedTrackId))
      .filter(archivedTrack => !!archivedTrack);
    const bacs = this.store.selectSnapshot(BacsState.bacs);
    bacs.forEach(bac => {
      const selectedTracksOfBac = archivedTracks.filter(aTr => aTr.idTrackbox === bac.id);

      if (selectedTracksOfBac.length) {
        ctx.dispatch(new TrackRestoreSuccess(selectedTracksOfBac, bac));
      }
    });

    // Update trash
    ctx.setState(removeMultipleTracks(archivedTracksId));
    const newArchivedTracksLength = ctx.getState().archivedTracks.length;
    ctx.patchState({
      count: newArchivedTracksLength,
    });
    ctx.dispatch(new UnselectAll());
  }

  @Action(AddedToTrashCount)
  addedToTrashCount(ctx: StateContext<TrashStateModel>, { trackNmb }: AddedToTrashCount) {
    const currentCount = ctx.getState().count;
    ctx.patchState({
      count: currentCount + trackNmb,
    });
  }
}
