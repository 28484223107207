import { Component, HostBinding, Input } from '@angular/core';
import { slideInOutBlock } from '@radioking/ui/animations';

@Component({
  selector: 'rk-fold-item',
  template: `
    <div class="header" (click)="toggleOpen()">
      <div class="title">{{ name }}</div>
      <div class="icon">
        <mat-icon
          fontSet="fa"
          fontIcon="fa-angle-right"
          class="collapse-arrow"
        ></mat-icon>
      </div>
    </div>
    <div [@slideInOutBlock] *ngIf="isOpen" class="content"><ng-content></ng-content></div>
  `,
  styleUrls: ['./fold-item.component.scss'],
  animations: [slideInOutBlock],
})
export class FoldItemComponent {
  @Input()
  name: string;

  @HostBinding('class.open')
  isOpen = true;

  toggleOpen() {
    this.isOpen = !this.isOpen;
  }
}
