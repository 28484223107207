import amplitude from 'amplitude-js';

export const AMPLITUDE_EVENT = {
  ONBOARDING_SLIDESHOW: {
    NEW: 'slideshow-welcome', // First time - Slideshow displayed
    LAUNCH: 'slideshow-launch', // Second step
    DISCOVER: 'slideshow-discover', // Click on "Discover"
    CLOSE: 'slideshow-close', // Quit slideshow - X button or click outside
  },
  ONBOARDING_MOBILE: {
    NEW_START: 'onboarding-resp-new-start', // First step - Show onboarding
    NEW_STEP_1: 'onboarding-resp-new-step-1', // Second step - Available on mobile
    NEW_STEP_2: 'onboarding-resp-new-step-2', // Third step - Free tracks
    ADD_TRACKS: 'onboarding-resp-new-add-tracks', // Third step - Add free tracks
    NEW_STEP_2_YES_1: 'onboarding-resp-new-step-2-yes-1', // Fourth step - Radio available
    NEW_STEP_2_YES_2: 'onboarding-resp-new-step-2-yes-2', // Fifth step - Share radio
    NEW_STEP_2_NO_1: 'onboarding-resp-new-step-2-no-1', // Fourth step - Radio available soon
    NEW_STEP_2_NO_2: 'onboarding-resp-new-step-2-no-2', // Fifth step - Share radio
    NEW_END: 'onboarding-resp-new-end', // Last step - End of onboarding
    START: 'onboarding-resp-start', // First step - Existing radio - Show onboarding
    END: 'onboarding-resp-end', // Last step - Existing radio - End of onboarding
  },
  ONBOARDING_PRODUCT_TOUR: {
    LIBRARY_START: 'onboarding-library-start', // First time - Automatically displayed
    LIBRARY_STEP: 'onboarding-library-step', // Repeatable steps
    LIBRARY_END: 'onboarding-library-end', // End tour
    PROGRAM_START: 'onboarding-program-start',
    PROGRAM_STEP: 'onboarding-program-step',
    PROGRAM_END: 'onboarding-program-end',
    BREAK_START: 'onboarding-break-start',
    BREAK_STEP: 'onboarding-break-step',
    BREAK_END: 'onboarding-break-end',
    PLANNING_START: 'onboarding-planning-start',
    PLANNING_STEP: 'onboarding-planning-step',
    PLANNING_END: 'onboarding-planning-end',
    DAILY_GEN_START: 'onboarding-regenerate-start',
    DAILY_GEN_END: 'onboarding-regenerate-end',
  },
  UPGRADE_MODAL: {
    OPEN: 'upgrade-modal',
    MONTHLY: 'upgrade-modal-monthly',
    ANNUALLY: 'upgrade-modal-annually',
    DISCOVER: 'upgrade-modal-discover',
    START: 'upgrade-modal-start',
    PRO: 'upgrade-modal-pro',
    BUSINESS: 'upgrade-modal-business',
    CLOSE: 'upgrade-modal-close',
    FAQ_1: 'upgrade-modal-faq-1',
    FAQ_2: 'upgrade-modal-faq-2',
    FAQ_3: 'upgrade-modal-faq-3',
    FAQ_4: 'upgrade-modal-faq-4',
    FAQ_5: 'upgrade-modal-faq-5',
    FAQ_6: 'upgrade-modal-faq-6',
    FAQ_7: 'upgrade-modal-faq-7',
    FAQ_8: 'upgrade-modal-faq-8',
  },
  CHECKLIST: {
    'CHECKLIST-LIBRARY': 'checklist-add-tracks',
    CHECKLIST_LIBRARY_DESKTOP: 'checklist-add-tracks-desktop',
    CHECKLIST_LIBRARY_FREE: 'checklist-add-tracks-free',
    'CHECKLIST-RADIO': 'checklist-launch-radio',
    CHECKLIST_RADIO_CTA: 'checklist-launch-radio-cta',
    'CHECKLIST-PLAYLIST': 'checklist-playlist',
    CHECKLIST_PLAYLIST_NEW: 'checklist-playlist-new',
    'CHECKLIST-PLANNING': 'checklist-planning',
    CHECKLIST_PLANNING_NEW: 'checklist-planning-new',
    'CHECKLIST-GENERATION': 'checklist-regenerate',
    CHECKLIST_GENERATION_NEW: 'checklist-regenerate-new',
    'CHECKLIST-SHARE': 'checklist-share',
    CHECKLIST_SHARE_NEW: 'checklist-share-new',
    'CHECKLIST-TUTORIAL': 'checklist-helpcenter',
    CHECKLIST_TUTORIAL_NEW: 'checklist-helpcenter-new',
    CHECKLIST_CONGRATULATIONS: 'checklist-congratulations',
    CHECKLIST_CONGRATULATIONS_CTA_CLOSE: 'checklist-congratulations-cta-close',
    CHECKLIST_CONGRATULATIONS_CLOSE: 'checklist-congratulations-close',
  },
  ONBOARDING_DASHBOARD: {
    DASHBOARD_ADD_TRACKS: 'dashboard-add-tracks-desktop',
    DASHBOARD_ADD_TRACKS_FREE: 'dashboard-add-tracks-free',
    DASHBOARD_LAUNCH_RADIO: 'dashboard-launch-radio',
    DASHBOARD_REGENERATE: 'dashboard-regenerate',
    DASHBOARD_SHARE: 'dashboard-share',
    DASHBOARD_DOCUMENTATION: 'dashboard-documentation',
    CTA_CONVERSION_HEADER: 'cta-conversion-header',
    CTA_CONVERSION_SIDEBAR: 'cta-conversion-sidebar',
  },
  GENERATION: {
    OPEN_PANEL: 'generation-add-track-open-panel',
    ADD_TRACK: 'generation-add-track',
    MOVE_TRACK: 'generation-move-track',
    DELETE_TRACK: 'generation-delete-track',
    CHANGE_DATE: 'generation-change-date',
    REGENERATE_DAY: 'generation-regenerate-day',
    LISTEN_TRACK: 'generation-listen-track',
    CLICK_TODAY: 'generation-click-on-today',
  },
  PLANNING: {
    CTA_ADD: 'planning-cta-add',
    CTA_ADD_INSIDE: 'planning-cta-add-inside',
    NEW: 'planning-new',
    PLAYLIST: 'planning-playlist',
    PROGRAM: 'planning-program',
    BROADCAST_RANDOM: 'planning-broadcast-random',
    BROADCAST_SEQUENTIAL: 'planning-broadcast-sequential',
    BROADCAST_FIXEDTIME: 'planning-broadcast-fixedtime',
    REPETITION_ACTIVE: 'planning-repetition-active',
    REPETITION_EVERYWEEK: 'planning-repetition-everyweek',
    REPETITION_EVERY2WEEKS: 'planning-repetition-every2weeks',
    REPETITION_EVERYDAY: 'planning-repetition-everyday',
    ENDS_DATE: 'planning-ends-date',
    ENDS_NUMBER_REPETITIONS: 'planning-ends-number-repetitions',
    INCRUSTATION_ACTIVE: 'planning-incrustation-active',
    INCRUSTATION_TRACK: 'planning-incrustation-track',
    INCRUSTATION_PLAYLIST: 'planning-incrustation-playlist',
    INCRUSTATION_PLAYLIST_LIST: 'planning-incrustation-playlist-list',
    INCRUSTATION_PLAYLIST_NUMBER: 'planning-incrustation-playlist-number',
    INCRUSTATION_REPEAT: 'planning-incrustation-repeat',
  },
  BREAKS: {
    CTA_ADD: 'breaks-cta-add',
    CTA_ADD_INSIDE: 'breaks-cta-add-inside',
    NEW: 'breaks-new',
    TRACK: 'breaks-track',
    PLAYLIST: 'breaks-playlist',
    PLAYLIST_RANDOM: 'breaks-playlist-random',
    PLAYLIST_SEQUENTIAL: 'breaks-playlist-sequential',
    PROGRAM: 'breaks-program',
    INTERRUPT_MUSIC: 'breaks-interrupt-music',
    INTERRUPT_MUSIC_TIME: 'breaks-interrupt-music-time',
    REPETITION: 'breaks-repetition',
    ENDS_DATE: 'breaks-ends-date',
    REPEAT_DURING_DAY: 'breaks-repeat-during-day',
  },
  MODAL: {
    NAME: 'Modal Viewed',
    DISCOVER_INFO: 'ModalDiscoverInfo',
    DEMO_INFO: 'ModalDemoInfo',
    UPGRADE_OFFER: 'ModalPricing',
    CHANGE_RADIO: 'ModalChangeRadio',
    ARCHIVE_TRACK: 'ModalArchiveTrack',
    DELETE_TRACK: 'ModalDeleteDefinitelyTrack',
    MOVE_TRACK: 'ModalMoveTrack',
    CONFIRM_EDIT_TRACK: 'ModalConfirmEditMultipleTrack',
    TAG_TRACK: 'ModalAddTagsToTrack',
    EDIT_MIXPOINT_TRACK: 'ModalEditMixpointTrack',
    ADD_TO_PLAYLIST: 'ModalAddTrackToPlaylist',
    ADD_DUPLICATE_PLAYLIST: 'ModalAddDuplicateToPlaylist',
    DELETE_PLAYLIST: 'ModalDeletePlaylist',
    DUPLICATE_PLAYLIST: 'ModalDuplicatePlaylist',
    DELETE_TAG: 'ModalDeleteTag',
    REMOVE_TAG_FROM_TRACK: 'ModalRemoveTagFromTrack',
    UPLOAD_AND_ADD_PLAYLIST: 'ModalUploadAndAddToPlaylist',
    EDIT_SLUG: 'ModalEditSlug',
    WIDGET: {
      PLAYER: 'ModalWidgetPlayer',
      SHOUTOUT: 'ModalWidgetShoutout',
      PLAYED_TRACKS: 'ModalWidgetPlayedTracks',
      TOP_TRACKS: 'ModalWidgetTopTracks',
      CURRENT_TRACK: 'ModalWidgetCurrentTrack',
      NEXT_TRACK: 'ModalWidgetNextTrack',
    },
    ADD_EDIT_PLANNING: 'ModalAddOrEditPlanningSlot',
    ADD_EDIT_BREAK: 'ModalAddOrEditBreakSlot',
    HISTORY_REPORT: 'ModalHistoryReport',
  },
  NAV: {
    CLICK_NAV: 'Navigation Clicked',
    CLICK_SUBNAV: 'Subnavigation Clicked',
  },
  BUTTON: {
    NAME: 'Button Clicked',
    ADD_LINK: 'ButtonAddLink',
    DELETE_LINK: 'ButtonDeleteLink',
  },
  BUTTON_TOGGLE: {
    NAME: 'Button Toggle Clicked',
  },
  SWITCH: {
    NAME: 'Switch Clicked',
  },
  RADIO_BUTTON: {
    NAME: 'Radio Button Clicked',
  },
  ORIGIN: {
    RADIO_PAGE: 'Radio Page',
    LIBRARY: 'Library',
  },
};

export function aLogEvent(event: string, properties: object = null) {
  amplitude.getInstance().logEvent(event, properties);
}
