import { EventProperties } from '@app/core/states/events-tracking.state';

const tag = '[EventsTracking]';

export class LogEvent {
  static readonly type = `${tag} log event`;
  constructor(public event: string) {}
}

export class SetEventProperties {
  static readonly type = `${tag} set event properties`;
  constructor(public eventProperties: EventProperties) {}
}

export class LogNav {
  static readonly type = `${tag} log nav event`;
  constructor(
    public event: string,
    public subnav = true, // Default case because more subnav than nav
  ) {}
}

export class LogModal {
  static readonly type = `${tag} log modal event`;
  constructor(public properties: EventProperties) {}
}

export class LogButton {
  static readonly type = `${tag} log button event`;
  constructor(public properties: EventProperties) {}
}

export class LogButtonToggle {
  static readonly type = `${tag} log button toggle event`;
  constructor(public properties: EventProperties) {}
}

export class LogSwitch {
  static readonly type = `${tag} log switch event`;
  constructor(public properties: EventProperties) {}
}

export class LogRadioButton {
  static readonly type = `${tag} log radio button event`;
  constructor(public properties: EventProperties) {}
}
