import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { HotkeysHelpClueComponent } from './hotkeys-help-clue/hotkeys-help-clue.component';
import { HotkeysHelpTooltipComponent } from './hotkeys-help-tooltip/hotkeys-help-tooltip.component';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [HotkeysHelpTooltipComponent, HotkeysHelpClueComponent],
  exports: [HotkeysHelpTooltipComponent, HotkeysHelpClueComponent],
})
export class UiHotkeysHelpModule {}
