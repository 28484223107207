import { ArchivedTrack } from '@app/library/models/trash.model';

const tag = '[Trash]';

export class ArchivedTracksRequest {
  static readonly type = `${tag} request archived tracks from trash`;
}

export class ArchivedTracksSuccess {
  static readonly type = `${tag} success archived tracks from trash`;
  constructor(public archivedTracks: ArchivedTrack[]) {}
}

export class ArchivedTracksFailure {
  static readonly type = `${tag} error archived tracks from trash`;
  constructor(public error: Error) {}
}

export class ArchivedTrackDeleteRequest {
  static readonly type = `${tag} request delete archived tracks from trash`;

  constructor(public archivedTracksId: number[]) {}
}

export class ArchivedTrackDeleteSuccess {
  static readonly type = `${tag} success delete archived tracks from trash`;

  constructor(public archivedTracksId: number[], public translateParams: object) {}
}

export class ArchivedTrackDeleteFailure {
  static readonly type = `${tag} error delete archived tracks from trash`;

  constructor(public error: Error) {}
}

export class ArchivedTrackRestoreRequest {
  static readonly type = `${tag} request restore archived tracks from trash`;

  constructor(public archivedTracksId: number[]) {}
}

export class ArchivedTrackRestoreSuccess {
  static readonly type = `${tag} success restore archived tracks from trash`;

  constructor(public archivedTracksId: number[], public translateParams: object) {}
}

export class ArchivedTrackRestoreFailure {
  static readonly type = `${tag} error restore archived tracks from trash`;

  constructor(public error: Error) {}
}

export class AddedToTrashCount {
  static readonly type = `${tag} tracks count added to trash`;
  constructor(public trackNmb: number) {}
}
