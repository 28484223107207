<div class="flex flex-1">
  <mat-sidenav-container fullscreen class="w-full h-full min-w-fill min-h-full">
    <mat-sidenav
      #sidenav
      [mode]="isOnMobile ? 'over' : 'side'"
      [disableClose]="!isOnMobile"
      class="flex flex-col"
    >
      <rk-fold-container-sidebar
        #sidebar
        [lockedOpen]="true"
        (foldedChanged)="changeFold($event)"
        [class.closed]="menuClosed"
        [mobile]="isOnMobile"
      >
        <rk-radio-sidebar (toggleSideNav)="toggleSideNav()"></rk-radio-sidebar>

        <div
          class="navbar-content"
          rkPerfectScrollbar
          [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
        >
          <rk-navigation [navigation]="navigation" layout="vertical"></rk-navigation>
        </div>

        <div class="mt-auto" *ngIf="showUpgradeBlock()">
          <div class="demo-container" [class.end-soon]="(demoRemainingDays$ | async) < 4">
            <div
              class="mb-3"
              [innerHTML]="
                'SIDEBAR.DEMO.REMAINING'
                  | translate : { days: demoRemainingDays$ | async }
              "
            ></div>
            <rk-button
              class="w-full"
              [btnStyle]="(demoRemainingDays$ | async) < 4 ? 'orange' : 'main'"
              [label]="'SIDEBAR.DEMO.SUBSCRIBE_NOW' | translate"
              [fullWidth]="true"
              (click)="updateOffer()"
            ></rk-button>
          </div>
        </div>
        <div class="logo-container self-center" [class.mt-auto]="!showUpgradeBlock()">
          <img class="logo" src="assets/rk-logo.png" alt="RadioKing logo" />
        </div>
        <div
          *ngIf="!isOnMobile"
          class="fold-menu"
          (click)="toggleSideNav()"
          (touchend)="onTouchFold()"
        >
          <mat-icon fontSet="fa" fontIcon="fa-angle-left"></mat-icon>
          <span translate *ngIf="!menuClosed">SIDEBAR.MENU.CLOSE_MENU</span>
          <span translate *ngIf="menuClosed">SIDEBAR.MENU.OPEN_MENU</span>
        </div>
      </rk-fold-container-sidebar>
    </mat-sidenav>

    <mat-sidenav-content [ngClass]="{ 'less-opacity': (lessOpacity$ | async) }">
      <rk-panel-container>
        <rk-panel-outside-content>
          <!-- app content -->
          <rk-header></rk-header>
          <!--
            <div class="navbar-content" rkPerfectScrollbar>
              <rk-navigation [navigation]="navigation" layout="vertical"></rk-navigation>
            </div>
          -->
          <div class="filler" [class.listening]="!!(listening$ | async)">
            <router-outlet></router-outlet>
          </div>
        </rk-panel-outside-content>
      </rk-panel-container>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<rk-custom-modal-wrapper></rk-custom-modal-wrapper>

<ng-container
  *ngIf="
    (showChecklist$ | async) && (radioPlan$ | async) < 2 && (destroyed$ | async) === false
  "
>
  <rk-onboarding-checklist-shell class="hidden md:inline"></rk-onboarding-checklist-shell>
  <rk-confetti class="absolute pointer-events-none opacity-0"></rk-confetti>
</ng-container>
