import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Error500 } from '@app/core/states/app.actions';
import { environment } from '@env/environment';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Logger } from '@radioking/shared/logger';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    if (!environment.production) {
      // Do something with the error
      log.error('Request error', response);
      if (response instanceof HttpErrorResponse && response.status >= 500) {
        this.throw500Action();
      }
    }
    throw response;
  }

  @Dispatch()
  throw500Action = () => new Error500();
}
