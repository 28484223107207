import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { RADIO_PLAN_DESC } from '@app/core/models/Radio';
import { LiveTrackingState } from '@app/core/states/live-tracking.state';
import { RadioState } from '@app/core/states/radio.state';
import { aLogEvent, AMPLITUDE_EVENT } from '@app/shared/amplitude-events';
import { PRICES } from '@app/shared/modals/upgrade-offer/components/upgrade-offer-modal/upgrade-offer-modal.component';
import { planBigger } from '@app/shared/utils';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'rk-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferComponent {
  @Select(LiveTrackingState.planDesc)
  planDesc$: Observable<RADIO_PLAN_DESC>;

  @Select(RadioState.radioPageAvailable)
  radioPageAvailable$: Observable<boolean>;

  @Input() plan: RADIO_PLAN_DESC;

  @Input() whmcsId: number;

  @Input() listeningHours: number;

  @Input() listeningLocale: string;

  @Input() diskSpace: number;

  @Input() streamCount: number;

  @Input() currency: string;

  @Input() monthlyPricing: string;

  @Input() annualPricing: string;

  @Input() billing: string;

  @HostBinding('class.recommended')
  @Input()
  recommended = false;

  @Input() focused$: Observable<void>;

  @Output() readonly clickButton = new EventEmitter();

  constructor(private readonly store: Store) {}

  openUpgradePage() {
    switch (this.plan) {
      case 'Discover':
        aLogEvent(AMPLITUDE_EVENT.UPGRADE_MODAL.DISCOVER);
        break;
      case 'Start':
        aLogEvent(AMPLITUDE_EVENT.UPGRADE_MODAL.START);
        break;
      case 'Pro':
        aLogEvent(AMPLITUDE_EVENT.UPGRADE_MODAL.PRO);
        break;
      default:
        aLogEvent(AMPLITUDE_EVENT.UPGRADE_MODAL.BUSINESS);
        break;
    }
    let upgradeOfferLink = this.store.selectSnapshot(RadioState.updateOfferUrl);
    const qParams = `&plan=${this.whmcsId}&periodicity=${this.billing.toLowerCase()}`;
    upgradeOfferLink = `${upgradeOfferLink}${qParams}`;

    window.open(upgradeOfferLink, '_blank');
    this.clickButton.emit();
  }

  openChangeOptionsPage() {
    window.open(this.store.selectSnapshot(RadioState.changeOptionsUrl), '_blank');
    this.clickButton.emit();
  }

  getPricing() {
    return PRICES[this.billing][this.currency][this.plan];
  }

  getNoDiscountPricing() {
    return PRICES['NoDiscount'][this.currency][this.plan];
  }

  getOptionPricing() {
    return PRICES['RadioPageOption'][this.currency];
  }

  getStreamsDescription() {
    switch (this.plan) {
      case 'Discover':
      case 'Start':
        return marker('GLOBAL.MODAL.UPGRADE_OFFER.STREAM_BASIC_TOOLTIP');
      default: {
        return marker('GLOBAL.MODAL.UPGRADE_OFFER.STREAMS_MULTIPLE_TOOLTIP');
      }
    }
  }

  planBigger(plan: RADIO_PLAN_DESC, compareTo: RADIO_PLAN_DESC) {
    return planBigger(plan, compareTo);
  }
}
