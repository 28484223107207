import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'rk-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input()
  disabled: boolean;

  @Input()
  fakeHover = false;

  @Input()
  style: any;

  @Input()
  label: string;

  @Input()
  faIcon: string;

  @Input()
  faFont = 'fa';

  @Input()
  svgIcon: string;

  @Input()
  iconSize = '15px';

  @Input()
  iconPosition: 'left' | 'right' = 'left';

  @Input()
  loading = false;

  @HostBinding('class.pulse')
  @Input()
  pulse = false;

  @Input()
  noMobile = false;

  @Input()
  btnStyle:
    | 'main'
    | 'orange'
    | 'orangeText'
    | 'flat'
    | 'link'
    | 'linkDanger'
    | 'playlist'
    | 'info'
    | 'delete'
    | 'warning'
    | 'success' = 'main';

  @Input()
  btnBig = false;

  @Input()
  semiBig = false;

  @Input()
  squarePadding = false;

  @Input()
  noPadding = false;

  @Input()
  noMinWidth = false;

  @Input()
  type = 'button';

  @Input()
  fullWidth = false;

  // tslint:disable-next-line:no-output-native
  @Output()
  readonly click: EventEmitter<any> = new EventEmitter();

  @Output()
  readonly focused: EventEmitter<any> = new EventEmitter();

  @Output()
  readonly blurred: EventEmitter<any> = new EventEmitter();

  constructor(private readonly elRef: ElementRef) {}

  onClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.click.emit(event);
  }

  isDisabled(): boolean {
    if (this.loading) {
      return true;
    }

    return this.disabled;
  }

  getBtnClasses(): any {
    return {
      orange: this.btnStyle === 'orange',
      'orange-text': this.btnStyle === 'orangeText',
      flat: this.btnStyle === 'flat',
      main: this.btnStyle === 'main',
      link: this.btnStyle === 'link',
      delete: this.btnStyle === 'delete',
      warning: this.btnStyle === 'warning',
      'link-danger': this.btnStyle === 'linkDanger',
      playlist: this.btnStyle === 'playlist',
      info: this.btnStyle === 'info',
      success: this.btnStyle === 'success',
      big: this.btnBig,
      disabled: this.isDisabled(),
      loading: this.loading,
      'semi-big': this.semiBig,
      'square-padding': this.squarePadding,
      'no-padding': this.noPadding,
      'only-icon': !this.label || this.label.length === 0,
      'icon-right': this.iconPosition === 'right',
      'fake-hover': this.fakeHover,
      'full-width': this.fullWidth,
      'no-mw': this.noMinWidth,
      'no-mobile': this.noMobile,
      'no-label': !this.label,
    };
  }

  getSvgColorClass(): string {
    if (this.btnStyle === 'orange') {
      return 'svg-brand';
    }
    if (this.btnStyle === 'main') {
      return '';
    }

    return 'svg-black';
  }

  getStyleIcon() {
    if (this.faIcon || this.svgIcon) {
      if (!this.label) {
        return {
          'font-size': this.iconSize,
          'line-height': this.iconSize,
          'min-height': this.iconSize,
          'min-width': this.iconSize,
          height: this.iconSize,
          width: this.iconSize,
          'margin-right': 0,
          'margin-left': 0,
        };
      }
    }

    return {
      'font-size': this.iconSize,
      'line-height': this.iconSize,
      'min-height': this.iconSize,
      'min-width': this.iconSize,
      height: this.iconSize,
      width: 'initial',
    };
  }

  getButton(): HTMLElement {
    return this.elRef.nativeElement;
  }
}
