<rk-mobile-side-header (closed)="back()" [showUserMenu]="false"></rk-mobile-side-header>
<div class="content">
  <ng-container *ngIf="isRadioStarted$ | async; else notStarted">
    <div class="button" (click)="restartRadio()">
      <div class="icon">
        <mat-icon [svgIcon]="'restart'"></mat-icon>
      </div>
      <span translate>SIDEBAR.MENU.RESTART_RADIO</span>
    </div>

    <div class="button" (click)="stopRadio()">
      <div class="icon">
        <mat-icon [svgIcon]="'stop'"></mat-icon>
      </div>
      <span translate>SIDEBAR.MENU.STOP_RADIO</span>
    </div>
  </ng-container>
  <ng-template #notStarted>
    <div class="button" (click)="startRadio()">
      <div class="icon start-radio">
        <mat-icon fontSet="fas" fontIcon="fa-play"></mat-icon>
      </div>
      <span translate>SIDEBAR.OFF.START_RADIO</span>
    </div>
  </ng-template>
  <ng-container *ngIf="hasMoreThanOneRadio$ | async">
    <div class="button" (click)="openChangeRadio()">
      <div class="icon">
        <mat-icon [svgIcon]="'radio'"></mat-icon>
      </div>
      <span translate>SIDEBAR.MENU.CHANGE_RADIO</span>
    </div>
  </ng-container>
</div>

<rk-mobile-change-radio
  [show]="showChangeRadio"
  (closeThis)="closeChangeRadio()"
></rk-mobile-change-radio>
