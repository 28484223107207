import { Track } from '@app/core/models/Track';
import { EntryTrack } from '@radioking/daily-generation';
import { SchedulerTrack } from '@radioking/scheduler-core';

const tag = '[audio]';

export class PlayEvent {
  static readonly type = `${tag} play event`;
}

export class Stop {
  static readonly type = `${tag} stop`;
}

export class End {
  static readonly type = `${tag} end`;
}

export class Resume {
  static readonly type = `${tag} resume`;
}

export class PlayAudio {
  static readonly type = `${tag} play audio`;
  constructor(public track: Track | SchedulerTrack | EntryTrack) {}
}

export class StopAudio {
  static readonly type = `${tag} stop audio`;
}

export class PlayError {
  static readonly type = `${tag} play error`;
}

export class PlayRadio {
  static readonly type = `${tag} play radio`;
  constructor(public radioUrl: string) {}
}
