import { Injectable } from '@angular/core';
import { RadioState } from '@app/core/states/radio.state';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import amplitude from 'amplitude-js';
import { Observable } from 'rxjs';
import { catchError, filter, mergeMap, take } from 'rxjs/operators';

import {
  CurrentTrackWidget,
  NextTrackWidget,
  PlayedTracksWidget,
  PlayerWidget,
  RadioPage,
  ShareLink,
  Smartlink,
  TopTracksWidget,
} from '../models/widgets.model';
import { ShareService } from '../share.service';

import {
  CurrentTrackWidgetRequest,
  CurrentTrackWidgetSuccess,
  LinksFailure,
  LinksRequest,
  LinksSuccess,
  NextTrackWidgetRequest,
  NextTrackWidgetSuccess,
  PlayedTracksWidgetRequest,
  PlayedTracksWidgetSuccess,
  PlayerRequest,
  PlayerSuccess,
  RadioPageFailure,
  RadioPageRequest,
  RadioPageSuccess,
  SmartlinkFailure,
  SmartlinkRequest,
  SmartlinkSuccess,
  TopTracksWidgetRequest,
  TopTracksWidgetSuccess,
  UpdateRadioPageFailure,
  UpdateRadioPageRequest,
  UpdateRadioPageSuccess,
  UpdateSmartlinkFailure,
  UpdateSmartlinkRequest,
  UpdateSmartlinkSuccess,
} from './share.actions';

export class ShareStateModel {
  radioPage: RadioPage;
  smartlink: Smartlink;
  player: PlayerWidget;
  links: ShareLink[];
  playedTracks: PlayedTracksWidget;
  topTracks: TopTracksWidget;
  currentTrack: CurrentTrackWidget;
  nextTrack: NextTrackWidget;
}

export const defaultRadioPage: RadioPage = {
  pageUrl: null,
  enabled: false,
  design: {
    display: 'center',
    displayName: false,
    displaySlogan: true,
    displayLogo: true,
    background: {
      type: 'gradient',
      color1: '#ff7f50',
      color2: '#ff3648',
    },
    primaryColor: '#ffffff',
    secondaryColor: '#000000',
  },
  player: {
    displayBuy: false,
    displayListenersCount: false,
    displayLike: false,
    displaySharing: false,
    displayCover: true,
    displayPopUp: false,
    displayShadow: true,
  },
  links: [],
};

const defaultSmartlink: Smartlink = {
  enabled: true,
};

const defaultPlayer: PlayerWidget = {
  mainColor: '#1e7fcb',
  contentColor: '#ffffff',
  displayBuy: true,
  displayCover: true,
  displayLike: true,
  displayListenersCount: false,
  displayPopUp: true,
  displaySharing: false,
  displayShadow: false,
  format: 'v',
  height: 365,
  sizeUnit: 'px',
  width: 275,
};

const defaultPlayedTracks: PlayedTracksWidget = {
  count: 3,
  displayDate: true,
  displayBuy: true,
};

const defaultTopTracks: TopTracksWidget = {
  count: 5,
  displayBuy: true,
};

const defaultCurrentTrack: CurrentTrackWidget = {
  displayBuy: true,
};

const defaultNextTrack: NextTrackWidget = {
  displayBuy: true,
};

@State<ShareStateModel>({
  name: 'share',
  defaults: {
    radioPage: null,
    smartlink: defaultSmartlink,
    links: [],
    player: defaultPlayer,
    playedTracks: defaultPlayedTracks,
    topTracks: defaultTopTracks,
    currentTrack: defaultCurrentTrack,
    nextTrack: defaultNextTrack,
  },
})
@Injectable()
export class ShareState {
  constructor(
    private readonly store: Store,
    private readonly shareService: ShareService,
  ) {}

  @Selector()
  static smartlink(ctx: ShareStateModel): Smartlink {
    return ctx.smartlink;
  }

  @Selector()
  static links(ctx: ShareStateModel): ShareLink[] {
    return ctx.links;
  }

  @Selector()
  static bdStream(ctx: ShareStateModel): ShareLink {
    return ctx.links.find(link => link.type === 'mobile');
  }

  @Selector()
  static hdStream(ctx: ShareStateModel): ShareLink {
    return ctx.links.find(link => link.type === 'hd');
  }

  @Selector()
  static m3uLink(ctx: ShareStateModel): ShareLink {
    return ctx.links.find(link => link.type === 'm3u');
  }

  @Selector()
  static player(ctx: ShareStateModel): PlayerWidget {
    return ctx.player;
  }

  @Selector()
  static radioPage(ctx: ShareStateModel): RadioPage {
    return ctx.radioPage;
  }

  @Selector()
  static playedTracks(ctx: ShareStateModel): PlayedTracksWidget {
    return ctx.playedTracks;
  }

  @Selector()
  static topTracks(ctx: ShareStateModel): TopTracksWidget {
    return ctx.topTracks;
  }

  @Selector()
  static currentTrack(ctx: ShareStateModel): CurrentTrackWidget {
    return ctx.currentTrack;
  }

  @Selector()
  static nextTrack(ctx: ShareStateModel): NextTrackWidget {
    return ctx.nextTrack;
  }

  @Action(SmartlinkRequest)
  getSmartlink(ctx: StateContext<ShareStateModel>) {
    return this.getCurrentRadioId().pipe(
      mergeMap(id => this.shareService.getSmartlink(id)),
      mergeMap(data => ctx.dispatch(new SmartlinkSuccess(data))),
      catchError(err => ctx.dispatch(new SmartlinkFailure(err))),
    );
  }

  @Action([SmartlinkSuccess, UpdateSmartlinkSuccess])
  getSmartlinkSuccess(
    ctx: StateContext<ShareStateModel>,
    { smartlink }: SmartlinkSuccess,
  ) {
    ctx.setState(
      patch({
        smartlink,
      }),
    );
  }

  @Action(UpdateSmartlinkRequest)
  updateSmartlink(
    ctx: StateContext<ShareStateModel>,
    { smartlink }: UpdateSmartlinkRequest,
  ) {
    return this.getCurrentRadioId().pipe(
      mergeMap(id => this.shareService.updateSmartlink(id, smartlink)),
      mergeMap(() => ctx.dispatch(new UpdateSmartlinkSuccess(smartlink))),
      catchError(err => ctx.dispatch(new UpdateSmartlinkFailure(err))),
    );
  }

  @Action(LinksRequest)
  getLinks(ctx: StateContext<ShareStateModel>) {
    return this.getCurrentRadioId().pipe(
      mergeMap(id => this.shareService.getLinks(id)),
      mergeMap(data => ctx.dispatch(new LinksSuccess(data))),
      catchError(err => ctx.dispatch(new LinksFailure(err))),
    );
  }

  @Action(LinksSuccess)
  funcName(ctx: StateContext<ShareStateModel>, { links }: LinksSuccess) {
    ctx.setState(
      patch({
        links,
      }),
    );
  }

  @Action(PlayerRequest)
  updatePlayer(ctx: StateContext<ShareStateModel>, { player, showModal }: PlayerRequest) {
    ctx.setState(
      patch({
        player,
      }),
    );
    if (showModal) {
      ctx.dispatch(new PlayerSuccess(player));
    }
  }

  @Action(PlayerSuccess)
  updatePlayerSuccess() {
    amplitude.getInstance().logEvent('player generated');
  }

  @Action(PlayedTracksWidgetRequest)
  updatePlayedTracks(
    ctx: StateContext<ShareStateModel>,
    { widget, showModal }: PlayedTracksWidgetRequest,
  ) {
    ctx.setState(
      patch({
        playedTracks: widget,
      }),
    );
    if (showModal) {
      ctx.dispatch(new PlayedTracksWidgetSuccess(widget));
    }
  }

  @Action(TopTracksWidgetRequest)
  updateTopTracks(
    ctx: StateContext<ShareStateModel>,
    { widget, showModal }: TopTracksWidgetRequest,
  ) {
    ctx.setState(
      patch({
        topTracks: widget,
      }),
    );
    if (showModal) {
      ctx.dispatch(new TopTracksWidgetSuccess(widget));
    }
  }

  @Action(CurrentTrackWidgetRequest)
  updateCurrentTrack(
    ctx: StateContext<ShareStateModel>,
    { widget, showModal }: CurrentTrackWidgetRequest,
  ) {
    ctx.setState(
      patch({
        currentTrack: widget,
      }),
    );

    if (showModal) {
      ctx.dispatch(new CurrentTrackWidgetSuccess(widget));
    }
  }

  @Action(NextTrackWidgetRequest)
  updateNextTrack(
    ctx: StateContext<ShareStateModel>,
    { widget, showModal }: NextTrackWidgetRequest,
  ) {
    ctx.setState(
      patch({
        nextTrack: widget,
      }),
    );

    if (showModal) {
      ctx.dispatch(new NextTrackWidgetSuccess(widget));
    }
  }

  @Action(RadioPageRequest)
  getRadioPage(ctx: StateContext<ShareStateModel>) {
    return this.getCurrentRadioId().pipe(
      mergeMap(id => this.shareService.getRadioPage(id)),
      mergeMap(data => ctx.dispatch(new RadioPageSuccess(data))),
      catchError(err => ctx.dispatch(new RadioPageFailure(err))),
    );
  }

  @Action([RadioPageSuccess, UpdateRadioPageSuccess])
  getRadioPageSuccess(
    ctx: StateContext<ShareStateModel>,
    { radioPage }: RadioPageSuccess,
  ) {
    ctx.patchState({
      radioPage,
    });
  }

  @Action(RadioPageFailure)
  getRadioPageFailure(ctx: StateContext<ShareStateModel>) {
    ctx.patchState({
      radioPage: defaultRadioPage,
    });
  }

  @Action(UpdateRadioPageRequest)
  updateRadioPage(
    ctx: StateContext<ShareStateModel>,
    { radioPage, file }: UpdateRadioPageRequest,
  ) {
    return this.getCurrentRadioId().pipe(
      mergeMap(id => this.shareService.updateRadioPage(id, radioPage, file)),
      mergeMap(data => ctx.dispatch(new UpdateRadioPageSuccess(data))),
      catchError(err => ctx.dispatch(new UpdateRadioPageFailure(err))),
    );
  }

  getCurrentRadioId(): Observable<number> {
    return this.store.select(RadioState.currentRadioId).pipe(
      filter(data => !!data),
      take(1),
    );
  }
}
